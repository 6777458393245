import { environment } from "conf"
import { SHIPIFY_SUPPLIER_COURIERS } from 'constants/api.constants'
import { request_auth } from "services/RequestWrapper"


/*
    Retrieves all collections.
    Method: [GET]
    URL: /admin/v1/collections/search

    Query params:
    =============
        (*) q - The search query string
        (*) count - The page capacity
        (*) skip - The number of elements skiped
*/
export function getSuppliers() {


    const baseUrl = `${environment.api_url}${SHIPIFY_SUPPLIER_COURIERS}`
    return new Promise((resolve, reject) => {
        const options = {
            method: 'GET',
        }
        request_auth(baseUrl, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data);
                } else {
                    reject(response.error);
                }
            })
            .catch(error => {
                reject(error);
            });
    });
}



export function updateSuppliers(couriers) {


    const baseUrl = `${environment.api_url}${SHIPIFY_SUPPLIER_COURIERS}`
    return new Promise((resolve, reject) => {
        const options = {
            method: 'PUT',
            body: couriers
        }
        request_auth(baseUrl, options)
            .then(response => {
                if (response.status) {
                    resolve(response);
                } else {
                    reject(response.error);
                }
            })
            .catch(error => {
                reject(error);
            });
    });
}