import { useUserDetailsContext } from 'contexts/shipify_context'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { getCurrencySymbol } from 'services/currency'
import './Rate.css'
import { isMobile } from 'react-device-detect'

export default function Rate({
    rateName,
    minDays,
    maxDays,
    price,
    cost
}) {
    const { t } = useTranslation()
    const {
        userDetails
    } = useUserDetailsContext()

    function renderRateTitles() {
        return <>
            <div className={isMobile ? "mobile-rate-name" : "rate-name"}>{rateName}</div>
            <div className={isMobile ? "mobile-rate-eta" : "rate-eta"}>
                {isMobile && <div className='rate-eta-label'>{t('SHIPIFY_RATE_ETA_LABEL')}</div>}
                <div className="rate-eta-range-text">{t('SHIPIFY_RATE_ETA_TEXT', { minDays, maxDays })}</div>
            </div>
        </>
    }

    return (
        <div className={isMobile ? 'mobile-rate-container' : 'rate-container'}>
            {
                isMobile ? <div className='mobile-rate-titles'>
                    {renderRateTitles()}
                </div> : <>
                    {renderRateTitles()}
                </>
            }
            <div className='mobile-rate-prices'>
                <div className='price-wrapper'>
                    <span>Price:</span>
                    <div className="rate-price">{getCurrencySymbol(userDetails?.currency)}{price}</div>
                </div>
                <div className='price-wrapper'>
                    <span>Cost:</span>
                    <div className="rate-price">{getCurrencySymbol(userDetails?.currency)}{cost}</div>
                </div>
            </div>
        </div>
    )
}