import React, { useState } from 'react'
import './EditShippingGroupSection.css'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import AccountSection from 'components/AccountSection/AccountSection'
import EditFieldSectionInput from 'components/EditFieldSectionInput/EditFieldSectionInput'
import MultiSelectStatusButton from 'components/MultiSelectStatusButton/MultiSelectStatusButton'
import AddCollectionsDialog from 'dialogs/AddCollectionsDialog/AddCollectionsDialog'
import ShippingMethodsEdit from 'components/ShippingMethodsEdit/ShippingMethodsEdit'
import CountrySelector from 'components/CountrySelector/CountrySelector'

/**
 * Represents the section for editing/creating a shipping group.
 * @param {string} mode - The mode of this section, it determins whether it is creating or editing an influencer, accepts VIEW_MODES.CREATE or VIEW_MODES.EDIT

 */
export default function EditShippingGroupSection({
    mode,
    groupName,
    setGroupName,
    selectedCountries,
    setSelectedCountries,
    selectedCollections,
    setSelectedCollections,
    rates,
    setRates,
    canCommenceInitialization = false,
    setAreRatesValid
}) {
    const { t } = useTranslation()

    const [multiSelectDialogsState, setMultiSelectDialogsState] = useState({
        productsDialogShown: false,
        collectionsDialogShown: false
    })

    function openAddCollectionsDialog() {
        setMultiSelectDialogsState(prev => ({
            ...prev,
            collectionsDialogShown: true
        }))
    }

    function closeAddCollectionsDialog() {
        setMultiSelectDialogsState(prev => ({
            ...prev,
            collectionsDialogShown: false
        }))
    }

    return (
        <div className={isMobile ? 'mobile-edit-shipping-group-section' : 'edit-shipping-group-section'}>
            <div className={isMobile ? "mobile-edit-shipping-group-section-subsection-row" : "edit-shipping-group-section-subsection-row"}>
                <div className={isMobile ? "mobile-edit-shipping-group-section-subsection-header" : "edit-shipping-group-section-subsection-header"}>
                    <div className="edit-shipping-group-section-subsection-header-title">{t('SHIPIFY_EDIT_SHIPPING_GROUP_SECTION_GROUP_NAME_SUBSECTION_HEADER_TITLE')}</div>
                    <div className={isMobile ? "mobile-edit-shipping-group-section-subsection-header-body" : "edit-shipping-group-section-subsection-header-body"}>{t('SHIPIFY_EDIT_SHIPPING_GROUP_SECTION_GROUP_NAME_SUBSECTION_HEADER_BODY')}</div>
                </div>
                <div className={isMobile ? "mobile-edit-shipping-group-section-subsection-content" : "edit-shipping-group-section-subsection-content"}>
                    <AccountSection hasTitle={false}>
                        <EditFieldSectionInput
                            title={t('SHIPIFY_EDIT_SHIPPING_GROUP_SECTION_GROUP_NAME_SUBSECTION_BODY_GROUP_NAME_FIELD_TITLE')}
                            value={groupName}
                            setValue={setGroupName}
                        />
                    </AccountSection>
                </div>
            </div>

            <div className={isMobile ? "mobile-edit-shipping-group-section-subsection-row" : "edit-shipping-group-section-subsection-row"}>
                <div className={isMobile ? "mobile-edit-shipping-group-section-subsection-header" : "edit-shipping-group-section-subsection-header"}>
                    <div className="edit-shipping-group-section-subsection-header-title">{t('SHIPIFY_EDIT_SHIPPING_GROUP_SECTION_PROPERTIES_SUBSECTION_HEADER_TITLE')}</div>
                    <div className={isMobile ? "mobile-edit-shipping-group-section-subsection-header-body" : "edit-shipping-group-section-subsection-header-body"}>{t('SHIPIFY_EDIT_SHIPPING_GROUP_SECTION_PROPERTIES_SUBSECTION_HEADER_BODY')}</div>
                </div>
                <div className={isMobile ? "mobile-edit-shipping-group-section-subsection-content" : "edit-shipping-group-section-subsection-content"}>
                    <AccountSection hasTitle={false}>
                        <CountrySelector 
                            selectedCountries={selectedCountries}
                            setSelectedCountries={setSelectedCountries}
                        />
                        <MultiSelectStatusButton
                            title={t('SHIPIFY_EDIT_SHIPPING_GROUP_SECTION_PROPERTIES_SUBSECTION_BODY_SELECT_COLLECTIONS_FIELD_TITLE')}
                            status={t('EDIT_LINK_SECTION_ADD_PRODUCTS_BUTTON_QUANTITY', { count: selectedCollections?.length })}
                            onClick={openAddCollectionsDialog}
                        />
                    </AccountSection>
                </div>
            </div>

            <div className={isMobile ? "mobile-edit-shipping-group-section-subsection-row" : "edit-shipping-group-section-subsection-row"}>
                <div className={isMobile ? "mobile-edit-shipping-group-section-subsection-header" : "edit-shipping-group-section-subsection-header"}>
                    <div className="edit-shipping-group-section-subsection-header-title">{t('SHIPIFY_EDIT_SHIPPING_GROUP_SECTION_ADD_RATES_SUBSECTION_HEADER_TITLE')}</div>
                    <div className={isMobile ? "mobile-edit-shipping-group-section-subsection-header-body" : "edit-shipping-group-section-subsection-header-body"}>{t('SHIPIFY_EDIT_SHIPPING_GROUP_SECTION_ADD_RATES_SUBSECTION_HEADER_BODY')}</div>
                    <ul className={isMobile ? "mobile-edit-shipping-group-section-subsection-header-body list" : "edit-shipping-group-section-subsection-header-body list"}>
                        <li><b>{t('SHIPIFY_SHIPPING_METHOD_ROW_EDIT_SHIPPING_NAME_FIELD_TITLE')}</b> - {t('SHIPIFY_EDIT_SHIPPING_GROUP_SECTION_ADD_RATES_SUBSECTION_BODY_LIST_1')}</li>
                        <li><b>{t('SHIPIFY_SHIPPING_METHOD_ROW_EDIT_ETA_FIELD_TITLE')}</b> - {t('SHIPIFY_EDIT_SHIPPING_GROUP_SECTION_ADD_RATES_SUBSECTION_BODY_LIST_2')}</li>
                        <li><b>{t('SHIPIFY_SHIPPING_METHOD_ROW_EDIT_PRICE_FIELD_TITLE')}</b> - {t('SHIPIFY_EDIT_SHIPPING_GROUP_SECTION_ADD_RATES_SUBSECTION_BODY_LIST_3')}</li>
                        <li><b>{t('SHIPIFY_SHIPPING_METHOD_ROW_EDIT_COST_FIELD_TITLE')}</b> - {t('SHIPIFY_EDIT_SHIPPING_GROUP_SECTION_ADD_RATES_SUBSECTION_BODY_LIST_4')}</li>
                    </ul>
                </div>
                <div className={isMobile ? "mobile-edit-shipping-group-section-subsection-content" : "edit-shipping-group-section-subsection-content"}>
                    <AccountSection hasTitle={false}>
                        <div className={isMobile ? "mobile-edit-shipping-group-section-shipping-methods-title" : "edit-shipping-group-section-shipping-methods-title"}>{t('SHIPIFY_EDIT_SHIPPING_GROUP_SECTION_ADD_RATES_SUBSECTION_TITLE')}</div>
                        <ShippingMethodsEdit 
                            rates={rates}
                            setRates={setRates}
                            canCommenceInitialization={canCommenceInitialization}
                            setAreRatesValid={setAreRatesValid}
                        />
                    </AccountSection>
                </div>
            </div>

            {
                multiSelectDialogsState.collectionsDialogShown && <AddCollectionsDialog
                    isDialogOpen={multiSelectDialogsState.collectionsDialogShown}
                    handleDialogClose={closeAddCollectionsDialog}
                    selectedCollections={selectedCollections}
                    setSelectedCollections={setSelectedCollections}
                />
            }
        </div>
    )
}