import React, {useState} from 'react';
import { Box, Divider , Stack, Paper, styled } from '@mui/material';
import './ShipifyPlanDetails.css'
import { useTranslation } from 'react-i18next'
import { useUserDetailsContext } from 'contexts/shipify_context'
import { DEFAULT_CURRENCY_CODE } from 'constants/general.constants'
import { getCurrencySymbol } from 'services/currency'
import { isMobile } from 'react-device-detect';
import { MdInfo } from 'react-icons/md';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  minWidth: isMobile? '100px' : '150px'
  
}));



export default function ShipifyPlanDetails(){
  const { t } = useTranslation()

  const {
    userDetails,
    updateRateInformation,
    activateApplication,
    canActiveApp
} = useUserDetailsContext()

const freeTrialDays = userDetails.freeTrialDaysLeft

  return (
    <Box
      className='Shipify-plan-details'
      sx={{
        display: 'grid',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Stack
        direction="row" useFlexGap flexWrap="wrap"
        divider={isMobile? '' : <Divider orientation="vertical" flexItem />}
        spacing={2}
      >
        <Item>
          <div className='shipify-plan-details'>
            <span>{t('SHIPIFY_PLAN_TITLE')}</span>
            <span>{userDetails.subscriptionPlan}</span>
          </div>
        </Item>
        <Item>
          <div className='shipify-plan-details'>
            <span>{t('SHIPIFY_PLAN_PRICE_TITLE')}</span>
            <span>{getCurrencySymbol(DEFAULT_CURRENCY_CODE)}19.9<span>/ Per Month</span></span>
          </div>
        </Item>
        <Item>
          <div className='shipify-plan-details'>
            <span>{t('SHIPIFY_PLAN_FEE_TITLE')}</span>
            <span>{freeTrialDays > 0 ? '$0.0' : '$0.25'}<span>/ Per Order</span></span>
          </div>
        </Item>
        {freeTrialDays > 0 && 
          <Item>
            <div className='shipify-plan-details'>
              <span>{t('SHIPIFY_PLAN_FREE_TRIAL_TITLE')}</span>
              <span>{Math.ceil(freeTrialDays)}</span>
            </div>
          </Item>
        }
      </Stack>
      {freeTrialDays > 0 && <div className='shipify-free-trial-info'><MdInfo/>{t('SHIPIFY_PLAN_FREE_TRIAL_INFO')}</div> }
    </Box>
  );
};
