import { createGroup, deleteShippingGroup, editGroup, getShippingGroups, getShippingGroupsCount } from "api/shipping_groups/shipping_groups"
import { getStoreCollections, getStoreSupportedCountries } from "api/store/store"
import { activateApp, auth, authVerify, checkHealth, deactivateApp, updateRateInfo, validateBlock, validateCarrierService } from "api/users/users"
import { SHIPIFY_SITE_BASE_URL } from "constants/api.constants"
import { ALL_COUNTRIES, APPLICATION_READY_TIMEOUT_MILISECONDS, DEFAULT_CURRENCY_CODE, PRICING_PLAN_DATA, SHIPIFY_SHOP_URL_KEY, SHIPIFY_TOKEN_KEY, SIDEBAR_MODES, SHIPIFY_CHROME_EXTENSION_KEY} from "constants/general.constants"
import { AUTH_TOKEN_KEY_LOCAL_STORAGE } from "constants/storage.constants"
import useQueryParams from "hooks/useQueryParams"
import { createContext, useContext, useEffect, useState } from "react"
import { LocalStorageGetItem } from "services/LocalStorage"
import { LocalSharedPreferences } from "services/SharedPreferences"
import { extractCollectionData, extractShipifyShippingGroup } from "utils/dataUtils"
import { getSuppliers, updateSuppliers } from "api/supplier_couriers/supplier_couriers"; // Import your supplier functions
import { getPriceRules, updatePriceRules } from "api/pricing_rules/pricing_rules"


const shipifyContext = createContext()

const shippingDataInitialState = {
    currency: DEFAULT_CURRENCY_CODE,
    aliexpressAccount: null,
    isAppActive: false,
    isThirdPartyCcsOn: false,
    rateDescription: '',
    rateName: '',
    totalCountries: 0,
    totalProducts: 0,
    totalMappedProducts: 0,
    totalShippingGroups: 0,
    shopUrl: '',
    username: '',
    shopName: '',
    isAnotherRegularRateOn: false,
    isAnotherCarrierAppRateOn: false,
    isThemeAppBlockAdded: false,
    isThemeAppEmbedBlockAdded: false,
    shippingGroups: [],
    collections: [],
    supportedCountries: [],
    subscriptionPlan: PRICING_PLAN_DATA.FREE.name,
    freeTrialDaysLeft: 0,
    sidbarMode: SIDEBAR_MODES.OPEN,
    sidebarToggle: false,
    suppliers: [], // Add suppliers state
    suppliersFetched: false, // Track if suppliers have been fetched
    useCustomRates: false,
    pricingRulesFetched: false, // Track if suppliers have been fetched
    pricingRules: {},
    appActivated: false,
    chromeExtensionActive: false,
}

export function useUserDetailsContext() {
    return useContext(shipifyContext)
}

/**
 * Providing an userDetails context to all children components.
 */
export default function ShipifyProvider({ children, onCollectionsLoaded, onUserLoaded, onReady }) {
    const query = useQueryParams()
    const [userDetails, setUserDetails] = useState(shippingDataInitialState)

    useEffect(() => {
        setTimeout(() => {
            onReady(true)
        }, APPLICATION_READY_TIMEOUT_MILISECONDS)

        if (query.get(SHIPIFY_TOKEN_KEY) && query.get(SHIPIFY_SHOP_URL_KEY)) {
            login(query.get(SHIPIFY_TOKEN_KEY), query.get(SHIPIFY_SHOP_URL_KEY))
            return
        } else {
            const token = LocalStorageGetItem(SHIPIFY_TOKEN_KEY, null)
            if (token) {
                verify()
                return
            }
        }
        window.location.href = SHIPIFY_SITE_BASE_URL
    }, [])


    function chromeExtensionActivated(){
            const timestamp = localStorage.getItem(SHIPIFY_CHROME_EXTENSION_KEY);
            if (timestamp) {
              const currentTime = Date.now();
              const timeDifference = currentTime - parseInt(timestamp, 10);
              if (timeDifference <= 10000) {
                setUserDetails(prev => ({
                    ...prev,
                    chromeExtensionActive: true
                }))
              } else {
                setUserDetails(prev => ({
                    ...prev,
                    chromeExtensionActive: false
                }))
              }
            }     
    }


    function requestInitialData() {
        fetchStoreCollections()
        fetchSupportedCountries()
    }

    function fetchSupportedCountries() {
        getStoreSupportedCountries()
            .then(response => {
                setUserDetails(prev => ({
                    ...prev,
                    supportedCountries: ALL_COUNTRIES
                }))
            })
            .catch(console.log)
    }

    function setUserProperties(userData) {
        setUserDetails(prev => ({
            ...prev,
            currency: userData?.currency ?? shippingDataInitialState.currency,
            aliexpressAccount: userData?.aliexpress_account ?? shippingDataInitialState.aliexpressAccount,
            isAppActive: userData?.is_app_active ?? shippingDataInitialState.isAppActive,
            isThirdPartyCcsOn: userData?.is_third_party_ccs_active ?? shippingDataInitialState.isThirdPartyCcsOn,
            rateDescription: userData?.rate_description ?? shippingDataInitialState.rateDescription,
            rateName: userData?.rate_name ?? shippingDataInitialState.rateName,
            totalCountries: userData?.total_countries ?? shippingDataInitialState.totalCountries,
            totalProducts: userData?.total_products ?? shippingDataInitialState.totalProducts,
            totalMappedProducts: userData?.total_product_mappings ?? shippingDataInitialState.totalMappedProducts,
            totalShippingGroups: userData?.total_shipping_groups ?? shippingDataInitialState.totalShippingGroups,
            shopUrl: userData?.shop_url ?? shippingDataInitialState.shopUrl,
            username: userData?.username ?? shippingDataInitialState.username,
            shopName: userData?.shop_name ?? shippingDataInitialState.shopName,
            isAnotherRegularRateOn: userData?.is_another_regular_rate_active ?? shippingDataInitialState.isAnotherRegularRateOn,
            isAnotherCarrierAppRateOn: userData?.is_another_carrier_app_rate_active ?? shippingDataInitialState.isAnotherCarrierAppRateOn,
            isThemeAppBlockAdded: userData?.is_theme_app_block_added ?? shippingDataInitialState.isThemeAppBlockAdded,
            isThemeAppEmbedBlockAdded: userData?.is_theme_app_embed_block_added ?? shippingDataInitialState.isThemeAppEmbedBlockAdded,
            subscriptionPlan: userData?.subscription_plan ?? shippingDataInitialState.subscriptionPlan,
            freeTrialDaysLeft: userData?.free_trial_days_left ?? shippingDataInitialState.freeTrialDaysLeft,
            useCustomRates: userData?.is_use_custom_rates ?? shippingDataInitialState.useCustomRates
        }))
    }

    function login(token, shopUrl) {
        auth(token, shopUrl)
            .then((userData) => {
                LocalSharedPreferences.set_key(SHIPIFY_TOKEN_KEY, userData.token)
                setUserProperties(userData)
                requestInitialData();
                onUserLoaded(true)
            }).catch((error) => {
                console.log(error)
                window.location.href = SHIPIFY_SITE_BASE_URL
            })
    }

    function verify() {
        authVerify()
            .then((userData) => {
                LocalSharedPreferences.set_key(SHIPIFY_TOKEN_KEY, userData.token)
                setUserProperties(userData);
                requestInitialData();
                onUserLoaded(true)
            }).catch((error) => {
                window.location.href = SHIPIFY_SITE_BASE_URL
            })
    }

    function logout() {
        LocalSharedPreferences.remove_key(AUTH_TOKEN_KEY_LOCAL_STORAGE)
        window.location.href = SHIPIFY_SITE_BASE_URL
    }

    function validateCCS(onSuccess = () => { }, onFailure = () => { }) {
        validateCarrierService()
            .then(response => {
                setUserDetails(prev => ({
                    ...prev,
                    isThirdPartyCcsOn: response
                }))
                onSuccess(response)
            })
            .catch(error => {
                console.log(error)
                onFailure(error)
            })
    }

    function checkShippingGroups(onSuccess = () => { }, onFailure = () => { }) {
        getShippingGroupsCount()
            .then(response => {
                setUserDetails(prev => ({
                    ...prev,
                    totalShippingGroups: response
                }))
                onSuccess(response)
            })
            .catch(error => {
                console.log(error)
                onFailure(error)
            })
    }

    function validateMethods(onSuccess = () => { }, onFailure = () => { }) {
        validateBlock()
            .then(response => {
                setUserDetails(prev => ({
                    ...prev,
                    isThemeAppBlockAdded: response?.is_theme_app_block_added ?? shippingDataInitialState.isThemeAppBlockAdded,
                    isThemeAppEmbedBlockAdded: response?.is_theme_app_embed_block_added ?? shippingDataInitialState.isThemeAppEmbedBlockAdded
                }))
                onSuccess(response?.is_theme_app_block_added && response?.is_theme_app_embed_block_added)
            })
            .catch(error => {
                console.log(error)
                onFailure(error)
            })
    }

    function updateRateInformation(rateName, rateDescription, useCustomRates, onSuccess = () => { }, onFailure = () => { }) {
        updateRateInfo(rateName, rateDescription, useCustomRates)
            .then(response => {
                setUserDetails(prev => ({
                    ...prev,
                    rateName,
                    rateDescription,
                    useCustomRates
                }))
                onSuccess(response)
            })
            .catch(error => {
                console.log(error)
                onFailure(error)
            })
    }

    function activateApplication(onSuccess = () => { }, onFailure = () => { }) {
        activateApp()
            .then(response => {
                setUserDetails(prev => ({
                    ...prev,
                    isAppActive: response,
                    appActivated: response
                }))
                onSuccess(response)
            })
            .catch(error => {
                console.log(error)
                onFailure(error)
            })
    }



    function deactivateApplication(onSuccess = () => { }, onFailure = () => { }) {
        deactivateApp()
            .then(response => {
                if (response.status) {
                    setUserDetails(prev => ({
                        ...prev,
                        isAppActive: false,
                        appActivated: false
                    }))
                    onSuccess(response)
                }
            })
            .catch(error => {
                console.log(error)
                onFailure(error)
            })
    }





    function hideActivatePop() {
        setUserDetails(prev => ({
            ...prev,
            appActivated: false
        }))
    }


    function showActivatePop() {
        setUserDetails(prev => ({
            ...prev,
            appActivated: true
        }))
    }

    function fetchShippingGroups(onSuccess = () => { }, onFailure = () => { }) {
        getShippingGroups()
            .then(shippingGroups => {
                const extractedShippingGroups = shippingGroups?.groups ? shippingGroups?.groups.map(order => extractShipifyShippingGroup(order)) : []
                for (let shippingGroup of extractedShippingGroups) {
                    shippingGroup.collections = shippingGroup?.collections.filter(collectionId => userDetails?.collections.find(collection => collection?.id === collectionId) !== undefined)
                }
                setUserDetails(prev => ({
                    ...prev,
                    shippingGroups: extractedShippingGroups
                }))
                onSuccess(extractedShippingGroups)
            })
            .catch(error => {
                console.log(error)
                onFailure(error)
            })
    }

    function removeShippingGroup(shippingGroupId, onSuccess = () => { }, onFailure = () => { }) {
        deleteShippingGroup(shippingGroupId)
            .then(response => {
                setUserDetails(prev => ({
                    ...prev,
                    shippingGroups: prev.shippingGroups.filter(shippingGroup => shippingGroup.id !== shippingGroupId),
                    totalShippingGroups: prev.shippingGroups.filter(shippingGroup => shippingGroup.id !== shippingGroupId).length
                }))
                onSuccess()
            })
            .catch(error => {
                console.log(error)
                onFailure(error)
            })
    }

    function canActiveApp() {
        return !userDetails?.isAppActive && (
            userDetails?.isThirdPartyCcsOn && userDetails?.totalShippingGroups > 0 &&
            userDetails?.isThemeAppBlockAdded && userDetails?.isThemeAppEmbedBlockAdded &&
            userDetails?.rateName.length > 0 
        )
    }

    function hasRemainingTasks() {
        return (
            !userDetails?.isAppActive ||
            !userDetails?.isThirdPartyCcsOn ||
            userDetails?.totalShippingGroups === 0 ||
            !userDetails?.isThemeAppBlockAdded ||
            !userDetails?.isThemeAppEmbedBlockAdded ||
            userDetails?.rateName === ''
        )
    }

    function checkHealthProperties(onSuccess = () => { }, onFailure = () => { }) {
        checkHealth()
            .then(response => {
                setUserDetails(prev => ({
                    ...prev,
                    isAnotherCarrierAppRateOn: response?.data?.is_another_carrier_app_rate_active ?? false,
                    isAnotherRegularRateOn: response?.data?.is_another_regular_rate_active ?? false,
                    isAppActive: response?.data?.is_app_active ?? false,
                    isThemeAppBlockAdded: response?.data?.is_theme_app_block_added ?? false,
                    isThemeAppEmbedBlockAdded: response?.data?.is_theme_app_embed_block_added ?? false,
                    isThirdPartyCcsOn: response?.data?.is_third_party_ccs_active ?? false,
                    // rateName: response?.rate_name ?? '',
                    // rateDescription: response?.rate_description ?? '',
                }))
                onSuccess(response)
            })
            .catch(error => {
                console.log(error)
                onFailure(error)
            })
    }

    function createShippingGroup(groupName, selectedCountries, collectionIds, groupRates, onSuccess = () => { }, onFailure = () => { }) {
        createGroup(groupName, selectedCountries, collectionIds, groupRates)
            .then(() => {
                setUserDetails(prev => ({
                    ...prev,
                    shippingGroups: [...prev.shippingGroups].unshift({
                        id: '',
                        createdAt: new Date().getTime(),
                        groupName: groupName,
                        countries: selectedCountries,
                        collections: collectionIds,
                        rates: groupRates
                    }),
                }))
                checkShippingGroups()
                onSuccess()
            }).catch((error) => {
                onFailure(error)
            })
    }

    function editShippingGroup(shippingGroupId, groupName, selectedCountries, collectionIds, groupRates, onSuccess = () => { }, onFailure = () => { }) {
        editGroup(shippingGroupId, groupName, selectedCountries, collectionIds, groupRates)
            .then(() => {
                setUserDetails(prev => {
                    const newShippingGroupsArray = [...prev.shippingGroups]
                    const shippingGroupToUpdate = newShippingGroupsArray.find(shippingGroup => shippingGroup?.id === shippingGroupId)
                    if (!shippingGroupToUpdate) {
                        return prev
                    }

                    shippingGroupToUpdate.groupName = groupName
                    shippingGroupToUpdate.countries = selectedCountries
                    shippingGroupToUpdate.collections = collectionIds
                    shippingGroupToUpdate.rates = groupRates

                    return {
                        ...prev,
                        shippingGroups: newShippingGroupsArray
                    }
                })
                onSuccess()
            }).catch((error) => {
                onFailure()
            })
    }

    function fetchStoreCollections(onSuccess = () => { }, onFailure = () => { }) {
        getStoreCollections()
            .then(response => {
                setUserDetails(prev => ({
                    ...prev,
                    collections: response?.map(collection => extractCollectionData(collection))
                }))
                onSuccess()
            })
            .catch(error => {
                onFailure(error)
            })
            .finally(() => {
                onCollectionsLoaded(true)
            })
    }

    function findCollectionInContext(collectionId) {
        return userDetails?.collections.find(collection => collection?.id === collectionId)
    }

    function isFreeUser() {
        return userDetails?.subscriptionPlan === PRICING_PLAN_DATA.FREE.name
    }

    function hasSupportedCountries() {
        return userDetails?.supportedCountries.length > 0
    }

    function setSidebarMode(mode) {
        setUserDetails(prev => ({
            ...prev,
            sidebarMode: mode
        }))
    }

    function setSidebarToggle(val) {
        setUserDetails(prev => ({
            ...prev,
            sidebarToggle: val
        }))
    }


    function fetchSuppliers() {
        if (!userDetails.suppliersFetched) {
            getSuppliers()
                .then(response => {
                    const couriersData = response.suppliers[0].couriers;
                    const processedCouriers = Object.entries(couriersData).map(([key, value], index) => ({
                        id: index + 1,
                        courierId: key,
                        original_name: value.original_name,
                        display_name: value.display_name,
                        is_active: value.is_active
                    }));
                    setUserDetails(prev => ({
                        ...prev,
                        suppliers: processedCouriers,
                        suppliersFetched: true
                    }));
                })
                .catch(console.log);
        }
    }


    function editSupplier(updatedSuppliers) {
        const payload = {
            supplier_id: "AliExpress",
            couriers: updatedSuppliers.map(supplier => ({
                courier_id: supplier.courierId,
                original_name: supplier.original_name,
                display_name: supplier.display_name,
                is_active: supplier.is_active
            }))
        };
        return updateSuppliers(payload)
            .then(response => {
                setUserDetails(prev => ({
                    ...prev,
                    suppliers: prev.suppliers.map(supplier =>
                        updatedSuppliers.find(updated => updated.courierId === supplier.courierId) || supplier
                    )
                }));
                return response;
            });
    }


    function editAccountSupplier(account) {
        setUserDetails(prev => ({
            ...prev,
            aliexpressAccount: account
        }));
    }
    

    function fetchPricingRules() {
        if (!userDetails.pricingRulesFetched) {
            getPriceRules()
                .then(response => {
                    setUserDetails(prev => ({
                        ...prev,
                        pricingRules: response,
                        pricingRulesFetched: true
                    }));
                })
                .catch(console.log);
        }
    }


    function updatePricingRules(type, operator, intValue) {

        return updatePriceRules('BASIC', operator, intValue)
            .then(response => {
                setUserDetails(prev => ({
                    ...prev,
                    pricingRules: {
                        rule:{
                        operation: operator,
                        value: intValue
                        },
                        type: type
                    }
                }))
                return response;
                
            });
    }



    return (
        <shipifyContext.Provider value={{
            userDetails,
            setUserProperties,
            logout,
            validateCCS,
            checkShippingGroups,
            validateMethods,
            updateRateInformation,
            activateApplication,
            deactivateApplication,
            fetchShippingGroups,
            removeShippingGroup,
            canActiveApp,
            checkHealthProperties,
            createShippingGroup,
            editShippingGroup,
            fetchStoreCollections,
            findCollectionInContext,
            isFreeUser,
            hasRemainingTasks,
            hasSupportedCountries,
            setSidebarMode,
            setSidebarToggle,
            fetchSuppliers,
            editSupplier,
            editAccountSupplier,
            fetchPricingRules,
            updatePricingRules,
            hideActivatePop,
            showActivatePop,
            chromeExtensionActivated


        }}>
            {children}
        </shipifyContext.Provider>
    );
}