import LoaderPage from 'pages/LoaderPage/LoaderPage'
import React from 'react'
import { isMobile } from 'react-device-detect'

/**
 * Represents a loader of the dashboard pages.
 * It covers the whole screen except the dashboard topbar.
 */
export default function DashboardLoader() {
    return (
        <LoaderPage
            styles={{ 
                backgroundColor: '#ffffffaa', 
                height: `calc(100dvh - var(${isMobile ? '--mobile-dashboard-topbar-height' : '--dashboard-topbar-height'}))`, 
                marginTop: `var(${isMobile ? '--mobile-dashboard-topbar-height' : '--dashboard-topbar-height'})` 
            }} 
            marginBottom={0} 
            isFullScreen={true} 
        />
    )
}