import React, { useState } from 'react'
import './TodoDisplayMethodTask.css'
import { useUserDetailsContext } from 'contexts/shipify_context'
import { useTranslation } from 'react-i18next'
import { VscDebugRestart } from "react-icons/vsc"
import LoaderButton from 'components/LoaderButton/LoaderButton'
import { SHIPIFY_SITE_BASE_URL } from 'constants/api.constants'
import { isMobile } from 'react-device-detect'
import { ALERT_MESSAGE_TYPES, INPUT_VALIDATION_INDICATIONS, SHIPIFY_ADD_APP_EMBED, SHIPIFY_ADD_APP_BLOCK } from 'constants/general.constants'
import AlertMessage from 'components/AlertMessage/AlertMessage'
import { environment } from 'conf'

export default function TodoDisplayMethodTask({
    learnMoreLink = 'https://shopify.com'
}) {
    const { t } = useTranslation()
    const {
        userDetails,
        validateMethods
    } = useUserDetailsContext()

    const [isValidatingMethods, setIsValidatingMethods] = useState(false)
    const [validationStatus, setValidationStatus] = useState(INPUT_VALIDATION_INDICATIONS.NO_INDICATION)

    function validateShippingMethods() {
        setIsValidatingMethods(true)
        setValidationStatus(INPUT_VALIDATION_INDICATIONS.NO_INDICATION)
        validateMethods(response => {
            setIsValidatingMethods(false)
            setValidationStatus(response ? INPUT_VALIDATION_INDICATIONS.VALID : INPUT_VALIDATION_INDICATIONS.INVALID)
        }, error => {
            setIsValidatingMethods(false)
            setValidationStatus(INPUT_VALIDATION_INDICATIONS.INVALID)
        })
    }

    return (
        <div className={isMobile ? 'mobile-todo-list-task-details' : 'todo-list-task-details'}>
            <div className="todo-add-shipping-groups-task-details">
                <div className="todo-list-task-details-text">{t('SHIPIFY_TODO_TASK_DISPLAY_METHODS_MESSAGE')}</div>
                <div className='todo-list-task-details-text'>
                    <a href={learnMoreLink} target="_blank">{t('SHIPIFY_LEARN_MORE_TEXT')}</a>
                </div>
            </div>
            { (!userDetails?.isThemeAppBlockAdded || !userDetails?.isThemeAppEmbedBlockAdded) &&
                <div className="todo-list-buttons-container">
                        <a href={SHIPIFY_ADD_APP_EMBED(userDetails?.shopUrl, environment.ShopifyAppEmbedId)} target='_blank'>
                            <LoaderButton
                                className='todo-list-task-content-button'
                                buttonText={t('SHIPIFY_TODO_TASK_DISPLAY_METHODS_CUSTOMIZE_THEME_BUTTON_TEXT')}

                            />
                        </a>
                        <a href={SHIPIFY_ADD_APP_BLOCK(userDetails?.shopUrl, environment.ShopifyAppEmbedId, environment.appHandle)} target='_blank'>
                            <LoaderButton
                                className='todo-list-task-content-button'
                                buttonText={t('SHIPIFY_TODO_TASK_DISPLAY_METHODS_APP_BLOCK_BUTTON_TEXT')}
                            />
                        </a>
                    {
                        (!userDetails?.isThemeAppBlockAdded || !userDetails?.isThemeAppEmbedBlockAdded) && <LoaderButton
                            className='todo-list-task-content-button'
                            buttonText={<div className='todo-list-task-content-button-with-image'>
                                <VscDebugRestart className='todo-list-task-content-button-image' />
                                <span>{t('SHIPIFY_TODO_TASK_DISPLAY_METHODS_CHECK_BUTTON_TEXT')}</span>
                            </div>}
                            isLoading={isValidatingMethods}
                            loaderCircleColor='white'
                            loaderArchColor='transparent'
                            onClick={validateShippingMethods}
                        />
                    }
                </div>
            }
            {
                validationStatus !== INPUT_VALIDATION_INDICATIONS.NO_INDICATION && (
                    validationStatus === INPUT_VALIDATION_INDICATIONS.INVALID ?
                        <AlertMessage message={userDetails?.isThemeAppBlockAdded && !userDetails?.isThemeAppEmbedBlockAdded? 
                            t('SHIPIFY_NO_APP_EMBED_ERROR') : userDetails?.isThemeAppEmbedBlockAdded && !userDetails?.isThemeAppBlockAdded ? 
                            t('SHIPIFY_NO_APP_BLOCK_ERROR') : t('SHIPIFY_NO_APP_EXTENSIONS_ERROR')} type={ALERT_MESSAGE_TYPES.ERROR} 
                        /> 
                        :
                        '')
            }

            {(userDetails?.isThemeAppBlockAdded && userDetails?.isThemeAppEmbedBlockAdded) && <AlertMessage message={t('SHIPIFY_TODO_TASK_DISPLAY_METHODS_CHECK_SUCCESS_TEXT')} type={ALERT_MESSAGE_TYPES.SUCCESS} />}
        </div>
    )
}