import React, { useState } from 'react'
import './TodoAddShippingGroupTask.css'
import { useUserDetailsContext } from 'contexts/shipify_context'
import { useTranslation } from 'react-i18next'
import { VscDebugRestart } from "react-icons/vsc"
import LoaderButton from 'components/LoaderButton/LoaderButton'
import { SHIPIFY_CREATE_SHIPPING_GROUP_PAGE_URL } from 'constants/routes.constants'
import { isMobile } from 'react-device-detect'
import AlertMessage from 'components/AlertMessage/AlertMessage'
import { ALERT_MESSAGE_TYPES, INPUT_VALIDATION_INDICATIONS } from 'constants/general.constants'
import { useHistoryContext } from 'contexts/History'


export default function TodoAddShippingGroupTask() {
    const { t } = useTranslation()
    const {
        userDetails,
        checkShippingGroups
    } = useUserDetailsContext()

    const history = useHistoryContext()

    const [isCheckingShippingGroups, setIsCheckingShippingGroups] = useState(false)
    const [validationStatus, setValidationStatus] = useState(INPUT_VALIDATION_INDICATIONS.NO_INDICATION)

    function validateCarrierCalculatedService() {
        setIsCheckingShippingGroups(true)
        setValidationStatus(INPUT_VALIDATION_INDICATIONS.NO_INDICATION)
        checkShippingGroups(response => {
            setIsCheckingShippingGroups(false)
            setValidationStatus(response > 0 ? INPUT_VALIDATION_INDICATIONS.VALID : INPUT_VALIDATION_INDICATIONS.INVALID)
        }, error => {
            setIsCheckingShippingGroups(false)
            setValidationStatus(INPUT_VALIDATION_INDICATIONS.INVALID)
        })
    }

    function goToNewGroup() {
        if (window.location.pathname !== SHIPIFY_CREATE_SHIPPING_GROUP_PAGE_URL) {
            history.push(SHIPIFY_CREATE_SHIPPING_GROUP_PAGE_URL)
        }
    }

    return (
        <div className={isMobile ? 'mobile-todo-list-task-details' : 'todo-list-task-details'}>
            <div className="todo-add-shipping-groups-task-details">
                <div className="todo-list-task-details-text">{t('SHIPIFY_TODO_TASK_ADD_SHIPPING_GROUPS_MESSAGE')}</div>
            </div>
            {userDetails?.totalShippingGroups === 0 &&
                <div className="todo-list-buttons-container">
                    <a onClick={goToNewGroup}>
                        <LoaderButton
                            className='todo-list-task-content-button'
                            buttonText={t('SHIPIFY_TODO_TASK_ADD_SHIPPING_GROUPS_ADD_SHIPPING_GROUP_BUTTON_TEXT')}
                        />
                    </a>
                    {
                        userDetails?.totalShippingGroups <= 0 && <LoaderButton
                            className='todo-list-task-content-button'
                            buttonText={<div className='todo-list-task-content-button-with-image'>
                                <VscDebugRestart className='todo-list-task-content-button-image' />
                                <span>{t('SHIPIFY_TODO_TASK_DISPLAY_METHODS_CHECK_BUTTON_TEXT')}</span>
                            </div>}
                            isLoading={isCheckingShippingGroups}
                            loaderCircleColor='white'
                            loaderArchColor='transparent'
                            onClick={validateCarrierCalculatedService}
                        />
                    }
                </div>
            }
            {
                validationStatus !== INPUT_VALIDATION_INDICATIONS.NO_INDICATION && (
                    validationStatus === INPUT_VALIDATION_INDICATIONS.INVALID &&
                        <AlertMessage message={t('SHIPIFY_TODO_TASK_DISPLAY_METHODS_CHECK_FAILURE_TEXT')} type={ALERT_MESSAGE_TYPES.ERROR} /> )
            }



            {userDetails?.totalShippingGroups > 0 &&
                <AlertMessage message={t('SHIPIFY_TODO_TASK_DISPLAY_METHODS_CHECK_SUCCESS_TEXT')} type={ALERT_MESSAGE_TYPES.SUCCESS} />
            }
        </div>
    )
}