import { environment } from "conf"
import { SHIPIFY_SHIPPING_RATES_URL, SEARCH_ROUTE } from 'constants/api.constants'
import { request_auth } from "services/RequestWrapper"


/*
    Retrieves all collections.
    Method: [GET]
    URL: /admin/v1/collections/search

    Query params:
    =============
        (*) q - The search query string
        (*) count - The page capacity
        (*) skip - The number of elements skiped
*/
export function getShippingRates(shopUrl, productId, variantId, countryCode, quantity) {


    const baseUrl = `${environment.api_url}${SHIPIFY_SHIPPING_RATES_URL}?country_code=${countryCode}&products=${productId}:${variantId}:${quantity}`
    return new Promise((resolve, reject) => {
        const options = {
            method: 'GET',
            headers: {
                "shop-url": shopUrl,
              },
        }
        request_auth(baseUrl, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data);
                } else {
                    reject(response.error);
                }
            })
            .catch(error => {
                reject(error);
            });
    });
}