import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Grid, Typography, Tooltip, Select, MenuItem, Skeleton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { useUserDetailsContext } from 'contexts/shipify_context';
import { useTranslation } from 'react-i18next';
import { getCurrencySymbol } from 'services/currency';
import './ShipifyStatisticsWithDateRange.css';
import { MdInfo, MdOutlineInfo } from 'react-icons/md';
import Loader from 'components/Loader/Loader';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  legend: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  legendItem: {
    display: 'flex',
    alignItems: 'center',
    margin: '5px 8px',
  },
  legendColor: {
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    display: 'inline-block',
    marginRight: '8px',
    minWidth: '10px',
    minHeight: '10px',
  },
  sumValue: {
    fontSize: '20px',
    fontWeight: 'bold',
  },
  percentChange: {
    fontSize: '0.75rem',
    color: '#17c653',
    marginLeft: '8px',
    backgroundColor: '#dfffea',
    padding: '2px 5px',
    fontWeight: '500',
    borderRadius: '5px',
  },
  percentChangeMinus: {
    backgroundColor: '#FFEEF3',
    color: '#F8285A',
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'space-around',
    marginBottom: '10px',
  },
  formControl: {
    minWidth: 120,
  },
}));

export default function ShipifyProfitStatistics({ data, title, onRangeChange, tooltipText }) {
  const classes = useStyles();
  const [selectedRange, setSelectedRange] = useState('last30Days');
  const [chartData, setChartData] = useState({
    labels: ['Revenue', 'Cost', 'Profit'],
    datasets: [
      {
        data: [0, 0, 0],
        backgroundColor: ['#36A2EB', '#FF6384', '#4BC0C0'],
      },
    ],
  });
  const [sumValue, setSumValue] = useState(0);
  const [percentChange, setPercentChange] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();
  const { userDetails } = useUserDetailsContext();

  const prev_total_profit = data?.prev_analytics?.totals?.total_profit ?? 0;

  const processData = useCallback(() => {
    if (!data || !data.curr_analytics.totals) {
      setIsLoading(true);
      setChartData({
        labels: ['Revenue', 'Cost', 'Profit'],
        datasets: [
          {
            data: [0, 0, 0],
            backgroundColor: ['#36A2EB', '#FF6384', '#4BC0C0'],
          },
        ],
      });
      return;
    }

    const { total_revenue, total_cost, total_profit, total_fee } = data?.curr_analytics?.totals;

    setChartData({
      labels: ['Revenue', 'Cost','Profit'],
      datasets: [
        {
          data: [total_revenue / 100, (total_cost + total_fee) / 100, total_profit / 100],
          backgroundColor: ['#36A2EB', '#FF6384', '#4BC0C0', ],
        },
      ],
    });
    
    setSumValue((total_profit / 100).toFixed(2));
    setPercentChange(100*(total_profit-prev_total_profit)/prev_total_profit);
    setIsLoading(false)
  }, [data]);

  useEffect(() => {
    processData();
  }, [processData]);

  const handleRangeChange = (event) => {
    setIsLoading(true);
    setSelectedRange(event.target.value);
    if (onRangeChange) {
      onRangeChange(event.target.value);
    }
  };

  const memoizedChartData = useMemo(() => chartData, [chartData]);


  const renderSkeleton = () => (
    <>
      <Grid item container xs={12} justifyContent="space-around" mt={1} alignItems={'center'} className={'shipify-mini-stats-container'}>
        <Grid item xs={12} pb={'10px'}>
          <Skeleton variant="text" sx={{ fontSize: '1.5rem', width: "50%" }} animation="wave" />
          <Skeleton variant="text" sx={{ fontSize: '1.5rem' }} animation="wave" />
          <Skeleton variant="text" sx={{ fontSize: '1.5rem' }} animation="wave" />
          <Skeleton variant="text" sx={{ fontSize: '1.5rem' }} animation="wave" />
        </Grid>
      </Grid>
    </>
  );


  return (
    <>
      <div className="shipify-mini-stats-header-container">
        <div className="shipify-data-section-title">
          <span>{title}</span>
          <Tooltip title={<div><p>{tooltipText}</p></div>} arrow placement="bottom"
            slotProps={{
            popper: {
                modifiers: [
                {
                    name: 'offset',
                    options: {
                    offset: [0, -5],
                    },
                },
                ],
            },
            }} >
                <span><MdOutlineInfo/></span>
            </Tooltip>
        </div>
          <Select
            value={selectedRange}
            onChange={handleRangeChange}
            size='small'
          >
            <MenuItem className='shipify-range-item' dense value="lastWeek">Last 7 days</MenuItem>
            <MenuItem className='shipify-range-item' dense value="lasTwoWeeks">Last 14 days</MenuItem>
            <MenuItem className='shipify-range-item' dense value="last30Days">Last 30 days</MenuItem>
            <MenuItem className='shipify-range-item' dense value="lastMonth">Last month</MenuItem>
            <MenuItem className='shipify-range-item' dense value="allTime">All time</MenuItem>
          </Select>
        </div>
      <Grid item container xs={12} justifyContent="space-around" className="shipify-mini-stats-container">
      {isLoading || !data || !data.curr_analytics.totals ? 
        renderSkeleton() 
        :
        <>
          <Grid item xs={12}>
            <Typography
              className={classes.sumValue}
              display={'flex'}
              fontSize={'21px'}
              justifyContent={'flex-start'}
              mb={1}
              alignItems={'center'}
              fontWeight={500}
            >
              {getCurrencySymbol(userDetails?.currency)}{sumValue} 
              {prev_total_profit > 0 && <span className={percentChange >= 0 ? classes.percentChange : `${classes.percentChange} ${classes.percentChangeMinus}`}>{percentChange >= 0 ? '↑' : '↓'} {percentChange.toFixed(2)}%</span> }
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <div className={`${classes.legend} shipify-stats-label-wrapper`}>
              {memoizedChartData.labels.map((label, index) => (
                <div key={index} className={classes.legendItem}>
                  <div className="stats-label">
                    <span
                      className={classes.legendColor}
                      style={{ backgroundColor: memoizedChartData.datasets[0].backgroundColor[index] }}
                    ></span>
                    <Tooltip
                      title={label === 'Revenue' ? t('SHIPIFY_STATS_REVENUE_TOOLTIP') : label === 'Cost' ? t('SHIPIFY_STATS_COST_TOOLTIP') : t('SHIPIFY_STATS_PROFIT_TOOLTIP')}
                      arrow
                      slotProps={{
                        popper: {
                          modifiers: [
                            {
                              name: 'offset',
                              options: {
                                offset: [0, -10],
                              },
                            },
                          ],
                        },
                      }}
                    >
                      <Typography variant="body2">{label}</Typography>
                    </Tooltip>
                  </div>
                  <Typography variant="body2">
                    {`${getCurrencySymbol(userDetails?.currency)}${memoizedChartData.datasets[0].data[index].toFixed(2)}`}
                  </Typography>
                </div>
              ))}
            </div>
            </Grid>
          </>
          }
      </Grid>
    </>
  );
}

ShipifyProfitStatistics.propTypes = {
  data: PropTypes.shape({
    totals: PropTypes.shape({
      order_quantity: PropTypes.number,
      total_revenue: PropTypes.number,
      total_cost: PropTypes.number,
      total_fee: PropTypes.number,
      total_profit: PropTypes.number,
      total_change: PropTypes.number,
    }),
  }),
  title: PropTypes.string,
  onRangeChange: PropTypes.func,
};
