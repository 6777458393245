import { SHIPIFY_COUNT_API_ROUTE } from "constants/api.constants";
import { request_auth } from "services/RequestWrapper";
import { environment } from 'conf'
const SHIPPING_GROUPS_API_BASE_ROUTE = '/shipping_groups'

/*
    Gets the number of shipping groups available.

    Method: [GET]
    URL: /shipping_groups

*/
export function getShippingGroups() {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'GET'
        }
        request_auth(environment.api_url + SHIPPING_GROUPS_API_BASE_ROUTE, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data);
                } else {
                    reject(response.error);
                }
            })
            .catch(error => {
                reject(error);
            });
    });
}

function extractShippingGroupBody(title, countryCodes, collectionIds, rates) {
    return {
        title,
        country_codes: countryCodes,
        collection_ids: collectionIds,
        rates: rates.map(rate => ({
            display_name: rate?.rateName,
            min_delivery_days: rate?.minDays,
            max_delivery_days: rate?.maxDays,
            price: rate?.price,
            original_price: rate?.cost

        }))
    }
}

/*
    creates a new shipping group.

    Method: [POST]
    URL: /shipping_groups

*/
export function createGroup(title, countryCodes, collectionIds, rates) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST',
            body: extractShippingGroupBody(title, countryCodes, collectionIds, rates)
        }
        request_auth(environment.api_url + SHIPPING_GROUPS_API_BASE_ROUTE, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data);
                } else {
                    reject(response.error);
                }
            })
            .catch(error => {
                reject(error);
            });
    });
}

/*
    edits a specific shipping group.

    Method: [PUT]
    URL: /shipping_groups

*/
export function editGroup(shippingGroupId, title, countryCodes, collectionIds, rates) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'PUT',
            body: extractShippingGroupBody(title, countryCodes, collectionIds, rates)
        }
        request_auth(environment.api_url + SHIPPING_GROUPS_API_BASE_ROUTE + `/${shippingGroupId}`, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data);
                } else {
                    reject(response.error);
                }
            })
            .catch(error => {
                reject(error);
            });
    });
}

/*
    Deletes a specific shipping group.

    Path params:
    =============
    (*) group_id - The id of the shipping group to delete

    Method: [DELETE]
    URL: /shipping_groups/:group_id

*/
export function deleteShippingGroup(shippingGroupId) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'DELETE'
        }
        request_auth(environment.api_url + SHIPPING_GROUPS_API_BASE_ROUTE + `/${shippingGroupId}`, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data);
                } else {
                    reject(response.error);
                }
            })
            .catch(error => {
                reject(error);
            });
    });
}

/*
    Gets the number of shipping groups available.

    Method: [GET]
    URL: /shipping_groups/count

*/
export function getShippingGroupsCount() {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'GET'
        }
        request_auth(environment.api_url + SHIPPING_GROUPS_API_BASE_ROUTE + SHIPIFY_COUNT_API_ROUTE, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data);
                } else {
                    reject(response.error);
                }
            })
            .catch(error => {
                reject(error);
            });
    });
}