import React, { useState } from 'react'
import './ShippingGroupsTableRowView.css'
import { isMobile } from 'react-device-detect'
import { Table, TableBody, TableCell, TableHead, TableRow, tableCellClasses } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { getFormattedDateTime } from 'utils/dateUtils'
import { ORDERS_TABLE_ROW_HEIGHT, SHIPPING_GROUPS_TABLE_RATE_HEADER_HEIGHT_PX, SHIPPING_GROUPS_TABLE_RATE_ROW_GAP_PX, SHIPPING_GROUPS_TABLE_RATE_ROW_HEIGHT_PX } from 'constants/general.constants'
import { styled } from '@mui/system'
import { useHistoryContext } from 'contexts/History'
import { IoIosArrowDown } from 'react-icons/io'
import CollapsingElement from 'components/CollapsingElement/CollapsingElement'
import { RiDeleteBin5Fill } from 'react-icons/ri'
import { MdModeEdit } from 'react-icons/md'
import Rate from 'components/Rate/Rate'
import ColorfulTag from 'components/ColorfulTag/ColorfulTag'
import { getCurrencySymbol } from 'services/currency'
import { useUserDetailsContext } from 'contexts/shipify_context'
import { SHIPIFY_SHIPPING_GROUPS_EDIT_ROUTE, SHIPIFY_SHIPPING_GROUPS_ROUTE } from 'constants/routes.constants'

/**
 * Represents a row view for the orders table
 * @param {object} rowData - The data object of a specific row
 */
export default function ShippingGroupsTableRowView({
    rowData,
    columnCount,
    onDeleteItem = () => { }
}) {
    const { t } = useTranslation()
    const history = useHistoryContext()
    const {
        userDetails
    } = useUserDetailsContext()

    const [isExpanded, setIsExpanded] = useState(false)

    const rateRowHeight = isMobile ? ((SHIPPING_GROUPS_TABLE_RATE_ROW_HEIGHT_PX * rowData?.ratesCount) + (SHIPPING_GROUPS_TABLE_RATE_ROW_GAP_PX * (rowData?.ratesCount - 1))) : SHIPPING_GROUPS_TABLE_RATE_HEADER_HEIGHT_PX + (SHIPPING_GROUPS_TABLE_RATE_ROW_HEIGHT_PX * rowData?.ratesCount) + (SHIPPING_GROUPS_TABLE_RATE_ROW_GAP_PX * (rowData?.ratesCount - 1))

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        // hide all borders
        '&:not(:last-child) td, & th': {
            border: 0,
        }
    }))

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.body}`]: {
            textAlign: 'center',
            padding: '0',
        },
    }))

    const RateTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.body}`]: {
            borderBottomColor: '#9a9a9a'
        },
        [`&.${tableCellClasses.head}`]: {
            borderBottomColor: '#9a9a9a',
            fontWeight: 'bolder'
        },
    }))

    function onExpandOrder() {
        setIsExpanded(prev => !prev)
    }

    function goToEditShippingGroup(shippingGroupId) {
        history.push(`${SHIPIFY_SHIPPING_GROUPS_ROUTE}/${shippingGroupId}${SHIPIFY_SHIPPING_GROUPS_EDIT_ROUTE}`)
    }

    function renderRowContent() {
        return isMobile ? <StyledTableCell padding="normal" align={'left'}>
            <div className='mobile-shipping-groups-table-row-view'>
                <div className="mobile-shipping-groups-table-row-view-title-row">
                    <IoIosArrowDown className={`mobile-store-orders-table-row-view-expand-button-image ${isExpanded ? 'expanded' : ''}`} onClick={onExpandOrder} />
                    <div className="mobile-shipping-groups-table-row-view-group-name">{rowData?.groupName}</div>
                    <div className="mobile-shipping-groups-table-row-view-rates-count">{t('MOBILE_SHIPIFY_SHIPPING_GROUPS_TABLE_ROW_RATES_TEXT_other', { count: rowData?.ratesCount })}</div>
                    <div className="mobile-shipping-groups-table-row-view-actions">
                        <MdModeEdit className="shipping-groups-table-row-view-action-button-image" onClick={() => goToEditShippingGroup(rowData?.id)} />
                        <RiDeleteBin5Fill className="shipping-groups-table-row-view-action-button-image shipping-groups-table-row-view-delete-button-image" onClick={() => onDeleteItem(rowData?.id)} />
                    </div>
                </div>
                <div className="mobile-shipping-groups-table-row-view-count-row">
                    <ColorfulTag
                        text={`${rowData?.countriesCount} countries`}
                        backgroundColor='rgb(144 238 144 / 55%)'
                    />
                    <ColorfulTag
                        text={`${rowData?.collectionsCount} collections`}
                        backgroundColor='lightgrey'
                    />
                </div>

            </div>
        </StyledTableCell> : <>
            <StyledTableCell padding="normal" align={'left'}>
                <IoIosArrowDown className={`store-orders-table-row-view-expand-button-image ${isExpanded ? 'expanded' : ''}`} onClick={onExpandOrder} />
            </StyledTableCell>
            <StyledTableCell padding="normal" align={'left'}>
                <div className="store-orders-table-row-view shipping-groups-table-row-view-group-name">
                    {rowData?.groupName}
                </div>
            </StyledTableCell>
            <StyledTableCell padding="normal" align={'left'}>
                <div className="shipping-groups-table-row-view-date">{getFormattedDateTime(new Date(rowData?.createdAt))}</div>
            </StyledTableCell>
            <StyledTableCell padding="normal" align='right'>
                <div className='store-orders-table-row-view store-orders-table-row-view-items'>{rowData?.countriesCount}</div>
            </StyledTableCell>
            <StyledTableCell padding="normal" align='right'>
                <div className='store-orders-table-row-view store-orders-table-row-view-total'>{rowData?.collectionsCount}</div>
            </StyledTableCell>
            <StyledTableCell padding="normal" align={'left'}>
                <div className="store-orders-table-row-view shipping-groups-table-row-view-rates-count">{rowData?.ratesCount}</div>
            </StyledTableCell>
            <StyledTableCell padding="normal" align='right'>
                <div className="store-orders-table-row-view shipping-groups-table-row-view-actions">
                    <div className="shipping-groups-table-row-view-action-button shipping-groups-table-row-view-action-edit-button" onClick={() => goToEditShippingGroup(rowData?.id)}>
                        <MdModeEdit className="shipping-groups-table-row-view-action-button-image" />
                        <div className="shipping-groups-table-row-view-action-button-text">{t('SHIPIFY_SHIPPING_GROUPS_TABLE_ROW_ACTION_EDIT_TEXT')}</div>
                    </div>
                    <div className="shipping-groups-table-row-view-action-button shipping-groups-table-row-view-action-delete-button" onClick={() => onDeleteItem(rowData?.id)}>
                        <RiDeleteBin5Fill className="shipping-groups-table-row-view-action-button-image" />
                        <div className="shipping-groups-table-row-view-action-button-text">{t('SHIPIFY_SHIPPING_GROUPS_TABLE_ROW_ACTION_DELETE_TEXT')}</div>
                    </div>
                </div>
            </StyledTableCell>
        </>
    }

    return (
        <>
            {
                isMobile ? <TableRow style={{ height: `${ORDERS_TABLE_ROW_HEIGHT}px` }}>
                    {renderRowContent()}
                </TableRow> : <StyledTableRow style={{ height: `${ORDERS_TABLE_ROW_HEIGHT}px` }}>
                    {renderRowContent()}
                </StyledTableRow>
            }
            <TableRow>
                <TableCell style={{ padding: 0, backgroundColor: '#f1f1f1' }} colSpan={isMobile ? 1 : columnCount}>
                    <CollapsingElement
                        expanded={isExpanded}
                    >
                        <div className={isMobile ? "mobile-shipping-groups-table-row-view-more-details-container" : "shipping-groups-table-row-view-more-details-container"}>
                            {!isMobile && <div className="shipping-groups-table-row-view-more-details-title">{t('SHIPIFY_SHIPPING_GROUPS_TABLE_ROW_MORE_DETAILS_TITLE')}</div>}
                            <div className={isMobile ? "mobile-shipping-groups-table-row-view-more-details" : "shipping-groups-table-row-view-more-details"} style={{ height: 'auto' }}> {/* height: `${rateRowHeight}px` */}
                                {isMobile ? <>
                                    {rowData?.rates?.map((rate, index) => (
                                        <Rate
                                            key={index} 
                                            rateName={rate?.rateName}
                                            minDays={rate?.minDays}
                                            maxDays={rate?.maxDays}
                                            price={(rate?.price / 100).toFixed(2)}
                                            cost={(rate?.cost / 100).toFixed(2)}
                                        />
                                    ))}
                                </> : <Table size="small" sx={{ width: isMobile ? '100%' : 'auto' }}>
                                    <TableHead>
                                        <TableRow>
                                            <RateTableCell>{t('SHIPIFY_SHIPPING_GROUPS_TABLE_ROW_MORE_DETAILS_TABLE_HEADER_RATE_NAME')}</RateTableCell>
                                            <RateTableCell>{t('SHIPIFY_SHIPPING_GROUPS_TABLE_ROW_MORE_DETAILS_TABLE_HEADER_ETA')}</RateTableCell>
                                            <RateTableCell align="right">{t('SHIPIFY_SHIPPING_GROUPS_TABLE_ROW_MORE_DETAILS_TABLE_HEADER_PRICE', { currency: getCurrencySymbol(userDetails?.currency) })}</RateTableCell>
                                            <RateTableCell align="right">{t('SHIPIFY_SHIPPING_GROUPS_TABLE_ROW_MORE_DETAILS_TABLE_HEADER_COST', { currency: getCurrencySymbol(userDetails?.currency) })}</RateTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rowData?.rates.map((rate,index) => (
                                            <TableRow key={index}>
                                                <RateTableCell component="th" scope="row">{rate?.rateName}</RateTableCell>
                                                <RateTableCell>{t('SHIPIFY_RATE_ETA_TEXT', { minDays: rate?.minDays, maxDays: rate?.maxDays })}</RateTableCell>
                                                <RateTableCell align="right">{(rate?.price / 100).toFixed(2)}</RateTableCell>
                                                <RateTableCell align="right">{(rate?.cost / 100).toFixed(2)}</RateTableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>}
                            </div>
                        </div>
                    </CollapsingElement>
                </TableCell>
            </TableRow>
        </>
    )
}