export const ADMIN_PAGES_URL_PREFIX = '/admin'
export const LINKS_URL_PREFIX = '/links'
export const SHORTS_URL_PREFIX = '/shorts'
export const SPECIFIC_SHORT_URL_PREFIX = SHORTS_URL_PREFIX + '/:shortId'
export const SPECIFIC_LINK_URL_PREFIX = LINKS_URL_PREFIX + '/:linkId'
export const PREVIEW_LINK_PAGE_SUFFIX = '/preview'
export const PREVIEW_INFLUECNER_PAGE_SUFFIX = '/preview'
export const EDIT_URL_SUFFIX = '/edit'
export const INFLUENCERS_PAGE_URL_PREFIX = '/influencers'
export const SPECIFIC_INFLUENCER_URL_PREFIX = '/:influencerUsername'
export const CREATE_INFLUENCER_URL_SUFFIX = '/new'
export const INFLUENCERS_URL_PREFIX = ADMIN_PAGES_URL_PREFIX + INFLUENCERS_PAGE_URL_PREFIX

export const WELCOME_PAGE_URL = '/'

export const HOME_PAGE_URL = ADMIN_PAGES_URL_PREFIX + '/home'

export const LOGIN_PAGE_URL = '/login'
export const LOGIN_STORE_PAGE_URL = '/login-store'
export const LOGIN_INFLUENCER_PAGE_URL = '/login/influencer'

export const SIGN_UP_PAGE_URL = '/register'
export const REGISTER_STORE_PAGE_URL = '/register-store'
export const SIGN_UP_REQUEST_PAGE_URL = '/register-request'

export const SHORTS_PAGE_URL = ADMIN_PAGES_URL_PREFIX + SHORTS_URL_PREFIX
export const CREATE_NEW_SHORT_PAGE_URL = ADMIN_PAGES_URL_PREFIX + SHORTS_URL_PREFIX + '/new'
export const EDIT_SHORT_PAGE_URL = ADMIN_PAGES_URL_PREFIX + SPECIFIC_SHORT_URL_PREFIX + '/edit'
export const PREVIEW_SHORT_PAGE_URL = ADMIN_PAGES_URL_PREFIX + SPECIFIC_SHORT_URL_PREFIX + PREVIEW_LINK_PAGE_SUFFIX

export const LINKS_PAGE_URL = ADMIN_PAGES_URL_PREFIX + LINKS_URL_PREFIX
export const CREATE_NEW_LINK_PAGE_URL = ADMIN_PAGES_URL_PREFIX + LINKS_URL_PREFIX + '/new'
export const EDIT_LINK_PAGE_URL = ADMIN_PAGES_URL_PREFIX + SPECIFIC_LINK_URL_PREFIX + '/edit'
export const PREVIEW_LINK_PAGE_URL = ADMIN_PAGES_URL_PREFIX + SPECIFIC_LINK_URL_PREFIX + PREVIEW_LINK_PAGE_SUFFIX

export const INFLUENCERS_PAGE_URL = INFLUENCERS_URL_PREFIX
export const CREATE_INFLUENCER_PAGE_URL = INFLUENCERS_URL_PREFIX + CREATE_INFLUENCER_URL_SUFFIX
export const EDIT_INFLUENCER_PAGE_URL = INFLUENCERS_URL_PREFIX + SPECIFIC_INFLUENCER_URL_PREFIX + EDIT_URL_SUFFIX
export const PREVIEW_INFLUENCER_PAGE_URL = INFLUENCERS_URL_PREFIX + SPECIFIC_INFLUENCER_URL_PREFIX + PREVIEW_INFLUECNER_PAGE_SUFFIX

export const ANALYTICS_PAGE_URL = ADMIN_PAGES_URL_PREFIX + '/analytics'

export const ACCOUNT_PAGE_URL = ADMIN_PAGES_URL_PREFIX + '/my-account'

export const FORGOT_PASSWORD_PAGE_URL = '/forgot-password'

export const INFLUENCER_FORGOT_PASSWORD_PAGE_URL = '/forgot-password/influencer'

export const CREATE_NEW_PASSWORD_PAGE_URL = '/reset-password'

export const EXTERNAL_AUTH_PAGE_URL = '/auth'

export const PRIVACY_POLICY_PAGE_URL = '/privacy-policy'

export const TERMS_PAGE_URL = '/terms'

export const SETTINGS_PAGE_URL = `${ADMIN_PAGES_URL_PREFIX}/settings`

export const APPEARANCE_PAGE_URL = `${ADMIN_PAGES_URL_PREFIX}/appearance`

export const PRICING_PLANS_PAGE_URL = '/pricing-plans'

export const SHOPIFY_HELP_PAGE_URL = '/help/shopify'

export const ACCOUNT_VERIFICATION_PAGE_URL = '/verify-account'

export const ROLE_QUERY_PARAMETER_KEY = 'role'
export const ROLE_QUERY_PARAMETER_EDIT_VALUE = 'edit'
export const ROLE_QUERY_PARAMETER_CREATE_VALUE = 'create'
export const LINK_ID_QUERY_PARAMETER_KEY = 'linkId'
export const AUTH_QUERY_PARAMETER_KEY = 'auth'
export const RESET_PASSWORD_TOKEN_QUERY_PARAMETER_KEY = 'token'
export const PRODUCT_STATUS_QUERY_PARAMETER = 'product_status'
export const IS_OUT_OF_STOCK_QUERY_PARAMETER = 'include_out_of_stock'
export const IS_SEARCH_IN_TAGS_QUERY_PARAMETER = 'include_search_tags'
export const LOGIN_INFLUENCER_MERCHANT_NAME_QUERY_PARAMETER = 'merchant'
export const LOGIN_INFLUENCER_INFLUENCER_USERNAME_QUERY_PARAMETER = 'influencer'
export const LOGIN_INFLUENCER_INFLUENCER_PASSWORD_QUERY_PARAMETER = 'password'
export const SELECTED_INFLUENCER_USERNAME_QUERY_PARAMETER = 'selected_influencer_username'


// Shipify
export const SHIPIFY_HOME_PAGE_URL = '/'
export const SHIPIFY_SHIPPING_GROUPS_ROUTE = '/shipping-groups'
export const SHIPIFY_SHIPPING_GROUPS_EDIT_ROUTE = '/edit'
export const SHIPIFY_SHIPPING_GROUPS_PAGE_URL = SHIPIFY_SHIPPING_GROUPS_ROUTE
export const SHIPIFY_PRODUCTS_PAGE_URL = '/products'
export const SHIPIFY_HOW_TO_USE_PAGE_URL = '/how-to-use'
export const SHIPIFY_SETTINGS_AND_PLANS_PAGE_URL = '/settings-and-plans'
export const SHIPIFY_CREATE_SHIPPING_GROUP_PAGE_URL = '/shipping-groups/new'
export const SHIPIFY_EDIT_SHIPPING_GROUP_PAGE_URL = '/shipping-groups/:shippingGroupId/edit'
export const SHIPIFY_SHIPPING_COMPANIES_PAGE_URL = '/shipping-companies'
export const SHIPIFY_ALIXPRESS_CALLBACK_URL = '/auth/callback'
