import React from 'react'

export default function ShipifyProductsImage(props) {
    return (
        <svg {...props}
            id="svg2"
            width="800px" height="800px"
            viewBox="0 0 1200 1200" enableBackground="new 0 0 1200 1200">
                <path id="path23160" d="M0,467.478V176.805c3.479-45.64,36.064-76.909,77.47-77.47h290.673
                    c38.559,0.646,74.477,14.596,105.962,33.613l455.536,500.354c27.468,35.271,28.876,79.164,0,108.844l-336.771,335.491
                    c-34.029,29.361-81.72,32.02-108.842,0L55.062,600.009C24.908,562.69,0.447,513.568,0,467.478z M146.938,352.233
                    c32.17,29.66,78.342,26.916,105.961,0c29.526-31.898,27.06-78.551,0-105.961c-31.94-29.075-78.454-26.768-105.961,0
                    C117.739,280.374,118.836,322.978,146.938,352.233z M506.438,100.615h111.403c46.704,2.88,101.974,21.285,131.893,55.062
                    L1178.7,634.582c28.985,33.471,27.808,81.543,0,110.123l-335.491,335.492c-46.495,26.355-89.107,24.518-117.806-8.965
                    l329.088-329.089c29.633-32.787,26.469-80.075,0-108.843L670.618,203.787C625.179,147.074,527.354,103.175,506.438,100.615z"
                />
        </svg>
    )
}