import { Alert, AlertTitle } from '@mui/material'
import { ALERT_MESSAGE_TYPES } from 'constants/general.constants'
import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'
import { grey } from '@mui/material/colors'



export default function AlertMessage({
    title = '',
    message = '',
    type = ALERT_MESSAGE_TYPES.INFO,
    background = '',
    icon = '',
}) {
    return (
        <Alert severity={type} sx={{
            width: '100%',
            backgroundColor: background
        }}>
            {title && <AlertTitle sx={{ fontWeight: 600 }}>{title}</AlertTitle>}
            {message}
            {icon && <SvgIcon sx={{width: 15, height: 15, color: grey}}>
            {/* credit: plus icon from https://heroicons.com/ */}
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="grey"
            >
                <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d={icon}
                />
            </svg>
         </SvgIcon>}
     </Alert>
    )
}