import { environment } from "conf"
import { SHIPIFY_STORE_ANALYTICS } from 'constants/api.constants'
import { request_auth } from "services/RequestWrapper"


/*
    Retrieves all collections.
    Method: [GET]
    URL: /admin/v1/collections/search

    Query params:
    =============
        (*) q - The search query string
        (*) count - The page capacity
        (*) skip - The number of elements skiped
*/
export function getStoreAnalytics(from_date, to_date ) {


    const urlParams = from_date && to_date ?`?from_date=${from_date}&to_date=${to_date}` : ''
    const baseUrl = `${environment.api_url}${SHIPIFY_STORE_ANALYTICS}${urlParams}`

    return new Promise((resolve, reject) => {
        const options = {
            method: 'GET',
            body:{
                from_date: `${from_date}`,
                to_date:`${to_date}`
            }
        }
        request_auth(baseUrl, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data);
                } else {
                    reject(response.error);
                }
            })
            .catch(error => {
                reject(error);
            });
    });
}