import React, { useEffect, useRef, useState } from 'react'
import './ShippingMethodsEdit.css'
import { useTranslation } from 'react-i18next'
import ShippingMethodRowEdit from 'components/ShippingMethodRowEdit/ShippingMethodRowEdit'
import { FiPlus } from 'react-icons/fi'
import { generateRandomInteger } from 'utils/utils'

export default function ShippingMethodsEdit({
    rates = [],
    setRates = () => { },
    canCommenceInitialization = false,
    setAreRatesValid = () => { }
}) {
    const { t } = useTranslation()

    const [shippingMethods, setShippingMethods] = useState([])

    useEffect(() => {
        if (canCommenceInitialization) {
            setShippingMethods(initializeShippingMethods())
        }
    }, [canCommenceInitialization])

    useEffect(() => {
        setAreRatesValid(rates?.length > 0 && rates?.every(rate => rate?.rateName !== '' && rate?.minDays <= rate?.maxDays))
    }, [rates])

    function addShippingMethod() {
        const newShippingMethodsArray = [...shippingMethods]
        newShippingMethodsArray.push({
            id: generateRandomInteger(1_000_000),
            rateName: '',
            minDays: 0,
            maxDays: 0,
            price: 0,
            cost: 0
        })
        setShippingMethods(newShippingMethodsArray)
        setRates(newShippingMethodsArray)
    }

    function removeShippingMethod(id) {
        const newShippingMethodsArray = [...shippingMethods.filter(shippingMethod => shippingMethod.id !== id)]
        setShippingMethods(newShippingMethodsArray)
        setRates(newShippingMethodsArray)
    }

    function updateShippingMethod(id, shippingName, minETADays, maxETADays, price, cost) {
        const newShippingMethodsArray = [...shippingMethods]
        const shippingMethodToUpdate = newShippingMethodsArray.find(shippingMethod => shippingMethod?.id === id)
        if (!shippingMethodToUpdate) {
            return
        }

        shippingMethodToUpdate.rateName = shippingName
        shippingMethodToUpdate.minDays = minETADays
        shippingMethodToUpdate.maxDays = maxETADays
        shippingMethodToUpdate.price = price
        shippingMethodToUpdate.cost = cost
        
        setShippingMethods(newShippingMethodsArray)
        setRates(newShippingMethodsArray)
    }

    function initializeShippingMethods() {
        if (rates.length === 0) {
            return [{
                id: generateRandomInteger(1_000_000),
                rateName: '',
                minDays: 0,
                maxDays: 0,
                price: 0,
                cost: 0
            }]
        }

        return rates.map(rate => ({
            ...rate,
            id: generateRandomInteger(1_000_000)
        }))
    }

    return (
        <div className='shipping-methods-edit-container'>
            <div className="shipping-methods-edit-list">
                {
                    shippingMethods.map(shippingMethod => <ShippingMethodRowEdit
                        key={shippingMethod.id}
                        id={shippingMethod.id}
                        name={shippingMethod.rateName}
                        minDays={shippingMethod.minDays}
                        maxDays={shippingMethod.maxDays}
                        price={shippingMethod.price}
                        cost={shippingMethod.cost}
                        onRemoveShippingMethod={removeShippingMethod}
                        onUpdateShippingMethod={updateShippingMethod}
                    />)
                }
            </div>
            <div className="shipping-methods-edit-add-shipping-method-button" onClick={addShippingMethod}>
                <FiPlus className='shipping-methods-edit-add-shipping-method-button-image' />
                <div className="shipping-methods-edit-add-shipping-method-button-text">{t('SHIPIFY_SHIPPING_METHODS_EDIT_ADD_SHIPPING_METHOD_BUTTON_TEXT')}</div>
            </div>
        </div>
    )
}