import React, { useEffect, useState, useMemo } from 'react'
import './ShipifySettingsAndPlansPage.css'
import { useUserDetailsContext } from 'contexts/shipify_context'
import AccountSection from 'components/AccountSection/AccountSection'
import { useTranslation } from 'react-i18next'
import PaydinSwitch from 'components/PaydinSwitch/PaydinSwitch'
import EditFieldSectionInput from 'components/EditFieldSectionInput/EditFieldSectionInput'
import LoaderButton from 'components/LoaderButton/LoaderButton'
import PaydinDialog, { PAYDIN_DIALOG_BUTTON_TYPES } from 'dialogs/PaydinDialog/PaydinDialog'
import ApprovalMessage from 'components/ApprovalMessage/ApprovalMessage'
import { isMobile } from 'react-device-detect'
import { CHROME_EXTENSION_LINK, SHIPIFY_CHROME_EXTENSION_KEY} from 'constants/general.constants'
import ShipifyLinkSupplier from 'components/ShipifyLinkSupplier/ShipifyLinkSupplier'
import ShipifyAliExpressLogo from 'embeddedImages/ShipifyAliExpressLogo'
import ShipifyPricingRules from 'components/ShipifyPricingRules/ShipifyPricingRules'
import ShipifyPlanDetails from 'components/ShipifyPlanDetails/ShipifyPlanDetails'
import { LiaCheckCircleSolid } from 'react-icons/lia'
import Loader from 'components/Loader/Loader'
import ShipifyBanner from 'components/ShipifyBanner/ShipifyBanner'
import { FiPlus } from 'react-icons/fi'
import DsersLogo from 'embeddedImages/DsersLogo'
import Divider from '@mui/material/Divider';
import { LoadingButton } from '@mui/lab'
import { use } from 'i18next'


export default function ShipifySettingsAndPlansPage() {
    const { t } = useTranslation()
    const {
        userDetails,
        updateRateInformation,
        activateApplication,
        deactivateApplication,
        hideActivatePop,
        chromeExtensionActivated
    } = useUserDetailsContext()

    const [isAppActive, setIsAppActive] = useState(userDetails?.isAppActive)
    const [isUseCustomRates, setIsUseCustomRates] = useState(userDetails?.useCustomRates)
    const [rateData, setRateData] = useState({
        rateName: userDetails?.rateName,
        rateDescription: userDetails?.rateDescription,
        useCustomRates: isUseCustomRates,
        hasModified: false
    })
    const [isSavingRateInfo, setIsSavingRateInfo] = useState(false)
    const [isAbleToSaveRateInfo, setIsAbleToSaveRateInfo] = useState(false)
    const [approvalMessageState, setApprovalMessageState] = useState({
        isMessageShown: false,
        text: ''
    })
    const [dialogState, setDialogState] = useState({
        isOpen: false,
        handleDialogClose: closeDialog,
        title: '',
        leftButtonText: '',
        rightButtonText: '',
        rightButtonClickHandler: () => { }
    })
    const [isExtensionInstalled, setIsExtensionInstalled] = useState(userDetails.chromeExtensionActive);


    useEffect(() => {
        chromeExtensionActivated()
      }, []);


    useEffect(() => {
        setIsAbleToSaveRateInfo(
            (rateData.hasModified && (!['', userDetails.rateName].includes(rateData.rateName)
                || ![userDetails.rateDescription].includes(rateData.rateDescription)))
        )
    }, [userDetails, rateData])

    function closeDialog() {
        setDialogState(prev => ({
            ...prev,
            isOpen: false,
            title: '',
            message: '',                
            leftButtonText: '',
            rightButtonText: '',
            confirmationCheckboxLabel: '',
            rightButtonClickHandler: '',
            class: 'activate-'        
        }))
        hideActivatePop()
    }

    function setRateName(value) {
        setRateData(prev => ({
            ...prev,
            rateName: value,
            hasModified: true
        }))
    }

    function setRateDescription(value) {
        setRateData(prev => ({
            ...prev,
            rateDescription: value,
            hasModified: true
        }))
    }

    function saveRateInfo(useCustomRates) {
        setIsSavingRateInfo(true)
        setIsUseCustomRates(useCustomRates)
        updateRateInformation(rateData.rateName, rateData.rateDescription, useCustomRates, response => {
            setIsSavingRateInfo(false)
            closeDialog()
            setApprovalMessageState(prev => ({
                ...prev,
                isMessageShown: true,
                text: 'Settings saved successfully'
            }))
        }, error => {
            setIsSavingRateInfo(false)
            setApprovalMessageState(prev => ({
                ...prev,
                isMessageShown: true,
                text: error
            }))
            closeDialog()
        })
    }

    function onAppStatusChange(isAppActive) {
        if (!isAppActive) {
            setDialogState(prev => ({
                ...prev,
                isOpen: true,
                title: 'App Deactivation',
                message: 'By turning off the app, your store returns to selecting one general shipping method for the entire cart, and not individually for every product.',
                leftButtonText: 'Cancel',
                rightButtonText: 'Deactivate',
                confirmationCheckboxLabel: 'I confirm, deactivate app',
                messageFontSize: 16,
                rightButtonClickHandler: deactivateApp,
                closeOnRightClick: false,
            }))
        } else {
            activateApp()
        }
    }

    function activateApp() {
        setIsSavingRateInfo(true)
        setDialogState(prev => ({
            ...prev,
            isOpen: true,
            title: <center><Loader styles={{height: '70px', width: '70px', borderWidth: '3px'}}/></center>,
            message: <div><p className='activating-text'><b>Activating app</b><span className="dots"><span>.</span><span>.</span><span>.</span></span><br/><br/><br/></p></div>,                
            leftButtonText: '',
            rightButtonText: '',
            confirmationCheckboxLabel: '',
            rightButtonClickHandler: '',
            messageFontSize: 21,
            class: 'activate-dialog'
        }))
        activateApplication(response => {
            setIsAppActive(response)
            setIsSavingRateInfo(false)
            if (response) {
                setDialogState(prev => ({
                    ...prev,
                    isOpen: true,
                    title: <LiaCheckCircleSolid style={{width: '100px', height: '100px', color: '#65C466'}}/>,
                    message: <><p><b>App Activated</b></p><p style={{fontSize:'16px', marginTop: '10px'}}>The app has been successfully activated in your online store</p></>,
                    leftButtonText: '',
                    rightButtonText: '',
                    confirmationCheckboxLabel: '',
                    rightButtonClickHandler: '',
                    minHeight: '250px',
                    messageFontSize: 21,
                }))
            } 
        }, error => {
            setIsSavingRateInfo(false)
            console.log(error)
            closeDialog()
            setIsAppActive(false)
            setApprovalMessageState(prev => ({
                ...prev,
                isMessageShown: true,
                text: error ? error : 'An error occurred'
            }))
        })
    }



    function deactivateApp() {
        setIsSavingRateInfo(true)
        deactivateApplication(response => {
            if (response.status) {
                setIsAppActive(false)
                setIsSavingRateInfo(false)
                closeDialog()
                setApprovalMessageState(prev => ({
                    ...prev,
                    isMessageShown: true,
                    text: 'App deactivated successfully'
                }))
            }
        }, error => {
            setIsSavingRateInfo(false)
            setIsAppActive(true)
            setApprovalMessageState(prev => ({
                ...prev,
                isMessageShown: true,
                text: error
            }))
            closeDialog()
        })
    }


    function continueSell() {
        if (!isUseCustomRates){
            setDialogState(prev => ({
                ...prev,
                isOpen: true,
                title: t('SHIPIFY_CONTINUE_SELL_NO_RATES'),
                message: 'By turning on this you will continue sell product if not rates reccieved from main supplier, custom rates will be used as default',
                leftButtonText: 'Cancel',
                rightButtonText: 'Confirm',
                confirmationCheckboxLabel: 'I confirm to continue sell',
                messageFontSize: 16,
                rightButtonClickHandler: () => {
                    saveRateInfo(true);
                }
            }))
        } else {
        setIsUseCustomRates(false);
        saveRateInfo(false);
        }
    }

    function hideApprovalMassage() {
        setApprovalMessageState(prev => ({
            ...prev,
            isMessageShown: false
        }))
    }


    const handleLinkClick = () => {
        window.open(CHROME_EXTENSION_LINK, '_blank');
    };

    const canActivate = userDetails?.isThirdPartyCcsOn && userDetails?.totalShippingGroups > 0 &&
    userDetails?.isThemeAppBlockAdded && userDetails?.isThemeAppEmbedBlockAdded &&
    userDetails?.rateName.length > 0 
        
    const aliexpressAccount = useMemo(() => userDetails?.aliexpressAccount, [userDetails, userDetails.aliexpressAccount]);

    return (
        <div className={isMobile ? 'mobile-shipify-settings-and-plans-page-container' : 'shipify-settings-and-plans-page-container'}>
            {!isMobile && <div className="shipify-settings-and-plans-page-title">{t('SHIPIFY_SETTINGS_AND_PLANS_PAGE_TITLE')}</div>}
            <div className="shipify-settings-and-plans-page-content">
                <AccountSection hasTitle={true} title='Plan'>
                    <div className='shipify-plan-section'>
                       <ShipifyPlanDetails/>
                    </div>
                </AccountSection>

                <AccountSection hasTitle={true} title='General'>
                    {/* {
                        !userDetails?.isAppActive && <AlertMessage
                            title={t('SHIPIFY_COMPLETE_GUIDE_ALERT_TITLE')}
                            message={t('SHIPIFY_COMPLETE_GUIDE_ALERT_MESSAGE')}
                            type={ALERT_MESSAGE_TYPES.WARNING}
                        />
                    } */}
                    
                    <PaydinSwitch
                        label={t('SHIPIFY_SETTINGS_AND_PLANS_PAGE_APP_ACTIVATION_SWITCH_LABEL')}
                        checked={isAppActive}
                        setChecked={onAppStatusChange}
                        flipChildren={true}
                        disabled={!canActivate || isSavingRateInfo}
                    />
                    

                    <PaydinSwitch
                        label={t('SHIPIFY_CONTINUE_SELL_NO_RATES')}
                        checked={isUseCustomRates}
                        setChecked={continueSell}
                        flipChildren={true}
                        disabled={isSavingRateInfo}
                    />

                {/* </AccountSection>
                <AccountSection hasTitle={false}> */}
                    <EditFieldSectionInput
                        title={t('SHIPIFY_SETTINGS_AND_PLANS_PAGE_UPDATE_RATE_RATE_NAME_FIELD_TITLE')}
                        value={rateData.rateName}
                        setValue={setRateName}
                        maxLength={40}
                        styles={{
                            width: isMobile ? '100%' : '70%'
                        }}
                    />
                    <EditFieldSectionInput
                        title={t('SHIPIFY_SETTINGS_AND_PLANS_PAGE_UPDATE_RATE_RATE_DESCRIPTION_FIELD_TITLE')}
                        value={rateData.rateDescription}
                        setValue={setRateDescription}
                        maxLength={80}
                    />
                    <div className="shipify-settings-and-plans-page-buttons-section">
                        <LoaderButton
                            buttonText={t('SHIPIFY_SETTINGS_AND_PLANS_PAGE_UPDATE_RATE_SAVE_BUTTON_TEXT')}
                            className='shipify-settings-and-plans-page-save-button'
                            isLoading={isSavingRateInfo}
                            onClick={() => saveRateInfo(isUseCustomRates)}
                            isDisabled={!isAbleToSaveRateInfo}
                        />
                    </div>
                </AccountSection>

                <AccountSection hasTitle={true} title='AliExpress' id={'accounts-third-party'}>
                    <ShipifyBanner 
                            svg={<ShipifyAliExpressLogo />}
                            title={aliexpressAccount ? t("SHIPIFY_CONNECTED_SUPPLIER_BANNER_TITLE") : t('SHIPIFY_ADD_SUPPLIER_BANNER_TITLE')}
                            description={aliexpressAccount ? t('SHIPIFY_CONNECTED_SUPPLIER_ACCOUNT', {account: aliexpressAccount}) : t('SHIPIFY_ADD_SUPPLIER_BANNER_TEXT')}
                            button={
                                !aliexpressAccount? 
                                    <ShipifyLinkSupplier 
                                        buttonClass='banner-button aliexpress-account'
                                        btn_text={t('SHIPIFY_ADD_SUPPLIER_BANNER_BTN')}
                                        btn_svg={<FiPlus/>}
                                    />
                                :
                                <div className='shipify-banner-btn-wrapper'>
                                    <div className={isMobile? "shipify-link-supplier-button-mobile" : "shipify-link-supplier-button" }>
                                        <div className="shipify-link-supplier-button-text">{t("SHIPIFY_CONNECTED_BANNER_BUTTON_TEXT")}</div>
                                    </div>
                                </div>
                                }
                    />
                    
                    <Divider/>
                    
                    <ShipifyBanner 
                        svg={<DsersLogo />}
                        title={t("SHIPIFY_ADD_CHROME_BANNER_TITLE")}
                        description={t('RECOMENDED_CHROME_EXTENSION_TEXT')}
                        button={
                            <div className='shipify-banner-btn-wrapper'>
                                {userDetails.chromeExtensionActive ? 
                                    <div className={isMobile? "shipify-link-supplier-button-mobile" : "shipify-link-supplier-button" }>
                                        <div className="shipify-link-supplier-button-text">{t("SHIPIFY_EXTENSION_INSTALLED_BANNER_BUTTON_TEXT")}</div>
                                    </div>
                                    :
                                    <LoadingButton className="banner-button" variant='outline' onClick={handleLinkClick}>
                                        <FiPlus/>
                                        {t("SHIPIFY_ADD_CHROME_BANNER_BUTTON_TEXT")}
                                    </LoadingButton>
                                }
                            </div>
                        }
                    />
                </AccountSection>


                <AccountSection hasTitle={true} title='Pricing Rules'>
                       <ShipifyPricingRules/>
                </AccountSection>
                <br/>
                <br/>
            </div>
            {
                dialogState.isOpen && <PaydinDialog
                    title={dialogState.title}
                    message={dialogState.message}
                    isDialogOpen={dialogState.isOpen}
                    handleDialogClose={dialogState.handleDialogClose}
                    leftButtonText={dialogState.leftButtonText}
                    rightButtonText={dialogState.rightButtonText}
                    leftButtonType={PAYDIN_DIALOG_BUTTON_TYPES.PRIMARY}
                    rightButtonType={PAYDIN_DIALOG_BUTTON_TYPES.SECONDARY}
                    onRightButtonClick={dialogState.rightButtonClickHandler}
                    closeOnRightClick={dialogState.closeOnRightClick}
                    isLeftButtonWithLoader={false}
                    dialogLayout={dialogState.dialogLayout}
                    confirmationCheckboxLabel={dialogState.confirmationCheckboxLabel}
                    minHeight={dialogState.minHeight}
                    messageFontSizePx={dialogState.messageFontSize}
                    classDialog={dialogState.class}
                />
            }
            <ApprovalMessage
                isMessageShown={approvalMessageState.isMessageShown}
                closeMessage={hideApprovalMassage}
                text={approvalMessageState.text}
            />
        </div>
    )
}