import React, {useState} from 'react';
import { Box, Autocomplete, ListItem, styled, Tooltip,tooltipClasses, Drawer, Tabs, Tab, IconButton, Paper, InputBase, Divider, FormControl, InputLabel, Select, MenuItem, CircularProgress, Alert, TextField, List, ListItemButton, ListItemText, InputAdornment } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import { DataGrid } from '@mui/x-data-grid';
import ShipifyAliExpressLogo from 'embeddedImages/ShipifyAliExpressLogo';
import { MdImage, MdInfoOutline, MdSearch, MdInfo } from 'react-icons/md';
import ShipifySection from 'components/ShipifySection/ShipifySection';
import ShipifyBanner from 'components/ShipifyBanner/ShipifyBanner';
import ShipifyLinkSupplier from 'components/ShipifyLinkSupplier/ShipifyLinkSupplier';
import { Unstable_NumberInput as BaseNumberInput } from '@mui/base';
import { FiPlus } from 'react-icons/fi';
import Snackbar from '@mui/material/Snackbar';
import { Add, Remove } from '@mui/icons-material';
import { isMobile } from 'react-device-detect';
import { ALL_COUNTRIES } from 'constants/general.constants';


const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      fontSize: '12px',
    },
  }));


const blue = {
    100: '#daecff',
    200: '#b6daff',
    300: '#66b2ff',
    400: '#3399ff',
    500: '#007fff',
    600: '#0072e5',
    700: '#0059B2',
    800: '#004c99',
  };
  
  const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
  };
  
  const StyledInputRoot = styled('div')(
    ({ theme }) => `
      font-family: 'IBM Plex Sans', sans-serif;
      font-weight: 400;
      color: ${theme.palette.mode === 'dark' ? grey[300] : grey[500]};
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
    `,
  );
  
  const StyledInput = styled('input')(
    ({ theme }) => `
      font-size: 0.875rem;
      font-family: inherit;
      font-weight: 400;
      line-height: 1;
      color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
      background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
      border: 1px solid #c4c4c4;
      box-shadow: 0px 2px 4px ${theme.palette.mode === 'dark' ? 'rgba(0,0,0, 0.5)' : 'rgba(0,0,0, 0.05)'
      };
      border-radius: 8px;
      margin: 0 8px;
      padding: 10px 12px;
      outline: 0;
      min-width: 0;
      width: 5rem;
      text-align: center;
    
      &:hover {
        border-color: #000;
      }
    
      &:focus {
        border-color: #3874cb;
        box-shadow: 0 0 0 1px ${theme.palette.mode === 'dark' ? '#3874cb' : '#3874cb'};
      }
    
      &:focus-visible {
        outline: 0;
      }
    `,
  );
  
  
  const StyledButton = styled('button')(
    ({ theme }) => `
      font-family: 'IBM Plex Sans', sans-serif;
      font-size: 0.875rem;
      box-sizing: border-box;
      line-height: 1.5;
      border: 1px solid;
      border-radius: 999px;
      border-color: #c4c4c4;
      background: ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
      color: ${theme.palette.mode === 'dark' ? grey[200] : grey[900]};
      width: 32px;
      height: 32px;
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      transition-property: all;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: 120ms;
    
      &:hover {
        cursor: pointer;
        background: ${theme.palette.mode === 'dark' ? blue[700] : blue[500]};
        border-color: ${theme.palette.mode === 'dark' ? blue[700] : blue[500]};
        color: ${grey[50]};
      }
    
      &:focus-visible {
        outline: 0;
      }
    
      &.increment {
        order: 1;
      }
    `,
  );


const NumberInput = React.forwardRef(function CustomNumberInput(props, ref) {
    return (
      <BaseNumberInput
        slots={{
          root: StyledInputRoot,
          input: StyledInput,
          incrementButton: StyledButton,
          decrementButton: StyledButton,
        }}
        slotProps={{
          incrementButton: {
            children: <Add fontSize="small" />,
            className: 'increment',
          },
          decrementButton: {
            children: <Remove fontSize="small" />,
          },
  
        }}
        {...props}
        ref={ref}
      />
    );
  });
  

export default function ProductDrawer ({
  drawerOpen, handleDrawerClose, activeTab, handleChangeTab, selectedProduct, handleInputChange, supplierMainLink,
  handleImportButtonClick, isMappingLoading, validateLink, userDetails, alertInfo, columnsVariants, columnsRates,
  selectedVariant, handleVariantChange, inputKey, setSelectedQuantity, searchTerm, handleSearchChange, filteredCountries,
  selectedCountry, handleCountrySelect, rowsRates, isLoading, isRates, t, CHROME_EXTENSION_LINK, setAlertInfo, setSelectedCountry, activeCountrySelector, 
  setActiveCountrySelector, mappingCountry, setMappingCountry
}) {

  return (
    <Drawer
    anchor={isMobile ? "bottom" : "right"}
    open={drawerOpen}
      onClose={handleDrawerClose}
      ModalProps={{
        keepMounted: true,
        onClick: (event) => {
          event.stopPropagation();
        },
      }}
      sx={{ width: isMobile ? 0 : '30%', flexShrink: 0, '& .MuiDrawer-paper': { width: isMobile ? '100%' : '60%', height: isMobile ? '90%' : '100%', boxSizing: 'border-box', borderRadius: isMobile ? "15px" : "" } }}
      variant="temporary"
    >
      <div className='shipify-drawer-header'>
        <div className='shipify-drawer-title'>{t("SHIPIFY_PRODUCTS_DRAWER_TITLE")}</div>
        <div className='shipify-close-drawer'>
          <IconButton
            onClick={handleDrawerClose}
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </div>

      <Tabs className={isMobile ? "shipify-product-tabs-mobile" : "shipify-product-tabs"} value={activeTab} onChange={handleChangeTab} aria-label="Product Tabs">
        <Tab label={t("SHIPIFY_PRODUCTS_DRAWER_TABS_1")} />
        <Tab label={t("SHIPIFY_PRODUCTS_DRAWER_TABS_2")} />
        <Tab label={t("SHIPIFY_PRODUCTS_DRAWER_TABS_3")} />
      </Tabs>

      {activeTab === 0 && (
        <Box p={2} className='shipify-drawer-mapping-box'>
          <Box className="shipify-drawer-poduct-details">
            <div className='shipify-drawer-product-image'>
              {selectedProduct?.productImg ? <img src={selectedProduct?.productImg} /> : <MdImage />}
            </div>
            <div className='shipify-drawer-product-title'>{selectedProduct?.title}</div>
            <div className={isMobile ? 'shipify-drawer-mapping-mobile' : 'shipify-drawer-mapping'}>
              <div className='drawer-map-title'>{t("SHIPIFY_PRODUCTS_DRAWER_MAPPING_TITLE")}
                <CustomTooltip
                  className='shipify-shipping-price-tooltip'
                  title={t('SHIPIFY_PRODUCTS_DRAWER_MAPPING_TOOLTIP')}
                  placement="top"
                  arrow
                >
                  <div>
                    <MdInfoOutline className='shipify-shipping-price-info' />
                  </div>
                </CustomTooltip>
              </div>
              <Paper
                className='shipify-drawer-mapping-input'
                component="form"
                sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: "80%" }}
              >
                <IconButton sx={{ p: '10px' }} aria-label="menu">
                  <ShipifyAliExpressLogo className="supplier-logo-svg" />
                </IconButton>
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder={t("SHIPIFY_PRODUCTS_DRAWER_MAPPING_INPUT_PLACEHOLDER")}
                  value={supplierMainLink}
                  onChange={handleInputChange}
                  inputProps={{ 'aria-label': 'Import a supplier by pasting an AliExpress link' }}
                  disabled={!userDetails.aliexpressAccount}
                />
                {activeCountrySelector && <img
                    className='mapping-input-country'
                    loading="lazy"
                    width="30"
                    srcSet={`https://flagcdn.com/w40/${mappingCountry[0].code.toLowerCase()}.png 2x`}
                    src={`https://flagcdn.com/w40/${mappingCountry[0].code.toLowerCase()}.png`}
                    alt=""
                /> }
                <Divider sx={{ height: 28, m: 1 }} orientation="vertical" />
                <LoadingButton
                  sx={{ width: 80 }}
                  className='drawer-map-save-btn'
                  onClick={handleImportButtonClick}
                  loading={isMappingLoading}
                  disabled={!validateLink(supplierMainLink) || !userDetails.aliexpressAccount}>
                  <span>{t("SHIPIFY_PRODUCTS_DRAWER_MAPPING_IMPORT_BUTTON")}</span>
                </LoadingButton >
              </Paper>
              
              {!validateLink(supplierMainLink) && supplierMainLink.length !== 0 &&
                <div style={{ display: "flex", alignItems: "center", gap: "5px", fontWeight: 400, fontSize: "13px", color: "red" }}>
                  <MdInfo />
                  {t("SHIPIFY_PRODUCTS_DRAWER_MAPPING_VALID_LINK")}
                </div>
              }

              <div>
                {userDetails.aliexpressAccount === null ? 
                  <ShipifySection sectionClassName='shipify-supplier-banner'>
                    <ShipifyBanner 
                      svg={<ShipifyAliExpressLogo />}
                      title={t('SHIPIFY_ADD_SUPPLIER_BANNER_TITLE')}
                      description={t('SHIPIFY_ADD_SUPPLIER_BANNER_TEXT')}
                      recomendedText={userDetails.chromeExtensionActive ? '' : t('RECOMENDED_CHROME_EXTENSION_TEXT')}
                      recomendedLink={userDetails.chromeExtensionActive ? '' : CHROME_EXTENSION_LINK}
                      button={<ShipifyLinkSupplier 
                        buttonClass='banner-button'
                        btn_text={t('SHIPIFY_ADD_SUPPLIER_BANNER_BTN')}
                        btn_svg={<FiPlus/>}
                      />}
                    />
                  </ShipifySection>
                :  
                <Snackbar
                  open={alertInfo.open}
                  autoHideDuration={activeCountrySelector ? null : 7000}
                  onClose={() => setAlertInfo({ ...alertInfo, open: false })}
                  sx={{ position: 'unset' }}
                >
                  <Alert
                    onClose={() => setAlertInfo({ ...alertInfo, open: false })}
                    severity={alertInfo.severity}
                    sx={{ width: '100%' }}
                  >
                    {alertInfo.message}
                  </Alert>
                </Snackbar>
                }
              </div>
              {activeCountrySelector && <Autocomplete
                    id="mapping-country-select"
                    ListboxProps={{ style: { maxHeight: '120px' } }}
                    sx={{marginTop: '5px'}}
                    options={ALL_COUNTRIES}
                    onOpen={()=>setMappingCountry(prev => ({ ...prev, isClicked: true}))} 
                    autoHighlight
                    getOptionLabel={(option) => option ? option.name : ''}
                    size='small'
                    value={mappingCountry.length > 0 ? mappingCountry[0] : ''}
                    onChange={(event, option) => {
                        if (option !== null) {
                            setMappingCountry([{ code: option.code, name: option.name, isClicked: true }]);
                        } else {
                            setMappingCountry([{ code: 'US', name: 'United States', isClicked: false }]);
                        }
                    }}
                    renderOption={(props, option) => (
                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props} value={option.code} >
                        <img
                            loading="lazy"
                            width="20"
                            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                            src={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png`}
                            alt=""
                        />
                        {option.name}
                        </Box>
                    )}
                    renderInput={(params) => (
                        <TextField
                        {...params}
                        label={t("SHIPIFY_PRODUCTS_DRAWER_COUNTRY_SELECTOR_MOBILE")}
                        className={`mapping-country-input${!mappingCountry[0].isClicked ? '-pulse' : ''}`}
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                        />
                    )}
                    isOptionEqualToValue={(option, value) => option.name === value.name}
                    /> }
            </div>
          </Box>
        </Box>
      )}

      {activeTab === 1 && (
        <Box p={2} sx={{ justifyContent: "center", display: "flex", maxHeight: "calc(100% - 150px)" }}>
              <Box sx={{ width: isMobile ? "100%" : "90%" }} className="shipify-drawer-poduct-variants">
            <DataGrid
              className='shipify-products-data-grid'
              rows={selectedProduct.variants}
              columns={columnsVariants}
              pageSizeOptions={[10, 25, 50]}
              rowHeight={70}
              initialState={{
                pagination: {
                  paginationModel: { pageSize: 10, page: 0 },
                },
              }}
              disableRowSelectionOnClick
            />
          </Box>
        </Box>
      )}

      {activeTab === 2 && (
        <Box p={2} sx={{ justifyContent: "center", display: "flex", flexDirection: "column", gap: "20px", alignItems: "center" }}>
          <Box sx={{ maxWidth: "100%", width: "90%", paddingTop: "15px", display: "flex", justifyContent: 'space-between', alignItems: 'center' }} className="shipify-drawer-product-shipping">
            <FormControl sx={{ m: 1, minWidth: "calc(100% - 175px)", margin: 0 }} size="small">
              <InputLabel id="variant-select-label">{t("SHIPIFY_PRODUCTS_DRAWER_RATES_SELECT_VARIANT")}</InputLabel>
              <Select
                labelId="variant-select-label"
                id="variant-select"
                value={selectedVariant}
                label="Select Variant"
                onChange={handleVariantChange}
                fullWidth={true}
                sx={{ borderRadius: '8px' }}
              >
                {selectedProduct?.variants && selectedProduct?.variants.map((variant, index) => (
                  <MenuItem key={index} value={variant.id}>{variant.title}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <NumberInput
              key={inputKey}
              aria-label="Quantity Input"
              min={1}
              defaultValue={1}
              max={99}
              onChange={(event, val) => setSelectedQuantity(val)}
              readOnly
            />
          </Box>

          <Box sx={{ maxWidth: "100%", width: "90%", paddingTop: "10px" }} className="shipify-drawer-product-shipping">
          <div className={isMobile ? 'shipify-drawer-shipping-methods-container-mobile' : 'shipify-drawer-shipping-methods-container'}>
          {!isMobile ?
                <div className='shipify-drawer-countries-block'>
                    <div className='shipify-drawer-shipping-title'>{t("SHIPIFY_PRODUCTS_DRAWER_RATES_SHIP_TO")}</div>
                    <TextField
                    label={t("SHIPIFY_PRODUCTS_DRAWER_COUNTRY_SELECTOR_SEARCH")}
                    variant="outlined"
                    fullWidth
                    value={searchTerm}
                    onChange={handleSearchChange}
                    size='small'
                    sx={{ maxWidth: '90%', margin: '0 auto' }}
                    InputProps={{
                        endAdornment: (
                        <InputAdornment position="end" sx={{ fontSize: 20 }}>
                            <MdSearch />
                        </InputAdornment>
                        ),
                    }}
                    />
                    <List className='shipify-drawer-country-list'>
                    {filteredCountries.map((country, index) => (
                        <ListItemButton className={selectedCountry[0]?.code === country.code ? 'shipify-country-item selectedItem' : 'shipify-country-item'} key={index} value={country.code} onClick={() => handleCountrySelect(country.code, country.name)}>
                        <img
                            loading="lazy"
                            width="20"
                            srcSet={`https://flagcdn.com/w40/${country.code.toLowerCase()}.png 2x`}
                            src={`https://flagcdn.com/w20/${country.code.toLowerCase()}.png`}
                            alt=""
                        />
                        <ListItemText primary={country.name} />
                        </ListItemButton>
                    ))}
                    {filteredCountries.length === 0 && (
                        <ListItem>
                        <ListItemText primary={t("SHIPIFY_PRODUCTS_DRAWER_NO_COUTRNY_LABEL")} />
                        </ListItem>
                    )}
                    </List>
                </div>
                :
                <div className='shipify-drawer-countries-block'>
                    <Autocomplete
                    id="country-select-demo"
                    options={ALL_COUNTRIES}
                    autoHighlight
                    getOptionLabel={(option) => option ? option.name : ''}
                    size='small'
                    value={selectedCountry.length > 0 ? selectedCountry[0] : ''}
                    onChange={(event, option) => {
                        if (option !== null) {
                        handleCountrySelect(option.code, option.name);
                        } else {
                        setSelectedCountry([{ code: '', name: '' }]);
                        }
                    }}
                    renderOption={(props, option) => (
                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props} value={option.code} >
                        <img
                            loading="lazy"
                            width="20"
                            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                            src={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png`}
                            alt=""
                        />
                        {option.name}
                        </Box>
                    )}
                    renderInput={(params) => (
                        <TextField
                        {...params}
                        label={t("SHIPIFY_PRODUCTS_DRAWER_COUNTRY_SELECTOR_MOBILE")}
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                        />
                    )}
                    isOptionEqualToValue={(option, value) => option.name === value.name}
                    />

                </div>
                }
              <div className='shipify-drawer-shipping-details-block'>
              {!isMobile && <div className='shipify-ship-country'>{selectedCountry[0]?.name}</div>}
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#fafafa', minWidth: '100%', height: "calc(100% - 75px)", padding: '15px' }}>
                  {isLoading ? (
                    <CircularProgress />
                  ) : rowsRates.length > 0 ? (
                    <DataGrid
                      className='shipify-products-data-grid'
                      rows={rowsRates}
                      columns={columnsRates}
                      initialState={{
                        pagination: {
                          paginationModel: { pageSize: 10, page: 0 },
                        },
                      }}
                      disableRowSelectionOnClick
                      hideFooter
                    />
                  ) : selectedCountry[0]?.code && selectedVariant && !isRates && !isLoading ?
                    <Alert
                      severity="error"
                      sx={{ width: '100%', justifyContent: 'center', textAlign: 'center' }}
                    >
                      {t("SHIPIFY_NO_RATES_MESSAGE")}
                    </Alert>
                    : (!selectedCountry[0]?.code || !selectedVariant) && (
                      t("SHIPIFY_PRODUCTS_DRAWER_RATES_SELECT")
                    )}
                </Box>
              </div>
            </div>
          </Box>
        </Box>
      )}
    </Drawer>
  );
}

