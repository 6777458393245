import React from 'react';
import './ShipifyHowToUsePage.css';
import ImageBox from 'components/ImageBox/ImageBox';
import HowToUseImage0 from 'assets/banner-how.jpg';
import HowToUseImage1 from 'assets/ccs-how.jpg';
import HowToUseImage2 from 'assets/groups-how.jpg';
import HowToUseImage3 from 'assets/display-how.jpg';
import HowToUseImage4 from 'assets/custom-service-how.png';
import { SHIPIFY_HOW_TO_USE_LEARN_MORE_ABOUT_SHOPIFY_CCS_LINK_URL } from 'constants/general.constants';

export default function ShipifyHowToUsePage() {
    return (
        <div className='how-to-use-page-container'>
            <ImageBox
                image={HowToUseImage0}
                className='how-to-use-image'
            />

            <div className='how-to-use-page-text-section'>
                <div className='how-to-use-page-section-title'>How to Use Shipify</div>
                <p>
                    This user guide is intended for you so that you can use and configure the application effectively in your store and for information on the application features. Follow these simple steps to get started:
                </p>
                <br />
                <div className='how-to-use-page-section-title'>Activating Third-Party Calculated Shipping</div>
                <p>
                    Before diving into the functionalities of our app ensure that Third-Party Calculated Shipping is activated on your Shopify store. If this feature is not enabled, follow Shopify's guidelines to activate it.
                </p>
                <p>
                    Learn more about Shopify CCS – <a className='how-to-use-page-link' href={SHIPIFY_HOW_TO_USE_LEARN_MORE_ABOUT_SHOPIFY_CCS_LINK_URL} target='_blank'>Here</a>
                </p>
            </div>

            <ImageBox
                image={HowToUseImage1}
                className='how-to-use-image'
            />

            <div className='how-to-use-page-text-section'>
                <br />
                <div className='how-to-use-page-section-title'>Creating a Shipping Group</div>
                <p>
                    In order for you to create and manage shipping methods for your products, a shipping group must be created. Shipping group components:
                </p>
                <ol>
                    <li><div className='how-to-use-page-ordered-list-item-label'> Group Name</div>Used as an internal identifier for you and not shown to customers.</li>
                    <br />
                    <li><div className='how-to-use-page-ordered-list-item-label'> Properties</div>
                        <ul>
                            <li>Select countries - for which the shipping methods you will create later will be displayed.</li>
                            <li>Select collections - you need to select collections of products for which you want to create
                                shipping methods. To fully control how products are associated with shipping methods, you can use manual or automatic collection creation from your Shopify panel.</li>
                        </ul>
                    </li>
                    <br />
                    <li>
                        <div className='how-to-use-page-ordered-list-item-label'> Add Rates</div>After you have defined for which countries and products you want to display the shipping methods, it's time to create them, Shipping method properties:
                        <ul>
                            <li>Rate Name - that will be displayed to the end customers</li>
                            <li>Estimated Delivery - times in minimum/maximum days</li>
                            <li>Method Price - according to the default currency of your Shopify store (the currency
                                conversion will be done for end customers from our App Block)</li>
                        </ul>
                    </li>
                </ol>
            </div>

            <ImageBox
                image={HowToUseImage2}
                className='how-to-use-image'
            />

            <div className='how-to-use-page-text-section'>
                <br />
                <div className='how-to-use-page-section-title'>Displaying Shipping Methods in Your Store</div>
                <p>
                    Using our app you can allow your customers to choose shipping methods on the product pages as well as on the cart page.
                </p>
                <p>
                    In order to implement the display and selection of shipping methods in your store you will need:
                </p>
                <ul>
                    <li>Add Our App Block named "Shipify App Block" to display shipping methods on product page.</li>
                    <li>Activate our App Embed to display shipping methods on the cart page.</li>
                </ul>
                <p>
                    Our app block can be easily customized, adapted for mobile and desktop display, and also allows translation and modification of the text for all languages.
                </p>
                <p>
                    The implementation process is carried out from your Shopify admin panel:
                </p>
                <p>
                    <b>Online Store</b> &rarr; <b>Themes</b> &rarr; <b>Customize</b>
                </p>
            </div>

            <ImageBox
                image={HowToUseImage3}
                className='how-to-use-image'
            />

            <div className='how-to-use-page-text-section'>
                <br />
                <div className='how-to-use-page-section-title'>Custom Service Name & Description on Checkout</div>
                <p>
                    Ensure a smooth checkout process by setting a Service Name and Description for your rate. This rate will be displayed on the checkout page, providing clarity to your customers about the total price of the shipping methods they selected before.
                </p>
            </div>

            <ImageBox
                image={HowToUseImage4}
                className='how-to-use-image'
            />

            <div className='how-to-use-page-text-section'>
                <br />
                <div className='how-to-use-page-section-title'>Running the App in Your Store</div>
                <p>
                    Once all configurations are in place, it's time to run Shipify in your store. Enjoy the seamless shipping management experience and provide your customers with reliable and transparent shipping methods all over the store and break free from cart-wide shipping limitations.
                </p>
            </div>
        </div>
    );
}
