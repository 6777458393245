import React, { useEffect, useState } from 'react'
import './ShippingGroupView.css'
import { ALL_COUNTRIES, SIDEBAR_MODES, VIEW_MODES } from 'constants/general.constants'
import { t } from 'i18next'
import { useHistoryContext } from 'contexts/History'
import { SHIPIFY_HOME_PAGE_URL, SHIPIFY_SHIPPING_GROUPS_PAGE_URL } from 'constants/routes.constants'
import PaydinDialog, { PAYDIN_DIALOG_BUTTON_TYPES } from 'dialogs/PaydinDialog/PaydinDialog'
import ApprovalMessage from 'components/ApprovalMessage/ApprovalMessage'
import { isMobile } from 'react-device-detect'
import { logger } from 'services/CloudLogger'
import PageViewTopbar from 'components/PageViewTopbar/PageViewTopbar'
import DashboardLoader from 'components/DashboardLoader/DashboardLoader'
import LoaderPage from 'pages/LoaderPage/LoaderPage'
import LoaderButton from 'components/LoaderButton/LoaderButton'
import { useUserDetailsContext } from 'contexts/shipify_context'
import EditShippingGroupSection from 'components/EditShippingGroupSection/EditShippingGroupSection'
import { useParams } from 'react-router-dom'
import BackButton from 'components/BackButton/BackButton'

/**
 * Represents a portion of the screen that displays the influencer's edit/create panel.
 * @param {string} mode - The mode of this view, it determins whether it is creating or editing an influencer, accepts VIEW_MODES.CREATE or VIEW_MODES.EDIT
 */
export default function ShippingGroupView({
    mode
}) {
    const history = useHistoryContext()
    const {
        userDetails,
        createShippingGroup,
        editShippingGroup,
        findCollectionInContext
    } = useUserDetailsContext()

    const { shippingGroupId } = useParams()
    const [isLoadingInfluencer, setIsLoadingInfluencer] = useState(false)
    const [isButtonProcessing, setIsButtonProcessing] = useState(false)
    const [isAbleToSaveShippingGroup, setIsAbleToSaveShippingGroup] = useState(false)
    const [shippingGroupPropertiesModified, setShippingGroupPropertiesModified] = useState(false)
    const [groupName, setGroupName] = useState('')
    const [selectedCountries, setSelectedCountries] = useState([])
    const [selectedCollections, setSelectedCollections] = useState([])
    const [groupRates, setGroupRates] = useState([])
    const [canCommenceInitialization, setCanCommenceInitialization] = useState(false)
    const [dialogState, setDialogState] = useState({
        isOpen: false,
        handleDialogClose: closeDialog,
        title: '',
        message: '',
        leftButtonText: '',
        rightButtonText: '',
        rightButtonClickHandler: () => { }
    })
    const [approvalMessageState, setApprovalMessageState] = useState({
        didChangesSaved: false,
        message: ''
    })
    const [areRatesValid, setAreRatesValid] = useState(false)

    useEffect(() => {
        if (mode === VIEW_MODES.EDIT) {
            const currentShippingGroup = userDetails?.shippingGroups.find(shippingGroup => shippingGroup?.id === shippingGroupId)
            if (currentShippingGroup) {
                setGroupName(currentShippingGroup?.groupName)
                setSelectedCountries(currentShippingGroup?.countries.map(countryCode => {
                    const currentCountry = ALL_COUNTRIES.find(country => country?.code === countryCode)
                    return currentCountry
                }))
                setSelectedCollections(currentShippingGroup?.collections.map(collectionId => findCollectionInContext(collectionId)))
                setGroupRates(currentShippingGroup?.rates)
            } else {
                history.replace(SHIPIFY_HOME_PAGE_URL)
            }
        }
        setCanCommenceInitialization(true)
    }, [])

    useEffect(() => {
        setIsAbleToSaveShippingGroup(
            groupName !== '' &&
            areRatesValid && 
            selectedCollections.length > 0 &&
            selectedCountries.length > 0
        )
    }, [groupName, areRatesValid, selectedCollections, selectedCountries])

    function setShippingGroupName(value) {
        setShippingGroupPropertiesModified(true)
        setGroupName(value)
    }

    function setCountries(value) {
        setShippingGroupPropertiesModified(true)
        setSelectedCountries(value)
    }

    function setCollections(value) {
        setShippingGroupPropertiesModified(true)
        setSelectedCollections(value)
    }

    function setRates(value) {
        setShippingGroupPropertiesModified(true)
        setGroupRates(value)
    }

    function renderContent() {
        if (mode === VIEW_MODES.EDIT) {
            return <EditShippingGroupSection
                mode={mode}
                groupName={groupName}
                setGroupName={setShippingGroupName}
                selectedCountries={selectedCountries}
                setSelectedCountries={setCountries}
                selectedCollections={selectedCollections}
                setSelectedCollections={setCollections}
                rates={groupRates}
                setRates={setRates}
                canCommenceInitialization={canCommenceInitialization}
                setAreRatesValid={setAreRatesValid}
            />
        } else {
            return <EditShippingGroupSection
                mode={mode}
                groupName={groupName}
                setGroupName={setShippingGroupName}
                selectedCountries={selectedCountries}
                setSelectedCountries={setCountries}
                selectedCollections={selectedCollections}
                setSelectedCollections={setCollections}
                rates={groupRates}
                setRates={setGroupRates}
                canCommenceInitialization={canCommenceInitialization}
                setAreRatesValid={setAreRatesValid}
            />
        }
    }

    async function save() {
        setIsButtonProcessing(true)

        const countryCodes = selectedCountries.map(country => country?.code)
        const collectionIds = selectedCollections.map(collection => collection?.id)

        if (mode === VIEW_MODES.CREATE) {
            createShippingGroup(groupName, countryCodes, collectionIds, groupRates, () => {
                history.push(SHIPIFY_SHIPPING_GROUPS_PAGE_URL)
            }, error => {
                logger.error(`Error - create shipping group failure - ${error}`)
                showSaveDetailsErrorMessage()
                setIsButtonProcessing(false)
            })
        } else {
            editShippingGroup(shippingGroupId, groupName, countryCodes, collectionIds, groupRates, () => {
                setApprovalMessageState(prev => ({
                    ...prev,
                    didChangesSaved: true,
                    message: t('SHIPIFY_EDIT_SHIPPING_GROUP_SECTION_EDIT_SHIPPING_GROUP_SUCCESS')
                }))
                setIsButtonProcessing(false)
                setShippingGroupPropertiesModified(false)
            }, error => {
                logger.error(`Error - edit shipping group failure - ${error}`)
                showSaveDetailsErrorMessage()
                setIsButtonProcessing(false)
            })
        }
    }

    function closeDialog() {
        setDialogState(prev => ({
            ...prev,
            isOpen: false
        }))
    }

    function showSaveDetailsErrorMessage() {
        setDialogState(prev => ({
            ...prev,
            isOpen: true,
            title: t('EDIT_LINK_PAGE_SAVE_DETAILS_ERROR_DIALOG_TITLE'),
            leftButtonText: t('EDIT_LINK_PAGE_SAVE_DETAILS_ERROR_DIALOG_RIGHT_BUTTON_TEXT'),
            rightButtonText: null,
            rightButtonClickHandler: () => { },
            isRightButtonWithLoader: false
        }))
    }

    function showDiscardChangesDialog() {
        setDialogState(prev => ({
            ...prev,
            isOpen: true,
            title: t('EDIT_INFLUENCER_SECTION_DISCARD_CHANGES_DIALOG_TITLE'),
            message: t('EDIT_INFLUENCER_SECTION_DISCARD_CHANGES_DIALOG_MESSAGE'),
            leftButtonText: t('EDIT_INFLUENCER_SECTION_DISCARD_CHANGES_DIALOG_LEFT_BUTTON_TEXT'),
            rightButtonText: t('EDIT_INFLUENCER_SECTION_DISCARD_CHANGES_DIALOG_RIGHT_BUTTON_TEXT'),
            rightButtonClickHandler: () => history.goBack(2),
            isRightButtonWithLoader: false
        }))
    }

    function goBack() {
        if (shippingGroupPropertiesModified) {
            showDiscardChangesDialog()
        } else {
            history.goBack()
        }
    }

    function renderTopbarSaveButton() {
        switch (mode) {
            case VIEW_MODES.CREATE: return isMobile ? t('SHIPIFY_EDIT_SHIPPING_GROUP_SECTION_CREATE_GROUP_MOBILE_BUTTON_TEXT') : t('SHIPIFY_EDIT_SHIPPING_GROUP_SECTION_CREATE_GROUP_BUTTON_TEXT')
            case VIEW_MODES.EDIT: return isMobile ? t('SHIPIFY_EDIT_SHIPPING_GROUP_SECTION_EDIT_GROUP_MOBILE_BUTTON_TEXT') : t('SHIPIFY_EDIT_SHIPPING_GROUP_SECTION_EDIT_GROUP_BUTTON_TEXT')
            default: return ''
        }
    }

    function getTitle() {
        return mode === VIEW_MODES.CREATE ? t('SHIPIFY_EDIT_SHIPPING_GROUP_SECTION_CREATE_GROUP_TITLE') : t('SHIPIFY_EDIT_SHIPPING_GROUP_SECTION_EDIT_GROUP_TITLE')
    }

    return (
        <div className={isMobile ? 'mobile-shipping-group-view-container' : 'shipping-group-view-container'}>
            {
                isMobile ? <PageViewTopbar
                    title={getTitle()}
                    buttonText={isMobile? "Save": "Save Changes"}
                    isButtonLoading={isButtonProcessing}
                    isButtonDisabled={!isAbleToSaveShippingGroup || isButtonProcessing}
                    onBackButtonClick={goBack}
                    onButtonClick={save}
                    rightElement={null}
                /> : <div className={`shipping-group-view-titles-container ${userDetails?.sidebarMode === SIDEBAR_MODES.MINIMIZED && 'minimized'}`}>
                    <BackButton
                        disableHistoryPopOnClick={true}
                        onClick={goBack}
                    />
                    <div className="shipping-group-view-title">{getTitle()}</div>
                    <LoaderButton
                        className='shipping-group-view-save-button'
                        buttonText={renderTopbarSaveButton()}
                        isLoading={isButtonProcessing}
                        isDisabled={!isAbleToSaveShippingGroup || isButtonProcessing}
                        onClick={save}
                    />
                </div>
            }
            <div className={isMobile ? "mobile-shipping-group-view-content" : "shipping-group-view-content"}>
                {isLoadingInfluencer && (isMobile ? <LoaderPage styles={{ backgroundColor: '#ffffffaa' }} isContained={true} isFullscreen={true} /> : <DashboardLoader />)}
                {renderContent()}
            </div>
            {
                dialogState.isOpen && <PaydinDialog
                    isDialogOpen={dialogState.isOpen}
                    handleDialogClose={dialogState.handleDialogClose}
                    title={dialogState.title}
                    message={dialogState.message}
                    closeOnRightClick={false}
                    rightButtonType={PAYDIN_DIALOG_BUTTON_TYPES.SECONDARY}
                    leftButtonText={dialogState.leftButtonText}
                    rightButtonText={dialogState.rightButtonText}
                    isLeftButtonWithLoader={false}
                    isRightButtonWithLoader={dialogState.isRightButtonWithLoader}
                    onRightButtonClick={dialogState.rightButtonClickHandler}
                />
            }
            <ApprovalMessage
                isMessageShown={approvalMessageState.didChangesSaved}
                closeMessage={() => setApprovalMessageState(prev => ({ ...prev, didChangesSaved: false }))}
                text={approvalMessageState.message}
            />
        </div>
    )
}