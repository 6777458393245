import {
     SHIPIFY_COLLECTIONS_API_ROUTE, SHIPIFY_COUNTRIES_API_ROUTE, STORE_ORDERS_API_URL_SUFFIX,
} from "constants/api.constants"
import { request_auth } from "services/RequestWrapper"
import { environment } from 'conf'
const baseUrl = '/store'

/*
    Retrieves all the collections.

    Method: [GET]
    URL: /store/collections
*/
export function getStoreCollections() {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'GET'
        }

        const url = environment.api_url + baseUrl + SHIPIFY_COLLECTIONS_API_ROUTE
        request_auth(url, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data.collections)
                } else {
                    reject(response.error)
                }
            })
            .catch(reject)
    })
}

/*
    Retrieves all the store's supported countries.

    Method: [GET]
    URL: /store/countries
*/
export function getStoreSupportedCountries() {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'GET'
        }

        const url = environment.api_url + baseUrl + SHIPIFY_COUNTRIES_API_ROUTE
        request_auth(url, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data)
                } else {
                    reject(response.error)
                }
            })
            .catch(reject)
    })
}



export function getStoreOrders() {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'GET'
        }

        const url = environment.api_url + baseUrl + STORE_ORDERS_API_URL_SUFFIX
        request_auth(url, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data)
                } else {
                    reject(response.error)
                }
            })
            .catch(reject)
    })
}