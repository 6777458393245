import React, { useState } from 'react'
import './DashboardTopBar.css'
import {
    LINKS_PAGE_MOBILE_OPTIONS_DRAWER_TOP_BORDER_RADIUS_PX,
    MOBILE_DASHBOARD_TOPBAR_OPTIONS_DRAWER_CLOSING_DELAY,
    PAYDIN_DRAWER_DIRECTION,
    PAYDIN_DRAWER_TRANSITION_DURATION_WITH_BUFFER,
    PRIVACY_POLICY_URL,
    TERMS_OF_USE_URL,
    CONTACT_US_URL,
    CALENDLY_MEETING_LINK
} from 'constants/general.constants'
import { CiLogout } from 'react-icons/ci'
import { MdOutlinePrivacyTip } from 'react-icons/md'
import { MdContactSupport, MdSettings } from 'react-icons/md'
import { GrDocumentText } from 'react-icons/gr'
import { AiOutlineClose } from 'react-icons/ai'
import { useTranslation } from 'react-i18next'
import {
    SHIPIFY_HOW_TO_USE_PAGE_URL,
    SHIPIFY_SHIPPING_GROUPS_PAGE_URL,
    SHIPIFY_PRODUCTS_PAGE_URL,
    SHIPIFY_SETTINGS_AND_PLANS_PAGE_URL,
    SHIPIFY_HOME_PAGE_URL,
    SHIPIFY_SHIPPING_COMPANIES_PAGE_URL
} from 'constants/routes.constants'
import { useHistoryContext } from 'contexts/History'
import { BrowserView, MobileView, isMobile } from 'react-device-detect'
import PaydinDialog from 'dialogs/PaydinDialog/PaydinDialog'
import { PAYDIN_DIALOG_BUTTON_TYPES } from 'dialogs/PaydinDialog/PaydinDialog'
import PaydinDrawer from 'components/PaydinDrawer/PaydinDrawer'
import PaydinDrawerListContent from 'components/PaydinDrawerListContent/PaydinDrawerListContent'
import { useUserDetailsContext } from 'contexts/shipify_context'
import ShipifySidebarHomeImage from 'embeddedImages/ShipifySidebarHomeImage'
import ShipifySidebarSettingsImage from 'embeddedImages/ShipifySidebarSettingsImage'
import ShipifySmallLogo from 'embeddedImages/ShipifySmallLogo'
import ShipifyShippingImage from 'embeddedImages/ShipifyShippingImage'
import ShipifySidebarHowToUseImage from 'embeddedImages/ShipifySidebarHowToUseImage'
import ShipifySidebarShippingCompImage from 'embeddedImages/ShipifySidebarShippingCompImage'
import ShipifyProductsImage from 'embeddedImages/ShipifyProductsImage'
import ShipifySmallLogoV2 from 'embeddedImages/ShipifySmallLogoV2'
import { FaRegCalendarCheck } from 'react-icons/fa6'



/**
 * Represents the dashboard top bar for all admin pages.
 */
export default function DashboardTopBar() {
    const { t } = useTranslation()
    const {
        userDetails,
        logout,
        isFreeUser
    } = useUserDetailsContext()
    const history = useHistoryContext()

    const [isLogoutConfirmationDialogOpen, setIsLogoutConfirmationDialogOpen] = useState(false)
    const [optionsDrawerState, setOptionsDrawerState] = useState({
        isOpen: false,
        onOpen: openMoreOptionsDrawer,
        onClose: closeMoreOptionsDrawer,
        children: <></>
    })

    function showLoginConfirmationDialog() {
        optionsDrawerState.onClose()

        setTimeout(() => {
            setIsLogoutConfirmationDialogOpen(true)
        }, MOBILE_DASHBOARD_TOPBAR_OPTIONS_DRAWER_CLOSING_DELAY)
    }

    function goToHome(triggeredFromDrawer = true) {
        closeDrawerAndGoToRoute(SHIPIFY_HOME_PAGE_URL, triggeredFromDrawer)
    }

    function goToSettings(triggeredFromDrawer = true) {
        closeDrawerAndGoToRoute(SHIPIFY_SETTINGS_AND_PLANS_PAGE_URL, triggeredFromDrawer)
    }

    function goToProducts(triggeredFromDrawer = true) {
        closeDrawerAndGoToRoute(SHIPIFY_PRODUCTS_PAGE_URL, triggeredFromDrawer)
    }

    function goToShipping(triggeredFromDrawer = true) {
        closeDrawerAndGoToRoute(SHIPIFY_SHIPPING_GROUPS_PAGE_URL, triggeredFromDrawer)
    }

    function goToHowToUse(triggeredFromDrawer = true) {
        closeDrawerAndGoToRoute(SHIPIFY_HOW_TO_USE_PAGE_URL, triggeredFromDrawer)
    }

    function goToSupplierCouriers(triggeredFromDrawer = true) {
        closeDrawerAndGoToRoute(SHIPIFY_SHIPPING_COMPANIES_PAGE_URL, triggeredFromDrawer)
    }

    function goToContactUs() {
        goToUrl(CONTACT_US_URL)
    }

    function goToPrivacyPolicy() {
        goToUrl(PRIVACY_POLICY_URL)
    }

    function goToTermsAndConditions() {
        goToUrl(TERMS_OF_USE_URL)
    }
    function logOut() {
        logout()
    }

    function closeDialog() {
        setIsLogoutConfirmationDialogOpen(false)
    }

    function closeMoreOptionsDrawer() {
        setOptionsDrawerState(prev => ({
            ...prev,
            isOpen: false
        }))
    }

    function goToUrl(url) {
        window.location.href = url
    }
    

    function closeDrawerAndGoToRoute(url, triggeredFromDrawer = true) {
        if (isMobile) {
            if (triggeredFromDrawer) {
                history.goBack()
                if (window.location.pathname !== url) {
                    setTimeout(() => { // wait for the drawer to close
                        history.push(url)
                    }, PAYDIN_DRAWER_TRANSITION_DURATION_WITH_BUFFER)
                }
            } else {
                history.push(url)
            }
        } else {
            if (window.location.pathname !== url) {
                history.push(url)
            }
        }
    }

    function renderUserCircle() {
        return <div onClick={openMoreOptionsDrawer} className='mobile-dashboard-topbar-user-circle-button mobile-dashboard-button'>
            <div onClick={openMoreOptionsDrawer} className='mobile-dashboard-topbar-user-circle-button-image mobile-dashboard-topbar-button-image'>
                <div className="mobile-dashboard-topbar-user-circle-button-username-initials-cirlce">
                    <div className="mobile-dashboard-topbar-user-circle-button-text">{userDetails?.username?.[0] ?? ''}</div>
                </div>
            </div>
        </div>
    }

    function renderRightSection() {
        return (<>
            <div className='mobile-dashboard-topbar-right-section'>
                {renderUserCircle()}
            </div>
        </>)
    }

    const handleLinkClick = () => {
        window.open(CALENDLY_MEETING_LINK, '_blank');
    };

    function openMoreOptionsDrawer() {
        setOptionsDrawerState(prev => ({
            ...prev,
            isOpen: true,
            onClose: closeMoreOptionsDrawer,
            children: <>
                <PaydinDrawerListContent
                    options={[
                        {
                            text: t('DASHBOARD_TOPBAR_SETTINGS_BUTTON_TEXT'),
                            image: <MdSettings className='mobile-dashboard-topbar-more-options-drawer-option-image' />,
                            onClick: goToSettings
                        },
                        // {
                        //     text: t('DASHBOARD_TOPBAR_HOW_TO_USE_BUTTON_TEXT'),
                        //     image: <GrDocumentText className='mobile-dashboard-topbar-more-options-drawer-option-image' />,
                        //     onClick: goToHowToUse
                        // },
                        {
                            text: t('MOBILE_DASHBOARD_TOPBAR_MORE_OPTIONS_DRAWER_OPTION_CONTACT_US'),
                            image: <MdContactSupport className='mobile-dashboard-topbar-more-options-drawer-option-image' />,
                            onClick: goToContactUs
                        },
                        {
                            text: t('SHIPIFY_BOOK_AN_EXPERT_BUTTON'),
                            image: <FaRegCalendarCheck className='mobile-dashboard-topbar-more-options-drawer-option-image' />,
                            onClick: handleLinkClick
                        },
                        {
                            text: t('MOBILE_DASHBOARD_TOPBAR_MORE_OPTIONS_DRAWER_OPTION_PRIVACY_POLICY'),
                            image: <MdOutlinePrivacyTip className='mobile-dashboard-topbar-more-options-drawer-option-image' />,
                            onClick: goToPrivacyPolicy
                        },
                        {
                            text: t('MOBILE_DASHBOARD_TOPBAR_MORE_OPTIONS_DRAWER_OPTION_LOGOUT'),
                            className: 'mobile-dashboard-topbar-more-options-drawer-option-logout',
                            image: <CiLogout className='mobile-dashboard-topbar-more-options-drawer-option-image' />,
                            onClick: showLoginConfirmationDialog
                        },
                        {
                            text: t('MOBILE_DASHBOARD_TOPBAR_MORE_OPTIONS_DRAWER_OPTION_CANCEL'),
                            image: <AiOutlineClose className='mobile-dashboard-topbar-more-options-drawer-option-image' />,
                            onClick: closeMoreOptionsDrawer
                        }
                    ]}
                />
            </>
        }))
    }

    return (
        <>
            <BrowserView>
                <div className='dashboard-topbar-container'>
                    <div className="dashboard-topbar-content">
                        {/* <LayoutTopbarRightSection /> */}
                    </div>
                    <div className="dashboard-topbar-bottom-line" />
                </div>
            </BrowserView>

            <MobileView>
                <div className='mobile-dashboard-topbar-container'>
                    <div className="mobile-dashboard-topbar-content">
                        <div className="mobile-dashboard-topbar-paylink-title-container">
                            <div className="mobile-dashboard-topbar-paylink-logo-container">
                                <ShipifySmallLogoV2 className='mobile-dashboard-topbar-paylink-logo' />
                                <div className="mobile-dashboard-topbar-store-name">{userDetails?.username}</div>
                            </div>
                            {renderRightSection()}
                        </div>
                        <div className="mobile-dashboard-topbar-buttons-container">
                            <div onClick={() => goToHome(false)} className={`mobile-dashboard-topbar-links-button mobile-dashboard-button mobile-dashboard-button ${window.location.pathname === SHIPIFY_HOME_PAGE_URL ? 'selected' : ''} ${isFreeUser() ? 'disabled' : ''}`}>
                                <ShipifySidebarHomeImage className='mobile-dashboard-topbar-links-button-image mobile-dashboard-topbar-button-image' />
                                <div className="mobile-dashboard-topbar-links-button-label">{t('DASHBOARD_TOPBAR_HOME_BUTTON_TEXT')}</div>
                            </div>
                            <div onClick={() => goToShipping(false)} className={`mobile-dashboard-topbar-links-button mobile-dashboard-button mobile-dashboard-button ${window.location.pathname === SHIPIFY_SHIPPING_GROUPS_PAGE_URL ? 'selected' : ''} ${isFreeUser() ? 'disabled' : ''}`}>
                                <ShipifyShippingImage className='mobile-dashboard-topbar-shipping-groups-button-image mobile-dashboard-topbar-button-image' />
                                <div className="mobile-dashboard-topbar-links-button-label">{t('DASHBOARD_TOPBAR_SHIPPING_GROUPS_BUTTON_TEXT')}</div>
                            </div>
                            {/* Leave this code in comment */}
                            <div onClick={() => goToProducts(false)} className={`mobile-dashboard-topbar-shorts-button mobile-dashboard-button mobile-dashboard-button ${window.location.pathname === SHIPIFY_PRODUCTS_PAGE_URL ? 'selected' : ''} ${isFreeUser() ? 'disabled' : ''}`}>
                                <ShipifyProductsImage className='mobile-dashboard-topbar-shorts-button-image mobile-dashboard-topbar-button-image' />
                                <div className="mobile-dashboard-topbar-links-button-label">{t('DASHBOARD_TOPBAR_PRODUCTS_BUTTON_TEXT')}</div>
                            </div>
                            <div onClick={() => goToSupplierCouriers(false)} className={`mobile-dashboard-topbar-shorts-button mobile-dashboard-button mobile-dashboard-button ${window.location.pathname === SHIPIFY_SHIPPING_COMPANIES_PAGE_URL ? 'selected' : ''} ${isFreeUser() ? 'disabled' : ''}`}>
                                <ShipifySidebarShippingCompImage className='mobile-dashboard-topbar-shorts-button-image mobile-dashboard-topbar-button-image' />
                                <div className="mobile-dashboard-topbar-links-button-label">{t('DASHBOARD_TOPBAR_SHIPPING_COMPANIES_BUTTON_TEXT_MOBILE')}</div>
                            </div>
                            {/* <div onClick={() => goToSettings(false)} className={`mobile-dashboard-topbar-analytics-button mobile-dashboard-button ${window.location.pathname === SHIPIFY_SETTINGS_AND_PLANS_PAGE_URL ? 'selected' : ''} ${isFreeUser() ? 'disabled' : ''}`}>
                                <ShipifySidebarSettingsImage className='mobile-dashboard-topbar-influencers-button-image mobile-dashboard-topbar-button-image' />
                                <div className="mobile-dashboard-topbar-links-button-label">{t('DASHBOARD_TOPBAR_SETTINGS_BUTTON_TEXT')}</div>
                            </div> */}
                        </div>
                    </div>
                    <PaydinDrawer
                        direction={PAYDIN_DRAWER_DIRECTION.BOTTOM}
                        isOpen={optionsDrawerState.isOpen}
                        onOpen={optionsDrawerState.onOpen}
                        onClose={optionsDrawerState.onClose}
                        hasPadding={false}
                        topRightBorderRadiusPx={LINKS_PAGE_MOBILE_OPTIONS_DRAWER_TOP_BORDER_RADIUS_PX}
                        topLeftBorderRadiusPx={LINKS_PAGE_MOBILE_OPTIONS_DRAWER_TOP_BORDER_RADIUS_PX}
                    >
                        {optionsDrawerState.children}
                    </PaydinDrawer>
                </div>
            </MobileView>
            {
                isLogoutConfirmationDialogOpen && <PaydinDialog
                    title={t('DASHBOARD_TOPBAR_LOGOUT_CONFIRMATION_DIALOG_TITLE')}
                    message={t('DASHBOARD_TOPBAR_LOGOUT_CONFIRMATION_DIALOG_MESSAGE')}
                    isDialogOpen={isLogoutConfirmationDialogOpen}
                    handleDialogClose={closeDialog}
                    leftButtonText={t('DASHBOARD_TOPBAR_LOGOUT_CONFIRMATION_DIALOG_LEFT_BUTTON_TEXT')}
                    rightButtonText={t('DASHBOARD_TOPBAR_LOGOUT_CONFIRMATION_DIALOG_RIGHT_BUTTON_TEXT')}
                    rightButtonType={PAYDIN_DIALOG_BUTTON_TYPES.SECONDARY}
                    onRightButtonClick={logOut}
                    closeOnRightClick={false}
                    isLeftButtonWithLoader={false}
                />
            }
        </>
    )
}