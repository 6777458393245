import React, { useState } from 'react';
import { Grid, List, ListItemText, ListItemButton, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import './ShipifyVideoPlayer.css'
import { MdVideoLibrary } from 'react-icons/md';
import { isMobile } from 'react-device-detect'


const useStyles = makeStyles((theme) => ({
  root: {
    float: 'right',
  },
  videoPlayer: {
    width: '100%',
    minHeight: '100%',
    height: '100%',
    borderRadius: '15px',
  },
  listItem: {
    cursor: 'pointer',
  },
}));

export default function VideoListPlayer({ videos }) {
  const classes = useStyles();
  const [selectedVideoIndex, setSelectedVideoIndex] = useState(0);

  const handleVideoSelect = (index) => {
    setSelectedVideoIndex(index);
  };

  return (
    <Grid item 
        maxWidth={'100%'} 
        container 
        flexDirection={isMobile ? 'column' : 'flex'} 
        spacing={2} 
        mt={'20px'} 
        justifyContent={isMobile ? 'center' : 'space-around'} 
        className={classes.root}
        gap={'20px'}
        flexWrap={'nowrap'}
    >
      <Grid item xs={4} width={'100%'} maxWidth={ isMobile? '100%' : '200px'} className='shipify-video-list-wrapper'>
        <List component="nav">
          {videos.map((video, index) => (
            <ListItemButton
            
              key={index}
              onClick={() => handleVideoSelect(index)}
              className={`${classes.listItem} ${selectedVideoIndex === index ? 'selected' : ''}`}
            >
                <MdVideoLibrary/>
              <ListItemText primary={video.title} />
            </ListItemButton>
          ))}
        </List>
      </Grid>
      <Grid item xs={8} width={'100%'} maxWidth={'100%'} paddingLeft={0} className='shipify-video-wrapper'> 
        <video key={videos[selectedVideoIndex].url} controls className={classes.videoPlayer}>
          <source src={videos[selectedVideoIndex].url} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </Grid>
    </Grid>
  );
};
