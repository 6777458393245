import { environment } from "conf"
import { ALL_PRODUCTS_API_BASE_URL, PRODUCT_MAPPING_SUFFIX_URL, SEARCH_ROUTE } from 'constants/api.constants'
import { request_auth } from "services/RequestWrapper"




/*
    Retrieves all collections.
    Method: [GET]
    URL: /admin/v1/collections/search

    Query params:
    =============
        (*) q - The search query string
        (*) count - The page capacity
        (*) skip - The number of elements skiped
*/
export function getProductMapping(product_id) {
    const baseUrl = ALL_PRODUCTS_API_BASE_URL + "/" + product_id + PRODUCT_MAPPING_SUFFIX_URL
    return new Promise((resolve, reject) => {
        const options = {
            method: 'GET',
        }
        request_auth(environment.api_url + baseUrl, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data);
                } else {
                    reject(response.error);
                }
            })
            .catch(error => {
                reject(error);
            });
    });
}


export function updateProductMapping(product_id, supplier_name, supplier_id, is_force, ship_to_country ) {
    const baseUrl = ALL_PRODUCTS_API_BASE_URL + "/" + product_id + PRODUCT_MAPPING_SUFFIX_URL
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST',
            body:{
                supplier_name,
                supplier_id,
                is_force: is_force,
                ship_to_country: ship_to_country
            }
        }
        request_auth(environment.api_url + baseUrl, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data);
                } else {
                    reject(response.error);
                }
            })
            .catch(error => {
                reject(error);
            });
    });
}


export function deleteProductMapping() {
    const baseUrl = ALL_PRODUCTS_API_BASE_URL + PRODUCT_MAPPING_SUFFIX_URL
    return new Promise((resolve, reject) => {
        const options = {
            method: 'DELETE',
        }
        request_auth(environment.api_url + baseUrl, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data);
                } else {
                    reject(response.error);
                }
            })
            .catch(error => {
                reject(error);
            });
    });
}