import React from 'react'
import './MultiLineText.css'

/**
 * Represents a text which can spread over a given number of lines.
 * @param {string} text - The displayed text
 * @param {string} className - The displayed text's class name
 * @param {number} lines - The given number of lines to spread the text over
 */
export default function MultiLineText({
    text = '',
    className = '',
    lines = 1,
    oneWord = false
}) {
    return (
        <div className={lines === 1 ? `one-line-text ${className}` : `multi-line-text ${oneWord && 'one-word'} ${className}`} style={{ WebkitLineClamp: lines }}>{text}</div>
    )
}