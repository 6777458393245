import React, { useState, useEffect } from 'react'
import './ShipifyPricingRules.css'
import { Stack, Paper, styled, Button, Badge, Box, Alert, Snackbar, Tooltip, tooltipClasses, InputAdornment } from '@mui/material'
import { DataGrid, GridToolbarQuickFilter, GridLogicOperator, heIL } from '@mui/x-data-grid';
import { useUserDetailsContext } from 'contexts/shipify_context'
import { isMobile } from 'react-device-detect'
import { FormControl, InputLabel, MenuItem, Select, TextField, IconButton } from '@mui/material';
import {
    Unstable_NumberInput as BaseNumberInput,
    numberInputClasses,
} from '@mui/base';
import { MdKeyboardArrowDown, MdKeyboardArrowUp, MdInfoOutline, MdInfo } from 'react-icons/md'
import { getPriceRules, updatePriceRules } from 'api/pricing_rules/pricing_rules';
import LoaderButton from 'components/LoaderButton/LoaderButton'
import { height } from '@mui/system';
import { useTranslation } from 'react-i18next'



const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        fontSize: '12px',
    },
}));



const NumberInput = ({ value, onValueChange, currency, isFixed}) => {
    const [inputValue, setInputValue] = useState(`${parseFloat(value).toFixed(2)}`);
    const [isFocused, setIsFocused] = useState(false);

    useEffect(() => {
        // Only update the inputValue from external value when not focused to avoid interrupting typing
        if (!isFocused) {
            setInputValue(`${parseFloat(value).toFixed(2)}`);
        }
    }, [value, isFocused]);


    const handleChange = (event) => {
        const newValue = event.target.value;
        let regex;
        // Determine the regex based on whether the operator is fixed
        if (isFixed) {
            // Allow negative and positive numbers
            regex = /^-?\d*\.?\d*$/;
        } else {
            // Allow only positive numbers
            regex = /^\d*\.?\d*$/;
        }
    
        // Only update the input value if it matches the regex
        if (regex.test(newValue)) {
            setInputValue(newValue);
            onValueChange(newValue === '' ? '0.00' : newValue);
        }
    };
    

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
        // When focus is lost, format the input
        let finalValue = parseFloat(inputValue);
        if (isNaN(finalValue)) {
            finalValue = 0.00; // Default to 0 if the input is not a number
        }
        const formattedValue = finalValue.toFixed(2);
        setInputValue(formattedValue);
        onValueChange(formattedValue);
    };

    const increment = () => {
        const newValue = (parseFloat(value) + 0.10).toFixed(2);
        setInputValue(newValue);
        onValueChange(newValue);
    };

    const decrement = () => {
        let newDecreasedValue = (parseFloat(value) - 0.10).toFixed(2);

        // Check if the operator is not fixed and the new value is below zero
        if (!isFixed && parseFloat(newDecreasedValue) < 0) {
            newDecreasedValue = '0.00';
        }
        
        setInputValue(newDecreasedValue);
        onValueChange(newDecreasedValue);
    };
    

    return (
        <Stack direction="row-reverse" spacing={0} alignItems="center">
            <TextField
                variant="outlined"
                value={inputValue}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                size="small"
                type="text"
                sx={{ width: '120px', textAlign: 'center' }}
                InputProps={{
                    endAdornment: <InputAdornment position="start">{currency}</InputAdornment>,
                  }}
            />
            <Stack direction="column"
                sx={{ height: '40px', justifyContent: 'center', background: '#eaeaea', borderBottomRightRadius: '4px', borderTopRightRadius: '4px' }}
                spacing={0} alignItems="center" position="absolute">
                <IconButton className='shipify-price-input-btn' aria-label="increase" size="small" onClick={increment}>
                    <MdKeyboardArrowUp />
                </IconButton>
                <IconButton className='shipify-price-input-btn' aria-label="decrease" size="small" onClick={decrement}>
                    <MdKeyboardArrowDown />
                </IconButton>
            </Stack>
        </Stack>
        
    );
};




const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    padding: theme.spacing(1),
    textAlign: 'center',
    boxShadow: "none"
}));


export default function ShipifyPricingRules() {
    // image1,
    // image2,
    // btn_text,
    // status
    const [originalOperator, setOriginalOperator] = useState('');
    const [originalValue, setOriginalValue] = useState(0.00);
    const [operator, setOperator] = useState('');
    const [value, setValue] = useState(0.00);
    const [rows, setRows] = useState([
        { id: "1", name: "AliExpress Standard", cost: "15", price: "" },
        { id: "2", name: "DHL Express", cost: "22.5", price: "" },
        { id: "3", name: "FedEx", cost: "33", price: "" },
    ]);
    const [change, setChange] = useState(false)
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // Can be 'error' or 'success'
    const [pricingRulesFetched, setPricingRulesFetched] = useState(false)

    const { t } = useTranslation()

    const {
        userDetails,
        setUserProperties,
        fetchPricingRules,
        updatePricingRules
    } = useUserDetailsContext()


    const calculateAndUpdateRows = () => {
        const updatedRows = rows.map((row) => {
            const cost = parseFloat(row.cost);
            let price = cost;

            if (operator === 'FIXED') {
                price = cost + parseFloat(value);
            } else if (operator === "PERCENT") {
                price = cost * parseFloat(value);
            }

            return { ...row, price: price.toFixed(2) };
        });

        setRows(updatedRows);
    };


    // Now, useEffect to re-calculate prices only when operator or value changes
    useEffect(() => {
        calculateAndUpdateRows();
        setChange(true)
    }, [operator, value]); // Do NOT include rows here to avoid the loop



    // const [rows, setRows] = useState(null);
    const handleChange = (event) => {
        setOperator(event.target.value);
        
        const isFixed = event.target.value == 'FIXED' ? true : false
        let newValue = parseFloat(value).toFixed(2);
    
        // If isFixed is false and the value is below zero, adjust it to zero
        if (!isFixed && parseFloat(newValue) < 0) {
            newValue = '0.00';
            setValue(newValue)
        };
    };

    const handleSaveChange = async () => {
        const intValue = parseFloat(value);

        try {
            const response = await updatePricingRules('BASIC', operator, intValue);
            // Assuming the response has a boolean 'status' indicating success
            if (response && response.status === true) {
                setOriginalOperator(operator);
                setOriginalValue(value);
                setSnackbarMessage('Update successful');
                setSnackbarSeverity('success');
                setSnackbarOpen(true);
            } else {
                // Handle failure (status false or no status)
                setSnackbarMessage('Update failed');
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
            }
        } catch (error) {
            // Handle error (e.g., network error, response not ok)
            setSnackbarMessage(error.length > 0 ? error : 'An error occurred' );
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    };



    useEffect(() => {
        if (!pricingRulesFetched && !userDetails.pricingRulesFetched) {
            fetchPricingRules();
            setPricingRulesFetched(true);
        } else if (userDetails.pricingRulesFetched) {
            const fetchedValue = (parseFloat(userDetails.pricingRules?.rule?.value?? 0)).toFixed(2)
            const operation = userDetails.pricingRules?.rule?.operation
            setOperator(operation? (operation === 'FIXED' && fetchedValue === '0.00') || (operation === 'PERCENT' && fetchedValue === '1.00') ? '' : operation: '');
            setValue(fetchedValue? fetchedValue === '1.00' && operation === 'PERCENT' ? 0 : fetchedValue : 0);
            setOriginalOperator(operation? (operation === 'FIXED' && fetchedValue === '0.00') || (operation === 'PERCENT' && fetchedValue === '1.00') ? '' : operation : '');
            setOriginalValue(fetchedValue? fetchedValue === '1.00' && operation === 'PERCENT' ? 0 : fetchedValue : 0);


        }
    }, [pricingRulesFetched, userDetails.pricingRulesFetched, fetchPricingRules, userDetails.pricingRules]);
    


    const handleValueChange = (newValue) => {
        setValue(newValue);
    };


    

    const columnsRates = React.useMemo(
        () => [
            { field: 'name', minWidth: 250, width: 250, headerName: 'Shipping Name', flex: 1 },
            {
                field: 'cost',
                minWidth: 150,
                headerName: 'Shipping Cost',
                flex: 1,
                valueGetter: (params) =>
                    `${parseFloat(params.value).toFixed(2)}`, 
            },
            {
                field: 'price',
                minWidth: 150,
                headerName: 'Shipping Price',
                flex: 1,
                valueGetter: (params) =>
                  operator === '' && value == 0.00 ? parseFloat(params.value).toFixed(2): params.value
            },
            {
                field: 'currency',
                minWidth: 150,
                headerName: 'Currency',
                flex: 1,
                valueGetter: (params) =>
                    `${userDetails.currency}`,
            },

        ]);




    return (
        <>
            <Stack
                direction={isMobile ? "column" : "row"}
                spacing={5}
                className={isMobile ? 'shipify-pricing-rules-row-mobile' : 'shipify-pricing-rules-row'}
            >
                <Item className='shipify-pricing-rules-item'>
                    <div className='shipify-pricing-rules-title'>
                        Shipping Cost ({userDetails.currency})
                    </div>
                </Item>

                <Item className='shipify-pricing-rules-item'>
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <InputLabel id="demo-select-small-label">Operator</InputLabel>
                        <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            className='shipify-pricing-rules-select'
                            value={operator}
                            label="Operator"
                            onChange={handleChange}
                            sx={{ textAlign: 'left' }}
                        >
                            <MenuItem value={"FIXED"}>+</MenuItem>
                            <MenuItem value={"PERCENT"}>x</MenuItem>
                        </Select>
                    </FormControl>
                </Item>


                <Item className='shipify-pricing-rules-item'>
                    <NumberInput 
                    value={parseFloat(value)} 
                    onValueChange={handleValueChange} 
                    currency={operator === 'FIXED'? userDetails.currency : ''}
                    isFixed={operator === 'FIXED'}
                    />
                </Item>

                <Item sx={{ display: 'flex', alignItems: 'center', gap: '5px' }} className='shipify-pricing-rules-item'>
                    <div>
                        = Shipping Price
                    </div>
                    <CustomTooltip
                        className='shipify-shipping-price-tooltip'
                        title={<div><p>{t('SHIPIFY_PRICING_RULES_TOOLTIP', {currency: userDetails.currency})}</p></div>}
                        placement="top"
                        arrow
                    >
                        <div>
                            <MdInfoOutline className='shipify-shipping-price-info' />
                        </div>
                    </CustomTooltip>
                </Item>



                <Item className='shipify-pricing-rules-item'>
                    <LoaderButton
                        buttonText={'Save'}
                        className='shipify-settings-and-plans-page-save-button'
                        onClick={handleSaveChange}
                        isDisabled={!(operator !== originalOperator || value != originalValue)}
                    />
                </Item>

            </Stack>

            { (operator === 'FIXED' && value < 0 || operator === 'PERCENT' && value < 1 ) && 
                        <div className='shipify-price-rules-note'>
                            <MdInfo/>
                                {t('SHIPIFY_PRICING_RULES_DISCOUNT_ALERT')}
                        </div>
            }

            <div className='shipify-price-rules-note' style={{position:'unset', color: 'grey'}}>
                <MdInfo/>
                {t('SHIPIFY_PRICING_RULES_TOOLTIP_2')}
            </div>

            <Box sx={{ borderBottom: 0, borderColor: 'divider', p: 1, display: 'block', marginTop: '15px' }}>
                <div className='shipify-pricing-rules-data'> Exmaple Data </div>
                <DataGrid className='price-rules-table'
                    rows={rows}
                    columns={columnsRates}
                    disableRowSelectionOnClick
                    hideFooter
                />
            </Box>

            <Snackbar sx={{ padding: '0px 15px' }} open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} >
                <Alert
                    onClose={() => setSnackbarOpen(false)}
                    severity={snackbarSeverity}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </>
    )
}


const blue = {
    100: '#DAECFF',
    200: '#80BFFF',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
};

const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
};

const StyledInputRoot = styled('div')(
    ({ theme }) => `
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 400;
    border-radius: 8px;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
    display: grid;
    grid-template-columns: 1fr 19px;
    grid-template-rows: 1fr 1fr;
    overflow: hidden;
    column-gap: 8px;
    padding: 4px;
  
    &.${numberInputClasses.focused} {
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
    }
  
    &:hover {
      border-color: ${blue[400]};
    }
  
    // firefox
    &:focus-visible {
      outline: 0;
    }
  `,
);

const StyledInputElement = styled('input')(
    ({ theme }) => `
    font-size: 0.875rem;
    font-family: inherit;
    font-weight: 400;
    line-height: 1.5;
    grid-column: 1/2;
    grid-row: 1/3;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    background: inherit;
    border: none;
    border-radius: inherit;
    padding: 8px 12px;
    outline: 0;
  `,
);

const StyledButton = styled('button')(
    ({ theme }) => `
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    appearance: none;
    padding: 0;
    width: 19px;
    height: 19px;
    font-family: system-ui, sans-serif;
    font-size: 0.875rem;
    line-height: 1;
    box-sizing: border-box;
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 0;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 120ms;
  
    &:hover {
      background: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
      border-color: ${theme.palette.mode === 'dark' ? grey[600] : grey[300]};
      cursor: pointer;
    }
  
    &.${numberInputClasses.incrementButton} {
      grid-column: 2/3;
      grid-row: 1/2;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      border: 1px solid;
      border-bottom: 0;
      &:hover {
        cursor: pointer;
        background: ${blue[400]};
        color: ${grey[50]};
      }
  
    border-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
    background: ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
    color: ${theme.palette.mode === 'dark' ? grey[200] : grey[900]};
    }
  
    &.${numberInputClasses.decrementButton} {
      grid-column: 2/3;
      grid-row: 2/3;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      border: 1px solid;
      &:hover {
        cursor: pointer;
        background: ${blue[400]};
        color: ${grey[50]};
      }
  
    border-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
    background: ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
    color: ${theme.palette.mode === 'dark' ? grey[200] : grey[900]};
    }
    & .arrow {
      transform: translateY(-1px);
    }
  `,
);