import React from 'react'
import './MultiSelectStatusButton.css'
import { IoIosArrowForward } from 'react-icons/io'
import { isMobile } from 'react-device-detect'
import MultiLineText from 'components/MultiLineText/MultiLineText'

/**
 * Represents a button that opens a {@link MultiSelectDialog} component
 *      and displays the status of the selected elements.
 * @param {string} title - The button's title
 * @param {string} status - The button's status
 * @param {function} onClick - The click handler of the button
 */
export default function MultiSelectStatusButton({
    title = '',
    status = '',
    onClick = () => { }
}) {
    return (
        <div className={isMobile ? "mobile-multi-select-status-button" : "multi-select-status-button"} onClick={onClick}>
            <div className={isMobile ? "mobile-multi-select-status-button-title" : "multi-select-status-button-title"}>{title}</div>
            <div className={isMobile ? "mobile-multi-select-status-button-right-section" : "multi-select-status-button-right-section"}>
                <MultiLineText
                    text={status}
                    className={isMobile ? "mobile-multi-select-status-button-right-section-quantity" : "multi-select-status-button-right-section-quantity"}
                    lines={1}
                    oneWord={true}
                />
                <IoIosArrowForward className="multi-select-status-button-right-section-image" />
            </div>
        </div>
    )
}