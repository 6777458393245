import { environment } from "conf"
import { SHIPIFY_PRICING_RULES_URL, SEARCH_ROUTE } from 'constants/api.constants'
import { request_auth } from "services/RequestWrapper"


/*
    Retrieves all collections.
    Method: [GET]
    URL: /admin/v1/collections/search

    Query params:
    =============
        (*) q - The search query string
        (*) count - The page capacity
        (*) skip - The number of elements skiped
*/
export function getPriceRules() {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'GET'
        }
        request_auth(environment.api_url + SHIPIFY_PRICING_RULES_URL, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data);
                } else {
                    reject(response.error);
                }
            })
            .catch(error => {
                reject(error);
            });
    });
}



export function updatePriceRules(type, operation, value) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'PUT',
            body: {
                type: `${type}`,
                rule: 
                    {
                     operation: `${operation}`,
                     value: value
                    }
                   

            }
        }
        request_auth(environment.api_url + SHIPIFY_PRICING_RULES_URL , options)
            .then(response => {
                if (response.status) {
                    resolve(response);
                } else {
                    reject(response.message);
                }
            })
            
    });
}