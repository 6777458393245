import { environment } from "conf"
import { ALL_PRODUCTS_API_BASE_URL, SEARCH_ROUTE } from 'constants/api.constants'
import { request_auth } from "services/RequestWrapper"


/*
    Retrieves all collections.
    Method: [GET]
    URL: /admin/v1/collections/search

    Query params:
    =============
        (*) q - The search query string
        (*) count - The page capacity
        (*) skip - The number of elements skiped
*/
export function getAllProducts(query, key) {
    const searchQuery = query ? `q=${query}` : ''
    const nextKey = key ? `next=${key}` : '' 
    const productsURL = query ? ALL_PRODUCTS_API_BASE_URL + '?' + searchQuery + '&' + nextKey : ALL_PRODUCTS_API_BASE_URL + '?' + nextKey
    return new Promise((resolve, reject) => {
        const options = {
            method: 'GET'
        }
        request_auth(environment.api_url + productsURL, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data);
                } else {
                    reject(response.error);
                }
            })
            .catch(error => {
                reject(error);
            });
    });
}