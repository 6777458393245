import React from 'react'
import './PageViewTopbar.css'
import BackButton from 'components/BackButton/BackButton'
import { isMobile } from 'react-device-detect'
import LoaderButton from 'components/LoaderButton/LoaderButton'

/**
 * Represents a topbar for all the view components: LinkView, ShortView and InfluencerView.
 * @param {string} title - The title of the topbar
 * @param {string} buttonText - The text for the button of the topbar
 * @param {boolean} isButtonDisabled - Determins whether the topbar's button is disabled or not
 * @param {function} onBackButtonClick - A callback to perform when the topbar's back button is clicked
 * @param {function} onButtonClick - A callback to perform when the topbar's button is clicked
 * @param {Node} rightElement - The right element of the topbar
 */
export default function PageViewTopbar({
    title = '',
    buttonText = '',
    isButtonDisabled = false,
    isButtonLoading = false,
    onBackButtonClick = () => { },
    onButtonClick = () => { },
    rightElement = null
}) {
    return (
        <div className={isMobile ? "mobile-page-view-topbar" : "page-view-topbar"}>
            <div style={{ width: isMobile ? '20%' : '33.3%' }}>
                <BackButton
                    disableHistoryPopOnClick={true}
                    onClick={onBackButtonClick}
                />
            </div>

            <div style={{ width: isMobile ? '60%' : '33.3%' }}>
                <div className="page-view-title">{title}</div>
            </div>

            <div style={{ width: isMobile ? '20%' : '33.3%' }}>
                {
                    rightElement ?? (
                        (isMobile && buttonText) ? <LoaderButton
                            className='page-view-topbar-save-button'
                            isDisabled={isButtonDisabled}
                            buttonText={buttonText}
                            isLoading={isButtonLoading}
                            loaderCircleColor='#0095f6'
                            onClick={onButtonClick}
                        /> : <div style={{ width: '56px' }}></div>
                    )
                }
            </div>
        </div>
    )
}