import React from 'react'
import './ShipifySplash.css'
import ShipifySplashGif from 'assets/splash-gif.gif'
import ShipifySplashGif2 from 'assets/splash-gif-2.gif'
import ImageBox from 'components/ImageBox/ImageBox'

/**
 * Represents the splash screen (first screen of the app).
 */
export default function ShipifySplash() {
    return (
        <div className="shipify-splash-container">
            <ImageBox 
                image={ShipifySplashGif2}
                className='shipify-splash-image'
            />
        </div>
    )
}