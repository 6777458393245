import React, { useState, useMemo } from 'react'
import './ShipifyLinkSupplier.css'
import { Stack, Paper, styled, Avatar, Button, Badge, CircularProgress } from '@mui/material'
import { FiPlus } from 'react-icons/fi'
import { AliExpressAuthUrlAPI, AliExpressAuthCallbackAPI } from 'api/aliexpress/aliexpress'
import { useUserDetailsContext } from 'contexts/shipify_context'
import { isMobile } from 'react-device-detect'
import LoaderButton from 'components/LoaderButton/LoaderButton'
import { LoadingButton } from '@mui/lab'


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    padding: theme.spacing(1),
    textAlign: 'center',
}));

export default function ShipifyLinkSupplier({
    image1,
    image2,
    btn_text,
    btn_svg,
    status,
    buttonClass
}) {

    // State to manage the button text/loader
    const [isLoading, setIsLoading] = useState(false);


    const {
        userDetails,
        setUserProperties
    } = useUserDetailsContext()

    const handleLoginClick = async () => {
        setIsLoading(true); // Show loader
        AliExpressAuthUrlAPI().then(redirectUrl => window.location.href = redirectUrl).
            catch(err => {
                console.log(err);
                // setIsLoading(false);
            })
    };

    const aliexpressAccount = useMemo(() => userDetails?.aliexpressAccount, [userDetails, userDetails.aliexpressAccount]);

    return (
        <Stack
            direction={isMobile? "column" : "row"}
            spacing={5}
            className={isMobile? 'shipify-supplier-link-row-mobile' : 'shipify-supplier-link-row'}
        >
            {aliexpressAccount === null ?
                <Item elevation={0}>
                    <LoadingButton className={buttonClass} variant='outline' startIcon={image1} onClick={handleLoginClick} loading={isLoading} >
                        {btn_svg}
                        {btn_text}
                    </LoadingButton>
                    
                      
                </Item> :
                <>
                    <Item elevation={0} className="shipify-stack-item">
                        <img width={"100px"} src={image2}/>
                    </Item>

                    <Item elevation={0} className="shipify-stack-item">
                        <div className='shipify-link-supplier-title-text'>{aliexpressAccount}</div>
                    </Item>

                    <Item elevation={0} className="shipify-stack-item">
                        <Badge color="success" badgeContent=" " variant="dot">      
                            <div className={isMobile? "shipify-link-supplier-button-mobile" : "shipify-link-supplier-button" }>
                                <div className="shipify-link-supplier-button-text">{status}</div>
                            </div>
                        </Badge>
                    </Item>
                </>}
        </Stack>
    )
}