import React from 'react'
import './AddCollectionsDialogCollection.css'
import ImageBox from 'components/ImageBox/ImageBox'
import { BsCheck } from 'react-icons/bs'
import { useTranslation } from 'react-i18next'
import MultiLineText from 'components/MultiLineText/MultiLineText'
import { isMobile } from 'react-device-detect'

/**
 * Represents a collection item inside the {@link AddCollectionsDialog} component
 * @param {object} data - The data of this collection
 * @param {boolean} isItemSelected - Determins whether this item is selected or not
 * @param {function} onItemClick - A function to perfomr whenever this item is clicked
 */
export default function AddCollectionsDialogCollection({
    data = {},
    isItemSelected = false,
    onItemClick = () => { }
}) {
    const { t } = useTranslation()
    
    return (
        <div className='add-collections-dialog-collection-container' onClick={onItemClick}>
            <div className="add-collections-dialog-collection-image-frame">
                <ImageBox
                    image={data?.image}
                    className='add-collections-dialog-collection-image'
                    showBorder={true}
                    border='1px solid #979797'
                    isImageFitCover={true}
                    showImageMask={true}
                    borderRadiusPx={0}
                />
                {data?.title && <MultiLineText 
                    text={data?.title}
                    className={isMobile ? 'mobile-add-collections-dialog-collection-title' : 'add-collections-dialog-collection-title'}
                    lines={3}
                    oneWord={true}
                />}
                {isItemSelected && <div className='selected-item-mask'>
                    <BsCheck className='add-collections-dialog-collection-selected-v-mark' />
                </div>}
                {data?.productsCount && <div className='add-collections-dialog-collection-products-count'>{t('ADD_COLLECTIONS_DIALOG_COLLECTION_PRODUCTS_COUNT', { count: data?.productsCount })}</div>}
            </div>
        </div>
    )
}