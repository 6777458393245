import React from 'react'
import './ShipifyDataSection.css'
import ShipifySection from 'components/ShipifySection/ShipifySection'
import Loader from 'components/Loader/Loader'

export default function ShipifyDataSection({
    title,
    value,
    image,
    sectionClassName = '',
    customComponent,
    customClass,
    loading = false
}) {
    return (
        <ShipifySection sectionClassName={sectionClassName}>
            <div className={`shipify-data-section-container ${customClass? customClass : ''}`}>
                <div className="shipify-data-section-data">
                    <div className="shipify-data-section-title">{title}</div>
                    <div className="shipify-data-section-value"> {loading ? <Loader/> : value }</div>
                </div>
                {image}
                {customComponent && <div className="shipify-data-section-custom">{customComponent}</div>}
            </div>
        </ShipifySection>
    )
}