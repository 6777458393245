import React from 'react'
import './ShipifyShippingGroupsPage.css'
import { useTranslation } from 'react-i18next'
import { FiPlus } from 'react-icons/fi'
import ShippingGroupsTable from 'components/ShippingGroupsTable/ShippingGroupsTable'
import { isMobile } from 'react-device-detect'
import { useHistoryContext } from 'contexts/History'
import { SHIPIFY_CREATE_SHIPPING_GROUP_PAGE_URL } from 'constants/routes.constants'

export default function ShipifyShippingGroupsPage() {
    const { t } = useTranslation()
    const history = useHistoryContext()

    function goToShippingGroupCreation() {
        history.push(SHIPIFY_CREATE_SHIPPING_GROUP_PAGE_URL)
    }

    return (
        <div className={isMobile ? 'mobile-shipify-shipping-groups-page' : 'shipify-shipping-groups-page'}>
            <div className="shipify-shipping-groups-page-topbar">
                {!isMobile && <div className="shipify-shipping-groups-page-topbar-title">{t('SHIPIFY_SHIPPING_GROUPS_PAGE_TITLE')}</div>}
                <div className="shipify-shipping-groups-page-topbar-create-group-button" onClick={goToShippingGroupCreation}>
                    <FiPlus className='shipify-shipping-groups-page-topbar-create-group-button-image' />
                    <div className="shipify-shipping-groups-page-topbar-create-group-button-text">{t('SHIPIFY_SHIPPING_GROUPS_PAGE_CREATE_GROUP_BUTTON_TEXT')}</div>
                </div>
            </div>
            <ShippingGroupsTable />
        </div>
    )
}