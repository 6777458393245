import React, { useState, useCallback, useMemo, useEffect } from 'react';
import Box from '@mui/material/Box';
import './ShipifyShippingComp.css'
import { DataGrid, GridToolbarQuickFilter, GridLogicOperator } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next'
import { useUserDetailsContext } from 'contexts/shipify_context'
import { isMobile } from 'react-device-detect'
import LoaderButton from 'components/LoaderButton/LoaderButton'
import { Switch, Alert, Snackbar, Button, TextField } from '@mui/material';
import { getSuppliers, updateSuppliers } from 'api/supplier_couriers/supplier_couriers';
import AccordionSlideshow from 'components/ShipifyAccordion/ShipifyAccordion';
import guideImage from 'assets/couriers-banner.png'
import editCourier from 'assets/edit-courier-guide.gif'
import bulkEditCourier from 'assets/bulk-edit-courier-guide.gif'


  const slides = [
    {
      title: "Display Name",
      description: "The original name is the name of the shipping company as it appears in Aliexpress. You can change this name by creating a unique display name that will be shown to customers in your store",
      steps: [
        'Click on the shipping company dispaly name cell to edit.',
        'Multiple cell edit for easily process.',
        'Dont forget to save the changes.'
      ],
      gifUrl: `${editCourier}`,
    },
    {
      title: "Active",
      description: "Allows you full control over the shipping companies you want to work with in your online store. If certain shipping companies are disabled, they will not appear in the online store.",
      // description: "Explore various shipping methods available for your products easily.Explore various shipping methods available for your products easily.",
      steps: [
        'You can Active/Deactive multiple companies',
        'Dont forger to save the changes.'
      ],
      gifUrl: `${bulkEditCourier}`,
    },
  ];


  function EditToolbar({ selectionModel, handleToggleStatusForSelected, handleReplaceAll }) {
    const [isSwitchOn, setIsSwitchOn] = useState(true);
    const [isEditOpen, setIsEditOpen] = useState(false);
    const [findValue, setFindValue] = useState('');
    const [replaceValue, setReplaceValue] = useState('');
  
    const handleSwitchChange = (event) => {
      setIsSwitchOn(event.target.checked);
      handleToggleStatusForSelected(event.target.checked);
    };
  
    const handleEditClick = () => {
      setIsEditOpen(!isEditOpen);
    };
  
    const handleFindChange = (event) => {
      setFindValue(event.target.value);
    };
  
    const handleReplaceChange = (event) => {
      setReplaceValue(event.target.value);
    };

    useEffect(() => {
      if (selectionModel.length === 0) {
        setIsEditOpen(false);
      }
    }, [selectionModel]);
  
    return (
      <>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', p: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '20px' }}>
          <div className='shipify-shipping-comp-search'>
            <GridToolbarQuickFilter 
              quickFilterParser={(searchInput) => 
                searchInput.split(',').map((value) => 
                value.trim()).filter((value) => value !== '')} 
            />
          </div>
          {selectionModel.length > 0 && (
            <div className='shipify-bulk-edit-button'>
              <LoaderButton 
                className='shipping-group-view-save-button bulk-edit'
                buttonText='Bulk Edit'
                onClick={handleEditClick}
              />
              <div className='bulk-active'>
                <span>Bulk Active:</span>
                <Switch
                  className='shipify-switch'
                  checked={isSwitchOn}
                  onChange={handleSwitchChange}
                  inputProps={{ 'aria-label': 'toggle status for selected rows' }}
                />
              </div>
            </div>
          )}
        </Box>
        {isEditOpen && selectionModel.length > 0 && (
          <Box sx={{ borderBottom: 1, borderColor: 'divider', padding:'15px 25px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '20px' }}>
            <TextField id="outlined-basic" label="Find" variant="outlined" size='small' sx={{width: '100%'}} value={findValue} onChange={handleFindChange} />
            <TextField id="outlined-basic" label="Replace" variant="outlined" size='small' sx={{width: '100%'}} value={replaceValue} onChange={handleReplaceChange} />
            <LoaderButton 
              className='shipping-group-view-save-button bulk-edit'
              buttonText='Replace All'
              onClick={() => handleReplaceAll(findValue, replaceValue)}
            />
          </Box>
        )}
      </>
    );
  }
  


export default function StartEditButtonGrid() {
  const [cellModesModel, setCellModesModel] = useState({});
  const [changes, setChanges] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [rows, setRows] = useState([]); 
  const [originalRows, setOriginalRows] = useState([]);
  const [changesMade, setChangesMade] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [localSuppliersFetched, setLocalSuppliersFetched] = useState(false);
  const [visibleRows, setVisibleRows] = useState([]);

  

  const { 
        userDetails, 
        setUserProperties,
        fetchSuppliers,
        editSupplier
    } = useUserDetailsContext()
    

  const { t } = useTranslation()



useEffect(() => {
    if (!localSuppliersFetched && !userDetails.suppliersFetched) {
        fetchSuppliers();
        setLocalSuppliersFetched(true);
    } else if (userDetails.suppliersFetched) {
        setRows(userDetails?.suppliers?? []);
        setOriginalRows(userDetails?.suppliers?? []);
    }
}, [localSuppliersFetched, userDetails.suppliersFetched, fetchSuppliers, userDetails.suppliers]);

  
const isSaveDisabled = useMemo(() => {
  // Check if there are any changes by comparing each changed row to its original state
  return !Object.keys(changes).some(key => {
    const originalRow = originalRows.find(row => row.id.toString() === key);
    const changedRow = { ...originalRow, ...changes[key] };
    // Assume that we are comparing specific fields that can be changed
    return originalRow.display_name !== changedRow.display_name ||
           originalRow.is_active !== changedRow.is_active 
  });
}, [changes, rows]);




  const processRowUpdate = useCallback((newRow, oldRow) => {
      newRow.display_name = newRow.display_name.trim();
      newRow.display_name = newRow.display_name.length ? newRow.display_name : oldRow.display_name;

      // Preserve the is_active state if it was toggled
      newRow.is_active = changes[newRow.id]?.is_active !== undefined ? changes[newRow.id].is_active : newRow.is_active;
      const newData = { ...changes, [newRow.id]: {...oldRow, ...newRow} }; // Keep a copy of new changes
      setChanges(newData);
      // Update rows to reflect the new changes immediately in the UI
      setRows(rows.map(row => (row.id === newRow.id ? { ...row, ...newRow } : row)));
    return newRow; // Return the updated row to be committed to the grid
  }, [changes, rows]);




  const handleSaveClick = async () => {
    // For demonstration, we pretend we've saved changes and now apply them
    const updatedRows = rows.map(row => changes[row.id] ? { ...row, ...changes[row.id] } : row);
    setRows(updatedRows); // Update rows with the latest changes
    handleSaveChanges(updatedRows)
    setChanges({}); // Clear changes after saving
  };




  const handleSaveChanges = async () => {
    const modifiedRowIds = Object.keys(changes);
    if (modifiedRowIds.length === 0) return;
  
    try {
      const updatedRows = rows.map(row => {
        if (changes[row.id]) {
          // Apply the change to the row
          return { ...row, ...changes[row.id] };
        }
        return row;
      });

      // Extract only the rows that have changes
      const modifiedRows = updatedRows.filter(row => modifiedRowIds.includes(row.id.toString()));


      // Format the data for API call
      const couriersData = modifiedRows.map(row => ({
        courier_id: row.courierId,    
        original_name: row.original_name,
        display_name: row.display_name, 
        is_active: row.is_active          
      }));

      // Create the payload for the API call
      const payload = {
        supplier_id: "AliExpress",
        couriers: couriersData
      };

      const response = await editSupplier(modifiedRows);
      if (response && response.data !== 0) {
        setSnackbarMessage('Update successful');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        setOriginalRows(updatedRows)
    } else {
        // Handle failure (status false or no status)
        setSnackbarMessage('Update failed');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
    }
  

      setRows(updatedRows); 
      setChanges({});
      setChangesMade(false); // Reset flag indicating changes have been made
    } catch (error) {
      // console.error('Error saving changes:', error);
    }
  };
  
  
  
  const handleToggleStatus = useCallback((id, currentStatus, courierId) => {
    setChanges(prevChanges => {
      // If there are already changes for this row, toggle based on that; otherwise, toggle based on current row status.
      const isCurrentlyOn = prevChanges[id] ? prevChanges[id].is_active : currentStatus;
      const updatedRow = { ...prevChanges[id], is_active: !isCurrentlyOn , courierId: courierId };
  
      return { ...prevChanges, [id]: updatedRow };
    });
    setChangesMade(true);
  }, [setChanges, setChangesMade]);
  


  
const handleToggleStatusForSelected = useCallback((switchState) => {
  const newChanges = { ...changes };

  selectionModel.forEach(id => {
    const row = rows.find(row => row.id === id);
    if (row) {
      newChanges[id] = { ...(newChanges[id] || row), is_active: switchState };
    }
  });

  setChanges(newChanges);
  // setRows(rows.map(row => newChanges[row.id] ? { ...row, ...newChanges[row.id] } : row));
}, [changes, rows, selectionModel]);


const handleReplaceAll = useCallback((findValue, replaceValue) => {
  if (!findValue) return;
  replaceValue = replaceValue.trim();

  const escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  };

  const newChanges = { ...changes };
  const lowerCaseFindValue = findValue.toLowerCase();
  
  const updatedRows = rows.map(row => {
    if (selectionModel.includes(row.id) && row.display_name.toLowerCase().includes(lowerCaseFindValue)) {
      const escapedFindValue = escapeRegExp(findValue); // Escape special characters
      const regex = new RegExp(escapedFindValue, 'gi'); // 'g' for global, 'i' for case-insensitive
      const updatedDisplayName = row.display_name.replace(regex, replaceValue).trim();
      newChanges[row.id] = { ...(newChanges[row.id] || row), display_name: updatedDisplayName };
      return { ...row, display_name: updatedDisplayName };
    }
    return row;
  });

  setChanges(newChanges);
  setRows(updatedRows);
}, [changes, rows, selectionModel]);


const handleFilterModelChange = (filterModel) => {
  const searchValue = filterModel.quickFilterValues?.[0] || '';

  // Filter rows based on the search value
  const filteredRows = rows.filter(row => {
      return row.courierId.toLowerCase().includes(searchValue.toLowerCase()) || row.original_name.toLowerCase().includes(searchValue.toLowerCase()) || row.display_name.toLowerCase().includes(searchValue.toLowerCase()) ;
  });

  const visibleRowIds = filteredRows.map(row => row.id);
  setVisibleRows(visibleRowIds);

  // Automatically select the filtered rows if there are selected rows
  if (selectionModel.length > 0) {
      setSelectionModel(visibleRowIds);
  }
};
  

  const columns = useMemo(
    () => [
        { field: 'courierId', minWidth: 175, cellClassName: 'shipify-non-edit-cell',  headerName: 'Service ID', flex: 1 },
        { field: 'original_name', minWidth: 175, cellClassName: 'shipify-non-edit-cell', headerName: 'Company Name', flex: 1 },
        { field: 'display_name', minWidth: 175, cellClassName: 'shipify-edit-display-name', headerName: 'Display Name', flex: 1, editable: true },
        {
          field: 'is_active',
          minWidth: 100, maxWidth: 120,
          cellClassName: 'shipify-status-cell',
          headerClassName: 'shipify-status-header',
          headerName: 'Active',
          flex: 1,
          renderCell: (params) => (
            <Switch
              className='shipify-switch'
              checked={changes[params.id]?.is_active ?? params.row.is_active}
              onChange={() => handleToggleStatus(params.id, params.row.is_active, params.row.courierId)}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          ),
        },
      ], [changes, handleToggleStatus]); // Ensure handleToggleStatus and changes are included in the dependency array


  return (
    <>
    <div className={isMobile ? 'mobile-shipify-shipping-comp-page-container' : 'shipify-shipping-comp-page-container'}>
        <div className="shipify-shipping-comp-page-title">{isMobile ? "" : t('SHIPIFY_SHIPPING_COMP_PAGE_TITLE')} 
        {/* Button placement */}
        <div className='shipify-shipping-comp-buttons'>
            <LoaderButton 
              className='shipping-group-view-save-button'
              buttonText={t('SHIPIFY_EDIT_SHIPPING_COMP_SAVE_BUTTON_TEXT')}
              onClick={handleSaveClick} 
              isDisabled={isSaveDisabled} 
              />
          
        </div>
        </div>
         <div className="shipify-shipping-comp-page-content">
            <AccordionSlideshow title={t('SHIPIFY_PRODUCTS_ACCORDION_TITLE')} description={t('SHIPIFY_SHIPPING_COMP_ACCORDION_TEXT')} guideImage={guideImage} slides={slides}/>
            <Box sx={{ height: "62dvh", width: '100%', borderRadius: '10px' }}>
            <DataGrid
              className='shipify-shipping-comp-data-grid'
              rows={rows}
              columns={columns}             
              checkboxSelection  
              cellModesModel={cellModesModel}
              onCellModesModelChange={setCellModesModel}
              processRowUpdate={processRowUpdate}
              slots={{
                toolbar: EditToolbar,
              }}     
              onRowSelectionModelChange={(newSelectionModel) => {
                const filteredSelectionModel = visibleRows.length > 0 ? newSelectionModel.filter(id => visibleRows.includes(id)) : newSelectionModel;
                setSelectionModel(filteredSelectionModel);
              }}
              onFilterModelChange={handleFilterModelChange}
              rowSelectionModel={selectionModel}
              slotProps={{
                toolbar: { selectionModel, handleToggleStatusForSelected, handleReplaceAll },
              }}
              disableRowSelectionOnClick
              loading={rows.length === 0 ? true : false }
            />

              </Box>
          </div>
    </div>

      <Snackbar sx={{padding: '0px 15px'}} open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} >
              <Alert
                onClose={() => setSnackbarOpen(false)}
                severity={snackbarSeverity}
                variant="filled"
                sx={{ width: '100%' }}
              >
                {snackbarMessage}
              </Alert>
      </Snackbar>
    </>
  );
}




