import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useUserDetailsContext } from 'contexts/shipify_context';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { TextField, Checkbox, Button } from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function CountrySelector({
    selectedCountries,
    setSelectedCountries
}) {
    const { t } = useTranslation()
    const {
        userDetails,
        hasSupportedCountries
    } = useUserDetailsContext()

    function handleCountrySelection(e, value, reason) {
        setSelectedCountries(value)
    }

    const checkOption = (option) => {
        const check = selectedCountries.some((c) => c.code === option.code);
        return check;
    };

    const checkGroup = (group) => {
        const groupLength = userDetails?.supportedCountries.filter((c) => c.group === group).length;
        const selectedGroupLength = selectedCountries.filter(
            (c) => c.group === group
        ).length;
        return groupLength === selectedGroupLength;
    };

    const selectGroup = (group) => {
        const groupedCountries = userDetails?.supportedCountries.filter((c) => c.group === group);
        const selectedGroupCountries = selectedCountries.filter(
            (c) => c.group === group
        );

        if (selectedGroupCountries.length > 0) {
            setSelectedCountries((prevState) => [
                ...prevState.filter((c) => c.group !== group)
            ]);
        } else {
            setSelectedCountries((prevState) => [...prevState, ...groupedCountries]);
        }
    };

    const unselectOption = (code) => {
        setSelectedCountries((prevState) =>
            prevState.filter((c) => c.code !== code)
        );
    };

    return (
        <Autocomplete
            id="country-select-demo"
            value={selectedCountries}
            onChange={handleCountrySelection}
            limitTags={isMobile ? 2 : 3}
            options={userDetails?.supportedCountries}
            autoHighlight
            multiple
            disabled={!hasSupportedCountries()}
            disableCloseOnSelect
            getOptionLabel={(option) => option.name}
            groupBy={(option) => option.group}
            renderOption={(props, option) => (
                <li key={option.code} {...props}>
                    <Checkbox
                        key={option.code}
                        icon={icon}
                        checkedIcon={checkedIcon}
                        checked={checkOption(option)}
                    />
                    <span>
                        <img
                            loading="lazy"
                            width="20"
                            style={{ marginRight: 10, marginLeft: 10 }}
                            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                            alt=""
                        />
                        {option.name}
                    </span>


                </li>
            )}
            renderGroup={(params) => (
                <div key={params?.group}>
                    <div key={params?.group} style={{ backgroundColor: '#f5f5f5' }}>
                        <Checkbox
                            key={params.group}
                            icon={icon}
                            checkedIcon={checkedIcon}
                            checked={checkGroup(params.group)}
                            onChange={() => selectGroup(params.group)}
                        />
                        <span style={{ fontWeight: '500' }} >{params.group}</span>
                    </div>
                    {params.children}
                </div>
            )}
            renderInput={(params) => (
                <TextField {...params} label={hasSupportedCountries() ? t('SHIPIFY_COUNTRY_SELECTOR_LABEL') : t('SHIPIFY_COUNTRY_SELECTOR_HAS_NO_COUNTRIES_LABEL')} placeholder={t('SHIPIFY_COUNTRY_SELECTOR_PLACEHOLDER')} />
            )}
            renderTags={() => {
                const limitedTags = selectedCountries.slice(0,25);
                return limitedTags.map((tag) => (
                    <Button
                        size="small"
                        style={{
                            backgroundColor: 'lightgray',
                            color: 'gray',
                            marginRight: 5,
                            marginBottom: 5,
                            borderRadius: 16,
                            textTransform: 'capitalize',
                            padding: '2px 10px'
                        }}
                        variant="contained"
                        key={tag.code}
                        endIcon={<CancelIcon />}
                        onClick={() => unselectOption(tag.code)}
                    >
                        <img
                            loading="lazy"
                            width="20"
                            style={{ marginRight: 10 }}
                            srcSet={`https://flagcdn.com/w40/${tag.code.toLowerCase()}.png 2x`}
                            src={`https://flagcdn.com/w20/${tag.code.toLowerCase()}.png`}
                            alt=""
                        />
                        {tag.name}
                    </Button>
                ));
            }}
        />
    )
}