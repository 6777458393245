import React, {useState} from 'react';
import { Box, Button, Typography, IconButton } from '@mui/material';
import './ShipifyBanner.css'
import CloseIcon from '@mui/icons-material/Close';
import { FiPlus } from 'react-icons/fi';
import ShipifyLinkSupplier from 'components/ShipifyLinkSupplier/ShipifyLinkSupplier';
import { MdInfoOutline } from 'react-icons/md';
import { width } from '@mui/system';








export default function ShipifyBanner({ svg, imageUrl, title, description, recomendedText, recomendedLink, closeIcon, onClose, button }){
  return (
    <Box
      className='shipify-banner'
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' , gap: '30px', paddingLeft: '10px'}}>
        {svg ? (
          <Box className='banner-svg' sx={{ height: 50, width: 50 }}>
            {svg}
          </Box>
        ) : (
          <Box
            className='banner-img'
            component="img"
            sx={{
              height: 50,
              width: 50,
            }}
            alt={title}
            src={imageUrl}
          />
        )}
        <Box>
          <Typography className='banner-title'>
            {title}
          </Typography>
          <Typography className='banner-text' variant="body2">
            {description}
          </Typography>
         { recomendedText && <Typography className='banner-text recomended-text-with-link' variant="body2">
            <MdInfoOutline/> 
            {recomendedText}
            <a target="_blank" href={recomendedLink}>Click Here</a> 
          </Typography>}
        </Box>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center'}}>
          {button}
          
        {closeIcon && <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon sx={{width:'20px', height: '20px'}} />
        </IconButton>}
      </Box>
    </Box>
  );
};
