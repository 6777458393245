import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"
// import en from "languages/en.json"
// import he from "languages/he.json"
import en from './languages/en.json'
import he from './languages/he.json'

// the translations
// (tip move them in a JSON file and import them)
const resources = {
	he: {
		translation: he
	},
	en: {
		translation: en
	}
};

i18n
	// use the plugin 'i18next-browser-languagedetector' to detect the language automatically
	.use(LanguageDetector)
	// passes i18n instance to react-i18next to make it available for all the components
	.use(initReactI18next)
	.init({
		resources,
		// this command overrides the auto detection language e set it
		lng: "en",
		// if the detection language finds a language not listed, then use the language indicated
		fallbackLng: "he",
		interpolation: {
			escapeValue: false // react already safes from xss
		},
		react: {
			transKeepBasicHtmlNodesFor: ["b"]
		}
	});

export default i18n;
