import React from 'react'
import './TodoList.css'
import PaydinAccordion from 'components/PaydinAccordion/PaydinAccordion'
import { isMobile } from 'react-device-detect'
import { GoCheckCircleFill } from 'react-icons/go'
import { useTranslation } from 'react-i18next'

/**
 * Represents a todo list.
 * @param {array} tasks - The list of tasks to perform
 * @param {string} expandedTaskName - The currently expanded task. A task is expanded after clicking on it
 * @param {function} onTaskClick - A function to perform whenever a task is clicked
 */
export default function TodoList({
    tasks = [],
    expandedTaskName = '',
    onTaskClick = () => { }
}) {
    const { t } = useTranslation()

    function renderTaskHeader(title, isDone) {
        return <div className='todo-list-task-header'>
            {isDone ? <GoCheckCircleFill className="todo-list-task-header-checked-mark" /> : <div className="todo-list-task-header-unchecked-mark"></div>}
            <div className="todo-list-task-header-title">{title}</div>
        </div>
    }

    function renderTaskContent(content, image) {
        return <div className={isMobile ? 'mobile-todo-list-task-content' : 'todo-list-task-content'}>
            {content}
            {
                (!isMobile && image) && <div className="todo-list-task-image">
                    {image}
                </div>
            }
        </div>
    }

    // Calculate the count of done tasks
    const doneTasksCount = tasks.filter(task => task.isDone).length;

    return (
        <>
            <div className="todo-list-title">{t('SHIPIFY_TODO_LIST_TITLE')}</div>
            <div className="todo-list-desc">
                <p className="desc-text">{t('SHIPIFY_TODO_LIST_DESC_TEXT')}</p>
                <div className="todo-list-counter">
                    <p>{`${doneTasksCount} / ${tasks.length} completed`}</p>
                </div>
            </div>
            <PaydinAccordion
                allowZeroExpands={true}
                borderColor={'transparent'}
                headerBackgroundColor={'#f3f3f3'}
                contentBackgroundColor={'#f3f3f3'}
                openAccordionName={expandedTaskName}
                borderRadiusPx={7}
                headerPadding={'0 5px'}
                contentPadding={'0 5px 10px 5px'}
                onExpanded={onTaskClick}
                accordions={tasks.map(task => ({
                    name: task?.name,
                    header: renderTaskHeader(task?.title, task?.isDone),
                    content: renderTaskContent(task?.content, task?.image)
                }))}
            />
        </>
    )
}