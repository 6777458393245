import React, { useEffect, useState } from 'react'
import { BrowserRouter, Switch } from 'react-router-dom'
import './App.css'
import {
	SHIPIFY_HOME_PAGE_URL,
	SHIPIFY_SHIPPING_GROUPS_PAGE_URL,
	SHIPIFY_PRODUCTS_PAGE_URL,
	SHIPIFY_HOW_TO_USE_PAGE_URL,
	SHIPIFY_SETTINGS_AND_PLANS_PAGE_URL,
	SHIPIFY_EDIT_SHIPPING_GROUP_PAGE_URL,
	SHIPIFY_CREATE_SHIPPING_GROUP_PAGE_URL,
	SHIPIFY_SHIPPING_COMPANIES_PAGE_URL,
	SHIPIFY_ALIXPRESS_CALLBACK_URL
} from 'constants/routes.constants'
import HistoryProvider from 'contexts/History'
import RouteWrapper from 'layouts/RouteWrapper'
import NotFound from 'pages/NotFound/NotFound'
import {
	APPLICATION_RECOVERY_TIMEOUT_MILISECONDS
} from 'constants/general.constants'
import BaseLayout from 'layouts/BaseLayout/BaseLayout'
import 'bootstrap/dist/css/bootstrap.min.css'
import { isMobile } from 'react-device-detect'
import ShipifyHomePage from 'pages/ShipifyHomePage/ShipifyHomePage'
import ShipifyProvider from 'contexts/shipify_context'
import ShipifyShippingGroupsPage from 'pages/ShipifyShippingGroupsPage/ShipifyShippingGroupsPage'
import ShipifyProductsPage from 'pages/ShipifyProductsPage/ShipifyProductsPage'
import ShipifyHowToUsePage from 'pages/ShipifyHowToUsePage/ShipifyHowToUsePage'
import ShipifySettingsAndPlansPage from 'pages/ShipifySettingsAndPlansPage/ShipifySettingsAndPlansPage'
import CreateShippingGroupPage from 'pages/CreateShippingGroupPage/CreateShippingGroupPage'
import EditShippingGroupPage from 'pages/EditShippingGroupPage/EditShippingGroupPage'
import ShipifySplash from 'pages/ShipifySplash/ShipifySplash'
import ShipifyShippingCompPage from 'pages/ShipifyShippingComp/ShipifyShippingComp'
import ShipifySupplierCallbackPage from 'pages/ShipifySupplierCallback/ShipifySupplierCallback'

function App() {

	const [isUserLoaded, setIsUserLoaded] = useState(false)
	const [isCollectionsLoaded, setIsCollectionsLoaded] = useState(false)
	const [isRecovery, setIsRecovery] = useState(false)
	const [isReady, setIsReady] = useState(false)
	const [isError, setIsError] = useState(false)

	useEffect(() => {

		setTimeout(() => { setIsRecovery(true) }, APPLICATION_RECOVERY_TIMEOUT_MILISECONDS)

		// This is used to hide all scroll bars in mobile devices
		if (isMobile) {
			const elHead = document.getElementsByTagName('head')[0]
			const elStyle = document.createElement('style')

			elHead.appendChild(elStyle)
			elStyle.innerHTML = '*::-webkit-scrollbar { display: none; }  body { -ms-overflow-style: none; scrollbar-width: none; }'
		}

		// catch general error and refresh the page
		// window.addEventListener("error", (event) => {
		//   window.location.replace(LINKS_PAGE_URL);
		// })

	}, [])

	useEffect(() => {
		if (isRecovery && !isUserLoaded) {
			setIsError(true)
		}
	}, [isRecovery])

	return (
		<>
			{
				isError ? <NotFound /> :
					<BrowserRouter>
						<Switch>
							<HistoryProvider>
								<ShipifyProvider onCollectionsLoaded={setIsCollectionsLoaded} onUserLoaded={setIsUserLoaded} onReady={setIsReady}>
									{(!isUserLoaded || !isReady) && <ShipifySplash />}
									{
										isUserLoaded && <>
											<RouteWrapper
												path={SHIPIFY_HOME_PAGE_URL}
												exact
												component={ShipifyHomePage}
												layout={BaseLayout}
											/>
											{
												isCollectionsLoaded && <RouteWrapper
													path={SHIPIFY_SHIPPING_GROUPS_PAGE_URL}
													exact
													component={ShipifyShippingGroupsPage}
													layout={BaseLayout}
												/>
											}
											<RouteWrapper
												path={SHIPIFY_PRODUCTS_PAGE_URL}
												exact
												component={ShipifyProductsPage}
												layout={BaseLayout}
											/>
											<RouteWrapper
												path={SHIPIFY_HOW_TO_USE_PAGE_URL}
												exact
												component={ShipifyHowToUsePage}
												layout={BaseLayout}
											/>
											<RouteWrapper
												path={SHIPIFY_SETTINGS_AND_PLANS_PAGE_URL}
												exact
												component={ShipifySettingsAndPlansPage}
												layout={BaseLayout}
											/>
											<RouteWrapper
												path={SHIPIFY_CREATE_SHIPPING_GROUP_PAGE_URL}
												exact
												component={CreateShippingGroupPage}
												layout={BaseLayout}
											/>
											<RouteWrapper
												path={SHIPIFY_EDIT_SHIPPING_GROUP_PAGE_URL}
												exact
												component={EditShippingGroupPage}
												layout={BaseLayout}
											/>
											<RouteWrapper
												path={SHIPIFY_SHIPPING_COMPANIES_PAGE_URL}
												exact
												component={ShipifyShippingCompPage}
												layout={BaseLayout}
											/>
											<RouteWrapper
												path={SHIPIFY_ALIXPRESS_CALLBACK_URL}
												exact
												component={ShipifySupplierCallbackPage}
												layout={BaseLayout}
											/>
										</>
									}
								</ShipifyProvider>
							</HistoryProvider>
						</Switch>
					</BrowserRouter>
			}
		</>
	)
}

export default App;
