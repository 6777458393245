import { DEFAULT_CURRENCY_CODE, NO_IMAGE_PLACEHOLDER_URL, ORDERS_TABLE_STATUS_OPTIONS } from "constants/general.constants"

/**
 * Extracts data from the given link
 * @param {object} link - The given link
 * @returns The extracted link
 */
export function extractLinkData(link) {
    return {
        linkId: link?.link_id,
        title: link?.description ?? '',
        description: link?.description,
        createdAt: link?.created_at,
        image: link?.images && link?.images.length > 0 ? link?.images[0] : null,
        images: link?.images && link?.images?.length > 0 ? link?.images : [],
        defaultImage: link?.link_default_image ?? '',
        currency: link?.currency,
        revenue: link?.revenue,
        views: link?.visits,
        checkouts: link?.checkouts ?? 0,
        isLinkExpired: link?.is_link_expired,
        influencerUsername: link?.influencer_username,
        influencerImage: link?.influencerImage,
        shoppingRedirectUrl: link?.shopping_redirect_url ?? '',
        urlType: link?.url_type ?? ''
    }
}

export function extractProductData(product) {
    return {
        id: product?.inventory_id ?? '',
        title: product?.title ?? '',
        imageUrl: product?.image?.src ?? '',
        productStatus: product?.product_status ?? '',
        hasNoQuantity: ![null, undefined].includes(product?.total_quantity) && product?.total_quantity <= 0,
        url: product?.url ?? ''
    }
}

export function extractProductRowData(productRow) {
    return {
        id: productRow?.product_id ?? '',
        title: productRow?.title ?? '',
        view: productRow?.view ?? 0,
        add: productRow?.add ?? 0,
        buy: productRow?.buy ?? 0,
        images: productRow?.image ?? {},
        rank: productRow?.rank ?? 0
    }
}

export function extractCollectionData(collection) {
    return {
        id: collection?.collection_id ?? '',
        title: collection?.title ?? '',
        image: collection?.image ?? '',
        productsCount: collection?.products_count ?? 0,
        description: collection?.description ?? '',
        url: collection?.url ?? ''
    }
}

export function extractInfluencerData(data) {
    return {
        username: data?.username ?? '',
        image: data?.profile_image_url ?? NO_IMAGE_PLACEHOLDER_URL,
        email: data?.email ?? '',
        promocode: data?.promocode ?? '',
        commissionRate: data?.commission,
        password: data?.password ?? '',
        isActive: data?.is_active ?? false
    }
}

export function extractBasicInfluencerAnalytics(influencer) {
    return {
        username: influencer?.username ?? '',
        totalClicks: influencer?.total_clicks ?? 0,
        totalSales: influencer?.total_sales ?? 0,
        currency: influencer?.currency ?? ''
    }
}

export function extractExtendedInfluencerAnalytics(influencer) {
    return {
        clicks: (influencer?.clicks_looks ?? 0) + (influencer?.clicks_shorts ?? 0),
        linksClicks: influencer?.clicks_looks ?? 0,
        shortsClicks: influencer?.clicks_shorts ?? 0,
        commission: influencer?.commission ?? 0,
        currency: influencer?.currency ?? '',
        username: influencer?.influencer_username ?? '',
        revenueOrganic: influencer?.revenue_external ?? 0,
        revenueLinks: influencer?.revenue_looks ?? 0,
        revenueShorts: influencer?.revenue_shorts ?? 0,
        salesOrganic: influencer?.sales_external ?? 0,
        salesLinks: influencer?.sales_looks ?? 0,
        salesShorts: influencer?.sales_shorts ?? 0,
        totalSales: (influencer?.sales_external ?? 0) + (influencer?.sales_looks ?? 0) + (influencer?.sales_shorts ?? 0),
        totalRevenue: influencer?.total_sales ?? 0,
        totalRefunded: influencer?.total_refunded ?? 0
    }
}

export function extractOrderRowData(orderRow) {
    function getStatusDisplayName(status) {
        switch (status) {
            case ORDERS_TABLE_STATUS_OPTIONS.PAID: return 'PAID'
            case ORDERS_TABLE_STATUS_OPTIONS.REFUNDED: return 'REFUNDED'
            case ORDERS_TABLE_STATUS_OPTIONS.PARTIALLY_REFUNDED: return 'PARTIALLY REFUNDED'
            default: return ''
        }
    }
    return {
        id: orderRow?.order_id ?? '',
        createdAt: orderRow?.created_at ?? 0,
        currency: orderRow?.currency ?? '',
        status: getStatusDisplayName(orderRow?.order_status ?? ''),
        url: orderRow?.order_url ?? '',
        totalProductsCount: orderRow?.products_count ?? 0,
        totalPrice: orderRow?.total_price ?? 0.0
    }
}

export function extractStoreOrderRowData(storeOrderRow) {
    function getStatusDisplayName(status) {
        switch (status) {
            case ORDERS_TABLE_STATUS_OPTIONS.PAID: return 'PAID'
            case ORDERS_TABLE_STATUS_OPTIONS.REFUNDED: return 'REFUNDED'
            case ORDERS_TABLE_STATUS_OPTIONS.PARTIALLY_REFUNDED: return 'PARTIALLY REFUNDED'
            default: return ''
        }
    }

    return {
        id: storeOrderRow?.order_id ?? '',
        createdAt: storeOrderRow?.created_at ?? 0,
        currency: storeOrderRow?.currency ?? DEFAULT_CURRENCY_CODE,
        influencerProfileImage: storeOrderRow?.influencer_profile_image_url ?? '',
        status: getStatusDisplayName(storeOrderRow?.order_status ?? ''),
        url: storeOrderRow?.order_url ?? '',
        totalProductsCount: storeOrderRow?.products_count ?? 0,
        totalPrice: storeOrderRow?.total_price ?? 0,
        linkDetails: {
            linkId: storeOrderRow?.link_details?.link_id ?? '',
            description: storeOrderRow?.link_details?.description ?? '',
            linkImage: storeOrderRow?.link_details?.images ? storeOrderRow?.link_details?.images[0] : '',
            linkDefaultImage: storeOrderRow?.link_details?.link_default_image ?? '',
            linkType: storeOrderRow?.link_details?.link_type ?? '',
            urlType: storeOrderRow?.link_details?.url_type ?? ''
        }
    }
}

export function extractOrderProduct(orderDetailsProduct) {
    return {
        title: orderDetailsProduct?.title ?? '',
        imageUrl: orderDetailsProduct?.image?.small?.src ?? '',
        price: orderDetailsProduct?.price ?? 0,
        quantity: orderDetailsProduct?.quantity ?? 0,
        variantTitle: orderDetailsProduct?.variant_title ?? ''
    }
}

export function extractShipifyShippingGroup(shippingGroup) {
    return {
        id: shippingGroup?.group_id ?? '',
        createdAt: shippingGroup?.created_at ?? '',
        groupName: shippingGroup?.title ?? '',
        countries: shippingGroup?.country_codes ?? [],
        collections: shippingGroup?.collection_ids ?? [],
        rates: shippingGroup?.rates ? shippingGroup?.rates.map(rate => extractRate(rate)) : []
    }
}

export function extractRate(rate) {
    return {
        id: rate?.rate_id ?? '',
        rateName: rate?.display_name ?? '',
        maxDays: rate?.max_delivery_days ?? 0,
        minDays: rate?.min_delivery_days ?? 0,
        price: rate?.price ?? 0,
        cost: rate?.original_price?? 0
    }
}