import React, { useEffect, useState } from 'react'
import './EditFieldSectionInput.css'
import { BsCheckCircle } from 'react-icons/bs'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import { CgCloseO } from 'react-icons/cg'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'
import Loader from 'components/Loader/Loader'
import { INPUT_VALIDATION_INDICATIONS } from 'constants/general.constants'
import Tooltip from 'components/Tooltip/Tooltip'

/**
 * Represents a field editing section.
 * @param {*} value - The value state of the input content
 * @param {function} setValue - A function for setting the value state of the input
 * @param {string} title - The input's title
 * @param {string} placeholder - The input's placeholder
 * @param {string} tooltipText - The input's tooltip text
 * @param {boolean} isMultiline - Determins whether the input is a multiline or not
 * @param {string} prefix - A prefix for the input. If non given, the prefix is not shown
 * @param {boolean} prefixStart - Determins whether the prefix will be shown at the start of the input or at the end
 * @param {boolean} enlargedTextField - Determins whether this input will be large (50px) or normal size (40px)
 * @param {boolean} isEmbeddedPrefix - Determins whether the prefix will be inside the input frame or not
 * @param {number} maxLength - The length limit for the input's value state. 
 *                              Shows a character count in the right top corner of the input. 
 *                              If non given, the character count is not shown
 * @param {number} rows - The number of rows for a multiline input. Ignored when "isMultiline" is false
 * @param {boolean} isDisabled - Determins whether the input field is disabled or not
 * @param {boolean} hasValidation - Determins whether the input field has a validation mechanism or not
 * @param {boolean} isValidating - Determins whether the validation machanism of the input field is in progress or not
 * @param {string} validationErrorMessage - An error message to display on validation error
 * @param {string} validationStatus - The validation status for the recently completed validation process
 * @param {boolean} shouldTestValidationStatus - Determins whether this input section should test its validation status when its value is empty or not
 * @param {boolean} showValidationIcon - Determins whether the validation icon will be shown or not
 * @param {boolean} showTopSection - Determins whether the top section of the input - which contains the title and character limit - will be shown or not
 * @param {function} onInputChange - A function for handling an input change
 * @param {string} id - An id for the input
 * @param {Node} titleButton - A JSX node representing the button in the same row as the input title
 * @param {object} titleStyles - A style object for the title element
 * @param {object} inputStyles - A style object for the input element
 * @param {boolean} isOptional - Detrermins whether this input field is optional or not
 * @param {boolean} isFramed - Detrermins whether this input field has a frame or it is blended into its container
 * @param {string} inputType - The type of this input
 */
export default function EditFieldSectionInput({
    value,
    setValue,
    title,
    placeholder,
    tooltipText,
    isMultiline,
    prefix,
    prefixStart = true,
    inputRef,
    enlargedTextField = false,
    isEmbeddedPrefix = true,
    maxLength = 0,
    rows = 2,
    isDisabled = false,
    hasValidation = false,
    shouldResetValidation = true,
    isValidating = false,
    isPassword = false,
    validationErrorMessage = '',
    validationStatus = INPUT_VALIDATION_INDICATIONS.NO_INDICATION,
    shouldTestValidationStatus = true,
    showValidationIcon = true,
    showTopSection = true,
    onInputChange = () => { },
    id = "",
    titleButton = null,
    titleStyles = {},
    inputStyles = {},
    styles = {},
    isOptional = false,
    isFramed = false,
    inputType = ''
}) {
    const { t } = useTranslation()

    const [showPassword, setShowPassword] = useState(false)
    const [inputValidationStatusStyle, setInputValidationStatusStyle] = useState({
        border: inputStyles?.border ?? '1px solid hsl(231, 24%, 90%)',
        themeColor: 'transparent'
    })
    const [helperText, setHelperText] = useState(validationErrorMessage)

    useEffect(() => {
        if (shouldTestValidationStatus)
            setHelperText(validationErrorMessage)
    }, [validationErrorMessage])

    useEffect(() => {
        if (hasValidation) {
            if (value) {
                switch (validationStatus) {
                    case INPUT_VALIDATION_INDICATIONS.NO_INDICATION:
                        setInputValidationStatusStyle(prev => ({
                            ...prev,
                            border: inputStyles?.border ?? '1px solid hsl(231, 24%, 90%)',
                            themeColor: 'transparent'
                        }))
                        setHelperText('')
                        break

                    case INPUT_VALIDATION_INDICATIONS.VALID:
                        setInputValidationStatusStyle(prev => ({
                            ...prev,
                            border: '2px solid green',
                            themeColor: 'green'
                        }))
                        setHelperText('')
                        break

                    default: // INPUT_VALIDATION_INDICATIONS.INVALID
                        markAsError()
                }
            } else {
                if (shouldTestValidationStatus) {
                    if (shouldResetValidation) {
                        resetValidationMarks()
                    } else {
                        markAsError()
                    }
                }
            }
        }
    }, [validationStatus, helperText])

    function markAsError() {
        setInputValidationStatusStyle(prev => ({
            ...prev,
            border: '2px solid red',
            themeColor: 'red'
        }))
        setHelperText(validationErrorMessage)
    }

    function resetValidationMarks() {
        setInputValidationStatusStyle(prev => ({
            ...prev,
            border: inputStyles?.border ?? '1px solid hsl(231, 24%, 90%)',
            themeColor: 'transparent'
        }))
        setHelperText('')
    }

    function hasMaxLength() {
        return maxLength > 0
    }

    function handleInputChange(e) {
        onInputChange(e.target.value)
        setValue(e.target.value)
    }

    function togglePasswordVisibility() {
        setShowPassword(prev => !prev)
    }

    function renderValidationStatusIndicator() {
        if (validationStatus !== INPUT_VALIDATION_INDICATIONS.NO_INDICATION) {
            if (validationStatus === INPUT_VALIDATION_INDICATIONS.VALID) {
                return <BsCheckCircle className='edit-field-section-input-validation-indicator' style={{ color: inputValidationStatusStyle.themeColor }} />
            } else {
                return <CgCloseO className='edit-field-section-input-validation-indicator' style={{ color: inputValidationStatusStyle.themeColor }} />
            }
        } else {
            return isEmbeddedPrefix && prefix && !prefixStart ? <></> : <div style={{ width: '20px' }}></div>
        }
    }

    function renderInput() {
        return <div className='edit-field-section-input-input-with-text-container'>
            <div className={`edit-field-section-input-input-container ${isDisabled ? 'disabled' : ''} ${enlargedTextField ? 'enlarged' : ''}`} style={{ ...inputStyles, border: inputValidationStatusStyle.border }}>
                <div className="edit-field-section-input-input-prefix-container">
                    {
                        (isEmbeddedPrefix && prefix && prefixStart) && <div className={`${isMobile ? "mobile-edit-field-section-input-prefix" : "edit-field-section-input-prefix"} embedded-prefix`}>{prefix}</div>
                    }
                    <input ref={inputRef} id={id} autoComplete='off' className='edit-field-section-input-input' disabled={isDisabled} maxLength={hasMaxLength() ? maxLength : null} onChange={handleInputChange} value={value} type={isPassword ? (showPassword ? 'text' : 'password') : inputType} placeholder={placeholder} />
                    {
                        (isEmbeddedPrefix && prefix && !prefixStart) && <div className={`${isMobile ? "mobile-edit-field-section-input-prefix" : "edit-field-section-input-prefix"} embedded-prefix`}>{prefix}</div>
                    }
                </div>
                {
                    (showValidationIcon && hasValidation) && (<div className="edit-field-section-input-validation-container">
                        {
                            isValidating ? <Loader styles={{ width: '20px', height: '20px', position: 'absolute', inset: 0, margin: 'auto' }} /> : (isPassword ? (
                                showPassword ? <AiOutlineEye className='sign-up-page-password-field-show-hide-password-image' onClick={togglePasswordVisibility} />
                                    : <AiOutlineEyeInvisible className='sign-up-page-password-field-show-hide-password-image' onClick={togglePasswordVisibility} />
                            ) : renderValidationStatusIndicator())
                        }
                    </div>)
                }
            </div>
            {helperText && <div className="edit-field-section-input-helper-text">{helperText}</div>}
        </div>
    }

    return (
        <div className={isMobile ? 'mobile-edit-link-section-input-container' : "edit-link-section-input-container"} style={styles}>
            {showTopSection && <div className="edit-link-section-input-title-container">
                <div className="edit-link-section-link-data-input-title-info-container">
                    {title && <div className="edit-link-section-input-title" style={titleStyles}>{title} {isOptional ? t('OPTIONAL_INPUT_TITLE_SUFFIX') : ''}</div>}
                    <Tooltip
                        tooltipText={tooltipText}
                    />
                </div>
                {
                    titleButton ?? (
                        hasMaxLength() && <div className="edit-link-section-link-data-input-character-limit">{t('EDIT_LINK_SECTION_LINK_DESCRIPTION_TEXTFIELD_CHARACTER_LIMIT', { length: value.length, limit: maxLength })}</div>
                    )
                }
            </div>}
            {
                isMultiline ?
                    <div className={`edit-field-section-input-input-container ${isDisabled ? 'disabled' : ''} ${isFramed ? '' : 'multiline'}`} style={inputStyles}>
                        <textarea id={id} autoComplete='off' className={`edit-field-section-input-input ${isDisabled ? 'disabled' : ''}`} disabled={isDisabled} rows={rows} maxLength={hasMaxLength() ? maxLength : null} onChange={handleInputChange} value={value} type="text" placeholder={placeholder} />
                    </div> : (
                        isEmbeddedPrefix ? renderInput() : (
                            prefix ? <div className={isMobile ? "mobile-edit-field-section-input-prefix-row" : "edit-field-section-input-prefix-row"}>
                                <div className={isMobile ? "mobile-edit-field-section-input-prefix" : "edit-field-section-input-prefix"}>{prefix}</div>
                                {renderInput()}
                            </div> : renderInput()
                        )
                    )
            }
        </div>
    )
}