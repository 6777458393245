import React, { useState } from 'react'
import './ShipifyHealthCheck.css'
import ShipifySection from 'components/ShipifySection/ShipifySection'
import { useTranslation } from 'react-i18next'
import AlertMessage from 'components/AlertMessage/AlertMessage'
import { ALERT_MESSAGE_TYPES } from 'constants/general.constants'
import { useUserDetailsContext } from 'contexts/shipify_context'
import LoaderButton from 'components/LoaderButton/LoaderButton'
import { VscDebugRestart } from 'react-icons/vsc'
import PaydinDialog, { PAYDIN_DIALOG_BUTTON_TYPES } from 'dialogs/PaydinDialog/PaydinDialog'
import Tooltip from '@mui/material/Tooltip';
import { Snackbar, Alert } from '@mui/material'

export default function ShipifyHealthCheck({
    sectionClassName = ''
}) {
    const { t } = useTranslation()
    const {
        userDetails,
        checkHealthProperties
    } = useUserDetailsContext()

    const [isCheckingHealthProperties, setIsCheckingHealthProperties] = useState(false)
    const [dialogState, setDialogState] = useState({
        isOpen: false,
        handleDialogClose: closeDialog,
        title: '',
        message: '',
        leftButtonText: '',
        rightButtonText: '',
        leftButtonClickHandler: () => { },
        rightButtonClickHandler: () => { }
    })


    function getAppStatusAlertMessage() {
        return <Tooltip title={<div><p>{userDetails?.isAppActive ? 'The app is active and running on your store.' : 'The app is not active on your store.'}</p></div>}
            arrow
            placement="top"
            slotProps={{
                popper: {
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, -14],
                            },
                        },
                    ],
                },
            }}
        >
            <div>
                <AlertMessage
                    message={userDetails?.isAppActive ? 'Active ' : 'Inactive '}
                    type={userDetails?.isAppActive ? ALERT_MESSAGE_TYPES.SUCCESS : ALERT_MESSAGE_TYPES.ERROR}
                    background='#ffffff'
                    icon='M12 11V16M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21ZM12.0498 8V8.1L11.9502 8.1002V8H12.0498Z'
                />
            </div>
        </Tooltip>
    }

    function getThirdPartyCCSStatusAlertMessage() {
        return <Tooltip title={<div><p>{userDetails?.isThirdPartyCcsOn ? 'Shopify CCS is enabled in your store, you can use the app.' : 'Please enable Shopify CCS in order to use the app.'}</p></div>}
            arrow
            placement="top"
            slotProps={{
                popper: {
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, -14],
                            },
                        },
                    ],
                },
            }}
        >
            <div>
                <AlertMessage
                    message={userDetails?.isThirdPartyCcsOn ? 'Active ' : 'Inactive '}
                    type={userDetails?.isThirdPartyCcsOn ? ALERT_MESSAGE_TYPES.SUCCESS : ALERT_MESSAGE_TYPES.ERROR}
                    background='#ffffff'
                    icon='M12 11V16M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21ZM12.0498 8V8.1L11.9502 8.1002V8H12.0498Z'
                />
            </div>
        </Tooltip>
    }

    function getShipifyCarrierServiceStatusAlertMessage() {
        return <Tooltip title= {<div><p>{userDetails?.isThirdPartyCcsOn ? (userDetails?.isAnotherCarrierAppRateOn ? 'Please note that there is an other carrier in your store and this may interfere with the continuous functioning of our app.' : 'There are no other active carriers in the store besides Shipify.') : `Shopify CCS is disabled on your online store. Therefore, we can't ensure that Shipify Carrier Service is not the only one exist.`}</p></div>}

            arrow
            placement="top"
            slotProps={{
                popper: {
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, -14],
                            },
                        },
                    ],
                },
            }}
        >
            <div>
                <AlertMessage
                    message={userDetails?.isThirdPartyCcsOn ? (userDetails?.isAnotherCarrierAppRateOn ? 'Warning ' : 'True ') : 'Error '}
                    type={userDetails?.isAnotherCarrierAppRateOn ? ALERT_MESSAGE_TYPES.WARNING : (userDetails?.isThirdPartyCcsOn ? ALERT_MESSAGE_TYPES.SUCCESS : ALERT_MESSAGE_TYPES.ERROR)}
                    background='#ffffff'
                    icon='M12 11V16M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21ZM12.0498 8V8.1L11.9502 8.1002V8H12.0498Z'
                />
            </div>
        </Tooltip>

    }


    function checkHealth() {
        setIsCheckingHealthProperties(true)
        checkHealthProperties(() => {
            setIsCheckingHealthProperties(false)
        }, error => {
            setIsCheckingHealthProperties(false)
                showHealthCheckErrorDialog()
        })
    }

    function showHealthCheckErrorDialog() {
        setDialogState(prev => ({
            ...prev,
            isOpen: true,
            title: 'Health Check Error',
            message: 'An error was occurred, try again later',
            leftButtonText: 'Refresh page',
            rightButtonText: '',
            leftButtonClickHandler: () => window.location.reload()
        }))
    }

    function closeDialog() {
        setDialogState(prev => ({
            ...prev,
            isOpen: false
        }))
    }

    return (
        <ShipifySection
            title={t('SHIPIFY_HEALTH_CHECK_SECTION_TITLE')}
            sectionClassName={sectionClassName}
            rightSection={<>
                <LoaderButton
                    buttonText={
                        <div className='shipify-health-check-check-button-content'>
                            <VscDebugRestart className='shipify-health-check-check-button-image' />
                            <span>{t('SHIPIFY_HEALTH_CHECK_SECTION_CHECK_BUTTON_TEXT')}</span>
                        </div>
                    }
                    isLoading={isCheckingHealthProperties}
                    className='shipify-health-check-check-button'
                    onClick={checkHealth}
                    loaderCircleColor='#3e8ae7'
                />
            </>}
        >
            <div className="shipify-health-check-content">
                <div className="shipify-health-check-subsection">
                    <div className="shipify-health-check-subsection-title">{t('SHIPIFY_HEALTH_CHECK_APP_ACTIVE_ON_STORE_SECTION_SUBTITLE')}</div>
                    {getAppStatusAlertMessage()}
                </div>
                <div className="shipify-health-check-subsection">
                    <div className="shipify-health-check-subsection-title">{t('SHIPIFY_HEALTH_CHECK_THIRD_PARTY_CALCULATION_SHIPPING_SECTION_SUBTITLE')}</div>

                    {getThirdPartyCCSStatusAlertMessage()}
                </div>
                <div className="shipify-health-check-subsection">
                    <div className="shipify-health-check-subsection-title">{t('SHIPIFY_HEALTH_CHECK_ONLY_SHIPIFY_CARRIER_SERVICE_SECTION_SUBTITLE')}</div>
                    {getShipifyCarrierServiceStatusAlertMessage()}
                </div>
            </div>
            {
                dialogState.isOpen && <PaydinDialog
                    isDialogOpen={dialogState.isOpen}
                    handleDialogClose={dialogState.handleDialogClose}
                    title={dialogState.title}
                    message={dialogState.message}
                    closeOnRightClick={false}
                    rightButtonType={PAYDIN_DIALOG_BUTTON_TYPES.SECONDARY}
                    leftButtonText={dialogState.leftButtonText}
                    rightButtonText={dialogState.rightButtonText}
                    isLeftButtonWithLoader={false}
                    onLeftButtonClick={dialogState.leftButtonClickHandler}
                />
            }


        </ShipifySection>
    )
}