import { request_auth } from 'services/RequestWrapper'
import { environment } from 'conf'
import { 
    SHIPIFY_ALIEXPRESS_AUTH_CALLBACK_URL,
    SHIPIFY_ALIEXPRESS_AUTH_URL
} from 'constants/api.constants'

export function AliExpressAuthUrlAPI() {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST'
        }
        request_auth(environment.api_url + SHIPIFY_ALIEXPRESS_AUTH_URL, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data);
                } else {
                    reject(response.error);
                }
            })
            .catch(error => {
                reject(error);
            });
    });
}


export function AliExpressAuthCallbackAPI(code) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST',
            body: {
                code
                
            }
        }
        request_auth(environment.api_url + SHIPIFY_ALIEXPRESS_AUTH_CALLBACK_URL, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data);
                } else {
                    reject(response.error);
                }
            })
            .catch(error => {
                reject(error);
            });
    });
}

