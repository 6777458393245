import React, { useState } from 'react'
import './UpgradeYourPlan.css'
import { FaCheck } from 'react-icons/fa6'
import { useTranslation } from 'react-i18next'
import { getCurrencySymbol } from 'services/currency'
import { useUserDetailsContext } from 'contexts/shipify_context'
import LoaderButton from 'components/LoaderButton/LoaderButton'
import { requestUpgradePlan } from 'api/billing/billing'
import PaydinDialog, { PAYDIN_DIALOG_BUTTON_TYPES } from 'dialogs/PaydinDialog/PaydinDialog'
import { isMobile } from 'react-device-detect'
import { DEFAULT_CURRENCY_CODE } from 'constants/general.constants'

const planContent = {
    name: 'PRO',
    price: 19.90,
    pricePeriod: 'Month',
    fee:0.25,
    features: [
        "Create Custom Rates Per Product",
        "Choose Rates From Product Page",
        "Control App Block Settings and Styling",
        "Live Chat and Email Support Service",
        "Multi Countries and Currencies Support",
        "Custom Shipping Name and Description on Checkout Page"
    ],
    hasTrial: true
}

export default function UpgradeYourPlan() {
    const { t } = useTranslation()
    const {
        userDetails
    } = useUserDetailsContext()

    const [isRequestingUpgradePlan, setIsRequestingUpgradePlan] = useState(false)
    const [dialogState, setDialogState] = useState({
        isOpen: false,
        handleDialogClose: closeDialog,
        title: '',
        leftButtonText: '',
        rightButtonText: '',
        rightButtonClickHandler: () => { }
    })

    function upgradePlan() {
        setIsRequestingUpgradePlan(true)
        requestUpgradePlan()
            .then(redirectUrl => window.location.href = redirectUrl)
            .catch(error => {
                console.log(error)
                setIsRequestingUpgradePlan(false)
                setDialogState(prev => ({
                    ...prev,
                    isOpen: true,
                    title: t('SHIPIFY_UPGRADE_YOUR_PLAN_UPGRADE_FAILED_DIALOG_TITLE'),
                    message: t('SHIPIFY_UPGRADE_YOUR_PLAN_UPGRADE_FAILED_DIALOG_MESSAGE'),
                    leftButtonText: t('SHIPIFY_UPGRADE_YOUR_PLAN_UPGRADE_FAILED_DIALOG_LEFT_BUTTON_TEXT'),
                    rightButtonText: ''
                }))
            })
    }

    function closeDialog() {
        setDialogState(prev => ({
            ...prev,
            isOpen: false
        }))
    }

    return (
        <div className={isMobile ? 'mobile-upgrade-your-plan' : 'upgrade-your-plan'}>
            <div className={isMobile ? "mobile-upgrade-your-plan-left" : "upgrade-your-plan-left"}>
                <div className={isMobile ? "mobile-upgrade-your-plan-plan-card" : "upgrade-your-plan-plan-card"}>
                    <div className="upgrade-your-plan-plan-title">{planContent.name}</div>
                    {
                        !isMobile && <div className="upgrade-your-plan-plan-features">
                            {
                                planContent.features.map(feature => <div key={feature} className='upgrade-your-plan-plan-feature'>
                                    <FaCheck className='upgrade-your-plan-plan-feature-check' />
                                    <div className="upgrade-your-plan-plan-feature-text">{feature}</div>
                                </div>)
                            }
                        </div>
                    }
                    <div className="upgrade-your-plan-lower-section">
                        <div className='upgrade-your-plan-plan-price-trial-container'>
                            <div className="upgrade-your-plan-plan-price-container">
                                <div className="upgrade-your-plan-plan-price">{getCurrencySymbol(DEFAULT_CURRENCY_CODE)}{planContent.price}</div>
                                {"/"}
                                <div className="upgrade-your-plan-plan-price-period">{planContent.pricePeriod}</div>
                                <div className='upgrade-your-plan-plan-fees'>{t('SHIPIFY_UPGRADE_PLAN_FEES', {fees:`${getCurrencySymbol(DEFAULT_CURRENCY_CODE)}${planContent.fee}`}) }</div>
                            </div>
                            {planContent.hasTrial && <div className="upgrade-your-plan-free-trial">{t('SHIPIFY_UPGRADE_YOUR_PLAN_FREE_TRIAL_TEXT')}</div>}
                        </div>
                        <LoaderButton
                            className='upgrade-your-plan-upgrade-button'
                            buttonText={t('SHIPIFY_UPGRADE_YOUR_PLAN_UPGRADE_BUTTON_TEXT')}
                            isLoading={isRequestingUpgradePlan}
                            onClick={upgradePlan}
                        />
                    </div>
                </div>
            </div>
            <div className={isMobile ? "mobile-upgrade-your-plan-right" : "upgrade-your-plan-right"}>
                <div className={isMobile ? "mobile-upgrade-your-plan-titles-container" : "upgrade-your-plan-titles-container"}>
                    <div className="upgrade-your-plan-title">{t('SHIPIFY_UPGRADE_YOUR_PLAN_TITLE')}</div>
                    <div className="upgrade-your-plan-subtitle">{t('SHIPIFY_UPGRADE_YOUR_PLAN_SUBTITLE')}</div>
                </div>
            </div>
            {
                dialogState.isOpen && <PaydinDialog
                    title={dialogState.title}
                    message={dialogState.message}
                    isDialogOpen={dialogState.isOpen}
                    handleDialogClose={dialogState.handleDialogClose}
                    leftButtonText={dialogState.leftButtonText}
                    rightButtonText={dialogState.rightButtonText}
                    leftButtonType={PAYDIN_DIALOG_BUTTON_TYPES.PRIMARY}
                    rightButtonType={PAYDIN_DIALOG_BUTTON_TYPES.SECONDARY}
                    onRightButtonClick={dialogState.rightButtonClickHandler}
                    closeOnRightClick={false}
                    isLeftButtonWithLoader={false}
                />
            }
        </div>
    )
}