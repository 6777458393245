import React, { useEffect, useRef, useState } from 'react'
import './ShippingMethodRowEdit.css'
import { useTranslation } from 'react-i18next'
import EditFieldSectionInput from 'components/EditFieldSectionInput/EditFieldSectionInput'
import { isMobile } from 'react-device-detect'
import { INPUT_VALIDATION_INDICATIONS } from 'constants/general.constants'
import { RiDeleteBin5Fill } from 'react-icons/ri'
import { ONLY_NUMBERS_REGEX } from 'constants/regex.constants'
import { getCurrencySymbol } from 'services/currency'
import { useUserDetailsContext } from 'contexts/shipify_context'
import { minWidth } from '@mui/system'

export default function ShippingMethodRowEdit({
    id = '',
    name = '',
    minDays = 0,
    maxDays = 0,
    price = 0,
    cost= 0,
    onRemoveShippingMethod = () => { },
    onUpdateShippingMethod = () => { }
}) {
    const { t } = useTranslation()
    const {
        userDetails
    } = useUserDetailsContext()

    const [shippingName, setShippingName] = useState(name)
    const [minETADays, setMinETADays] = useState(minDays)
    const [maxETADays, setMaxETADays] = useState(maxDays)
    const [shippingPrice, setShippingPrice] = useState((price / 100).toFixed(2))
    const [shippingCost, setShippingCost] = useState((cost / 100).toFixed(2))
    const [validationStatus, setValidationStatus] = useState({
        shippingName: INPUT_VALIDATION_INDICATIONS.NO_INDICATION,
        minETADays: INPUT_VALIDATION_INDICATIONS.NO_INDICATION,
        maxETADays: INPUT_VALIDATION_INDICATIONS.NO_INDICATION,
        price: INPUT_VALIDATION_INDICATIONS.NO_INDICATION,
        cost: INPUT_VALIDATION_INDICATIONS.NO_INDICATION
    })

    useEffect(() => {
        setValidationStatus(prev => ({
            ...prev,
            maxETADays: minETADays > maxETADays ? INPUT_VALIDATION_INDICATIONS.INVALID : INPUT_VALIDATION_INDICATIONS.NO_INDICATION,
            minETADays: minETADays > maxETADays ? INPUT_VALIDATION_INDICATIONS.INVALID : INPUT_VALIDATION_INDICATIONS.NO_INDICATION
        }))
    }, [maxETADays, minETADays])

    useEffect(() => {
        onUpdateShippingMethod(id, shippingName, minETADays, maxETADays, Number(shippingPrice) * 100, Number(shippingCost) * 100)
    }, [shippingName, maxETADays, minETADays, shippingPrice, shippingCost])

    function setPrice(price) {
        if (ONLY_NUMBERS_REGEX.test(price) && (price.indexOf('.') === -1 || price.split('.')[1].length <= 2)) {
            setShippingPrice(price)
        }
    }

    function setCost(cost) {
        if (ONLY_NUMBERS_REGEX.test(cost) && (cost.indexOf('.') === -1 || cost.split('.')[1].length <= 2)) {
            setShippingCost(cost)
        }
    }

    function renderETAAndPrice() {
        return <>
            <EditFieldSectionInput
                title={isMobile ? t('SHIPIFY_SHIPPING_METHOD_ROW_EDIT_MIN_ETA_DAYS_MOBILE_FIELD_TITLE') : t('SHIPIFY_SHIPPING_METHOD_ROW_EDIT_MIN_ETA_DAYS_FIELD_TITLE')}
                value={minETADays === 0 ? 0 : minDays}
                setValue={value => setMinETADays(value !== '' ? Number(value) : Number(0))}
                styles={{
                    width: '80px',
                    minWidth:'70px'
                    // maxWidth: isMobile? '20%': '70px'
                }}
                hasValidation={true}
                validationStatus={validationStatus.minETADays}
                showValidationIcon={false}
                prefix='D'
                inputType='number'
            />
            <EditFieldSectionInput
                title={isMobile ? t('SHIPIFY_SHIPPING_METHOD_ROW_EDIT_MAX_ETA_DAYS_MOBILE_FIELD_TITLE') : t('SHIPIFY_SHIPPING_METHOD_ROW_EDIT_MAX_ETA_DAYS_FIELD_TITLE')}
                value={maxETADays === 0 ? 0 : maxDays}
                setValue={value => {
                    setMaxETADays(value !== '' ? Number(value) : Number(0))
                    }
                }
                styles={{
                    width:'80px',
                    minWidth:'70px'
                    // maxWidth: isMobile? '20%': '70px'
                }}
                hasValidation={true}
                validationStatus={validationStatus.maxETADays}
                showValidationIcon={false}
                prefix='D'
                inputType='number'
            />
            <EditFieldSectionInput
                title={t('SHIPIFY_SHIPPING_METHOD_ROW_EDIT_PRICE_FIELD_TITLE')}
                value={shippingPrice}
                setValue={setPrice}
                styles={{
                    width: isMobile ? '25%' : 'auto',
                    minWidth: '90px'
                }}
                hasValidation={true}
                validationStatus={validationStatus.price}
                showValidationIcon={false}
                prefix={getCurrencySymbol(userDetails?.currency)}
            />
            <EditFieldSectionInput
                title={t('SHIPIFY_SHIPPING_METHOD_ROW_EDIT_COST_FIELD_TITLE')}
                value={shippingCost}
                setValue={setCost}
                styles={{
                    width: isMobile ? '25%' : 'auto',
                    minWidth: '90px'
                    // maxWidth: isMobile ? '30%' : '100px',
                }}
                hasValidation={true}
                validationStatus={validationStatus.cost}
                showValidationIcon={false}
                prefix={getCurrencySymbol(userDetails?.currency)}
            />
        </>
    }

    return (
        <div className='shipping-method-row-edit-container'>
            <div className={isMobile ? "mobile-shipping-method-row-edit" : "shipping-method-row-edit"}>
                <EditFieldSectionInput
                    title={t('SHIPIFY_SHIPPING_METHOD_ROW_EDIT_SHIPPING_NAME_FIELD_TITLE')}
                    value={shippingName}
                    setValue={setShippingName}
                    styles={{
                        width: isMobile ? '100%' : '40%',
                        minWidth: isMobile ? '100%' : '170px'
                    }}
                    hasValidation={true}
                    validationStatus={validationStatus.shippingName}
                    showValidationIcon={false}
                />
                {
                    isMobile ? <div className='shipping-method-row-edit'>
                        {renderETAAndPrice()}
                    </div> : <>
                        {renderETAAndPrice()}
                    </>
                }
            </div>
            <div className="shipping-method-button-row">
                <div className="shipping-method-row-edit-remove-button" onClick={() => onRemoveShippingMethod(id)}>
                    <RiDeleteBin5Fill className='shipping-method-row-edit-remove-button-image' />
                    <div className="shipping-method-row-edit-remove-button-text">{t('SHIPIFY_SHIPPING_METHOD_ROW_EDIT_DELETE_ROW_BUTTON_TEXT')}</div>
                </div>
            </div>
        </div>
    )
}