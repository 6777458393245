import React, { useState, useEffect } from 'react';
import { Grid, FormControlLabel, Checkbox, Box, Typography, Tooltip, Skeleton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Doughnut } from 'react-chartjs-2';
import 'chart.js/auto';
import './ShipifyMiniStatistics.css';
import PropTypes from 'prop-types';
import { MdOutlineInfo, MdSyncAlt } from 'react-icons/md';
import { useUserDetailsContext } from 'contexts/shipify_context';
import { useTranslation } from 'react-i18next';
import { getCurrencySymbol } from 'services/currency';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  card: {
    maxWidth: 400,
    margin: 'auto',
    textAlign: 'center',
    padding: '15px 20px',
  },
  pieChart: {
    height: 'auto',
    width: '100%',
    maxWidth: '100px',
    margin: 'auto',
  },
  legend: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  legendItem: {
    display: 'flex',
    alignItems: 'center',
    margin: '5px 8px',
  },
  legendColor: {
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    display: 'inline-block',
    marginRight: '8px',
    minWidth: '10px',
    minHeight: '10px',
  },
  sumValue: {
    fontSize: '20px',
    fontWeight: 'bold',
  },
}));

export default function ShipifyMiniStatistics({ data, fields, title, value, valueLabel, tooltipText, backgroundColors }) {
  const classes = useStyles();
  const [isMostUsed, setIsMostUsed] = useState(true);
  const [displayData, setDisplayData] = useState([]);
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: backgroundColors
      },
    ],
  });

  const chartOptions = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    cutout: '70%',
    maintainAspectRatio: true,
    
  };

  const { t } = useTranslation();

  const { userDetails, setUserProperties } = useUserDetailsContext();

  useEffect(() => {
    if (data) {
      processData();
    } else {
      setDisplayData([]);
      setChartData({
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: backgroundColors,
          },
        ],
      });
    }
  }, [isMostUsed, data, fields]);

  const processData = () => {
    if (!data || !Array.isArray(data[fields.ratesMostUsed]) || data[fields.ratesMostUsed].length === 0) {
      setDisplayData([]);
      setChartData({
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: backgroundColors,
          },
        ],
      });
      return;
    }
    const selectedData = isMostUsed ? data[fields.ratesMostUsed] : data[fields.ratesHighestRevenue];
    // const top3Data = selectedData.slice(0, 3);
    // const otherData = selectedData[3];

    setDisplayData(selectedData);

    const chartValues = selectedData.map(item => isMostUsed ? item[fields.quantity] : item[fields.revenue]);
    const chartValuesTooltip = selectedData.map(item => item[fields.cost]);
    const chartLabels = selectedData.map(item => item[fields.displayName] || item[fields.originalName] || 'Others');
    // const otherValue = isMostUsed ? otherData[fields.quantity] : otherData[fields.revenue];
    
    setChartData({
      labels: [...chartLabels],
      datasets: [
        {
          data: chartValues,
          backgroundColor: backgroundColors,
        },
      ],
      tooltipData: chartValuesTooltip
    });
  };

  const handleSwitchChange = () => {
    setIsMostUsed(!isMostUsed);
  };

  const renderSkeleton = () => (
    <>
      <Tooltip title='No Data Available' arrow placement="top"
        slotProps={{
          popper: {
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, -10],
                },
              },
            ],
          },
        }} >
        <Grid item container xs={12} justifyContent="space-around" mt={value ? '' : 1} alignItems={'center'} className={'shipify-mini-stats-container'}>
          <Grid container item xs={4}>
            <Skeleton variant="circular" width={80} height={80} animation="wave" />
          </Grid>

          <Grid item xs={8} pl={'15px'}>
            <Skeleton variant="text" sx={{ fontSize: '1.5rem' }} animation="wave" />
            <Skeleton variant="text" sx={{ fontSize: '1.5rem' }} animation="wave" />
            <Skeleton variant="text" sx={{ fontSize: '1.5rem' }} animation="wave" />
            <Skeleton variant="text" sx={{ fontSize: '1.5rem' }} animation="wave" />
          </Grid>
        </Grid>
      </Tooltip>
    </>
  );

  return (
    <>
      <div className='shipify-mini-stats-header-container'>
        <div className="shipify-data-section-title">
            <span>{title}</span> 
            
            <Tooltip title={<div><p>{tooltipText}</p></div>} arrow placement="bottom"
                slotProps={{
                popper: {
                    modifiers: [
                    {
                        name: 'offset',
                        options: {
                        offset: [0, -5],
                        },
                    },
                    ],
                },
                }} 
            >
                <span><MdOutlineInfo/></span>
            </Tooltip>
        </div>
        <FormControlLabel
          label={isMostUsed ? "Most Used" : "Highest Income"}
          control={
            <Checkbox
              icon={<MdSyncAlt />}
              checkedIcon={<MdSyncAlt />}
              checked={isMostUsed}
              onChange={handleSwitchChange}
            />
          }
        />
      </div>

      {displayData.length === 0 ?
        renderSkeleton()
        :
        <Grid item container xs={12} justifyContent="space-around" mt={value ? '' : 1} className={'shipify-mini-stats-container'}>
          <Grid container item xs={12}>
            {value &&
              <Typography
                className={classes.sumValue}
                display={'flex'}
                fontSize={'21px'}
                justifyContent={'center'}
                mb={1}
                alignItems={'baseline'}
                fontWeight={500}
              >
                {value}
                {valueLabel && <span className='shipify-value-label'> \ {valueLabel}</span>}
              </Typography>
            }
          </Grid>
          <Grid container item xs={3}>
            <Box className={`${classes.pieChart} shipify-chart-box`}>
              <Doughnut data={chartData} options={chartOptions} />
            </Box>
          </Grid>
          <Grid item xs={9}>
            <div className={`${classes.legend} shipify-stats-label-wrapper`}>
              {chartData.labels.map((label, index) => (
                <div key={index} className={classes.legendItem}>
                  <div className='stats-label'>
                    <span className={classes.legendColor} style={{ backgroundColor: chartData.datasets[0].backgroundColor[index] }}> </span>
                    <Tooltip title={label} arrow
                      slotProps={{
                        popper: {
                          modifiers: [
                            {
                              name: 'offset',
                              options: {
                                offset: [0, -10],
                              },
                            },
                          ],
                        },
                      }}
                    >
                      <Typography variant="body2">
                        {label}
                      </Typography>
                    </Tooltip>
                  </div>
                  <Tooltip 
                      title={
                       !isMostUsed && 
                        <Typography className='stats-rev-tooltip'>
                            <span className='stats-rev-tooltip-line'>
                                <span>Revenue:</span>
                                {getCurrencySymbol(userDetails?.currency)}{parseFloat(chartData.datasets[0].data[index]/100).toFixed(2)}
                            </span>
                            <span className='stats-rev-tooltip-line'>
                                <span>Cost:</span>
                                {getCurrencySymbol(userDetails?.currency)}{parseFloat(chartData.tooltipData[index]/100).toFixed(2)}
                            </span>
                            <span className='stats-rev-tooltip-line'>
                                <span>Profit:</span>
                                {getCurrencySymbol(userDetails?.currency)}{((parseFloat(chartData.datasets[0].data[index]/100))-(parseFloat(chartData.tooltipData[index]/100))).toFixed(2)}
                            </span>
                        </Typography>
                      } 
                      arrow
                      slotProps={{
                        popper: {
                          modifiers: [
                            {
                              name: 'offset',
                              options: {
                                offset: [0, -10],
                              },
                            },
                          ],
                        },
                      }}
                    >
                    <Typography variant="body2">  {isMostUsed ? `${chartData.datasets[0].data[index]}` : `${getCurrencySymbol(userDetails?.currency)}${parseFloat(chartData.datasets[0].data[index] / 100.00).toFixed(2)}`} </Typography>
                  </Tooltip>
                </div>
              ))}
            </div>
          </Grid>
        </Grid>}
    </>
  );
}

ShipifyMiniStatistics.propTypes = {
  data: PropTypes.object.isRequired,
  title: PropTypes.string,
  value: PropTypes.number,
  valueLabel: PropTypes.string,
  fields: PropTypes.shape({
    ratesMostUsed: PropTypes.string.isRequired,
    ratesHighestRevenue: PropTypes.string.isRequired,
    quantity: PropTypes.string.isRequired,
    revenue: PropTypes.string.isRequired,
    cost: PropTypes.string,
    key: PropTypes.string,
    displayName: PropTypes.string,
    originalName: PropTypes.string,
  }).isRequired,
};
