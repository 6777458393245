import React from 'react'
import './DashboardSidebar.css'
import { DASHBOARD_SIDEBAR_WIDTH_PX, CALENDLY_MEETING_LINK, SIDEBAR_MODES } from 'constants/general.constants'
import { styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import { useHistoryContext } from 'contexts/History'
import MuiDrawer from '@mui/material/Drawer'
import { useUserDetailsContext } from 'contexts/shipify_context'
import { SHIPIFY_HOME_PAGE_URL, SHIPIFY_HOW_TO_USE_PAGE_URL, SHIPIFY_PRODUCTS_PAGE_URL, SHIPIFY_SETTINGS_AND_PLANS_PAGE_URL, SHIPIFY_SHIPPING_GROUPS_PAGE_URL, SHIPIFY_SHIPPING_COMPANIES_PAGE_URL } from 'constants/routes.constants'
import ShipifySidebarHomeImage from 'embeddedImages/ShipifySidebarHomeImage'
import ShipifySidebarHowToUseImage from 'embeddedImages/ShipifySidebarHowToUseImage'
import ShipifySidebarSettingsImage from 'embeddedImages/ShipifySidebarSettingsImage'
import ShipifyProductsImage from 'embeddedImages/ShipifyProductsImage'
import ShipifySidebarShippingCompImage from 'embeddedImages/ShipifySidebarShippingCompImage'
import ShipifyLogo from 'embeddedImages/ShipifyLogo'
import ShipifySmallLogo from 'embeddedImages/ShipifySmallLogo'
import ShipifyShippingImage from 'embeddedImages/ShipifyShippingImage'
import UserMenu from 'components/UserMenu/UserMenu'
import ShipifySmallLogoV2 from 'embeddedImages/ShipifySmallLogoV2'
import ShipifyLogoV2 from 'embeddedImages/ShipifyLogoV2'
import { FaRegCalendarCheck } from 'react-icons/fa6'
import { minWidth } from '@mui/system'

export default function DashboardSidebar({
    open,
    setOpen
}) {
    const { t } = useTranslation()
    const history = useHistoryContext()
    const {
        isFreeUser,
        setSidebarToggle,
        userDetails
    } = useUserDetailsContext()

    function goToHome() {
        if (window.location.pathname !== SHIPIFY_HOME_PAGE_URL) {
            history.push(SHIPIFY_HOME_PAGE_URL)
        }
    }

    function goToSettings() {
        if (window.location.pathname !== SHIPIFY_SETTINGS_AND_PLANS_PAGE_URL) {
            history.push(SHIPIFY_SETTINGS_AND_PLANS_PAGE_URL)
        }
    }

    function goToProducts() {
        if (window.location.pathname !== SHIPIFY_PRODUCTS_PAGE_URL) {
            history.push(SHIPIFY_PRODUCTS_PAGE_URL)
        }
    }

    function goToShipping() {
        if (window.location.pathname !== SHIPIFY_SHIPPING_GROUPS_PAGE_URL) {
            history.push(SHIPIFY_SHIPPING_GROUPS_PAGE_URL)
        }
    }

    function goToHowToUse() {
        if (window.location.pathname !== SHIPIFY_HOW_TO_USE_PAGE_URL) {
            history.push(SHIPIFY_HOW_TO_USE_PAGE_URL)
        }
    }


    function goToShippingComp() {
        if (window.location.pathname !== SHIPIFY_SHIPPING_COMPANIES_PAGE_URL) {
            history.push(SHIPIFY_SHIPPING_COMPANIES_PAGE_URL)
        }
    }


    const openedMixin = (theme) => ({
        width: DASHBOARD_SIDEBAR_WIDTH_PX,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        overflowX: 'hidden',
    })

    const closedMixin = (theme) => ({
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: `calc(${theme.spacing(7)} + 1px)`,
        [theme.breakpoints.up('sm')]: {
            width: `calc(${theme.spacing(8)} + 1px)`,
        },
    })

    const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
        ({ theme, open }) => ({
            width: DASHBOARD_SIDEBAR_WIDTH_PX,
            flexShrink: 0,
            whiteSpace: 'nowrap',
            boxSizing: 'border-box',
            ...(open && {
                ...openedMixin(theme),
                '& .MuiDrawer-paper': openedMixin(theme),
            }),
            ...(!open && {
                ...closedMixin(theme),
                '& .MuiDrawer-paper': closedMixin(theme),
            }),
        }),
    )

    const toggleDrawer = () => {
        setSidebarToggle(userDetails.sidebarMode === SIDEBAR_MODES.OPEN ? true : false);
        setOpen(!open);
    };


    const handleLinkClick = () => {
        window.open(CALENDLY_MEETING_LINK, '_blank');
    };

    return (
        <>
            <button onClick={toggleDrawer} className={`toggle-drawer-button ${open ? 'open' : 'closed'}`}>
                {open ? '<' : '>'}
            </button>
            <Drawer
                variant="permanent"
                anchor="left"
                open={open}
            >
                <div className="dashboard-sidebar-container">
                    {open ? <a href='#' onClick={goToHome}><ShipifyLogoV2
                        className='dashboard-sidebar-image'
                    /></a> : <a href='#' onClick={goToHome}><ShipifySmallLogoV2
                        className='dashboard-sidebar-image sidebar-closed'
                    /></a>}
                    <UserMenu minimized={!open} />
                    <div className="dashboard-sidebar-content">
                        <div className="dashboard-sidebar-buttons-container">
                            <div onClick={goToHome} className={`dashboard-sidebar-links-button dashboard-sidebar-button ${open ? '' : 'sidebar-closed'} ${window.location.pathname === SHIPIFY_HOME_PAGE_URL ? 'selected' : ''}`}>
                                <ShipifySidebarHomeImage className='dashboard-sidebar-links-button-image dashboard-sidebar-button-image' />
                                <div className={`dashboard-sidebar-button-label ${open ? '' : 'sidebar-closed'}`}>{t('DASHBOARD_TOPBAR_HOME_BUTTON_TEXT')}</div>
                            </div>
                        
                            <div onClick={goToShipping} className={`dashboard-sidebar-links-button dashboard-sidebar-button ${open ? '' : 'sidebar-closed'} ${window.location.pathname.startsWith(SHIPIFY_SHIPPING_GROUPS_PAGE_URL) ? 'selected' : ''} ${isFreeUser() ? 'disabled' : ''}`}>
                                <ShipifyShippingImage className='dashboard-sidebar-shipping-groups-button-image dashboard-sidebar-button-image' />
                                <div className={`dashboard-sidebar-button-label ${open ? '' : 'sidebar-closed'}`}>{t('DASHBOARD_TOPBAR_SHIPPING_GROUPS_BUTTON_TEXT')}</div>
                            </div>

                            {/* Leave this code in comment */}
                            <div onClick={goToProducts} className={`dashboard-sidebar-links-button dashboard-sidebar-button ${open ? '' : 'sidebar-closed'} ${window.location.pathname.startsWith(SHIPIFY_PRODUCTS_PAGE_URL) ? 'selected' : ''} ${isFreeUser() ? 'disabled' : ''}`}>
                                <ShipifyProductsImage className='dashboard-sidebar-links-button-image dashboard-sidebar-button-image' />
                                <div className={`dashboard-sidebar-button-label ${open ? '' : 'sidebar-closed'}`}>{t('DASHBOARD_TOPBAR_PRODUCTS_BUTTON_TEXT')}</div>
                            </div>

                            <div onClick={goToShippingComp} className={`dashboard-sidebar-links-button dashboard-sidebar-button ${open ? '' : 'sidebar-closed'} ${window.location.pathname.startsWith(SHIPIFY_SHIPPING_COMPANIES_PAGE_URL) ? 'selected' : ''} ${isFreeUser() ? 'disabled' : ''}`}>
                                <ShipifySidebarShippingCompImage className='dashboard-sidebar-links-button-image dashboard-sidebar-button-image' />
                                <div className={`dashboard-sidebar-button-label ${open ? '' : 'sidebar-closed'}`}>{t('DASHBOARD_TOPBAR_SHIPPING_COMPANIES_BUTTON_TEXT')}</div>
                            </div>

                            {/* <div onClick={goToHowToUse} className={`dashboard-sidebar-links-button dashboard-sidebar-button ${open ? '' : 'sidebar-closed'} ${window.location.pathname.startsWith(SHIPIFY_HOW_TO_USE_PAGE_URL) ? 'selected' : ''} ${isFreeUser() ? 'disabled' : ''}`}>
                                <ShipifySidebarHowToUseImage className='dashboard-sidebar-links-button-image dashboard-sidebar-button-image' />
                                <div className={`dashboard-sidebar-button-label ${open ? '' : 'sidebar-closed'}`}>{t('DASHBOARD_TOPBAR_HOW_TO_USE_BUTTON_TEXT')}</div>
                            </div> */}

                            <div onClick={goToSettings} className={`dashboard-sidebar-links-button dashboard-sidebar-button ${open ? '' : 'sidebar-closed'} ${window.location.pathname.startsWith(SHIPIFY_SETTINGS_AND_PLANS_PAGE_URL) ? 'selected' : ''} ${isFreeUser() ? 'disabled' : ''}`}>
                                <ShipifySidebarSettingsImage className='dashboard-sidebar-links-button-image dashboard-sidebar-button-image' />
                                <div className={`dashboard-sidebar-button-label ${open ? '' : 'sidebar-closed'}`}>{t('DASHBOARD_TOPBAR_SETTINGS_BUTTON_TEXT')}</div>
                            </div>
                        </div>
                 

                        <div className="dashboard-sidebar-buttons-container">
                            <div onClick={handleLinkClick} className={`dashboard-sidebar-button dashboard-sidebar-button-link ${open ? '' : 'sidebar-closed'}`}>
                                <FaRegCalendarCheck className="dashboard-sidebar-book-an-expert-button-image"/>
                                <div className="dashboard-sidebar-button-label  link">{t("SHIPIFY_BOOK_AN_EXPERT_BUTTON")}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </Drawer>
        </>
    )
}