import React from 'react'
import './ShipifyRecommendedResources.css'
import { useTranslation } from 'react-i18next'
import ShipifyLink from 'components/ShipifyLink/ShipifyLink'
import ShipifySection from 'components/ShipifySection/ShipifySection'
import { isMobile } from 'react-device-detect'

export default function ShipifyRecommendedResources() {
    const { t } = useTranslation()

    return (
        <ShipifySection
            title={t('SHIPIFY_RECOMMENDED_RESOURCES_TITLE')}
            sectionClassName={isMobile ? 'mobile-shipify-recommended-resources' : 'shipify-recommended-resources'}
        >
            <div className="shipify-recommended-resources-section">
                <ShipifyLink
                    text={t('SHIPIFY_RECOMMENDED_RESOURCES_SECTION_1_LINK_TEXT')}
                    linkUrl='https://help.shopify.com/en/manual/online-store/themes/theme-structure/extend/apps'
                    textClasName='shipify-recommended-resources-section-link-text'
                />
                <div className="shipify-recommended-resources-section-text">{t('SHIPIFY_RECOMMENDED_RESOURCES_SECTION_1_TEXT')}</div>
            </div>
            <div className="shipify-recommended-resources-section">
                <ShipifyLink
                    text={t('SHIPIFY_RECOMMENDED_RESOURCES_SECTION_2_LINK_TEXT')}
                    linkUrl='https://help.shopify.com/en/manual/markets/languages/translate-adapt-app#third-party-app-compatibility'
                    textClasName='shipify-recommended-resources-section-link-text'
                />
                <div className="shipify-recommended-resources-section-text">{t('SHIPIFY_RECOMMENDED_RESOURCES_SECTION_2_TEXT')}</div>
            </div>
            <div className="shipify-recommended-resources-section">
                <ShipifyLink
                    text={t('SHIPIFY_RECOMMENDED_RESOURCES_SECTION_3_LINK_TEXT')}
                    linkUrl='https://help.shopify.com/en/manual/products/collections'
                    textClasName='shipify-recommended-resources-section-link-text'
                />
                <div className="shipify-recommended-resources-section-text">{t('SHIPIFY_RECOMMENDED_RESOURCES_SECTION_3_TEXT')}</div>
            </div>
        </ShipifySection>
    )
}