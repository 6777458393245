import React from 'react'
import './ShipifyLink.css'
import { RiExternalLinkLine } from 'react-icons/ri'

export default function ShipifyLink({
    linkUrl = '',
    text = '',
    className = '',
    textClassName = '',
    imageClassName = ''
}) {
    return (
        <a href={linkUrl} target='_blank' className={`shipify-link ${className}`}>
            <div className={textClassName}>{text}</div>
            <RiExternalLinkLine className={`shipify-link-image ${imageClassName}`} />
        </a>
    )
}