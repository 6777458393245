import React, { useState } from 'react'
import './TodoActivateThirdPartyCalculatedShippingTask.css'
import AlertMessage from 'components/AlertMessage/AlertMessage'
import { useUserDetailsContext } from 'contexts/shipify_context'
import { ALERT_MESSAGE_TYPES } from 'constants/general.constants'
import { useTranslation } from 'react-i18next'
import { VscDebugRestart } from "react-icons/vsc"
import LoaderButton from 'components/LoaderButton/LoaderButton'
import { isMobile } from 'react-device-detect'

export default function TodoActivateThirdPartyCalculatedShippingTask({
    learnMoreLink = 'https://google.com'
}) {
    const { t } = useTranslation()
    const {
        userDetails,
        validateCCS
    } = useUserDetailsContext()

    const [isValidatingCCS, setIsValidatingCCS] = useState(false)
    const [isCCSNotValidated, setIsCCSNotValidated] = useState(false)

    function validateCarrierCalculatedService() {
        setIsValidatingCCS(true)
        validateCCS(response => {
            setIsValidatingCCS(false)
            setIsCCSNotValidated(false)
        }, error => {
            setIsValidatingCCS(false)
            setIsCCSNotValidated(true)
        })
    }

    return (
        <div className={isMobile ? 'mobile-todo-list-task-details' : 'todo-list-task-details'}>
            <AlertMessage title={t('SHIPIFY_TODO_TASK_ACTIVATE_CCS_ALERT_TITLE')} message={<>
                <div className="todo-list-task-details-text">{t('SHIPIFY_TODO_TASK_ACTIVATE_CCS_ALERT_MESSAGE')}</div>
                <div className='todo-list-task-details-text'>
                    <a href={learnMoreLink} target="_blank">{t('SHIPIFY_LEARN_MORE_TEXT')}</a>
                </div>
            </>} type={userDetails?.isThirdPartyCcsOn ? ALERT_MESSAGE_TYPES.SUCCESS : ALERT_MESSAGE_TYPES.ERROR} />
            {
                !userDetails?.isThirdPartyCcsOn && <LoaderButton
                    className='todo-list-task-content-button'
                    buttonText={<div className='todo-list-task-content-button-with-image'>
                        {t('SHIPIFY_TODO_TASK_ACTIVATE_CCS_BUTTON_TEXT')}
                        <span><VscDebugRestart className='todo-list-task-content-button-image' /></span>
                    </div>}
                    isLoading={isValidatingCCS}
                    loaderCircleColor='white'
                    loaderArchColor='transparent'
                    onClick={validateCarrierCalculatedService}
                />
            }
            {isCCSNotValidated && <AlertMessage message={t('SHIPIFY_TODO_TASK_ACTIVATE_VALIDATE_CCS_FAILURE_TEXT')} type={ALERT_MESSAGE_TYPES.ERROR} />}
        </div>
    )
}