import React, { useMemo, useState, useEffect, useRef, useCallback } from 'react'
import './ShipifyHomePage.css'
import { useUserDetailsContext } from 'contexts/shipify_context'
import AlertMessage from 'components/AlertMessage/AlertMessage'
import { ALERT_MESSAGE_TYPES, SHIPIFY_HOME_PAGE_TASKS, SHIPIFY_TODO_LIST_ACTIVATE_THIRD_PARTY_CALCULATED_SHIPPING_TASK_LEARN_MORE_LINK_URL, SHIPIFY_TODO_LIST_DISPLAY_METHODS_TASK_LEARN_MORE_LINK_URL, CHROME_EXTENSION_LINK } from 'constants/general.constants'
import ShipifyDataSection from 'components/ShipifyDataSection/ShipifyDataSection'
import { useTranslation } from 'react-i18next'
import ShipifyHealthCheck from 'components/ShipifyHealthCheck/ShipifyHealthCheck'
import ShipifySection from 'components/ShipifySection/ShipifySection'
import TodoList from 'components/TodoList/TodoList'
import ImageBox from 'components/ImageBox/ImageBox'
import { isMobile } from 'react-device-detect'
import TodoActivateThirdPartyCalculatedShippingTask from 'components/TodoList/TodoActivateThirdPartyCalculatedShippingTask/TodoActivateThirdPartyCalculatedShippingTask'
import TodoAddShippingGroupTask from 'components/TodoList/TodoAddShippingGroupTask/TodoAddShippingGroupTask'
import TodoDisplayMethodTask from 'components/TodoList/TodoDisplayMethodTask/TodoDisplayMethodTask'
import TodoUpdateRateTask from 'components/TodoList/TodoUpdateRateTask/TodoUpdateRateTask'
import TodoActivateAppTask from 'components/TodoList/TodoActivateAppTask/TodoActivateAppTask'
import ShipifyProductsImage from 'embeddedImages/ShipifyProductsImage'
import ShipifyCountriesImage from 'embeddedImages/ShipifyCountriesImage'
import Gif1 from 'assets/1.gif'
import Gif2 from 'assets/2.gif'
import Gif3 from 'assets/3.gif'
import Gif4 from 'assets/4.gif'
import Gif5 from 'assets/5.gif'
import ShipifyMethodsImage from 'embeddedImages/ShipifyMethodsImage'
import ShipifyRecommendedResources from 'components/ShipifyRecommendedResources/ShipifyRecommendedResources'
import ShipifyLink from 'components/ShipifyLink/ShipifyLink'
import GuideImage from 'assets/guide-image.jpg'
import ShopifyGuideImage from 'assets/shopify-guide-image.png'
import UpgradeYourPlan from 'components/UpgradeYourPlan/UpgradeYourPlan'
import VideoListPlayer from 'components/ShipifyVideoPlayer/ShipifyVideoPlayer'
import ShipifyMiniStatistics from 'components/ShipifyMiniStatistics/ShipifyMiniStatistics'
import ShipifyStatisticsWithDateRange from 'components/ShipifyStatisticsWithDateRange/ShipifyStatisticsWithDateRange'
import { getStoreAnalytics } from 'api/analytics/analytics'
import { use } from 'i18next'
import ShipifyBanner from 'components/ShipifyBanner/ShipifyBanner'
import ShipifyAliExpressLogo from 'embeddedImages/ShipifyAliExpressLogo'
import ShipifyLinkSupplier from 'components/ShipifyLinkSupplier/ShipifyLinkSupplier'
import { FiPlus } from 'react-icons/fi'
import PaydinDialog, { PAYDIN_DIALOG_BUTTON_TYPES } from 'dialogs/PaydinDialog/PaydinDialog'
import { LiaCheckCircleSolid } from 'react-icons/lia'
import Loader from 'components/Loader/Loader'


export default function ShipifyHomePage() {
    const { t } = useTranslation()
    const {
        userDetails,
        hasRemainingTasks,
        isFreeUser,
        hideActivatePop
    } = useUserDetailsContext()

    const [dateRange, setDateRange] = useState({ from_date: '', to_date: '' });
    const [ratesMostUsedData, setRatesMostUsedData] = useState([]);
    const [ratesHighestRevenueData, setRatesHighestRevenueData] = useState([]);
    const [countryMostUsedData, setCountryMostUsedData] = useState([]);
    const [countryHighestRevenueData, setCountryHighestRevenueData] = useState([]);
    const [statisticsData, setStatisticsData] = useState(null);
    const [bannerOpen, setBannerOpen] = useState(true);
    const [activateApp, setIsActivategApp] = useState(userDetails.appActivated);
    const [localFetched, setLocalFetched] = useState(true)
    const [dialogState, setDialogState] = useState({
        isOpen: false,
        handleDialogClose: closeDialog,
        title: '',
        leftButtonText: '',
        rightButtonText: '',
        rightButtonClickHandler: () => { }
    })

    const formatDate = (date) => {
        if (!date) return '';
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    

    useEffect(() =>{
        getStoreAnalytics().then(response => {
            if(ratesMostUsedData.length == 0 && ratesHighestRevenueData.length == 0) {
                setRatesMostUsedData(response.curr_analytics.rates_most_used);
                setRatesHighestRevenueData(response.curr_analytics.rates_highest_revenue);
                setCountryMostUsedData(response.curr_analytics.countries_most_used);
                setCountryHighestRevenueData(response.curr_analytics.countries_highest_revenue);
            }
         })
      .catch(error => {
        console.log(error)
      });
    }, []);
    
    

    const fetchRevData = useCallback(async (from_date, to_date) => {
        try {
            const response = await getStoreAnalytics(from_date, to_date);
            setStatisticsData(response);

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }, []);

    useEffect(() => {
        // Set default range to 'lastMonth' on initial load
        const now = new Date();
        const from_date = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 29);
        const to_date = now; // Last day of the previous month

        setDateRange({ from_date: formatDate(from_date), to_date: formatDate(to_date) });
        fetchRevData(formatDate(from_date), formatDate(to_date));
    }, [fetchRevData]);

    const handleRangeChange = useCallback((rangeKey) => {
        const now = new Date();
        let from_date = '';
        let to_date = now;

        switch (rangeKey) {
            case 'lastWeek':
                from_date = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 6);
                break;
            case 'lasTwoWeeks':
                from_date = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 13);
                break;
            case 'last30Days':
                from_date = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 29);
                break;
            case 'lastMonth':
                from_date = new Date(now.getFullYear(), now.getMonth() - 1, 1);
                to_date = new Date(now.getFullYear(), now.getMonth(), 0); // Last day of the previous month
                break;
            case 'allTime':
                from_date = '';
                to_date = '';
                break;
            default:
                break;
        }

        setDateRange({ from_date: formatDate(from_date), to_date: formatDate(to_date) });
        fetchRevData(formatDate(from_date), formatDate(to_date));
    }, [fetchRevData]);



    const videos = [
        { title: 'Coming Soon', url: '#' },
        { title: 'Coming Soon', url: '#' },
        { title: 'Coming Soon', url: '#' },
    ];



    const taskList = useMemo(() => ([
        {
            name: SHIPIFY_HOME_PAGE_TASKS.ACTIVATE_CARRIER,
            title: t('SHIPIFY_TODO_TASK_ACTIVATE_CCS_TITLE'),
            content: <TodoActivateThirdPartyCalculatedShippingTask learnMoreLink={SHIPIFY_TODO_LIST_ACTIVATE_THIRD_PARTY_CALCULATED_SHIPPING_TASK_LEARN_MORE_LINK_URL} />,
            image: <ImageBox className='shipify-home-page-task-image' image={Gif1} />,
            isDone: isActivateCarrierTaskDone()
        },
        {
            name: SHIPIFY_HOME_PAGE_TASKS.ADD_SHIPPING_GROUP,
            title: t('SHIPIFY_TODO_TASK_ADD_SHIPPING_GROUPS_TITLE'),
            content: <TodoAddShippingGroupTask />,
            image: <ImageBox className='shipify-home-page-task-image' image={Gif2} />,
            isDone: isAddShippingGroupTaskDone()
        },
        {
            name: SHIPIFY_HOME_PAGE_TASKS.DISPLAY_METHODS,
            title: t('SHIPIFY_TODO_TASK_DISPLAY_METHODS_TITLE'),
            content: <TodoDisplayMethodTask learnMoreLink={SHIPIFY_TODO_LIST_DISPLAY_METHODS_TASK_LEARN_MORE_LINK_URL} />,
            image: <ImageBox className='shipify-home-page-task-image' image={Gif3} />,
            isDone: isDisplayMethodsTaskDone()
        },
        // {
        //     name: SHIPIFY_HOME_PAGE_TASKS.UPDATE_RATE_INFO,
        //     title: t('SHIPIFY_TODO_TASK_UPDATE_RATE_TITLE'),
        //     content: <TodoUpdateRateTask />,
        //     image: <ImageBox className='shipify-home-page-task-image' image={Gif4} />,
        //     isDone: isUpdateRateTaskDone()
        // },
        {
            name: SHIPIFY_HOME_PAGE_TASKS.ACTIVATE_APP,
            title: t('SHIPIFY_TODO_TASK_ACTIVATE_APP_TITLE'),
            content: <TodoActivateAppTask />,
            image: <ImageBox className='shipify-home-page-task-image' image={Gif5} />,
            isDone: isActivateAppTaskDone()
        }
    ]), [userDetails])
    const [expandedTask, setExpandedTask] = useState(taskList.find(task => !task?.isDone)?.name)

    function isActivateCarrierTaskDone() {
        return userDetails?.isThirdPartyCcsOn
    }

    function isAddShippingGroupTaskDone() {
        return userDetails?.totalShippingGroups > 0
    }

    function isDisplayMethodsTaskDone() {
        return userDetails?.isThemeAppBlockAdded && userDetails?.isThemeAppEmbedBlockAdded
    }

    function isUpdateRateTaskDone() {
        return userDetails?.rateName
    }

    function isActivateAppTaskDone() {
        return userDetails?.isAppActive
    }


    const toggleBanner = () => {
        setBannerOpen(bannerOpen);
    };


    useEffect(() => {
        const nextTask = taskList.find(task => !task?.isDone);
        if (nextTask) {
            setExpandedTask(nextTask.name);
        }
    }, [taskList]);


    useEffect(() => {
        if(userDetails.appActivated && localFetched && userDetails.isAppActive) {
            setLocalFetched(false);
            setIsActivategApp(false);
            setDialogState(prev => ({
                ...prev,
                isOpen: true,
                title: <LiaCheckCircleSolid style={{width: '100px', height: '100px', color: '#65C466'}}/>,
                message: <div><p><b>App Activated</b><br/></p><p style={{fontSize:'16px', marginTop: '10px'}}>The app has been successfully activated in your online store</p></div>,
                leftButtonText: '',
                rightButtonText: '',
                confirmationCheckboxLabel: '',
                rightButtonClickHandler: '',
                messageFontSize: 21,
                class: 'activate-dialog'
            }))  
            } 
    }, [localFetched, userDetails.appActivated, userDetails.isAppActive]);


    function closeDialog() {
        setIsActivategApp(false);
        setDialogState(prev => ({
            ...prev,
            isOpen: false
        }))
        hideActivatePop()
    }

    return (
        <div className={`${isMobile ? 'mobile-shipify-home-page-container' : 'shipify-home-page-container'} ${isFreeUser() ? 'free-subscription' : ''}`}>
            {
                isFreeUser() ? <UpgradeYourPlan /> : <>
                    {
                        !userDetails?.isAppActive && <AlertMessage
                            title={t('SHIPIFY_COMPLETE_GUIDE_ALERT_TITLE')}
                            message={t('SHIPIFY_COMPLETE_GUIDE_ALERT_MESSAGE')}
                            type={ALERT_MESSAGE_TYPES.WARNING}
                        />
                    }
                    <div className="shipify-home-page-content">
                        <div className={isMobile ? "shipify-home-page-content-column" : "shipify-home-page-content-row"}>
                            {/* <ShipifyDataSection
                                title={t('SHIPIFY_TOTAL_PRODUCTS_DATA_SECTION_TITLE')}
                                value={userDetails?.totalProducts}
                                image={<ShipifyProductsImage className={isMobile ? 'mobile-shipify-home-page-content-section-image' : 'shipify-home-page-content-section-image'} />}
                                sectionClassName={isMobile ? 'mobile-shipify-home-page-content-section' : 'shipify-home-page-content-section'}
                            /> */}


                            {/* <ShipifyDataSection
                                title={t('SHIPIFY_TOTAL_METHODS_DATA_SECTION_TITLE')}
                                value={userDetails?.totalShippingGroups}
                                image={<ShipifyMethodsImage className={isMobile ? 'mobile-shipify-home-page-content-section-image' : 'shipify-home-page-content-section-image'} />}
                                sectionClassName={isMobile ? 'mobile-shipify-home-page-content-section' : 'shipify-home-page-content-section'}
                            /> */}


                            <ShipifyDataSection 
                                sectionClassName={isMobile ? 'mobile-shipify-home-page-content-section shipify-mini-stats' : 'shipify-home-page-content-section shipify-mini-stats'}
                                customComponent={ 
                                    <ShipifyStatisticsWithDateRange
                                        data={statisticsData}
                                        title={t('SHIPIFY_REVENUE_STATISTICS_TITLE')}
                                        onRangeChange={handleRangeChange}
                                        tooltipText={t('SHIPIFY_ESTIMATED_FEES_TOOLTIP_REVENUE')}
                                    />
                                 }
                            />


                            <ShipifyDataSection 
                                sectionClassName={isMobile ? 'mobile-shipify-home-page-content-section shipify-mini-stats' : 'shipify-home-page-content-section shipify-mini-stats'}
                                customComponent={
                                    <ShipifyMiniStatistics
                                        title={t('SHIPIFY_COUNTRIES_STATISTICS_TITLE')}
                                        data={{
                                            countries_most_used: countryMostUsedData,
                                            countries_highest_revenue: countryHighestRevenueData,
                                        }}
                                        fields={{
                                            ratesMostUsed: 'countries_most_used',
                                            ratesHighestRevenue: 'countries_highest_revenue',
                                            quantity: 'quantity',
                                            revenue: 'revenue',
                                            cost: 'cost',
                                            displayName: 'key',
                                        }}
                                        tooltipText={t('SHIPIFY_ESTIMATED_FEES_TOOLTIP_STATS')}
                                        // backgroundColors={["#B3D9FF", "#66B2FF", "#3399FF", "#0575E6"]}
                                        backgroundColors={["#0575E6", "#3399FF", "#66B2FF", "#B3D9FF"]}
                                    />
                                }
                            />
                            
                              
                            <ShipifyDataSection 
                                sectionClassName={isMobile ? 'mobile-shipify-home-page-content-section shipify-mini-stats' : 'shipify-home-page-content-section shipify-mini-stats'}
                                customComponent={
                                    <ShipifyMiniStatistics
                                        title={t('SHIPIFY_SHIPPING_STATISTICS_TITLE')}
                                        data={{
                                            rates_most_used: ratesMostUsedData,
                                            rates_highest_revenue: ratesHighestRevenueData,
                                        }}
                                        fields={{
                                            ratesMostUsed: 'rates_most_used',
                                            ratesHighestRevenue: 'rates_highest_revenue',
                                            quantity: 'quantity',
                                            revenue: 'revenue',
                                            cost: 'cost',
                                            key:'key',
                                            displayName: 'display_name',
                                            originalName: 'original_name',
                                        }}
                                        tooltipText={t('SHIPIFY_ESTIMATED_FEES_TOOLTIP_STATS')}
                                        // backgroundColors={["#CCFFFF", "#33EFFF", "#00D4E6", "#00A3B3"]}
                                        backgroundColors={["#00A3B3", "#00D4E6", "#33EFFF", "#CCFFFF"]}

                                    />
                                }
                            />
                            
                            
                            {/* <ShipifyDataSection
                                title={t('SHIPIFY_SUPPORTED_COUNTERIES_DATA_SECTION_TITLE')}
                                value={userDetails?.totalCountries}
                                image={<ShipifyCountriesImage className={isMobile ? 'mobile-shipify-home-page-content-section-image' : 'shipify-home-page-content-section-image'} />}
                                sectionClassName={isMobile ? 'mobile-shipify-home-page-content-section' : 'shipify-home-page-content-section'}
                            /> */}

                        </div>

                        { userDetails.aliexpressAccount === null &&
                        <div className={isMobile ? "shipify-home-page-content-column" : "shipify-home-page-content-row"}>
                            <ShipifySection sectionClassName='shipify-supplier-banner'>
                                <ShipifyBanner 
                                    svg={<ShipifyAliExpressLogo />}
                                    title={t('SHIPIFY_ADD_SUPPLIER_BANNER_TITLE')}
                                    description={t('SHIPIFY_ADD_SUPPLIER_BANNER_TEXT')}
                                    recomendedText={userDetails.chromeExtensionActive ? '' : t('RECOMENDED_CHROME_EXTENSION_TEXT')}
                                    recomendedLink={userDetails.chromeExtensionActive ? '' : CHROME_EXTENSION_LINK}
                                    button={<ShipifyLinkSupplier 
                                        buttonClass='banner-button'
                                        btn_text={t('SHIPIFY_ADD_SUPPLIER_BANNER_BTN')}
                                        btn_svg={<FiPlus/>}
                                      />}
                                />
                            </ShipifySection>
                        </div>}

                        <div className={isMobile ? "shipify-home-page-content-column" : "shipify-home-page-content-row shipify-home-page-health-check-todo-row"}>
                            <ShipifyHealthCheck
                                sectionClassName={isMobile ? 'mobile-shipify-home-page-content-health-check-section' : 'shipify-home-page-content-health-check-section'}
                            />
                            {
                                hasRemainingTasks() ? <ShipifySection
                                    sectionClassName={isMobile ? 'mobile-shipify-home-page-content-todo-section' : 'shipify-home-page-content-todo-section'}
                                >
                                    <TodoList
                                        tasks={taskList}
                                        expandedTaskName={expandedTask}
                                        onTaskClick={setExpandedTask}
                                    />
                                </ShipifySection> : <>
                                    <ShipifyRecommendedResources />
                                    <ShipifySection sectionClassName={isMobile ? 'mobile-shipify-guide-section' : 'shipify-guide-section'}>
                                        <ImageBox
                                            image={ShopifyGuideImage}
                                            className='shipify-guide-image'
                                        />
                                        <ShipifyLink
                                            text={t('SHIPIFY_GUIDE_LINK_2_TEXT')}
                                            linkUrl='https://help.shopify.com/en/manual/markets'
                                            className='shipify-guide-link'
                                            textClassName='shipify-guide-link-text'
                                            imageClassName='shipify-guide-link-image'
                                        />
                                        <ShipifyLink
                                            text={t('SHIPIFY_GUIDE_LINK_3_TEXT')}
                                            linkUrl='https://help.shopify.com/en/manual/shipping/setting-up-and-managing-your-shipping/enabling-shipping-carriers'
                                            className='shipify-guide-link'
                                            textClassName='shipify-guide-link-text'
                                            imageClassName='shipify-guide-link-image'
                                        />
                                        <ShipifyLink
                                            text={t('SHIPIFY_GUIDE_LINK_4_TEXT')}
                                            linkUrl='https://help.shopify.com/en/manual/products/collections/automated-collections/auto-create'
                                            className='shipify-guide-link'
                                            textClassName='shipify-guide-link-text'
                                            imageClassName='shipify-guide-link-image'
                                        />
                                        <ShipifyLink
                                            text={t('SHIPIFY_GUIDE_LINK_5_TEXT')}
                                            linkUrl='https://help.shopify.com/en/manual/checkout-settings/checkout-customization#add-shipping-customization'
                                            className='shipify-guide-link'
                                            textClassName='shipify-guide-link-text'
                                            imageClassName='shipify-guide-link-image'
                                        />
                                    </ShipifySection>

                                </>
                            }
                        </div>
                        {/* <ShipifySection title='App Installation Guide'>
                            <VideoListPlayer videos={videos} />
                        </ShipifySection> */}
                    </div>

                    {
                        dialogState.isOpen && <PaydinDialog
                        title={dialogState.title}
                        message={dialogState.message}
                        isDialogOpen={dialogState.isOpen}
                        handleDialogClose={dialogState.handleDialogClose}
                        leftButtonText={dialogState.leftButtonText}
                        rightButtonText={dialogState.rightButtonText}
                        leftButtonType={PAYDIN_DIALOG_BUTTON_TYPES.PRIMARY}
                        rightButtonType={PAYDIN_DIALOG_BUTTON_TYPES.SECONDARY}
                        onRightButtonClick={dialogState.rightButtonClickHandler}
                        closeOnRightClick={true}
                        isLeftButtonWithLoader={false}
                        dialogLayout={dialogState.dialogLayout}
                        confirmationCheckboxLabel={dialogState.confirmationCheckboxLabel}
                        minHeight={dialogState.minHeight}
                        messageFontSizePx={dialogState.messageFontSize}
                        classDialog={dialogState.class}
                        />
                    }
                </>
            }
        </div>
    )
}