import React from 'react'
import './ShippingGroupsTableHeaderView.css'
import { useTranslation } from 'react-i18next'
import { Box, TableCell, TableSortLabel, tableCellClasses } from '@mui/material'
import { styled } from '@mui/system'
import { visuallyHidden } from '@mui/utils'
import { SHIPPING_GROUPS_TABLE_HEADERS_IDS } from 'constants/general.constants'
import { isMobile } from 'react-device-detect'
import { HiOutlineShoppingBag } from 'react-icons/hi'

/**
 * Represents a header view for the orders table
 * @param {string} order - The way of ordering the column data, e.g., asc or desc
 * @param {string} orderBy - The column to order the data by
 * @param {string} headerBackgroundColor - The background color of the header view
 * @param {string} headerTextColor - The text color of the header view
 * @param {string} createSortHandler - A function for creating the sort function for the data ordering
 * @param {array} removedColumnIds - An array of column IDs that are required to remove from display
 */
export default function ShippingGroupsTableHeaderView({
    order,
    orderBy,
    headerBackgroundColor = '',
    headerTextColor = '',
    createSortHandler = () => { },
    removedColumnIds = []
}) {
    const { t } = useTranslation()

    const headerContentMap = {
        [SHIPPING_GROUPS_TABLE_HEADERS_IDS.GROUP_NAME]: <div className="store-orders-table-header-text store-orders-table-header-view-date-text">{t('SHIPIFY_SHIPPING_GROUPS_TABLE_HEADER_GROUP_NAME_TITLE')}</div>,
        [SHIPPING_GROUPS_TABLE_HEADERS_IDS.CREATED_AT]: <div className='store-orders-table-header-view store-orders-table-header-text store-orders-table-header-view-source'>{t('SHIPIFY_SHIPPING_GROUPS_TABLE_HEADER_CREATED_AT_TITLE')}</div>,
        [SHIPPING_GROUPS_TABLE_HEADERS_IDS.COUNTRIES]: <div className='store-orders-table-header-view store-orders-table-header-text store-orders-table-header-view-total-products-count'>{t('SHIPIFY_SHIPPING_GROUPS_TABLE_HEADER_COUNTRIES_TITLE')}</div>,
        [SHIPPING_GROUPS_TABLE_HEADERS_IDS.COLLECTIONS]: <div className='store-orders-table-header-view store-orders-table-header-text store-orders-table-header-view-total-price'>{t('SHIPIFY_SHIPPING_GROUPS_TABLE_HEADER_COLLECTIONS_TITLE')}</div>,
        [SHIPPING_GROUPS_TABLE_HEADERS_IDS.RATES]: <div className='store-orders-table-header-view store-orders-table-header-text shipping-groups-tabe-header-rates'>{t('SHIPIFY_SHIPPING_GROUPS_TABLE_HEADER_RATES_TITLE')}</div>,
        [SHIPPING_GROUPS_TABLE_HEADERS_IDS.ACTIONS]: <div className='store-orders-table-header-view store-orders-table-header-text store-orders-table-header-view-actions'>{t('SHIPIFY_SHIPPING_GROUPS_TABLE_HEADER_ACTIONS_TITLE')}</div>
    }

    const headerStyle = {
        backgroundColor: headerBackgroundColor,
        color: headerTextColor
    }

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: headerStyle,
    }))

    const StyledDateTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            ...headerStyle,
            textAlign: 'start'
        },
    }))

    const ShippingGroupsTableHeaderCell = ({ id, label, align, tableCellView: TableCellView = StyledTableCell }) => <TableCellView
        key={id}
        align={align}
        padding={'none'}
        sortDirection={orderBy === id ? order : false}
    >
        <TableSortLabel
            active={orderBy === id}
            direction={orderBy === id ? order : 'asc'}
            onClick={createSortHandler(id)}
        >
            {label}
            {orderBy === id ? (
                <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
            ) : null}
        </TableSortLabel>
    </TableCellView>

    return (
        isMobile ? <ShippingGroupsTableHeaderCell align={'left'} tableCellView={StyledDateTableCell} label={
            <div className='orders-table-header-view orders-table-header-view-product'>
                <HiOutlineShoppingBag className="orders-table-header-view-product-image" />
                <div className="orders-table-header-text orders-table-header-view-product-text">{t('ORDERS_TABLE_HEADER_DATE')}</div>
            </div>
        } /> : <>
            {
                removedColumnIds?.length > 0 ? Object.keys(headerContentMap).filter(columnId => !removedColumnIds.includes(columnId)).map(columnId => <ShippingGroupsTableHeaderCell key={columnId} align={'left'} label={
                    headerContentMap[columnId]
                } />) : <>
                    <ShippingGroupsTableHeaderCell  align={'left'} label={
                        ''
                    } />
                    <ShippingGroupsTableHeaderCell id={SHIPPING_GROUPS_TABLE_HEADERS_IDS.GROUP_NAME} align={'left'} label={
                        headerContentMap[SHIPPING_GROUPS_TABLE_HEADERS_IDS.GROUP_NAME]
                    } />
                    <ShippingGroupsTableHeaderCell id={SHIPPING_GROUPS_TABLE_HEADERS_IDS.CREATED_AT} align={'left'} label={
                        headerContentMap[SHIPPING_GROUPS_TABLE_HEADERS_IDS.CREATED_AT]
                    } />
                    <ShippingGroupsTableHeaderCell id={SHIPPING_GROUPS_TABLE_HEADERS_IDS.COUNTRIES} align={'left'} label={
                        headerContentMap[SHIPPING_GROUPS_TABLE_HEADERS_IDS.COUNTRIES]
                    } />
                    <ShippingGroupsTableHeaderCell id={SHIPPING_GROUPS_TABLE_HEADERS_IDS.COLLECTIONS} align={'left'} label={
                        headerContentMap[SHIPPING_GROUPS_TABLE_HEADERS_IDS.COLLECTIONS]
                    } />
                    <ShippingGroupsTableHeaderCell id={SHIPPING_GROUPS_TABLE_HEADERS_IDS.RATES} align={'left'} label={
                        headerContentMap[SHIPPING_GROUPS_TABLE_HEADERS_IDS.RATES]
                    } />
                    <ShippingGroupsTableHeaderCell id={SHIPPING_GROUPS_TABLE_HEADERS_IDS.ACTIONS} align={'left'} label={
                        headerContentMap[SHIPPING_GROUPS_TABLE_HEADERS_IDS.ACTIONS]
                    } />
                </>
            }
        </>
    )
}