import React, { useState } from 'react'
import './TodoActivateAppTask.css'
import AlertMessage from 'components/AlertMessage/AlertMessage'
import { useUserDetailsContext } from 'contexts/shipify_context'
import { ALERT_MESSAGE_TYPES } from 'constants/general.constants'
import { Trans, useTranslation } from 'react-i18next'
import LoaderButton from 'components/LoaderButton/LoaderButton'
import { isMobile } from 'react-device-detect'
import ApprovalMessage from 'components/ApprovalMessage/ApprovalMessage'


export default function TodoActivateAppTask() {
    const { t } = useTranslation()
    const {
        userDetails,
        activateApplication,
        canActiveApp,
        checkHealthProperties
    } = useUserDetailsContext()

    const [isActivatingApp, setIsActivatingApp] = useState(false)
    const [isAppActivationFailed, setIsAppActivationFailed] = useState(false)
    const [approvalMessageState, setApprovalMessageState] = useState({
        isMessageShown: false,
        text: ''
    })

    function validateCarrierCalculatedService() {
        setIsActivatingApp(true)
        activateApplication(response => {
            setIsActivatingApp(false)
        }, error => {
            checkHealthProperties(response => {
            setIsActivatingApp(false)
            if (response.status) {
                setApprovalMessageState(prev => ({
                    ...prev,
                    isMessageShown: true,
                    text: t('SHIPIFY_GENERAL_ERROR_MESSAGE')
                }))
            }
            }, error => { 
                console.log(error) 
                setIsActivatingApp(false)
            })
        })
    }

    function hideApprovalMassage() {
        setApprovalMessageState(prev => ({
            ...prev,
            isMessageShown: false
        }))
    }

    return (
        <>
        <div className={isMobile ? 'mobile-todo-list-task-details' : 'todo-list-task-details'}>
            <div className="todo-activate-app-task-details">
                {
                    userDetails?.isAppActive && <AlertMessage
                        title={t('SHIPIFY_TODO_TASK_ACTIVATE_APP_APPLICATION_CONFIGURED_ALERT_TITLE')}
                        message={t('SHIPIFY_TODO_TASK_ACTIVATE_APP_APPLICATION_CONFIGURED_ALERT_MESSAGE')}
                        type={ALERT_MESSAGE_TYPES.SUCCESS}
                    />
                }
                {
                    userDetails?.isAnotherCarrierAppRateOn && <AlertMessage
                        title={t('SHIPIFY_TODO_TASK_ACTIVATE_APP_ANOTHER_CARRIER_SERVICE_ALERT_TITLE')}
                        message={t('SHIPIFY_TODO_TASK_ACTIVATE_APP_ANOTHER_CARRIER_SERVICE_ALERT_MESSAGE')}
                        type={ALERT_MESSAGE_TYPES.WARNING}
                    />
                }
                {
                    userDetails?.isAnotherRegularRateOn && <AlertMessage
                        title={t('SHIPIFY_TODO_TASK_ACTIVATE_APP_HIDE_OTHER_RATES_ALERT_TITLE')}
                        message={
                            <Trans
                                i18nKey='SHIPIFY_TODO_TASK_ACTIVATE_APP_HIDE_OTHER_RATES_ALERT_MESSAGE'
                                components={{
                                    b: <b />,
                                }}
                            />
                        }
                        type={ALERT_MESSAGE_TYPES.INFO}
                    />
                }
                {
                    !canActiveApp() && <AlertMessage
                        title={t('SHIPIFY_TODO_TASK_ACTIVATE_APP_CANT_ACTIVATE_ALERT_TITLE')}
                        message={
                            <Trans
                                i18nKey='SHIPIFY_TODO_TASK_ACTIVATE_APP_CANT_ACTIVATE_ALERT_MESSAGE'
                                components={{
                                    b: <b />,
                                }}
                            />
                        }
                        type={ALERT_MESSAGE_TYPES.WARNING}
                    />
                }
            </div>
            <div className="todo-list-buttons-container">
                {
                    <LoaderButton
                        className='todo-list-task-content-button'
                        buttonText={t('SHIPIFY_TODO_TASK_ACTIVATE_APP_ACTIVATE_APP_BUTTON_TEXT')}
                        isLoading={isActivatingApp}
                        loaderCircleColor='white'
                        loaderArchColor='transparent'
                        onClick={validateCarrierCalculatedService}
                        isDisabled={canActiveApp() ? false : true}
                    />
                }
            </div>
            {isAppActivationFailed && <AlertMessage message={t('SHIPIFY_TODO_TASK_ACTIVATE_APP_ACTIVATE_FAILURE_TEXT')} type={ALERT_MESSAGE_TYPES.ERROR} />}
        </div>
        <ApprovalMessage
                isMessageShown={approvalMessageState.isMessageShown}
                closeMessage={hideApprovalMassage}
                text={approvalMessageState.text}
        />
        </>
    )
}