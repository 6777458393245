import React, { useEffect, useState } from 'react'
import closeBubbleImage from 'assets/x-image.svg'
import './PaydinDialog.css'
import { useTranslation } from 'react-i18next'
import { 
    PAYDIN_DIALOG_CONTENT_PADDING_PX, 
    PAYDIN_DIALOG_ON_BUTTON_CLICKED_CALLBACK_DELAY_MILLISECONDS 
} from 'constants/general.constants'
import PopupDialog from 'dialogs/PopupDialog/PopupDialog'
import { useHistoryContext } from 'contexts/History'
import LoaderButton from 'components/LoaderButton/LoaderButton'
import { isMobile } from 'react-device-detect'
import PaydinCheckbox from 'components/PaydinCheckbox/PaydinCheckbox'

export const PAYDIN_DIALOG_BUTTON_TYPES = {
    PRIMARY: 'dialog-button-primary',
    SECONDARY: 'dialog-button-secondary',
    UNDERLINED: 'dialog-button-underlined',
}

/**
 * Represents a custom dialog.
 * It displays a message and/or title and/or buttons for asking the user to take action.
 * 
 * @param {string} title - A title for this dialog
 * @param {string} message - A message for this dialog 
 * @param {boolean} isDialogOpen - Determines whether the dialog is shown or not
 * @param {number} titleFontSizePx - The font size of the title in pixels
 * @param {number} messageFontSizePx - The font size of the message in pixels
 * @param {number} dialogHeightPx - The height of the dialog in pixels
 * @param {boolean} isCloseButtonShown - Determines whether the close button is shown or not
 * @param {boolean} isCloseOnTouchOutside - Determines whether or not the dialog will be closed if the user touches outside
 * @param {function} handleDialogClose - A handler function for clicking this dialog's close button
 * @param {function} closeButtonHandler - A callback function to perform when clicking the X button. If no callback defined, "handleDialogClose" is used
 * @param {string} leftButtonText - The text for the left button, if is required
 * @param {string} rightButtonText - The text for the right button, if is required
 * @param {function} onLeftButtonClick - A handler function for clicking this dialog's left button
 * @param {function} onRightButtonClick - A handler function for clicking this dialog's right button
 * @param {boolean} isLeftButtonWithLoader - Determins whether the left button loader will be shown on click or not
 * @param {boolean} isRightButtonWithLoader - Determins whether the right button loader will be shown on click or not
 * @param {boolean} closeOnLeftClick - Determins whether to close the dialog after clicking left button or not
 * @param {boolean} closeOnRightClick - Determins whether to close the dialog after clicking right button or not
 * @param {string} leftButtonType - The button type that defines the styling for left button, can be one of PAYDIN_DIALOG_BUTTON_TYPES.PRIMARY, PAYDIN_DIALOG_BUTTON_TYPES.SECONDARY or PAYDIN_DIALOG_BUTTON_TYPES.UNDERLINED
 * @param {string} rightButtonType - The button type that defines the styling for right button, can be one of PAYDIN_DIALOG_BUTTON_TYPES.PRIMARY, PAYDIN_DIALOG_BUTTON_TYPES.SECONDARY or PAYDIN_DIALOG_BUTTON_TYPES.UNDERLINED
 * @param {boolean} hasButtons - Determins whether the dialog has buttons or not
 * @param {DOMElement} dialogLayout - A complete custom layout for the dialog
 */
export default function PaydinDialog({
    title,
    message,
    isDialogOpen,
    titleFontSizePx = 24,
    messageFontSizePx = 16,
    isCloseButtonShown = true,
    isCloseOnTouchOutside = true,
    handleDialogClose = null,
    closeButtonHandler = null,
    leftButtonText = '',
    rightButtonText = '',
    onLeftButtonClick = () => { },
    onRightButtonClick = () => { },
    isLeftButtonWithLoader = true,
    isRightButtonWithLoader = true,
    closeOnLeftClick = true,
    closeOnRightClick = true,
    leftButtonType = PAYDIN_DIALOG_BUTTON_TYPES.PRIMARY,
    rightButtonType = PAYDIN_DIALOG_BUTTON_TYPES.SECONDARY,
    hasButtons = true,
    dialogLayout = null,
    hasNoWidthLimit = false,
    hasNoHeightLimit = false,
    maxHeight = '600px',
    maxWidth = '600px',
    minHeight = null,
    backdropColor = 'rgba(0, 0, 0, 0.5)',
    backgroundColor = 'white',
    borderRadiusPx = 20,
    paddingPx = PAYDIN_DIALOG_CONTENT_PADDING_PX,
    width = null,
    margin = '15px',
    styles = {},
    onDialogClosedRightButton = () => { },
    onDialogClosedLeftButton = () => { },
    confirmationCheckboxLabel = '',
    classDialog = ''
}) {
    const { t } = useTranslation()
    const history = useHistoryContext()

    const [isLeftButtonOperationPerforming, setIsLeftButtonOperationPerforming] = useState(false)
    const [isRightButtonOperationPerforming, setIsRightButtonOperationPerforming] = useState(false)
    const [isConfirmationCheckboxChecked, setIsConfirmationCheckboxChecked] = useState(false)

    const closeButtonStyle = {
        ...(t('direction.dir') === 'rtl' ? { left: `${PAYDIN_DIALOG_CONTENT_PADDING_PX}px` } : { right: `${PAYDIN_DIALOG_CONTENT_PADDING_PX}px` }),
        top: `${PAYDIN_DIALOG_CONTENT_PADDING_PX}px`
    }

    const titleStyle = {
        fontSize: `${titleFontSizePx}px`
    }

    const messageStyle = {
        fontSize: `${messageFontSizePx}px`
    }

    useEffect(() => {
        if (handleDialogClose)
            history.addBackHandler(handleDialogClose)
    }, [])

    function handleLeftButtonClick() {
        if (isLeftButtonWithLoader) {
            setIsLeftButtonOperationPerforming(true)
        }
        onLeftButtonClick()
        if (closeOnLeftClick) {
            setTimeout(() => {
                closeDialog()
            }, PAYDIN_DIALOG_ON_BUTTON_CLICKED_CALLBACK_DELAY_MILLISECONDS / 2) // in order for Safari to work properly
        }
        setTimeout(() => {
            onDialogClosedLeftButton()
        }, PAYDIN_DIALOG_ON_BUTTON_CLICKED_CALLBACK_DELAY_MILLISECONDS) // making sure that the dialog is closed before going back again
    }

    function handleRightButtonClick() {
        if (isRightButtonWithLoader) {
            setIsRightButtonOperationPerforming(true)
        }
        onRightButtonClick()
        if (closeOnRightClick) {
            setTimeout(() => {
                closeDialog()
            }, PAYDIN_DIALOG_ON_BUTTON_CLICKED_CALLBACK_DELAY_MILLISECONDS / 2) // in order for Safari to work properly
        }
        setTimeout(() => {
            onDialogClosedRightButton()
        }, PAYDIN_DIALOG_ON_BUTTON_CLICKED_CALLBACK_DELAY_MILLISECONDS) // making sure that the dialog is closed before going back again
    }

    function closeDialog() {
        if (handleDialogClose !== null) {
            handleDialogClose()
        }
        
        history.goBack()
    }

    return (
        <PopupDialog 
            childClassName='paydin-dialog' 
            isOpen={isDialogOpen} 
            onDialogClose={isCloseOnTouchOutside ? closeDialog : null}
            hasNoWidthLimit={hasNoWidthLimit}
            hasNoHeightLimit={hasNoHeightLimit}
            maxHeight={maxHeight}
            maxWidth={maxWidth}
            backdropColor={backdropColor}
            borderRadiusPx={borderRadiusPx}
            width={width}
            margin={margin}
            minHeight={minHeight}
            styles={styles}
        >
            <div dir={t('direction.dir')} className={`paydin-dialog ${classDialog}`} style={{ padding: `${paddingPx}px`, backgroundColor, minHeight: minHeight ?? 'unset' }}>
                {
                    dialogLayout ?? <>
                        <div className="paydin-dialog-content">
                            {isCloseButtonShown && <img src={closeBubbleImage} alt="close dialog" style={{ ...closeButtonStyle }} className="close-dialog-image" onClick={closeButtonHandler != null ? closeButtonHandler : closeDialog} />}
                            {title && <h4 className='paydin-dialog-title' style={{ ...titleStyle }}>{title}</h4>}
                            {message && <div className="paydin-dialog-message" style={{ ...messageStyle }}>{message}</div>}
                        </div>
                        {
                            confirmationCheckboxLabel && <PaydinCheckbox
                                label={confirmationCheckboxLabel}
                                checked={isConfirmationCheckboxChecked}
                                setChecked={setIsConfirmationCheckboxChecked}
                            />
                        }
                        {
                            hasButtons && <div className={isMobile ? 'mobile-paydin-dialog-buttons-container' : 'paydin-dialog-buttons-container'}>
                                {
                                    leftButtonText && <LoaderButton
                                        isLoading={isLeftButtonOperationPerforming}
                                        loaderArchColor='grey'
                                        onClick={handleLeftButtonClick}
                                        className={`paydin-dialog-left-button general-dialog-button ${leftButtonType}`}
                                        buttonText={leftButtonText} />
                                }
                                {
                                    rightButtonText && <LoaderButton
                                        isLoading={isRightButtonOperationPerforming}
                                        loaderArchColor='grey'
                                        onClick={handleRightButtonClick}
                                        isDisabled={confirmationCheckboxLabel && !isConfirmationCheckboxChecked}
                                        className={`paydin-dialog-right-button general-dialog-button ${rightButtonType}`}
                                        buttonText={rightButtonText} />
                                }
                            </div>
                        }
                    </>
                }
            </div>
        </PopupDialog>
    )
}