import React, { useEffect, useState }from 'react'
import './ShipifySupplierCallback.css'
import { useUserDetailsContext } from 'contexts/shipify_context'
import { CircularProgress, Box } from '@mui/material'
import useQueryParams from 'hooks/useQueryParams'
import { useHistoryContext } from 'contexts/History'
import { AliExpressAuthCallbackAPI } from 'api/aliexpress/aliexpress'
import {SHIPIFY_SETTINGS_AND_PLANS_PAGE_URL,SHIPIFY_HOME_PAGE_URL} from 'constants/routes.constants'
import { useTranslation } from 'react-i18next'
import ApprovalMessage from 'components/ApprovalMessage/ApprovalMessage'




export default function ShipifySupplierCallbackPage() {
    const { 
        userDetails, 
        setUserProperties,
        editAccountSupplier
    } = useUserDetailsContext()

    const { t } = useTranslation()

    const [approvalMessageState, setApprovalMessageState] = useState({
        isMessageShown: false,
        text: ''
    })

    const queryParams = useQueryParams()
    const code = queryParams.get('code')
    const history = useHistoryContext()

    function hideApprovalMassage() {
        setApprovalMessageState(prev => ({
            ...prev,
            isMessageShown: false
        }))
    }
    
    function goToSettings() {
        if (window.location.pathname !== SHIPIFY_SETTINGS_AND_PLANS_PAGE_URL) history.push(SHIPIFY_SETTINGS_AND_PLANS_PAGE_URL);
        setTimeout(() => { scrollToBottom(); }, 100); 
    }

    function goToHome() {
        if (window.location.pathname !== SHIPIFY_HOME_PAGE_URL){ 
            setTimeout(() => { history.push(SHIPIFY_HOME_PAGE_URL); }, 3000); 
        }
    }
    
    
    function scrollToBottom() {
        const element = document.getElementById("accounts-third-party");
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        } else {
            console.error('Element with ID "accounts-third-party" not found.');
        }
    }
    
    
    useEffect(() => {
        const updateSupplierAccount = async () => {
            try {
                const response = await AliExpressAuthCallbackAPI(code);
                editAccountSupplier(response);
                goToSettings();
            } catch (error) {
                console.error('Error updating supplier account:', error);
                setApprovalMessageState(prev => ({
                    ...prev,
                    isMessageShown: true,
                    text: t('SHIPIFY_GENERAL_ERROR_MESSAGE')
                }))
                goToHome();
            }
        };

        if (code) {
            updateSupplierAccount();
        } else {
            setApprovalMessageState(prev => ({
                ...prev,
                isMessageShown: true,
                text: t('SHIPIFY_GENERAL_ERROR_MESSAGE')
            }))
            goToHome();
        }
    }, []);



    return (
        <>
            <div>
                <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'center',
                    minHeight:'calc(100vh - 150px)', 
                    alignItems: 'center', 
                    flexDirection: 'column-reverse',
                    gap:'15px' }}
                    >
                    <div>
                        <span>{t('SHIPIFY_ALIEXPRESS_REDIRECT_LOADER_TITLE')}</span>
                    </div>
                    <CircularProgress />
                </Box>
            </div>

            <ApprovalMessage
                isMessageShown={approvalMessageState.isMessageShown}
                closeMessage={hideApprovalMassage}
                text={approvalMessageState.text}
            />
        </>
    )
}