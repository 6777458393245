import React from 'react';
import Box from '@mui/material/Box';
import { GridToolbarQuickFilter } from '@mui/x-data-grid';

export default function EditToolbar ({ searchLoading }) {
  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider', p: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <div className='shipify-products-page-search'>
        <GridToolbarQuickFilter
          quickFilterParser={(searchInput) =>
            searchInput.split(',').map((value) =>
              value.trim()).filter((value) => value !== '')}
          debounceMs={750}
          disabled={searchLoading }
        />
      </div>
    </Box>
  );
}



