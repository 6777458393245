import React from 'react'
import './Tooltip.css'
import { RxQuestionMark } from 'react-icons/rx'
import { isMobile } from 'react-device-detect'

/**
 * Represents a tooltip element that displays an information bubble when hovering over it.
 * @param {string} tooltipText - The information bubble text content
 */
export default function Tooltip({
    image,
    tooltipText,
    swapHorizontal = false
}) {
    return (
        <>
            {
                tooltipText && <div className="tooltip-container">
                    {image ?? <RxQuestionMark className="tooltip-image" />}
                    <div className={`${isMobile ? 'mobile-tooltip-bubble' : 'tooltip-bubble'} ${swapHorizontal ? 'horizontal-swap' : ''}`}>{tooltipText}</div>
                </div>
            }
        </>
    )
}