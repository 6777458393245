const debug = {
    ShopifyAppEmbedId : "ec76110b-fdea-4275-8d8b-50d07724c3c0",
    base_url: "http://127.0.0.1:5001",
    frontend_url: "http://127.0.0.1:3000/",
    dashboard_url: "http://127.0.0.1:3000/",
    is_dev: true,
}

const dev = {
    ShopifyAppEmbedId : "cbc1a205-38a6-43e5-92ae-9584929ef071",
    appHandle: "block", 
    api_url: `https://api-dev.shipifyapp.io`,
    dashboard_url: `https://admin-dev.shipifyapp.io/`,
    is_dev: true,
}

const prod = {
    ShopifyAppEmbedId : "712111e6-754e-4c5a-b0dd-9e2126987756",
    appHandle: "block", 
    api_url: `https://api.shipifyapp.io`,
    dashboard_url: `https://admin.shipifyapp.io/`,
    is_dev: false,
}

export const environment = (() => {
    switch (process.env.REACT_APP_STAGE) {
        case "debug":
            return debug;
        case "dev":
            return dev;
        case "prod":
            return prod;
        default:
            return dev;
    }
})()