import { SHIPIFY_UPGRADE_API_ROUTE } from "constants/api.constants";
import { request_auth } from "services/RequestWrapper";
import { environment } from 'conf'
const BILLING_API_BASE_URL = '/billing'

/*
    Requests upgrading the user's subscription plan.

    Method: [POST]
    URL: /billing/upgrade

*/
export function requestUpgradePlan() {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST',
            body: {
                billing_plan: 'PRO'
            }
        }
        request_auth(environment.api_url + BILLING_API_BASE_URL + SHIPIFY_UPGRADE_API_ROUTE, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data);
                } else {
                    reject(response.error);
                }
            })
            .catch(error => {
                reject(error);
            });
    });
}