import React from 'react'
import './ShipifySection.css'

export default function ShipifySection({
    title = '',
    sectionClassName = '',
    rightSection = null,
    children
}) {
    return (
        <div className={`shipify-section ${sectionClassName}`}>
            <div className="shipify-section-title-container">
                {title && <div className="shipify-section-title">{title}</div>}
                {rightSection ?? <div style={{ width: '40px' }}></div>}
            </div>
            {children}
        </div>
    )
}