import {
	BASE_LAYOUT_PAGES_WITH_DASHBOARD_TOPBAR,
	PADDED_PAGES,
	SIDEBAR_MODES,
	UNLIMITED_WIDTH_PAGES
} from "constants/general.constants"
import {
	SHIPIFY_CREATE_SHIPPING_GROUP_PAGE_URL,
	SHIPIFY_HOME_PAGE_URL, SHIPIFY_HOW_TO_USE_PAGE_URL, SHIPIFY_SETTINGS_AND_PLANS_PAGE_URL, SHIPIFY_SHIPPING_GROUPS_EDIT_ROUTE, SHIPIFY_SHIPPING_GROUPS_PAGE_URL, 
	SHIPIFY_SHIPPING_GROUPS_ROUTE,SHIPIFY_SHIPPING_COMPANIES_PAGE_URL, SHIPIFY_ALIXPRESS_CALLBACK_URL, SHIPIFY_PRODUCTS_PAGE_URL
} from 'constants/routes.constants'
import React, { useEffect, useState } from "react"
import './BaseLayout.css'
// import { useGeneralDataContext } from "contexts/User"
import { isMobile } from "react-device-detect"
import DashboardSidebar from "components/DashboardSidebar/DashboardSidebar"
import DashboardTopBar from "components/DashboardTopBar/DashboardTopBar"
import { useUserDetailsContext } from "contexts/shipify_context"
import { useHistoryContext } from "contexts/History"

export default function BaseLayout({ children }) {	
	const history = useHistoryContext()
	const {
		isFreeUser,
		setSidebarMode,
		setSidebarToggle,
		userDetails
	} = useUserDetailsContext()

    const [open, setOpen] = useState(userDetails.sidebarToggle ? false : window.innerWidth >= 1200)

	useEffect(() => {
		window.scrollTo(0, 0)

		const rootElement = document.querySelector('#root')

        const resizeObserver = new ResizeObserver(checkWindowSize)
        resizeObserver.observe(rootElement)

		if (isFreeUser() && window.location.pathname !== SHIPIFY_HOME_PAGE_URL) {
			history.replace(SHIPIFY_HOME_PAGE_URL)
			return
		}

        return () => resizeObserver.disconnect()
	}, [])

	useEffect(() => {
		setSidebarMode(open ? SIDEBAR_MODES.OPEN : SIDEBAR_MODES.MINIMIZED)
	}, [open])

	const baseLayoutStyles = {
		...(isLimitedPage() ? { maxWidth: `${getMaxWidth()}px` } : {}),
		...(isNotPaddedPage() ? { padding: 0 } : {})
	}

	function isNotPaddedPage() {
		if (window.location.pathname.startsWith(SHIPIFY_SHIPPING_GROUPS_ROUTE) && window.location.pathname !== SHIPIFY_SHIPPING_GROUPS_ROUTE ) {
			return true
		}
		return !PADDED_PAGES.includes(window.location.pathname)
	}

	function checkWindowSize() {
		if (!userDetails.sidebarToggle) {
			setOpen(window.innerWidth >= 1200)
		} else if (userDetails.sidebarMode === SIDEBAR_MODES.OPEN){
			setOpen(window.innerWidth < 1200)
		}
	}

	function isLimitedPage() {
		return !UNLIMITED_WIDTH_PAGES.includes(window.location.pathname)
	}

	function getMaxWidth() {
		if (window.location.pathname.startsWith(SHIPIFY_SHIPPING_GROUPS_ROUTE) && window.location.pathname.endsWith(SHIPIFY_SHIPPING_GROUPS_EDIT_ROUTE)) {
			return 1200
		}

		switch (window.location.pathname) {
			case SHIPIFY_SHIPPING_GROUPS_PAGE_URL:
			case SHIPIFY_HOME_PAGE_URL: 
			case SHIPIFY_SETTINGS_AND_PLANS_PAGE_URL:
			case SHIPIFY_PRODUCTS_PAGE_URL:
			case SHIPIFY_CREATE_SHIPPING_GROUP_PAGE_URL:
			case SHIPIFY_ALIXPRESS_CALLBACK_URL:
			case SHIPIFY_SHIPPING_COMPANIES_PAGE_URL:
				return 1200
			case SHIPIFY_HOW_TO_USE_PAGE_URL:
				return 1500
		}
	}
    
	function isDashboardTopBarShownInMobile() {
		if (window.location.pathname.startsWith(SHIPIFY_SHIPPING_GROUPS_ROUTE) && window.location.pathname.endsWith(SHIPIFY_SHIPPING_GROUPS_EDIT_ROUTE)) { // inside edit shipping group page
			return false
		}
        return BASE_LAYOUT_PAGES_WITH_DASHBOARD_TOPBAR.includes(window.location.pathname)
    }

    function isDashboardTopbarShown() {
        return !isMobile || isDashboardTopBarShownInMobile()
    }

	return (
		<div className="base-layout-container">
			{isDashboardTopbarShown() && <DashboardTopBar />}
			{!isMobile  && <DashboardSidebar open={open} setOpen={setOpen} />}
			<div className={isMobile ? "mobile-base-layout-content-container" : "base-layout-content-container"} style={baseLayoutStyles}>
				{children}
			</div>
		</div>
	)
}