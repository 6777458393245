import React, { useState } from 'react'
import './UserMenu.css'
import { FaCaretDown } from 'react-icons/fa6'
import { useUserDetailsContext } from 'contexts/shipify_context'
import PaydinMenu from 'components/PaydinMenu/PaydinMenu'
import { PAYDIN_MENU_OPENING_DIRECTIONS, PRIVACY_POLICY_URL, SHIPIFY_USER_MENU_BORDER_RADIUS_PX, TERMS_OF_USE_URL, USER_MENU_WIDTH_PX, CONTACT_US_URL } from 'constants/general.constants'
import { useTranslation } from 'react-i18next'

export default function UserMenu({
    minimized = false
}) {
    const { t } = useTranslation()
    const {
        userDetails,
        logout
    } = useUserDetailsContext()

    const [userMenuState, setUserMenuState] = useState({
        isOpen: false,
        anchorElement: null,
        onClose: () => { }
    })

    function openMenu(e) {
        setUserMenuState({
            isOpen: true,
            anchorElement: e.currentTarget,
            onClose: closeMenu
        })
    }

    function closeMenu() {
        setUserMenuState(prev => ({
            ...prev,
            isOpen: false
        }))
    }

    return (
        <>
            <div className="user-strip" onClick={openMenu}>
                <div className="user-strip-data">
                    {!minimized && <div className="user-strip-username">{userDetails?.username}</div>}
                </div>
                <FaCaretDown className='user-strip-arrow-image' />
            </div>
            <PaydinMenu
                widthPx={210}
                isOpen={userMenuState.isOpen}
                anchorElement={userMenuState.anchorElement}
                onClose={userMenuState.onClose}
                direction={PAYDIN_MENU_OPENING_DIRECTIONS.RIGHT}
                borderRadius={SHIPIFY_USER_MENU_BORDER_RADIUS_PX}
            >
                <div className="user-menu-container">
                     <a href={CONTACT_US_URL} className="user-menu-button">{t('USER_MENU_CONTACT_BUTTON_TEXT')}</a>
                    <a href={TERMS_OF_USE_URL} className="user-menu-button">{t('USER_MENU_TERMS_BUTTON_TEXT')}</a>
                    <a href={PRIVACY_POLICY_URL} className="user-menu-button">{t('USER_MENU_PRIVACY_BUTTON_TEXT')}</a>
                    <div className="user-menu-button" onClick={logout}>{t('USER_MENU_LOGOUT_BUTTON_TEXT')}</div>
                </div>
            </PaydinMenu>
        </>
    )
}