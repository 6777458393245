import PaydinTable from 'components/PaydinTable/PaydinTable'
import { SHIPPING_GROUPS_TABLE_HEADERS_IDS, SHIPPING_GROUPS_TABLE_ROWS_PER_PAGE, SHIPPING_GROUPS_TABLE_ROW_HEIGHT_PX, STORE_ORDERS_TABLE_ROWS_PER_PAGE, SHIPPING_GROUPS_TABLE_HEADER_BACKGROUND} from 'constants/general.constants'
import React, { useEffect, useState } from 'react'
import { HiOutlineShoppingBag } from 'react-icons/hi'
import { extractShipifyShippingGroup } from 'utils/dataUtils'
import './ShippingGroupsTable.css'
import { useTranslation } from 'react-i18next'
import { getShippingGroups } from 'api/shipping_groups/shipping_groups'
import ShippingGroupsTableHeaderView from 'components/ShippingGroupsTableHeaderView/ShippingGroupsTableHeaderView'
import ShippingGroupsTableRowView from 'components/ShippingGroupsTableRowView/ShippingGroupsTableRowView'
import { useUserDetailsContext } from 'contexts/shipify_context'
import PaydinDialog, { PAYDIN_DIALOG_BUTTON_TYPES } from 'dialogs/PaydinDialog/PaydinDialog'
import { useHistoryContext } from 'contexts/History'
import { FaBoxes } from 'react-icons/fa'

/**
 * Represents an orders table for a specific link.
 * @param {array} removedColumnIds - An array of column IDs to remove from display
 */
export default function ShippingGroupsTable({
    removedColumnIds = []
}) {
    const { t } = useTranslation()
    const history = useHistoryContext()
    const {
        userDetails,
        fetchShippingGroups,
        removeShippingGroup
    } = useUserDetailsContext()

    const [dialogState, setDialogState] = useState({
        isOpen: false,
        handleDialogClose: closeDialog,
        title: '',
        leftButtonText: '',
        rightButtonText: '',
        rightButtonClickHandler: () => { }
    })

    function createData(
        id,
        createdAt,
        groupName,
        countries,
        collections,
        rates
    ) {
        const data = {
            id,
            [SHIPPING_GROUPS_TABLE_HEADERS_IDS.GROUP_NAME]: groupName,
            [SHIPPING_GROUPS_TABLE_HEADERS_IDS.CREATED_AT]: createdAt,
            [SHIPPING_GROUPS_TABLE_HEADERS_IDS.COUNTRIES]: countries.length,
            [SHIPPING_GROUPS_TABLE_HEADERS_IDS.COLLECTIONS]: collections.length,
            [SHIPPING_GROUPS_TABLE_HEADERS_IDS.RATES]: rates.length,
            countries,
            collections,
            rates
        }

        if (removedColumnIds.length > 0) {
            Object.keys(data).forEach(columnId => {
                if (removedColumnIds.includes(columnId)) {
                    delete data[columnId]
                }
            })
        }

        return data
    }

    function normalizeTableData(items) {
        const normalizedData = []

        for (const item of items) {
            normalizedData.push(
                createData(
                    item?.id,
                    item?.createdAt,
                    item?.groupName,
                    item?.countries,
                    item?.collections,
                    item?.rates
                )
            )
        }

        return normalizedData
    }

    function getShippingGroupsData(count, skip, onSuccess = () => { }) {
        if (userDetails?.shippingGroups && userDetails?.shippingGroups.length > 0) {
            onSuccess(normalizeTableData(userDetails?.shippingGroups))
        } else {
            fetchShippingGroups(shippingGroups => {
                onSuccess(normalizeTableData(shippingGroups))
            }, error => {
                console.log(error)
            })
        }
    }

    function closeDialog() {
        setDialogState(prev => ({
            ...prev,
            isOpen: false
        }))
    }

    function onDeleteShippingGroup(id, onSuccess = () => { }, onFailure = () => { }) {
        setDialogState(prev => ({
            ...prev,
            isOpen: true,
            title: t('SHIPIFY_SHIPPING_GROUPS_TABLE_DELETE_SHIPPING_GROUP_DIALOG_TITLE'),
            message: t('SHIPIFY_SHIPPING_GROUPS_TABLE_DELETE_SHIPPING_GROUP_DIALOG_MESSAGE'),
            leftButtonText: t('SHIPIFY_SHIPPING_GROUPS_TABLE_DELETE_SHIPPING_GROUP_DIALOG_LEFT_BUTTON_TEXT'),
            rightButtonText: t('SHIPIFY_SHIPPING_GROUPS_TABLE_DELETE_SHIPPING_GROUP_DIALOG_RIGHT_BUTTON_TEXT'),
            rightButtonClickHandler: () => deleteShippingGroup(id, onSuccess, onFailure)
        }))
    }

    function deleteShippingGroup(shippingGroupId, onSuccess = () => { }, onFailure = () => { }) {
        removeShippingGroup(shippingGroupId, () => {
            history.goBack()
            onSuccess()
        }, error => {
            history.goBack()
            setTimeout(() => {
                showDeleteShippingGroupErrorDialog()
            }, 200)
            onFailure()
        })
    }

    function showDeleteShippingGroupErrorDialog() {
        setDialogState(prev => ({
            ...prev,
            isOpen: true,
            title: t('SHIPIFY_SHIPPING_GROUPS_TABLE_DELETE_SHIPPING_GROUP_ERROR_DIALOG_TITLE'),
            message: t('SHIPIFY_SHIPPING_GROUPS_TABLE_DELETE_SHIPPING_GROUP_ERROR_DIALOG_MESSAGE'),
            leftButtonText: t('SHIPIFY_SHIPPING_GROUPS_TABLE_DELETE_SHIPPING_GROUP_ERROR_DIALOG_LEFT_BUTTON_TEXT'),
            rightButtonText: '',
            rightButtonClickHandler: () => { }
        }))
    }

    return (
        <>
            <PaydinTable
                visibleRowsCount={SHIPPING_GROUPS_TABLE_ROWS_PER_PAGE}
                borderRadius='10px'
                totalCount={userDetails?.shippingGroups.length}
                rowHeightPx={SHIPPING_GROUPS_TABLE_ROW_HEIGHT_PX}
                rowView={ShippingGroupsTableRowView}
                headerView={ShippingGroupsTableHeaderView}
                dataGenerator={getShippingGroupsData}
                noItemsImage={<FaBoxes className='orders-table-no-items-image' />}
                noItemsText={t('SHIPIFY_SHIPPING_GROUPS_TABLE_NO_ITEMS_TO_DISPLAY_MESSAGE')}
                removedColumnIds={removedColumnIds}
                shouldPaginate={false}
                columnCount={7}
                onDeleteItem={onDeleteShippingGroup}
                headerBackgroundColor ={SHIPPING_GROUPS_TABLE_HEADER_BACKGROUND}
            />
            {
                dialogState.isOpen && <PaydinDialog
                    title={dialogState.title}
                    message={dialogState.message}
                    isDialogOpen={dialogState.isOpen}
                    handleDialogClose={dialogState.handleDialogClose}
                    leftButtonText={dialogState.leftButtonText}
                    rightButtonText={dialogState.rightButtonText}
                    leftButtonType={PAYDIN_DIALOG_BUTTON_TYPES.PRIMARY}
                    rightButtonType={PAYDIN_DIALOG_BUTTON_TYPES.SECONDARY}
                    onRightButtonClick={dialogState.rightButtonClickHandler}
                    closeOnRightClick={false}
                    isLeftButtonWithLoader={false}
                />
            }
        </>
    )
}