import { 
    SHIPIFY_APP_ACTIVATION_API_ROUTE,
    SHIPIFY_APP_DEACTIVATION_API_ROUTE,
    SHIPIFY_AUTH_API_URL, 
    SHIPIFY_AUTH_VERIFY_API_URL, 
    SHIPIFY_BLOCK_API_ROUTE, 
    SHIPIFY_CARRIER_SERVICE_API_ROUTE,
    SHIPIFY_HEALTH_API_ROUTE,
    SHIPIFY_RATE_INFO_API_ROUTE,
    SHIPIFY_VALIDATE_API_ROUTE
} from "constants/api.constants"
import { request, request_auth } from "services/RequestWrapper";
import { environment } from 'conf'
const USERS_API_BASE_ROUTE = '/users'

/*
    Authenticates the user with the given token and shop URL.

    Method: [GET]
    URL: /users/auth

*/
export function auth(token, shopUrl) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'GET',
            headers: {
                'Authorization': token,
                'shop_url': shopUrl
            }
        }
        request(environment.api_url + USERS_API_BASE_ROUTE + SHIPIFY_AUTH_API_URL, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data);
                } else {
                    reject(response.error);
                }
            })
            .catch(error => {
                reject(error);
            });
    });
}

/*
    Verifies the user and recieves its data.

    Method: [GET]
    URL: /users/auth_verify

*/
export function authVerify() {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'GET',
        }
        request_auth(environment.api_url + USERS_API_BASE_ROUTE + SHIPIFY_AUTH_VERIFY_API_URL, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data);
                } else {
                    reject(response.error);
                }
            })
            .catch(error => {
                reject(error);
            });
    });
}

/*
    Validates the carrier calculated shipping.

    Method: [POST]
    URL: /users/validate/carrier_service

*/
export function validateCarrierService() {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST',
        }
        request_auth(environment.api_url + USERS_API_BASE_ROUTE + SHIPIFY_VALIDATE_API_ROUTE + SHIPIFY_CARRIER_SERVICE_API_ROUTE, options)
            .then(response => {
                if (response.status) {
                    resolve(response.status);
                } else {
                    reject(response.error);
                }
            })
            .catch(error => {
                reject(error);
            });
    });
}

/*
    Validates the block.

    Method: [POST]
    URL: /users/validate/block

*/
export function validateBlock() {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST',
        }
        request_auth(environment.api_url + USERS_API_BASE_ROUTE + SHIPIFY_VALIDATE_API_ROUTE + SHIPIFY_BLOCK_API_ROUTE, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data);
                } else {
                    reject(response.error);
                }
            })
            .catch(error => {
                reject(error);
            });
    });
}

/*
    Updates the rate information.

    Body params:
    =============
    (*) rateName - The rate name
    (*) rateDescription - The rate description

    Method: [PUT]
    URL: /users/rate-info
    NEW URL: /users/info

*/
export function updateRateInfo(rateName, rateDescription, useCustomRates) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'PUT',
            body: {
                rate_name: rateName,
                rate_description: rateDescription,
                is_use_custom_rates: useCustomRates
            }
        }
        request_auth(environment.api_url + USERS_API_BASE_ROUTE + SHIPIFY_RATE_INFO_API_ROUTE, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data);
                } else {
                    reject(response.error);
                }
            })
            .catch(error => {
                reject(error);
            });
    });
}

/*
    Activates the app.

    Method: [POST]
    URL: /users/app-activation

*/
export function activateApp() {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST'
        }
        request_auth(environment.api_url + USERS_API_BASE_ROUTE + SHIPIFY_APP_ACTIVATION_API_ROUTE, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data);
                } else {
                    reject(response.error);
                }
            })
            .catch(error => {
                reject(error);
            });
    });
}


export function deactivateApp() {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST'
        }
        request_auth(environment.api_url + USERS_API_BASE_ROUTE + SHIPIFY_APP_DEACTIVATION_API_ROUTE, options)
            .then(response => {
                if (response.status) {
                    resolve(response);
                } else {
                    reject(response.error);
                }
            })
            .catch(error => {
                reject(error);
            });
    });
}

/*
    Checks the health properties.

    Method: [POST]
    URL: /users/health

*/
export function checkHealth() {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST'
        }
        request_auth(environment.api_url + USERS_API_BASE_ROUTE + SHIPIFY_HEALTH_API_ROUTE, options)
            .then(response => {
                if (response.status && response.data || !response.staus && response.data) {
                    resolve(response);
                } else {
                    reject(response.error);
                }
            })
            .catch(error => {
                reject(error);
            });
    });
}