import { DASHBOARD_TOPBAR_HEIGHT_PX, MOBILE_PAGE_VIEW_TOPBAR_HEIGHT_PX, SCROLL_TO_ID_DELAY_MILLISECONDS } from "constants/general.constants";
import { HEBREW_CHARACTERS_REGEX } from "constants/regex.constants";
import { isMobile } from "react-device-detect";


// Gets the mobile's OS - iOS/Android/Windows
export function getMobileOperatingSystem() {
	var userAgent = navigator.userAgent || navigator.vendor || window.opera;

	// Windows Phone must come first because its UA also contains "Android"
	if (/windows phone/i.test(userAgent)) {
		return "Windows Phone";
	}

	if (/android/i.test(userAgent)) {
		return "Android";
	}

	// iOS detection from: http://stackoverflow.com/a/9039885/177710
	if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
		return "iOS";
	}

	return "unknown";
}

// Gets the value of a query variable in the current URL 
export function getQueryVariable(variable, defaultValue = '') {
	var query = window.location.search.substring(1);
	var vars = query.split("&");
	for (var i = 0; i < vars.length; i++) {
		var pair = vars[i].split("=");
		if (pair[0] === variable) { return pair[1]; }
	}
	return defaultValue;
}

export function getValueFromSet(key, set, defaultValue) {
	return (!(key in set) || set[key] === undefined || set[key] === null) ? defaultValue : set[key];
}

/**
 * Calculates the pure height of an element, excluding padding.
 * @param {DOMElement} element - The element to calculate its pure height
 */
export function getElementHeight(element) {
	const computedStyles = window.getComputedStyle(element, null)
	return element.clientHeight - parseFloat(computedStyles.paddingTop) - parseFloat(computedStyles.paddingBottom)
}

/**
 * Copies text to the clipboard.
 * @param {string} text - The text to copy
 */
export async function copyToClipboard(text) {
	try {
		await navigator.clipboard.writeText(text);
		console.log('Text copied to clipboard');
	} catch (error) {
		console.error('Failed to copy text to clipboard:', error);
	}
}

export function isInputValueValid(value) {
	return !HEBREW_CHARACTERS_REGEX.test(value)
}

/**
 * Scrolls to a specific id via # in the page's URL.
 */
export function scrollToId() {
	setTimeout(() => {
		const id = window.location.href.substring(window.location.href.lastIndexOf('#') + 1)
		const element = document.getElementById(id)
		if (element) {
			window.scrollTo({ top: element.offsetTop - ((isMobile ? MOBILE_PAGE_VIEW_TOPBAR_HEIGHT_PX : DASHBOARD_TOPBAR_HEIGHT_PX) + 25), behavior: 'smooth' })
		}
	}, SCROLL_TO_ID_DELAY_MILLISECONDS)
}