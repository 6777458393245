import React from 'react'
import './ColorfulTag.css'

/**
 * Represents a colorful tag which displays a text with a bullet.
 * @param {string} text - The text of this tag
 * @param {string} backgroundColor - The background color of this tag
 * @param {boolean} isSmall - Determins whether this tag has reduced padding or not
 * @param {string} textColor - The text color of the tag
 * @param {string} textSize - The text size of the tag
 */
export default function ColorfulTag({
    text,
    backgroundColor,
    isSmall,
    textColor = '#555555',
    textSize = null
}) {
    return (
        <div className={`colorful-tag ${isSmall ? 'small' : ''}`} style={{ backgroundColor, fontSize: textSize ?? (isSmall ? '11px' : '13px') }}>
            <div className={`colorful-tag-bullet ${isSmall ? 'small' : ''}`}></div>
            <div className="colorful-tag-text" style={{ color: textColor }}>{text}</div>
        </div>
    )
}