import React, { useState, useMemo, useEffect } from 'react';
import Box from '@mui/material/Box';
import './ShipifyProductsPage.css'
import { DataGrid } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next'
import { useUserDetailsContext } from 'contexts/shipify_context'
import { isMobile } from 'react-device-detect'
import { Button } from '@mui/material';
import { MdAddLink, MdRemoveRedEye, MdEdit, MdImage } from 'react-icons/md';
import AliExpressLogo from 'embeddedImages/AliExpressLogo';
import ShopifyLogo from 'embeddedImages/ShopifyLogo';
import { ALL_COUNTRIES, CHROME_EXTENSION_LINK } from 'constants/general.constants';
import { getShippingRates } from 'api/shipping_rates/shipping_rates';
import { getAllProducts } from 'api/all_peoducts/all_products';
import { getProductMapping, updateProductMapping, deleteProductMapping } from 'api/all_peoducts/product_mapping';
import guideImage from 'assets/shipify-products-accordion.png'
import mappingGif from 'assets/product-mapping-guide.gif'
import viewerGif from 'assets/product-shipping-guide.gif'
import AccordionSlideshow from 'components/ShipifyAccordion/ShipifyAccordion';
import PaydinDialog, { PAYDIN_DIALOG_BUTTON_TYPES } from 'dialogs/PaydinDialog/PaydinDialog'
import ShipifyDataSection from 'components/ShipifyDataSection/ShipifyDataSection';
import ProductDrawer from './ProductDrawer';
import EditToolbar from './EditToolBar';


const slides = [
  {
    title: "Product Mapping",
    description: "When clicking on the link button, you can enter the product link from AliExpress and thus create a mapping to receive shipping methods in real time.",
    steps: [
      'Click the "Link" button.',
      'Enter the AliExpress product link.',
      'Confirm to create the mapping.'
    ],
    gifUrl: mappingGif,
  },
  {
    title: "Shipping Viewer",
    description: "Explore various shipping methods available for your products easily.Explore various shipping methods available for your products easily.",
    steps: [
      'Click on the "View" button.',
      'Select a variant and quantity.',
      'Choose country to view shipping methods.'
    ],
    gifUrl: viewerGif,
  },
];


export default function ShipifyProductsPage() {
  const {
    userDetails,
    setUserProperties
  } = useUserDetailsContext()

  const { t } = useTranslation()

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [supplierMainLink, setSupplierMainLink] = useState('');
  const [rows, setRows] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [shippingRates, setShippingRates] = useState([]);
  const [selectedVariant, setSelectedVariant] = useState('');
  const [selectedQuantity, setSelectedQuantity] = useState('1');
  const [inputKey, setInputKey] = useState(Date.now());
  const [isMappingLoading, setIsMappingLoading] = useState(false);
  const [isVaraintsLoading, setVariantsLoading] = useState(false)
  const [isRates, setIsRates] = useState(true)
  const [alertInfo, setAlertInfo] = useState({
    open: false,
    message: '',
    url: '',
    severity: 'info', // could be "error", "success", "info", "warning"
  });

  const [dialogState, setDialogState] = useState({
    isOpen: false,
    handleDialogClose: closeDialog,
    title: '',
    message: '',
    leftButtonText: '',
    rightButtonText: '',
    rightButtonClickHandler: () => { }
  })

  const [nextKey, setNextKey] = useState(null);
  const [prevKeys, setPrevKeys] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [productsPageSize, setProductsPageSize] = useState(25);
  const [cachedRows, setCachedRows] = useState([]); // Cache to store all loaded rows
  const [originalRows, setOriginalRows] = useState([]); // Cache to store all loaded rows
  const [rowsLoading, setRowsLoading] = useState(true);
  const [isFetchingNext, setIsFetchingNext] = useState(false);
  const [lastKey, setLastKey] = useState(null);
  const [productsSearchTerm, setProductsSearchTerm] = useState('');
  const [allProductsFetched, setAllProductsFetched] = useState(false);
  const [isSearching, setIsSearching] = useState(false)
  const [filteredRowCount, setFilteredRowCount] = useState(userDetails?.totalProducts);
  const [searchNextKey, setSearchNextKey] = useState(null);
  const [searchCurrentPage, setSearchCurrentPage] = useState(0);
  const [searchPageSize, setSearchPageSize] = useState(25);
  const [searchResults, setSearchResults] = useState([]);
  const [paginationLoading, setPaginationLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [totalProducts, setTotalProducts] = useState(null);
  const [mappedProducts, setMappedProducts] = useState(null);
  const [unMappedProducts, setUnMappedProducts] = useState(null);
  const [activeCountrySelector, setActiveCountrySelector] = useState(false);
  const [mappingCountry, setMappingCountry] = useState(
    [{ code: 'US', name: 'United States', isClicked: false }]
  )
  const [sortModel, setSortModel] = useState([]); // Add this line to define sortModel state

  const errors = [
    {
      error: "SHIP_TO_COUNTRY_PROHIBITED",
      message: t("SHIPIFY_MAPPING_ERROR_TEXT_1")
    },
    {
      error: "All SKU Unsaleable",
      message: t("SHIPIFY_MAPPING_ERROR_TEXT_2"),
    },
    {
      error: "ITEM_ID_NOT_FOUND",
      message: t("SHIPIFY_MAPPING_ERROR_TEXT_3")
    }
  ]

  const handleFilterModelChange = (filterModel) => {  
    if (!filterModel.quickFilterValues.length) {
      // If search input is cleared, reset to show cached rows and the last key
      setIsSearching(false);
      setCurrentPage(0);
      setProductsSearchTerm('');
      setFilteredRowCount(userDetails.totalProducts); 
      setSearchNextKey(null);
      setSearchResults([]);
      setProductsPageSize(25); 
      setSearchPageSize(25);
      setRows(cachedRows.slice(0, 25)); 
    } else {
      // Fetch filtered data
      setIsSearching(true);
      setCurrentPage(0);
      setSearchCurrentPage(0);
      setSearchPageSize(searchPageSize);
      if (searchTerm !== filterModel.quickFilterValues.join(' ')) {
        setFilteredRowCount(userDetails?.totalProducts)
      }
      setProductsSearchTerm(filterModel.quickFilterValues.join(' '));
      if (!allProductsFetched) {
        fetchProducts(filterModel.quickFilterValues.join(' '), null);
      } else {
        // If all products are fetched, filter client-side
        const filteredRows = cachedRows.filter(row => {
          return filterModel.quickFilterValues.some(value => {
            return Object.values(row).some(cell => 
              cell !== null && cell !== undefined && cell.toString().toLowerCase().includes(value.toLowerCase())
            );
          });
        });
        setFilteredRowCount(filteredRows.length); 
        setSearchResults(filteredRows);
        setRows(filteredRows.slice(0, searchPageSize));
      }
    }
  };
  
  

  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  }
  

  const deleteProductsMapping = async () => {
    try {
      const response = await deleteProductMapping();
    } catch (error) {
      console.error('Error deleting products mapping:', error);
    }
  } 

  const fetchProducts = async (query = null, key = null, prefetch = false, callback = null) => {
    if (prefetch && isFetchingNext) return; // Prevent multiple pre-fetch requests
    
    if (prefetch) {
      setIsFetchingNext(true);
    } else {
      setRowsLoading(true);
    }
    if (query) setSearchLoading(true);

    setPaginationLoading(true)

    try {
      const response = await getAllProducts(query, key);
      const processedData = response.products.map(processProductData);
      if (query) {
        if (key) {
          setSearchResults((prev) => [...prev, ...processedData]);
        } else {
          setSearchResults(processedData);
          setFilteredRowCount(processedData.length);
        }
        setRows(processedData.slice(0, searchPageSize));
        setSearchNextKey(response.next_key);

        if (key) setFilteredRowCount((prev) => prev + processedData.length); 
        
        setRowsLoading(false)
      } else {
        setCachedRows((prev) => {
          const updatedRows = [...prev, ...processedData];
          return updatedRows;
        });

        setOriginalRows((prev) => {
          const updatedRows = [...prev, ...processedData];
          return updatedRows;
        });
  
        setAllProductsFetched(response.next_key === null ? true : false);
  
        setNextKey(response.next_key);
        setLastKey(response.next_key); 
        
        if (callback) callback();

        if (totalProducts === null || mappedProducts === null ) {
          setTotalProducts(response.total_products);
          setMappedProducts(response.total_product_mappings);
          setUnMappedProducts(parseInt(response.total_products) - parseInt(response.total_product_mappings))
        }
      }
    } catch (error) {
      console.error('Error fetching products:', error);
    } finally {
      if (prefetch) {
        setIsFetchingNext(false);
      } else {
        setRowsLoading(false);
      }
      if (query) setSearchLoading(false);
      setPaginationLoading(false); // Set pagination loading state to false
    }
  };
  
  useEffect(() => {
    fetchProducts();
  }, []);
  

  useEffect(() => {
    if (isSearching) {
      updateDisplayedRows(searchCurrentPage, searchPageSize);
    } else {
      updateDisplayedRows(currentPage, productsPageSize);
    }
  }, [searchPageSize, productsPageSize, searchCurrentPage, currentPage, cachedRows]);

  
  const updateDisplayedRows = (page, size) => {
    const start = page * size;
    const end = start + size;
    const newRows = isSearching ? searchResults.slice(start, end) : cachedRows.slice(start, end);
    setRows(newRows);
  };
  
  
  const handleSortModelChange = (model) => {
    setSortModel(model);
    if (model.length === 0) {
      // No sorting, revert to original rows
      setCachedRows([...originalRows]);
      updateDisplayedRows(isSearching ? searchCurrentPage : currentPage, isSearching ? searchPageSize : productsPageSize);
      return;
    }

    if (model.length > 0) {
      const { field, sort } = model[0];
      const sortedRows = [...cachedRows].sort((a, b) => {
        if (a[field] < b[field]) {
          return sort === 'asc' ? -1 : 1;
        }
        if (a[field] > b[field]) {
          return sort === 'asc' ? 1 : -1;
        }
        return 0;
      });
      setCachedRows(sortedRows);
    }
    // Update the displayed rows based on the current page and page size
    updateDisplayedRows(isSearching ? searchCurrentPage : currentPage, isSearching ? searchPageSize : productsPageSize);
  };
  
  


  const handlePaginationModelChange = async (model) => {
    const { page, pageSize } = model;  
    const totalLoadedPages = isSearching
      ? Math.ceil(searchResults.length / pageSize)
      : Math.ceil(cachedRows.length / pageSize);
  
    // Check if the page size has changed
    const pageSizeChanged = isSearching ? pageSize !== searchPageSize : pageSize !== productsPageSize;
  
    if (pageSizeChanged) {
      // Page size changed, reset to the first page and show loader
      setPaginationLoading(true); 
  
      if (isSearching) {
        setSearchPageSize(pageSize);
        setSearchCurrentPage(0);
      } else {
        setProductsPageSize(pageSize);
        setCurrentPage(0);
      }
      setRowsLoading(true);
    } else {
      if (isSearching) {
        setSearchCurrentPage(page);
      } else {
        setCurrentPage(page);
      }
    }
  
    const newPage = pageSizeChanged ? 0 : page;
  
    const callback = () => {
      setRowsLoading(false);
      setPaginationLoading(false); 
    };
  
    if (isSearching) {
      if (newPage >= totalLoadedPages - 2 && searchNextKey) {
        await fetchProducts(productsSearchTerm, searchNextKey, true, callback);
        updateDisplayedRows(newPage, pageSize);
      } else {
        updateDisplayedRows(newPage, pageSize);
        setRowsLoading(false);
        setPaginationLoading(false); 
      }
    } else {
      if (newPage >= totalLoadedPages - 1 && nextKey) {
        await fetchProducts(null, nextKey, true, callback);
        updateDisplayedRows(newPage, pageSize);
      } else {
        updateDisplayedRows(newPage, pageSize);
        setRowsLoading(false);
        setPaginationLoading(false);
      }
    }
  };
  
  const debouncedHandlePaginationModelChange = debounce(handlePaginationModelChange, 300);

  
  const processProductData = (product) => {
    const productImg = product.main_image;
    const productSource = product.supplier_info?.supplier_info?.supplier_id ? product.supplier_info.supplier_info.supplier_name.toLowerCase() : "shopify";
    const supplierID = product.supplier_info?.supplier_info?.supplier_id;
    const supplierLink = supplierID ? `https://aliexpress.com/item/${supplierID}.html` : null;
    
    return {
      ...product,
      id: product.product_id,
      productImg,
      productSource,
      supplierID,
      supplierLink,
      variants: [],
    };
  };
  

  // Handle changes in the variant selection
  const handleVariantChange = (event) => {
    setSelectedVariant(event.target.value);
    setIsRates(true)
  };


  // Handle changes in the country selection
  const handleCountrySelect = (countryCode, countryName) => {
    setIsRates(true)
    setSelectedCountry([
      { code: countryCode, name: countryName }
    ]);
  };


  // Fetch shipping rates based on the selected variant and country
  useEffect(() => {
    const fetchShippingRates = async () => {
      if (selectedVariant && selectedCountry && selectedProduct && selectedQuantity) {
        setIsLoading(true);
        try {
          const rates = await getShippingRates(userDetails.shopUrl, selectedProduct.id, selectedVariant, selectedCountry[0].code, selectedQuantity);
          const ratesData = Object.values(rates)[0].rates;
          if (ratesData.length === 0) {
            setShippingRates([])
            setIsRates(false);
          }
          setShippingRates(ratesData);
        } catch (error) {
          setShippingRates([]); // Reset rates
          setIsRates(false)

        } finally {
          setIsLoading(false);
        }
      }
    };
    fetchShippingRates();
  }, [selectedVariant, selectedCountry, selectedProduct, selectedQuantity]);  // Fetch shipping rates based on the selected variant and country


  // Fetch varaints and mapping if exist
  useEffect(() => {
    const fetchProductVariants = async () => {
      if (drawerOpen && selectedProduct?.id || drawerOpen && selectedProduct?.supplier_info?.supplier_id) {
        setVariantsLoading(true);
        try {
          const response = await getProductMapping(selectedProduct.id);
          if (response && response.product) {

            const productImg = response.product.main_image;

            // Map variants to include the correct image and prepare for supplier_sku_id update
            let variantsArray = response.product.variants.map(variant => {
              const variantImage = response?.product?.images ? response.product.images[variant.image_position] :  productImg;
              return {
                ...variant,
                id: variant.variant_id, // Ensure this is set for keying purposes in React
                variantImg: variantImage, // Add the image to the variant data
                supplier_sku_id: null, // Initialize supplier_sku_id, to be updated next
              };
            });

            // If mapping data is available, use it to update supplier_sku_id for each variant
            if (response.variants_mapping) {
              const mappingArray = response.variants_mapping;
              variantsArray = variantsArray.map(variant => {
                const mapping = mappingArray.find(m => m.variant_id === variant.id);
                const supplier_sku_id = mapping ? mapping.supplier_product_details.sku_id : variant.supplier_sku_id;
                return { ...variant, supplier_sku_id };
              });
            }

            setSelectedProduct({
              ...selectedProduct,
              variants: variantsArray,
            });
          } else {
          }
        } catch (error) {
        } finally {
          setVariantsLoading(false);
        }
      }
    };
    fetchProductVariants();
  }, [drawerOpen, selectedProduct?.id, selectedProduct?.supplier_info?.supplier_id]);


  // Handle country search and filter countries
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };


  const filteredCountries = ALL_COUNTRIES.filter(country =>
    country.name.toLowerCase().includes(searchTerm.toLowerCase())
  );


  //Modal close force
  function closeDialog() {
    setDialogState(prev => ({
      ...prev,
      isOpen: false
    }))
  }


  // Handle drawer open and reset all values 
  const handleOpenDrawer = (product, tabIndex) => {
    setSelectedProduct(product);
    setSupplierMainLink(product.supplierLink || '');
    setActiveTab(tabIndex);
    setDrawerOpen(true);
    setSelectedVariant('');
    setShippingRates([]);
    setInputKey(Date.now()); // Update the key to force remount
    setSelectedQuantity('1');
    setActiveCountrySelector(false);
    setMappingCountry([{ code: 'US', name: 'United States', isClicked: false}]);
  };


  // Handle tabs change onClick
  const handleChangeTab = (event, newValue) => {
    setActiveTab(newValue);
    setAlertInfo({ open: false })
    if (newValue === 1) {
      // loadVariantMappings();
    }
  };


  // Check if the url is valid
  const validateLink = (link) => {
    return link.includes("aliexpress");
  };


  const updateRowsAndSelectedProduct = (matchId) => {
    const prodLink = `Https://aliexpress.com/item/${matchId}.html`;
  
    if (selectedProduct.supplier_info === null) {
      setMappedProducts(mappedProducts + 1)
      setUnMappedProducts(unMappedProducts - 1)
    }
    // Update rows (filtered or searched results)
    const updatedRows = rows.map(row => {
      if (row.id === selectedProduct.id) {
        const newSupplierInfo = {
          ...row.supplier_info,
          supplier_name: 'AliExpress',
          supplier_id: matchId
        };
        return { 
          ...row, 
          supplierID: matchId, 
          supplierLink: prodLink, 
          supplier_info: newSupplierInfo, 
          productSource: 'aliexpress' 
        };
      }
      return row;
    });
  
    // Update cachedRows (all loaded rows)
    const updatedCachedRows = cachedRows.map(row => {
      if (row.id === selectedProduct.id) {
        const newSupplierInfo = {
          ...row.supplier_info,
          supplier_name: 'AliExpress',
          supplier_id: matchId
        };
        return { 
          ...row, 
          supplierID: matchId, 
          supplierLink: prodLink, 
          supplier_info: newSupplierInfo, 
          productSource: 'aliexpress' 
        };
      }
      return row;
    });
  
    const updatedSelectedProduct = {
      ...selectedProduct,
      supplier_info: {
        ...selectedProduct.supplier_info,
        supplier_name: 'AliExpress',
        supplier_id: matchId
      },
      supplierID: matchId, 
      supplierLink: prodLink, 
      productSource: 'aliexpress'
    };
  
    setSelectedProduct(updatedSelectedProduct);
    setRows(updatedRows);
    setCachedRows(updatedCachedRows);
    setOriginalRows(updatedCachedRows);
  
    // If using search results, update them too
    if (isSearching) {
      const updatedSearchResults = searchResults.map(row => {
        if (row.id === selectedProduct.id) {
          const newSupplierInfo = {
            ...row.supplier_info,
            supplier_name: 'AliExpress',
            supplier_id: matchId
          };
          return { 
            ...row, 
            supplierID: matchId, 
            supplierLink: prodLink, 
            supplier_info: newSupplierInfo, 
            productSource: 'aliexpress' 
          };
        }
        return row;
      });
  
      setSearchResults(updatedSearchResults);
    }
  };
  


  const handleMappingResponse = async (response, matchId, supplierName) => {
    const allVariantsMapped = response.success_mapping === selectedProduct.variants.length ? true : false
    if (response.success_mapping === 0 && selectedProduct.variants.length === 1) {
      setDialogState(prev => ({
        ...prev,
        isOpen: true,
        isRightButtonWithLoader: true,
        title: t('EDIT_INFLUENCER_SECTION_DISCARD_CHANGES_DIALOG_TITLE'),
        message: t('SHIPIFY_FORCE_MAPPING_TEXT'),
        leftButtonText: t('EDIT_INFLUENCER_SECTION_DISCARD_CHANGES_DIALOG_LEFT_BUTTON_TEXT'),
        rightButtonText: t('EDIT_INFLUENCER_SECTION_DISCARD_CHANGES_DIALOG_RIGHT_BUTTON_TEXT'),
        rightButtonClickHandler: async () => {
          const responseForce = await updateProductMapping(selectedProduct.id, supplierName, matchId, true);
          if (responseForce.is_mapped) {
            setActiveCountrySelector(false)
            updateRowsAndSelectedProduct(matchId);
            setAlertInfo({
              open: true,
              message: `All ${responseForce.success_mapping}/${selectedProduct.variants.length} variants are mapped successfully`,
              severity: "success",
            });
          } else if (!responseForce.is_mapped) {
            const unmappedCount = selectedProduct.variants.length - response.success_mapping;
            setAlertInfo({
              open: true,
              message: `Mapping failed, ${unmappedCount}/${selectedProduct.variants.length} variants are not mapped`,
              severity: "error",
            });
          }
          closeDialog();
        },
      }));
    } else if (response.is_mapped && allVariantsMapped) {
      setActiveCountrySelector(false);
      updateRowsAndSelectedProduct(matchId);
      setAlertInfo({
        open: true,
        message: `All ${response.success_mapping}/${selectedProduct.variants.length} variants are mapped successfully`,
        severity: "success",
      });
    } else if (response.is_mapped && !allVariantsMapped) {
      setActiveCountrySelector(false);
      updateRowsAndSelectedProduct(matchId);
      setAlertInfo({
        open: true,
        message: `${response.success_mapping}/${selectedProduct.variants.length} variants are mapped successfully`,
        severity: "success",
      });
    } else if (!response.is_mapped && response.error === errors[0].error) {
      setAlertInfo({
        open: true,
        message: errors[0].message,
        severity: "error",
      });
      setActiveCountrySelector(true);
    } else if (!response.is_mapped && response.error === errors[1].error) {
      setAlertInfo({
        open: true,
        message: errors[1].message,
        severity: "error",
      });
    } else if (!response.is_mapped && response.error === errors[2].error) {
      setAlertInfo({
        open: true,
        message: errors[2].message,
        severity: "error",
      });
    } else if (!response.is_mapped && selectedProduct.variants[0].sku !== '') {
      const unmappedCount = selectedProduct.variants.length - response.success_mapping;
      setAlertInfo({
        open: true,
        message: `Mapping failed, ${unmappedCount}/${selectedProduct.variants.length} variants are not mapped`,
        severity: "error",
      });
    }
    
  };


  const handleImportButtonClick = async () => {
    const match = supplierMainLink.match(/aliexpress.*\/item\/(\d+)/);
    const supplierName = 'AliExpress';
    if (match && match[1]) {
      setIsMappingLoading(true);

      try {
        const response = await updateProductMapping(selectedProduct.id, supplierName, match[1], false, mappingCountry[0].code);
        await handleMappingResponse(response, match[1], supplierName);
      } catch (error) {
        const inProcessMessage = error === "Product mapping process already is running" ? true : false
        const errorMessage =  inProcessMessage ? 
           t('SHIPIFY_PRODUCT_MAPPING_IN_PROCESS') :
           userDetails.aliexpressAccount ?
           t("SHIPIFY_PRODUCTS_SKU_MAPPING_ERROR") :
           t('SHIPIFY_NO_ALIEXPRESS_ACCOUNT_MESSAGE', { url: '' });
        setAlertInfo({
          open: true,
          message: errorMessage,
          url: !userDetails.aliexpressAccount ? 'settings' : '',
          severity: "error",
        });
      } finally {
        setIsMappingLoading(false);
      }
    } else {
      setAlertInfo({
        open: true,
        message: t("SHIPIFY_PRODUCTS_SKU_MAPPING_INVALID_URL"),
        severity: "error",
      });
    }
  };


  const handleInputChange = (e) => {
    setSupplierMainLink(e.target.value);
    setAlertInfo({ open: false })

  };


  const columnsProducts = useMemo(
    () => [
      {
        field: 'productImg',
        cellClassName: 'shipify-non-edit-cell shipify-image-cell',
        width: 125, minWidth: 70, maxWidth: 125,
        headerName: '',
        sortable: false,
        flex: 1,
        renderCell: (params) =>
          <div className='shipify-product-image-container'>
            <div>
              {params.value ? <img className="shipify-product-image" src={params.value} /> : <MdImage />}
            </div>
          </div>
      },
      { field: 'title', cellClassName: 'shipify-non-edit-cell', width: 275, minWidth: 200, maxWidth: 300, headerName: 'Product', sortable: false, flex: 1 },
      { field: 'type', cellClassName: 'shipify-non-edit-cell', width: 200, minWidth: 150, maxWidth: 225, headerName: 'Type', sortable: false, flex: 1 },
      {
        field: 'productSource',
        cellClassName: 'shipify-non-edit-cell shipify-source-cell',
        width: 225, minWidth: 175, maxWidth: 250,
        headerName: 'Source',
        flex: 1,
        renderCell: (params) =>
          params.value === "shopify" ?
            <div>
              <div className='shipify-source-container'>
                <ShopifyLogo className="shipify-source-cell-img" />
              </div>
            </div>
            :
            <div>
              <div className='shipify-source-container'>
                <AliExpressLogo className="shipify-source-cell-img" />
              </div>
            </div>
      },
      {
        field: 'actions',
        cellClassName: 'shipify-non-edit-cell',
        headerName: 'Actions',
        sortable: false,
        width: 200, minWidth: 150, maxWidth: 300,
        renderCell: (params) => (
          <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
            <Button
              className='shipify-products-link-btn'
              onClick={() => handleOpenDrawer(params.row, 0)}
              startIcon={params.row.supplierID ? <MdEdit /> : <MdAddLink />}
            >{params.row.supplierID ? t("SHIPIFY_PRODUCTS_MAPPING_EDIT") : t("SHIPIFY_PRODUCTS_MAPPING_LINK")}
            </Button>

            <Button
              className='shipify-products-view-btn'
              onClick={() => handleOpenDrawer(params.row, 2)}
              startIcon={<MdRemoveRedEye />}
            >{t("SHIPIFY_PRODUCTS_VIEW_RATES")}
            </Button>
          </Box>
        ),
      }
    ]);


  const columnsVariants = useMemo(
    () => [
      {
        field: 'variantImg',
        maxWidth: 100,
        cellClassName: 'shipify-non-edit-cell shipify-image-cell',
        headerName: '',
        flex: 1,
        minWidth: 70,
        renderCell: (params) =>
          <div className='shipify-product-image-container'>
            <div>
              {params.value ? <img className="shipify-product-image" src={params.value} /> : <MdImage />}
            </div>
          </div>
      },
      { field: 'title', minWidth: 200, maxWidth: 225, cellClassName: 'shipify-non-edit-cell', headerName: 'Variant Title', flex: 1 },
      { field: 'id', minWidth: 150, maxWidth: 175, cellClassName: 'shipify-non-edit-cell', headerName: 'Shopify ID', flex: 1 },
      { field: 'sku', minWidth: 225, cellClassName: 'shipify-non-edit-cell', headerName: 'Shopify SKU', flex: 1 },
      { field: 'supplier_sku_id', minWidth: 175, maxWidth: 200, cellClassName: 'shipify-non-edit-cell', headerName: 'Supplier SKU ID', flex: 1 },
    ]);


  const columnsRates = useMemo(
    () => [
      { field: 'name', minWidth: 250, width: 250, headerName: 'Shipping Option', flex: 1 },
      {
        field: 'price',
        minWidth: 150,
        headerName: 'Shipping Price',
        flex: 1,
        valueGetter: (params) =>
          params.row.price === 0 ? 'Free' : parseFloat(params.row.price/100,).toFixed(2)
      },
      {
        field: 'original_price',
        minWidth: 150,
        headerName: 'Shipping Cost',
        flex: 1,
        valueGetter: (params) =>
          params.row.original_price === 0 ? 'Free' : params.row.original_price / 100,
      },
      { field: 'currency', minWidth: 150, headerName: 'Currency', flex: 1 },
      {
        field: 'deliveryRange',
        minWidth: 175,
        headerName: 'Delivery Days',
        flex: 1,
        valueGetter: (params) =>
          `${params.row.min_delivery_days} - ${params.row.max_delivery_days} days`,
      },
    ]);

  return (
    <div className={isMobile ? 'mobile-shipify-products-page-container' : 'shipify-products-page-container'}>
      <div className="shipify-products-page-title">{isMobile ? "" : t('SHIPIFY_PRODCUTS_PAGE_TITLE')} </div>
      <div className="shipify-products-page-content">
        <AccordionSlideshow title={t('SHIPIFY_PRODUCTS_ACCORDION_TITLE')} description={t('SHIPIFY_PRODUCTS_ACCORDION_TEXT')} slides={slides} guideImage={guideImage} />
        <div className={isMobile ? "shipify-home-page-content-column" : "shipify-home-page-content-row"}>
              <ShipifyDataSection
                  sectionClassName={isMobile ? 'mobile-shipify-products-page-content-section' : 'shipify-products-page-content-section'}
                  title={t('SHIPIFY_ALL_PRODUCTS_TEXT')}
                  value={totalProducts}
                  loading={!totalProducts}
               />

               <ShipifyDataSection
                  sectionClassName={isMobile ? 'mobile-shipify-products-page-content-section' : 'shipify-products-page-content-section'}
                  title={t('SHIPIFY_MAPPED_PRODUCTS_TEXT')}
                  value={mappedProducts}
                  loading={!totalProducts}
               />

               <ShipifyDataSection
                  sectionClassName={isMobile ? 'mobile-shipify-products-page-content-section' : 'shipify-products-page-content-section'}
                  title={t('SHIPIFY_UNMAPPED_PRODUCTS_TEXT')}
                  value={unMappedProducts}
                  loading={!unMappedProducts}
               />
        </div>
        {/* <div><button onClick={deleteProductsMapping}>Delete Mappings</button></div> */}
        <Box sx={{ height: rows.length < 10 ? "62dvh" : "94dvh", width: '100%', borderRadius: '10px' }}>
          <DataGrid
            className='shipify-products-data-grid'
            rows={rows}
            columns={columnsProducts}
            rowHeight={70}
            pagination
            paginationMode="server"
            sortingMode="client"
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            rowCount={isSearching ? filteredRowCount : !totalProducts ? userDetails.totalProducts : totalProducts}
            paginationModel={{ page: isSearching ? searchCurrentPage : currentPage, pageSize: isSearching ? searchPageSize : productsPageSize }} // Ensure correct pageSize is used
            onPaginationModelChange={!paginationLoading ? debouncedHandlePaginationModelChange : undefined} // Disable pagination change if loading
            loading={rowsLoading}
            filterMode={allProductsFetched && isSearching ? "client" : "server"}
            onFilterModelChange={handleFilterModelChange}
            initialState={{
              pagination: {
                paginationModel: { pageSize: 25, page: 0 },
              },
            }}
            slots={{
              toolbar: EditToolbar,
            }}
            slotProps={{
              toolbar: { searchLoading } // Pass props to the component
            }}
            disableRowSelectionOnClick
            disableColumnMenu
          />

        </Box>

        <ProductDrawer
          drawerOpen={drawerOpen}
          handleDrawerClose={() => setDrawerOpen(false)}
          activeTab={activeTab}
          handleChangeTab={handleChangeTab}
          selectedProduct={selectedProduct}
          handleInputChange={handleInputChange}
          supplierMainLink={supplierMainLink}
          handleImportButtonClick={handleImportButtonClick}
          isMappingLoading={isMappingLoading}
          validateLink={(link) => link.includes("aliexpress")}
          userDetails={userDetails}
          alertInfo={alertInfo}
          columnsVariants={columnsVariants}
          columnsRates={columnsRates}
          selectedVariant={selectedVariant}
          handleVariantChange={handleVariantChange}
          inputKey={inputKey}
          setSelectedQuantity={setSelectedQuantity}
          searchTerm={searchTerm}
          handleSearchChange={handleSearchChange}
          filteredCountries={filteredCountries}
          selectedCountry={selectedCountry}
          handleCountrySelect={handleCountrySelect}
          rowsRates={shippingRates.map((rate, index) => ({ id: index, ...rate }))}
          isLoading={isLoading}
          isRates={isRates}
          t={t}
          CHROME_EXTENSION_LINK={CHROME_EXTENSION_LINK}
          setAlertInfo={setAlertInfo}
          setSelectedCountry={setSelectedCountry}
          activeCountrySelector={activeCountrySelector}
          setActiveCountrySelector={setActiveCountrySelector}
          mappingCountry={mappingCountry}
          setMappingCountry={setMappingCountry}
        />

        {
          dialogState.isOpen && <PaydinDialog
            isDialogOpen={dialogState.isOpen}
            handleDialogClose={dialogState.handleDialogClose}
            title={dialogState.title}
            message={dialogState.message}
            closeOnRightClick={false}
            rightButtonType={PAYDIN_DIALOG_BUTTON_TYPES.SECONDARY}
            leftButtonText={dialogState.leftButtonText}
            rightButtonText={dialogState.rightButtonText}
            isLeftButtonWithLoader={false}
            isRightButtonWithLoader={dialogState.isRightButtonWithLoader}
            onRightButtonClick={dialogState.rightButtonClickHandler}
          />
        }

      </div>
    </div>
  );
}




