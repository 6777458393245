import React, { useState, useEffect } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Button,
  Typography,
  List,
  ListItem,
  styled
} from '@mui/material';
import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos';
import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect';
import './ShipifyAccordion.css'
import PaydinDialog, { PAYDIN_DIALOG_BUTTON_TYPES } from 'dialogs/PaydinDialog/PaydinDialog'
import { LiaCheckCircleSolid } from 'react-icons/lia'

const GifSlideshow = ({ slides }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [dialogState, setDialogState] = useState({
    isOpen: false,
    handleDialogClose: closeDialog,
    title: '',
    leftButtonText: '',
    rightButtonText: '',
    rightButtonClickHandler: () => { }
  });
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
  };

  const handleImageLoad = () => {
    setIsImageLoaded(true);
  };

  const handleBigGif = () => {
    if (isImageLoaded) {
      setDialogState(prev => ({
        ...prev,
        isOpen: true,
        title: '',
        message: <Box component="img" src={slides[currentIndex].gifUrl} />,
        leftButtonText: '',
        rightButtonText: '',
        confirmationCheckboxLabel: '',
        rightButtonClickHandler: '',
        class: 'image-zoom-accordion'
      }));
    }
  };

  function closeDialog() {
    setDialogState(prev => ({
      ...prev,
      isOpen: false
    }));
  }

  const handleBack = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + slides.length) % slides.length);
  };

  useEffect(() => {
    setIsImageLoaded(false);
  }, [currentIndex]);

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="center" width={'100%'}>
        <Box p={2} display="flex" flexDirection={isMobile ? "column" : "row"} alignItems="center" width={'100%'} justifyContent='space-around'>
          <Box display='block' width={isMobile ? '100%' : '50%'} minHeight={'200px'}>
            <AccordionText variant="h8" sx={{ marginRight: 2, fontWeight: 500 }}>
              {slides[currentIndex].title}
            </AccordionText>
            <AccordionText variant="body1" sx={{ fontSize: 15, marginTop: '10px' }}>
              {slides[currentIndex].description}
            </AccordionText>
            <List sx={{ paddingInlineStart: '40px', listStyleType: 'disc' }}>
              {slides[currentIndex].steps.map((step, index) => (
                <ListItem sx={{ display: 'list-item', fontSize: '15px', color: "#303030" }} key={index}>{step}</ListItem>
              ))}
            </List>
          </Box>
          <Box
            component="img"
            src={slides[currentIndex].gifUrl}
            alt="Slideshow GIF"
            sx={{ borderRadius: '4px', width: isMobile ? 'clamp(50px, 100%, 300px)' : 'clamp(50px, 50%, 300px)', height: 'auto', cursor: 'pointer' }}
            onClick={handleBigGif}
            onLoad={handleImageLoad}
          />
        </Box>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="center" sx={{ borderTop: '1px solid #ccc', paddingTop: '10px' }}>
        <Button size='small' sx={{ color: "rgb(90,90,90)" }} onClick={handleBack}>
          <ArrowForwardIos sx={{ width: '18px', height: '18px', rotate: '180deg' }} />
        </Button>
        <Typography variant='body1'>
          {`${currentIndex + 1} / ${slides.length}`}
        </Typography>
        <Button size='small' sx={{ color: "rgb(90,90,90)" }} onClick={handleNext}>
          <ArrowForwardIos sx={{ width: '18px', height: '18px' }} />
        </Button>
      </Box>
      {
        dialogState.isOpen && <PaydinDialog
          title={dialogState.title}
          message={dialogState.message}
          isDialogOpen={dialogState.isOpen}
          handleDialogClose={dialogState.handleDialogClose}
          leftButtonText={dialogState.leftButtonText}
          rightButtonText={dialogState.rightButtonText}
          leftButtonType={PAYDIN_DIALOG_BUTTON_TYPES.PRIMARY}
          rightButtonType={PAYDIN_DIALOG_BUTTON_TYPES.SECONDARY}
          onRightButtonClick={dialogState.rightButtonClickHandler}
          closeOnRightClick={true}
          isLeftButtonWithLoader={false}
          dialogLayout={dialogState.dialogLayout}
          confirmationCheckboxLabel={dialogState.confirmationCheckboxLabel}
          minHeight={dialogState.minHeight}
          messageFontSizePx={dialogState.messageFontSize}
          classDialog={dialogState.class}
        />
      }
    </>
  );
};

const AccordionButton = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 12,
  padding: '6px 12px',
  border: 'none',
  lineHeight: '1rem',
  color: '#303030',
  backgroundColor: '#fff',
  fontWeight: '500',
  borderRadius: 8,
  boxShadow: '0rem -.0625rem 0rem 0rem #b5b5b5 inset, 0rem 0rem 0rem .0625rem rgba(0, 0, 0, .1) inset, 0rem .03125rem 0rem .09375rem #FFF inset',
  fontFamily: [
    'Rubik',
    'sans serif',
  ].join(','),
  '&:hover': {
    backgroundColor: 'rgba(250, 250, 250, 1)',
    boxShadow: '0rem -.0625rem 0rem 0rem #b5b5b5 inset, 0rem 0rem 0rem .0625rem rgba(0, 0, 0, .1) inset, 0rem .03125rem 0rem .09375rem #FFF inset',
  },
  '&:active': {
    boxShadow: '-.0625rem 0rem .0625rem 0rem rgba(26, 26, 26, .122) inset, .0625rem 0rem .0625rem 0rem rgba(26, 26, 26, .122) inset, 0rem .125rem .0625rem 0rem rgba(26, 26, 26, .2) inset',
    backgroundColor: 'rgba(247, 247, 247, 1)',
  },
});

const AccordionText = styled(Typography)({
  fontFamily: [
    'Rubik',
    'sans serif',
  ].join(','),
  color: '#303030',
  lineHeight: 1.25
});

export default function AccordionSlideshow({ title, description, slides, guideImage }) {
  const [expanded, setExpanded] = useState(false);

  const { t } = useTranslation()

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Accordion
      className='shipify-box'
      expanded={expanded === 'panel1'}
      onChange={handleAccordionChange('panel1')}
      sx={{ borderRadius: '10px !important', marginBottom: '15px', boxShadow: '0 0 3px 3px #cccccc' }}
    >
      <AccordionSummary
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        sx={{ padding: '5px 30px', color: '#303030' }}
      >
        <Box sx={{ display: 'block', alignContent: 'center', flexShrink: 0, width: isMobile ? 'clamp(33%, 50%, 700px)' : 'clamp(33%, 66%, 700px)' }}>
          <AccordionText component="h6" fontWeight={500} sx={{ marginBottom: '10px' }}>{title}</AccordionText>
          <AccordionText sx={{ fontSize: '15px', marginBottom: '15px', letterSpacing: 0 }}>{description}</AccordionText>
          <AccordionButton variant="contained" size="small">{expanded ? t('SHIPIFY_PRODUCTS_ACCORDION_BUTTON_LESS') : t('SHIPIFY_PRODUCTS_ACCORDION_BUTTON')}</AccordionButton>
        </Box>
        <Box sx={{ width: 'clamp(50px, 100%, 175px)', maxWidth: '50%', flexShrink: 0, display: 'flex', alignItems: 'center' }}>
          <img src={guideImage} style={{ width: '100%', borderRadius: '10px' }} alt="Guide Image" />
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{ borderTop: '1px solid #ccc', padding: '8px 16px' }}>
        <GifSlideshow slides={slides} />
      </AccordionDetails>
    </Accordion>
  );
};
