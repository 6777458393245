import { isMobile } from "react-device-detect"
import {
    ACCOUNT_PAGE_URL,
    ANALYTICS_PAGE_URL,
    CREATE_NEW_LINK_PAGE_URL,
    CREATE_NEW_PASSWORD_PAGE_URL,
    CREATE_NEW_SHORT_PAGE_URL,
    FORGOT_PASSWORD_PAGE_URL,
    HOME_PAGE_URL,
    INFLUENCERS_PAGE_URL,
    INFLUENCER_FORGOT_PASSWORD_PAGE_URL,
    LINKS_PAGE_URL,
    LOGIN_INFLUENCER_PAGE_URL,
    LOGIN_PAGE_URL,
    LOGIN_STORE_PAGE_URL,
    PRIVACY_POLICY_PAGE_URL,
    REGISTER_STORE_PAGE_URL,
    SHIPIFY_CREATE_SHIPPING_GROUP_PAGE_URL,
    SHIPIFY_SHIPPING_COMPANIES_PAGE_URL,
    SHIPIFY_PRODUCTS_PAGE_URL,
    SHIPIFY_ALIXPRESS_CALLBACK_URL,
    SHIPIFY_HOME_PAGE_URL,
    SHIPIFY_HOW_TO_USE_PAGE_URL,
    SHIPIFY_SETTINGS_AND_PLANS_PAGE_URL,
    SHIPIFY_SHIPPING_GROUPS_PAGE_URL,
    SHORTS_PAGE_URL,
    SIGN_UP_PAGE_URL,
    SIGN_UP_REQUEST_PAGE_URL,
    TERMS_PAGE_URL,
} from "./routes.constants"
import { BiLink } from "react-icons/bi"
import ShortsImage from "embeddedImages/ShortsImage"
import { addTimeToDate, calculateDateRange } from "utils/dateUtils"
import ProductImageStyleDisplayImage from 'assets/woman_display_image.png'
import InsightsImage from "embeddedImages/InsightsImage"
import ShoppingBagImage1 from "embeddedImages/ShoppingBagImage1"
import ShoppingBagImage2 from "embeddedImages/ShoppingBagImage2"
import ShoppingBagImage3 from "embeddedImages/ShoppingBagImage3"

export const REQUEST_TIMEOUT_MILLISECONDS = 10000
export const IMAGE_UPLOAD_REQUEST_TIMEOUT_MILLISECONDS = 20000
export const TOP_BAR_HEIGHT_PX = 50
export const APPLICATION_RECOVERY_TIMEOUT_MILISECONDS = 20000
export const ANIMATIED_STRING_LOADER_ANIMATION_DURATION_SECONDS = 0.25
export const APPLICATION_READY_TIMEOUT_MILISECONDS = 500
export const MAX_ITEMS_SEARCH_RESULT_NUMBER = 50

const APPLICATION_RESOURCES_DOMAIN = 'https://cdn.paylink.cloudfront.payli.in/application_resources'
export const PAYLINK_LOGO_URL = APPLICATION_RESOURCES_DOMAIN + "/paylink-logo.webp"
export const PAYLINK_SMALL_LOGO_URL = APPLICATION_RESOURCES_DOMAIN + "/paylink-small-logo.webp"
export const NO_IMAGE_PLACEHOLDER_URL = APPLICATION_RESOURCES_DOMAIN + "/no_image.webp"
export const SHOPIFY_LOGO_URL = APPLICATION_RESOURCES_DOMAIN + "/shopify-logo.svg"
export const SHORTS_LOGO_URL = APPLICATION_RESOURCES_DOMAIN + "/shorts_icon.svg"
export const NO_IMAGE_GLOBE_PLACEHOLDER_URL = APPLICATION_RESOURCES_DOMAIN + "/www-globe.png"

export const WELCOME_PAGE_FIRST_SECTION_IMAGE_URL = APPLICATION_RESOURCES_DOMAIN + '/WelcomePage1.png'
export const WELCOME_PAGE_SECOND_SECTION_IMAGE_URL = APPLICATION_RESOURCES_DOMAIN + '/WelcomePage2.png'
export const WELCOME_PAGE_THIRD_SECTION_IMAGE_URL = APPLICATION_RESOURCES_DOMAIN + '/WelcomePage3.jpg'

export const HOME_PAGE_TODO_TASK_LOGO_IMAGE_URL = APPLICATION_RESOURCES_DOMAIN + '/todo-logo.png'
export const HOME_PAGE_TODO_TASK_ANALYTICS_IMAGE_URL = APPLICATION_RESOURCES_DOMAIN + '/todo-analytic.png'
export const HOME_PAGE_TODO_TASK_INSTANT_IMAGE_URL = APPLICATION_RESOURCES_DOMAIN + '/todo-instant.png'
export const HOME_PAGE_TODO_TASK_SHIPPING_IMAGE_URL = APPLICATION_RESOURCES_DOMAIN + '/todo-shipping.png'
export const HOME_PAGE_TODO_TASK_UTM_IMAGE_URL = APPLICATION_RESOURCES_DOMAIN + '/todo-utm.png'

export const ADMIN_PAGE_MENU_OPTION_HEIGHT_PX = 50
export const PAYDIN_DIALOG_CONTENT_PADDING_PX = 15
export const EDIT_LINK_PAGE_PRODUCT_SEARCH_REQUEST_SEND_DELAY_MILLISECONDS = 400
export const SIGN_UP_PAGE_VALIDATE_FIELD_REQUEST_SEND_DELAY_MILLISECONDS = 400
export const SIGN_UP_PAGE_USERNAME_INPUT_NAME = 'username'
export const SIGN_UP_PAGE_EMAIL_INPUT_NAME = 'email'
export const SHOPIFY_CONNECT_SHOPIFY_SHOP_URL_SUFFIX = ".myshopify.com"
export const PAGE_DEFAULT_MAX_WIDTH = 750
export const ADMIN_PAGE_LINKS_MAX_WIDTH = 1000
export const LINK_EDIT_PREVIEW_PAGE_MAX_WIDTH = 1030
export const LINK_PAGES_MAX_WIDTH = 1000
export const ANALYTICS_PAGE_MAX_WIDTH = 800
export const ACCOUNT_PAGE_MAX_WIDTH = 750
export const PRICING_PLANS_PAGE_MAX_WIDTH = 1200
export const SHOPIFY_HELP_PAGE_MAX_WIDTH = 1200
export const CREATE_INFLUENCER_PAGE_MAX_WIDTH = 630
export const PREVIEW_SHORT_PAGE_MAX_WIDTH = 700
export const CREATE_NEW_PASSWORD_PAGE_MAX_WIDTH = 500
export const USER_PASSWORD_MIN_LENGTH = 6
export const PAYLINK_LOGO_MAX_WIDTH_PX = 120
export const EDIT_LINK_PAGE_PRODUCT_SEARCH_QUERY_MIN_LENGTH = 2
export const NO_AUTH_PAGES = [
    LOGIN_STORE_PAGE_URL,
    LOGIN_PAGE_URL,
    LOGIN_INFLUENCER_PAGE_URL,
    REGISTER_STORE_PAGE_URL,
    SIGN_UP_PAGE_URL,
    SIGN_UP_REQUEST_PAGE_URL,
    FORGOT_PASSWORD_PAGE_URL,
    INFLUENCER_FORGOT_PASSWORD_PAGE_URL,
    CREATE_NEW_PASSWORD_PAGE_URL
]
export const PADDED_PAGES = [
    LOGIN_PAGE_URL,
    LOGIN_STORE_PAGE_URL,
    REGISTER_STORE_PAGE_URL,
    LOGIN_INFLUENCER_PAGE_URL,
    SIGN_UP_PAGE_URL,
    SIGN_UP_REQUEST_PAGE_URL,
    FORGOT_PASSWORD_PAGE_URL,
    INFLUENCER_FORGOT_PASSWORD_PAGE_URL,
    CREATE_NEW_PASSWORD_PAGE_URL,
    // This is nessecary for matching the url that's been redirected from server with and without '/'.
    // FIX - define all URLs as regex for optimizations
    CREATE_NEW_PASSWORD_PAGE_URL + '/',
    HOME_PAGE_URL,
    SHIPIFY_HOME_PAGE_URL,
    SHIPIFY_SHIPPING_GROUPS_PAGE_URL,
    SHIPIFY_SETTINGS_AND_PLANS_PAGE_URL,
    SHIPIFY_HOW_TO_USE_PAGE_URL,
    SHIPIFY_SHIPPING_COMPANIES_PAGE_URL,
    SHIPIFY_ALIXPRESS_CALLBACK_URL,
    SHIPIFY_PRODUCTS_PAGE_URL
]
export const UNLIMITED_WIDTH_PAGES = [
    PRIVACY_POLICY_PAGE_URL,
    TERMS_PAGE_URL,
]
export const ACCOUNT_PAGE_DANGER_ZONE_SECTION_PADDING_TOP_PX = 20
export const ACCOUNT_PAGE_DANGER_ZONE_SECTION_PADDING_BOTTON_PX = 20

export const ACCOUNT_PAGE_SHOP_WEBSITE_INPUT_NAME = 'shop-website'
export const ACCOUNT_PAGE_SHOP_SHIPPING_AND_RETURNS_INPUT_NAME = 'shipping-and-returns'
export const ACCOUNT_PAGE_NAME_INPUT_NAME = 'name'
export const ACCOUNT_PAGE_USERNAME_INPUT_NAME = 'username'
export const ACCOUNT_PAGE_EMAIL_INPUT_NAME = 'email'
export const ACCOUNT_PAGE_FACEBOOK_PIXEL_ID_INPUT_NAME = 'facebook-pixel-id'
export const ACCOUNT_PAGE_GOOGLE_ANALYTICS_ID_INPUT_NAME = 'google-analytics-id'
export const ACCOUNT_PAGE_URL_PARAMS_INPUT_NAME = 'url-params'

export const PRICING_PLAN_DATA = {
    FREE: {
        name: 'FREE',
        color: '#9a60d5',
        price: '0',
        pricingPeriod: 'month',
        ordinal: 1,
        features: [
            {
                id: '24-hours links',
                content: '24-hours links'
            },
            {
                id: 'Instant-store links',
                content: 'Instant-store links'
            },
            {
                id: 'Trackable Shorts links',
                content: 'Trackable Shorts links'
            },
            {
                id: 'Basic analytics',
                content: 'Basic analytics'
            },
            {
                id: 'Link customization',
                content: 'Link customization'
            }
        ]
    },
    STARTER: {
        name: 'STARTER',
        color: '#3d8e26',
        price: 29.9,
        pricingPeriod: 'month',
        ordinal: 2,
        features: [
            {
                id: 'Instant-store links',
                content: 'Instant-store links'
            },
            {
                id: 'Trackable Shorts links',
                content: 'Trackable Shorts links'
            },
            {
                id: 'Basic analytics',
                content: 'Basic analytics'
            },
            {
                id: 'Link customization',
                content: 'Link customization'
            },
            {
                id: 'Permanent links',
                content: <b>Permanent links</b>
            },
            {
                id: 'Influencers platform',
                content: <b>Influencers platform</b>
            },
            {
                id: 'Advanced analytics',
                content: <b>Advanced analytics</b>
            }
        ],
        description: 'Starting January 2024\n0.5 USD transaction fee\nOn smart multi-products links'
    },
    PRO: {
        name: 'PRO',
        color: '#e4bb55',
        price: 99.9,
        pricingPeriod: 'month',
        ordinal: 3,
        features: [
            {
                id: 'All from STARTER',
                content: 'All from STARTER'
            },
            {
                id: 'Chat-Shop',
                content: <b>Chat-Shop</b>
            },
            {
                id: 'Influencers reports',
                content: <b>Influencers reports</b>
            },
            {
                id: 'Shop-in-bio',
                content: <b>Shop-in-bio</b>
            },
            {
                id: 'Auto tagging',
                content: <b>Auto tagging</b>
            }
        ],
        description: 'Starting January 2024\n0.5 USD transaction fee\nOn smart multi-products links'
    },
    ENTERPRISE: {
        name: 'ENTERPRISE',
        color: '#1732f6',
        price: null,
        pricingPeriod: 'month',
        ordinal: 4,
        features: [],
        subtitle: "Let's talk"
    }
}

export const PRICING_PLANS_ENTERPRISE_PLAN_CONTACT_US_EMAIL = 'mailto:sales@paylinkshop.com'

export const UNAVAILABLE_PLANS = [PRICING_PLAN_DATA.PRO.name]
export const CONTACT_US_PLANS = [PRICING_PLAN_DATA.ENTERPRISE.name]

export const BOXED_MESSAGE_TYPES = {
    ERROR: 'error',
    INFO: 'info'
}

export const SHOPIFY_CONNECT_NAVIGATE_TO_ADMIN_PAGE_DELAY = 2000

export const PAYDIN_MENU_DEFAULT_WIDTH_PX = 250
export const PAYDIN_MENU_OPENING_DIRECTIONS = {
    LEFT: 'left',
    RIGHT: 'right'
}

export const DASHBOARD_TOPBAR_USER_MENU_BORDER_RADIUS_PX = 15
export const DASHBOARD_TOPBAR_USER_MENU_WIDTH_PX = 350

export const PAYDIN_DRAWER_DIRECTION = {
    TOP: 'top',
    RIGHT: 'right',
    LEFT: 'left',
    BOTTOM: 'bottom',
}

export const LINKS_PAGE_MOBILE_OPTIONS_DRAWER_TOP_BORDER_RADIUS_PX = 15

const PAYDIN_DRAWER_TRANSLATION_ANIMATION_DURATION_MILLISECONDS = 250
export const LINKS_PAGE_MOBILE_OPTIONS_DRAWER_CLOSING_DELAY = PAYDIN_DRAWER_TRANSLATION_ANIMATION_DURATION_MILLISECONDS
export const LINK_ITEM_MOBILE_OPTIONS_DRAWER_PADDING_PX = 20
export const DATE_PICKER_MOBILE_DRAWER_PADDING_PX = 10
export const MOBILE_DASHBOARD_TOPBAR_OPTIONS_DRAWER_CLOSING_DELAY = PAYDIN_DRAWER_TRANSLATION_ANIMATION_DURATION_MILLISECONDS
export const LINK_ID_ROUTE_PARAMETER_INDEX = 3
export const INFLUENCER_USERNAME_ROUTE_PARAMETER_INDEX = 3

export const VIEW_MODES = {
    PREVIEW: 'preview',
    EDIT: 'edit',
    CREATE: 'create'
}

export const ADD_PRODUCTS_DIALOG_PRODUCTS_LIST_ITEM_HEIGHT_PX = 190
export const ADD_PRODUCTS_DIALOG_FETCH_NEXT_PRODUCTS_COLUMN_THRESHOLD_COUNT = 1
export const ADD_PRODUCTS_DIALOG_PRODUCTS_IN_COLUMNS = isMobile ? 3 : 7

export const CREATE_LINK_PHASES = {
    ADD_IMAGE_PHASE: 0,
    ADD_LINK_PROPERTIES_PHASE: 1
}

export const DASHBOARD_LAYOUT_PAGES_WITH_DASHBOARD_TOPBAR = [
    LINKS_PAGE_URL,
    INFLUENCERS_PAGE_URL,
    SHORTS_PAGE_URL,
    HOME_PAGE_URL
]

export const BASE_LAYOUT_PAGES_WITH_DASHBOARD_TOPBAR = [
    SHIPIFY_SHIPPING_GROUPS_PAGE_URL,
    SHIPIFY_HOME_PAGE_URL,
    SHIPIFY_SETTINGS_AND_PLANS_PAGE_URL,
    SHIPIFY_HOW_TO_USE_PAGE_URL,
    SHIPIFY_SHIPPING_COMPANIES_PAGE_URL,
    SHIPIFY_ALIXPRESS_CALLBACK_URL,
    SHIPIFY_PRODUCTS_PAGE_URL
]

export const LINK_ITEM_HEIGHT_PX = isMobile ? 340 : 275
export const SHORT_ITEM_HEIGHT_PX = 85
export const LINKS_PAGE_FETCH_NEXT_LINKS_THRESHOLD_ROW_COUNT = 1
export const SHORTS_PAGE_FETCH_NEXT_LINKS_THRESHOLD_ROW_COUNT = 1
export const LINKS_PAGE_LINK_COLUMN_COUNT = isMobile ? 2 : 4

export const SHOPIFY_HELP_CENTER_URL = 'https://help.shopify.com/en'

export const TIME_UNITS = {
    SECOND: 'second',
    MINUTE: 'minute',
    HOUR: 'hour',
    DAY: 'day',
    MONTH: 'month',
    YEAR: 'year'
}

export const EDIT_LINK_SECTION_LINK_VANISH_EXPIRY_DATE_TIME_UNIT = TIME_UNITS.HOUR
export const EDIT_LINK_SECTION_LINK_VANISH_EXPIRY_DATE_VALUE = 24
export const CONTACT_SUPPORT_EMAIL = 'support@paydin.net'
export const CONTACT_SUPPORT_ADDRESS = 'Yosef Lishanski 27, Rishon LeTsiyon'

export const ADMIN_PAGES = [
    LINKS_PAGE_URL,
    ACCOUNT_PAGE_URL
]

export const PROMOCODE_INPUT_MAX_LENGTH = 20
export const SALES_AND_DISCOUNT_STRIP_SENTENCE_1_INPUT_MAX_LENGTH = 40
export const SALES_AND_DISCOUNT_STRIP_SENTENCE_2_INPUT_MAX_LENGTH = 40

export const ACCOUNT_VERIFICATION_PAGE_DISPLAY_VERIFY_RESULT_DELAY_MILLISECONDS = 700
export const ACCOUNT_VERIFICATION_PAGE_PROCEED_DELAY_MILLISECONDS = 2500

export const EDIT_LINK_SECTION_LINK_DESCRIPTION_CHARACTER_LIMIT = 50

export const WELCOME_PAGE_FIRST_SECTION_BACKGROUND_COLOR = '#2dc2df'
export const WELCOME_PAGE_SECOND_SECTION_BACKGROUND_COLOR = '#8497b0'
export const WELCOME_PAGE_SECTION_IMAGE_BORDER_RADIUS_PX = 5
export const WELCOME_PAGE_THIRD_SECTION_BACKGROUND_COLOR = '#dfe2ed'

export const IMAGE_BOX_DEFAULT_BORDER_RADIUS_PX = 10

export const PAYDIN_IMAGE_CROPPER_ASPECT_RATIOS_MENU_WIDTH_PX = 'fit-content'

export const PAYDIN_IMAGE_CROP_DIALOG_WIDTH = '90vmin'
export const PAYDIN_IMAGE_CROP_DIALOG_MAX_HEIGHT = '650px'
export const PAYDIN_IMAGE_CROP_DIALOG_INFLUENCER_WIDTH = '450px'
export const PAYDIN_IMAGE_CROP_DIALOG_INFLUENCER_MAX_HEIGHT = '500px'

export const PAYDIN_IMAGE_CROPPER_OBJECT_FITS = {
    CONTAIN: 'contain',
    VERTICAL_COVER: 'vertical-cover',
    HORIZONTAL_COVER: 'horizontal-cover'
}

export const PAYDIN_IMAGE_CROPPER_ASPECT_RATIOS = [
    {
        text: '1:1',
        ratio: 1 / 1
    },
    {
        text: '16:9',
        ratio: 16 / 9
    },
    {
        text: '4:5',
        ratio: 4 / 5
    }
]

export const PAYDIN_IMAGE_CROPPER_DEFAULT_ASPECT_RATIO = 1 / 1

export const PAYDIN_IMAGE_CROPPER_LOGO_ASPECT_RATIO = 4 / 1

export const LINKS_PAGE_LINKS_FILTER_COUNT = 28
export const SHORTS_PAGE_SHORTS_FILTER_COUNT = 28
export const INFLUENCER_PAGINATION_COUNT = 28

export const SETTINGS_PAGES_BUTTON_LOADER_CIRCLE_COLOR = '#6B718E'
export const DEFAULT_CURRENCY_CODE = 'USD'

export const APPROVAL_MESSAGE_HIDDING_DELAY_MILLISECONDS = 3000

export const APPEARANCE_PAGE_LINK_STYLE_CHECKOUT_BUTTON_SHAPE_KEYS = {
    OPTION1: 'shape1',
    OPTION2: 'shape2',
    OPTION3: 'shape3',
}

export const APPEARANCE_PAGE_LINK_STYLE_CHECKOUT_BUTTON_SHAPE_OPTIONS = [
    {
        key: APPEARANCE_PAGE_LINK_STYLE_CHECKOUT_BUTTON_SHAPE_KEYS.OPTION1,
        value: '0px',
        width: '60px',
        image: <div
            style={{
                width: '25px',
                height: '25px',
                backgroundColor: '#f6f6f6',
                border: '2px solid #b6b6b8',
                borderRadius: '0px'
            }}>
        </div>
    },
    {
        key: APPEARANCE_PAGE_LINK_STYLE_CHECKOUT_BUTTON_SHAPE_KEYS.OPTION2,
        value: '10px',
        width: '60px',
        image: <div
            style={{
                width: '25px',
                height: '25px',
                backgroundColor: '#f6f6f6',
                border: '2px solid #b6b6b8',
                borderRadius: '5px'
            }}>
        </div>
    },
    {
        key: APPEARANCE_PAGE_LINK_STYLE_CHECKOUT_BUTTON_SHAPE_KEYS.OPTION3,
        value: '25px',
        width: '60px',
        image: <div
            style={{
                width: '40px',
                height: '25px',
                backgroundColor: '#f6f6f6',
                border: '2px solid #b6b6b8',
                borderRadius: '25px'
            }}>
        </div>
    }
]

const PRODUCT_IMAGE_STYLE_BORDER_RADIUS_OPTION_WIDTH_PX = isMobile ? 90 : 100
const PRODUCT_IMAGE_STYLE_BORDER_RADIUS_OPTION_HEIGHT_PX = isMobile ? 140 : 150
const PRODUCT_IMAGE_STYLE_BORDER_RADIUS_OPTIONS_BORDER_WIDTH_PX = 1

const PRODUCT_IMAGE_STYLE_BORDER_RADIUS_SHARP_OPTION_BORDER_RADIUS_PX = 0
const PRODUCT_IMAGE_STYLE_BORDER_RADIUS_SEMI_ROUNDED_OPTION_BORDER_RADIUS_PX = 6
const PRODUCT_IMAGE_STYLE_BORDER_RADIUS_ROUNDED_OPTION_BORDER_RADIUS_PX = 10

export const APPEARNACE_PAGE_PRODUCT_IMAGE_STYLE_BORDER_RADIUS_KEYS = {
    OPTION1: 'shape1',
    OPTION2: 'shape2',
    OPTION3: 'shape3',
}
export const APPEARNACE_PAGE_PRODUCT_IMAGE_STYLE_BORDER_RADIUS_OPTIONS = [
    {
        key: APPEARNACE_PAGE_PRODUCT_IMAGE_STYLE_BORDER_RADIUS_KEYS.OPTION1,
        value: `${PRODUCT_IMAGE_STYLE_BORDER_RADIUS_SHARP_OPTION_BORDER_RADIUS_PX}px`,
        image: <img
            src={ProductImageStyleDisplayImage}
            style={{
                width: `${PRODUCT_IMAGE_STYLE_BORDER_RADIUS_OPTION_WIDTH_PX}px`,
                height: `${PRODUCT_IMAGE_STYLE_BORDER_RADIUS_OPTION_HEIGHT_PX}px`,
                borderRadius: '0px',
                objectFit: 'cover'
            }}
        />,
        styles: {
            width: `${PRODUCT_IMAGE_STYLE_BORDER_RADIUS_OPTION_WIDTH_PX + (PRODUCT_IMAGE_STYLE_BORDER_RADIUS_OPTIONS_BORDER_WIDTH_PX * 2)}px`,
            height: `${PRODUCT_IMAGE_STYLE_BORDER_RADIUS_OPTION_HEIGHT_PX + (PRODUCT_IMAGE_STYLE_BORDER_RADIUS_OPTIONS_BORDER_WIDTH_PX * 2)}px`,
            cursor: 'pointer'
        },
        text: 'Sharp'
    },
    {
        key: APPEARNACE_PAGE_PRODUCT_IMAGE_STYLE_BORDER_RADIUS_KEYS.OPTION2,
        value: `${PRODUCT_IMAGE_STYLE_BORDER_RADIUS_SEMI_ROUNDED_OPTION_BORDER_RADIUS_PX}px`,
        image: <img
            src={ProductImageStyleDisplayImage}
            style={{
                width: `${PRODUCT_IMAGE_STYLE_BORDER_RADIUS_OPTION_WIDTH_PX}px`,
                height: `${PRODUCT_IMAGE_STYLE_BORDER_RADIUS_OPTION_HEIGHT_PX}px`,
                borderRadius: `${PRODUCT_IMAGE_STYLE_BORDER_RADIUS_SEMI_ROUNDED_OPTION_BORDER_RADIUS_PX - PRODUCT_IMAGE_STYLE_BORDER_RADIUS_OPTIONS_BORDER_WIDTH_PX}px`,
                objectFit: 'cover'
            }}
        />,
        styles: {
            width: `${PRODUCT_IMAGE_STYLE_BORDER_RADIUS_OPTION_WIDTH_PX + (PRODUCT_IMAGE_STYLE_BORDER_RADIUS_OPTIONS_BORDER_WIDTH_PX * 2)}px`,
            height: `${PRODUCT_IMAGE_STYLE_BORDER_RADIUS_OPTION_HEIGHT_PX + (PRODUCT_IMAGE_STYLE_BORDER_RADIUS_OPTIONS_BORDER_WIDTH_PX * 2)}px`,
            cursor: 'pointer'
        },
        text: 'Semi-rounded'
    },
    {
        key: APPEARNACE_PAGE_PRODUCT_IMAGE_STYLE_BORDER_RADIUS_KEYS.OPTION3,
        value: `${PRODUCT_IMAGE_STYLE_BORDER_RADIUS_ROUNDED_OPTION_BORDER_RADIUS_PX}px`,
        image: <img
            src={ProductImageStyleDisplayImage}
            style={{
                width: `${PRODUCT_IMAGE_STYLE_BORDER_RADIUS_OPTION_WIDTH_PX}px`,
                height: `${PRODUCT_IMAGE_STYLE_BORDER_RADIUS_OPTION_HEIGHT_PX}px`,
                borderRadius: `${PRODUCT_IMAGE_STYLE_BORDER_RADIUS_ROUNDED_OPTION_BORDER_RADIUS_PX - PRODUCT_IMAGE_STYLE_BORDER_RADIUS_OPTIONS_BORDER_WIDTH_PX}px`,
                objectFit: 'cover'
            }}
        />,
        styles: {
            width: `${PRODUCT_IMAGE_STYLE_BORDER_RADIUS_OPTION_WIDTH_PX + (PRODUCT_IMAGE_STYLE_BORDER_RADIUS_OPTIONS_BORDER_WIDTH_PX * 2)}px`,
            height: `${PRODUCT_IMAGE_STYLE_BORDER_RADIUS_OPTION_HEIGHT_PX + (PRODUCT_IMAGE_STYLE_BORDER_RADIUS_OPTIONS_BORDER_WIDTH_PX * 2)}px`,
            cursor: 'pointer'
        },
        text: 'Rounded'
    }
]

export const APPEARANCE_PAGE_LINK_STYLE_CART_BUTTON_IMAGE_KEYS = {
    OPTION1: 'cart1',
    OPTION2: 'cart2',
    OPTION3: 'cart3',
}

export const APPEARANCE_PAGE_LINK_STYLE_CART_BUTTON_IMAGE_OPTIONS = [
    {
        key: APPEARANCE_PAGE_LINK_STYLE_CART_BUTTON_IMAGE_KEYS.OPTION1,
        image: <ShoppingBagImage1
            style={{
                width: '30px',
                height: '30px'
            }} />
    },
    {
        key: APPEARANCE_PAGE_LINK_STYLE_CART_BUTTON_IMAGE_KEYS.OPTION2,
        image: <ShoppingBagImage2
            style={{
                width: '30px',
                height: '30px'
            }} />
    },
    {
        key: APPEARANCE_PAGE_LINK_STYLE_CART_BUTTON_IMAGE_KEYS.OPTION3,
        image: <ShoppingBagImage3
            style={{
                width: '30px',
                height: '30px',
            }} />
    }
]

export const RESIZE_IMAGE_MAX_WIDTH = 1500
export const RESIZE_IMAGE_MAX_HEIGHT = 1500

export const INFLUENCER_LINK_PAGINATION_COUNT = 28
export const INFLUENCER_SHORT_PAGINATION_COUNT = 28

export const INPUT_VALIDATION_INDICATIONS = {
    NO_INDICATION: 'no-indication',
    VALID: 'valid',
    INVALID: 'invalid'
}

export const INFLUENCER_PROMOCODE_LENGTH_LIMIT = PROMOCODE_INPUT_MAX_LENGTH

export const EDIT_INFLUENCER_SECTION_VALIDATION_TIMEOUT_MILLISECONDS = 400

export const EDIT_LINK_SECTION_OPTIONS_MENU_ITEM_HEIGHT_PX = 50
export const EDIT_LINK_SECTION_OPTIONS_MENU_ITEM_MAX_COUNT = 7

export const RUNNING_MESSAGES_STRIP_TEXT_SWAP_DELAY_MILLISECONDS = 5000
export const RUNNING_MESSAGES_STRIP_TEXT_ANIMATION_DURATION = 500
export const RUNNING_MESSAGES_STRIP_TEXT_NO_ANIMATION_NAME = 'none'
export const RUNNING_MESSAGES_STRIP_TEXT_ANIMATION_IN_NAME = 'in-message-anim'
export const RUNNING_MESSAGES_STRIP_TEXT_ANIMATION_OUT_NAME = 'out-message-anim'

export const PASSWORD_INPUT_RULESET = [
    'Must contain at least 6 characters',
    'Must contain at least 1 number',
    'Must contain at least 1 uppercase letter'
]
export const REDUCED_PADDING_PAGES = [
    LINKS_PAGE_URL,
    SHORTS_PAGE_URL,
    INFLUENCERS_PAGE_URL,
    ANALYTICS_PAGE_URL
]
export const NO_BOTTOM_PADDING_PAGES = [
    LINKS_PAGE_URL,
    SHORTS_PAGE_URL,
    INFLUENCERS_PAGE_URL,
    CREATE_NEW_LINK_PAGE_URL,
    CREATE_NEW_SHORT_PAGE_URL
]

export const ADD_PRODUCTS_DIALOG_PRODUCT_STATUS = {
    ACTIVE: 'active',
    DRAFT: 'draft'
}

export const URL_PARAMETERS_OPTIONS = {
    MERCHANT_NAME: 'merchant_name',
    LINK_TITLE: 'link_title',
    INFLUENCER_USERNAME: 'influencer_username',
    LINK_TYPE: 'link_type',
}
export const URL_PARAMETERS_LINK_TYPE_OPTIONS = {
    ORGANIC: 'Organic',
    INFLUENCERS: 'Influencers',
}
export const USERNAME_FIELD_PREFIX = 'paylinkshop.com/'

export const EDIT_SHORT_PROMOCODE_INPUT_MAX_LENGTH = 20

export const EDIT_SHORT_SECTION_URL_TEST_DELAY_MILLISECONDS = 400

export const LINK_TYPES = {
    LOOK: 'LOOK',
    SHORT: 'SHORT'
}

export const ANALYTICS_TAB_OPTIONS = [
    {
        text: 'Links',
        image: <BiLink style={{ width: '15px', height: '15px' }} />
    },
    {
        text: 'Shorts',
        image: <ShortsImage style={{ width: '15px', height: '15px' }} />
    }
]

export const INFLUENCER_TAB_OPTIONS = [
    {
        text: 'Insights',
        image: <InsightsImage style={{ width: '15px', height: '15px' }} />
    },
    ...ANALYTICS_TAB_OPTIONS
]

export const ANALYTICS_PAGE_RETROACTIVE_DATA_REPORT_TIME_INTERVALS_NAMES = {
    LAST_24_HOURS: 'Last 24 hours',
    LAST_7_DAYS: 'Last 7 days',
    LAST_30_DAYS: 'Last 30 days',
    LAST_90_DAYS: 'Last 90 days',
    THIS_MONTH: 'This month',
    PREVIOUS_MONTH: 'Previous month',
    LIFETIME: 'Lifetime',
}

const TODAY_DATE = new Date()

export const ANALYTICS_PAGE_RETROACTIVE_DATA_REPORT_TIME_INTERVALS = [
    {
        id: 1,
        name: ANALYTICS_PAGE_RETROACTIVE_DATA_REPORT_TIME_INTERVALS_NAMES.LAST_24_HOURS,
        timeRange: calculateDateRange(
            ANALYTICS_PAGE_RETROACTIVE_DATA_REPORT_TIME_INTERVALS_NAMES.LAST_24_HOURS,
            TIME_UNITS.DAY,
            1
        )
    },
    {
        id: 2,
        name: ANALYTICS_PAGE_RETROACTIVE_DATA_REPORT_TIME_INTERVALS_NAMES.LAST_7_DAYS,
        timeRange: calculateDateRange(
            ANALYTICS_PAGE_RETROACTIVE_DATA_REPORT_TIME_INTERVALS_NAMES.LAST_7_DAYS,
            TIME_UNITS.DAY,
            7
        )
    },
    {
        id: 3,
        name: ANALYTICS_PAGE_RETROACTIVE_DATA_REPORT_TIME_INTERVALS_NAMES.LAST_30_DAYS,
        timeRange: calculateDateRange(
            ANALYTICS_PAGE_RETROACTIVE_DATA_REPORT_TIME_INTERVALS_NAMES.LAST_30_DAYS,
            TIME_UNITS.DAY,
            30
        )
    },
    {
        id: 4,
        name: ANALYTICS_PAGE_RETROACTIVE_DATA_REPORT_TIME_INTERVALS_NAMES.LAST_90_DAYS,
        timeRange: calculateDateRange(
            ANALYTICS_PAGE_RETROACTIVE_DATA_REPORT_TIME_INTERVALS_NAMES.LAST_90_DAYS,
            TIME_UNITS.DAY,
            90
        )
    },
    {
        id: 5,
        name: ANALYTICS_PAGE_RETROACTIVE_DATA_REPORT_TIME_INTERVALS_NAMES.THIS_MONTH,
        timeRange: [
            new Date(TODAY_DATE.getFullYear(), TODAY_DATE.getMonth(), 1),
            TODAY_DATE
        ]
    },
    {
        id: 6,
        name: ANALYTICS_PAGE_RETROACTIVE_DATA_REPORT_TIME_INTERVALS_NAMES.PREVIOUS_MONTH,
        timeRange: [
            new Date(TODAY_DATE.getFullYear(), TODAY_DATE.getMonth() - 1, 1),
            addTimeToDate(new Date(TODAY_DATE.getFullYear(), TODAY_DATE.getMonth(), 1), TIME_UNITS.DAY, -1)
        ]
    },
    {
        id: 7,
        name: ANALYTICS_PAGE_RETROACTIVE_DATA_REPORT_TIME_INTERVALS_NAMES.LIFETIME,
        timeRange: [null, null]
    }
]

export const NOTIFY_STORE_CONNECT_FINISH_MESSAGE = 'CompletedPaylinkProcess'

export const ANALYTICS_STRIP_LIFETIME_REVENUE_DISPLAY_THRESHOLD = 1000
export const PAYDIN_CHART_TYPES = {
    LINE: 'line',
    BAR: 'bar',
    PIE: 'pie'
}

export const PAYDIN_CHART_CONTENT_PADDING_PX = 20
export const PAYDIN_CHART_CONTENT_GAP_PX = 35
export const PAYDIN_CHART_CONTENT_MOBILE_GAP_PX = 25

export const PREVIEW_LINK_SECTIONS_BACKGROUND_COLOR = '#F6F7FA'

export const UTM_FORM_INPUT_UTM_PARAM_ADDITIONS_OPTIONS_LIST = [
    {
        template: '{{link.influencer_username}}',
        description: 'The influencer associated to link (if exist)'
    },
    {
        template: '{{link.title}}',
        description: 'Link title'
    },
    {
        template: '{{link.id}}',
        description: 'Link ID'
    }
]
export const PAYDIN_CHART_GRAPH_HEIGHT_PX = 280

export const ADD_PRODUCTS_DIALOG_PRODUCTS_FRAME_DESKTOP_HEIGHT_PX = 440

export const PAYDIN_RADIO_BUTTON_GROUP_ALIGNMENTS = {
    ROW: 'row',
    COLUMN: 'column'
}

export const APPEARANCE_OPTION_PICKER_DEFAULT_BORDER_WIDTH_PX = 3
export const APPEARANCE_OPTION_PICKER_PRODUCT_IMAGE_STYLE_BORDER_WIDTH_PX = 1
export const APPEARANCE_OPTION_PICKER_PRODUCT_IMAGE_STYLE_SELECTED_BORDER_WIDTH_PX = 2

export const DASHBOARD_PAGE_HORIZONTAL_PADDING_PX = 15

export const PAYDIN_DIALOG_ON_BUTTON_CLICKED_CALLBACK_DELAY_MILLISECONDS = 100

export const MOBILE_DASHBOARD_TOPBAR_RIGHT_SECTION_BUTTON_HEIGHT_PX = 50

export const DATE_PICKER_DESKTOP_WIDTH_PX = 660
export const DATE_PICKER_DESKTOP_BORDER_RADIUS_PX = 6
export const DATE_PICKER_DESKTOP_BORDER_COLOR = 'black'

export const ITEMS_VIEW_SECTION_MORE_ITEMS_LOADER_HEIGHT_PX = 60

export const ITEM_DESIGN_PATTERNS = {
    INSIDE: 'INSIDE',
    OUTSIDE: 'OUTSIDE'
}

export const SECTION_TYPES = {
    SINGLE: 'SINGLE',
    STRIP: 'STRIP',
    GRID: 'GRID'
}

export const PAYDIN_DRAWER_TRANSITION_DURATION = 250
export const PAYDIN_DRAWER_TRANSITION_DURATION_WITH_BUFFER = PAYDIN_DRAWER_TRANSITION_DURATION + 100

export const MULTI_SELECT_DIALOG_DESKTOP_HEIGHT_PX = 700
export const MULTI_SELECT_DIALOG_DONE_BUTTON_STRIP_HEIGHT_PX = 70
export const MULTI_SELECT_DIALOG_SELECTION_LIMIT_REACHED_ALERT_HIDE_DELAY_MILLISECONDS = 1500

export const EDIT_LINK_SECTION_ADD_COLLECTIONS_DIALOG_SELECTION_LIMIT = 5

export const ADD_PRODUCTS_DIALOG_RESET_FILTER_TRIGGER_DELAY_MILLISECONDS = 100
export const ADD_PRODUCTS_DIALOG_DESKTOP_COLUMN_COUNT = 7
export const ADD_PRODUCTS_DIALOG_MOBILE_COLUMN_COUNT = 3

export const ADD_COLLECTIONS_DIALOG_DESKTOP_COLUMN_COUNT = 6
export const ADD_COLLECTIONS_DIALOG_MOBILE_COLUMN_COUNT = 3

export const SHORT_URL_SELECTION_TYPE = {
    CUSTOM: 'CUSTOM',
    PRODUCT: 'PRODUCT',
    COLLECTION: 'COLLECTION'
}

export const SHORT_URL_SELECTION_TYPE_ELEMENT_IMAGE_BORDER_RADIUS = 20
export const SHORT_URL_SELECTION_TYPE_SELECTED_ELEMENT_BORDER_WIDTH = 3

export const SHORT_INFO_URL_NO_CUSTOM_IMAGE_DIMENS_PX = 118
export const SHORT_INFO_URL_CUSTOM_IMAGE_DIMENS_PX = 70

export const INFLUENCER_INSIGHTS_AOV_HEIGHT = '60%'
export const INFLUENCER_INSIGHTS_CR_HEIGHT = '40%'
export const INFLUENCER_INSIGHTS_SALES_BREAKDOWN_PIE_WIDTH = 250
export const INFLUENCER_INSIGHTS_SALES_BREAKDOWN_CHART_WIDTH = isMobile ? '100%' : 380
export const INFLUENCER_INSIGHTS_SALES_BREAKDOWN_CHART_HEIGHT = 360
export const INFLUENCER_INSIGHTS_SALES_BREAKDOWN_CONTAINER_WIDTH = isMobile ? '100%' : 290
export const INFLUENCER_INSIGHTS_SALES_BREAKDOWN_CONTAINER_HEIGHT = 115
export const INFLUENCER_INSIGHTS_ROW_COLUMN_GAP = 15
export const INFLUENCER_INSIGHTS_SINGLE_VALUE_CONTAINER_WIDTH = isMobile ? '50%' : (INFLUENCER_INSIGHTS_SALES_BREAKDOWN_CHART_WIDTH - INFLUENCER_INSIGHTS_ROW_COLUMN_GAP) / 2
export const INFLUENCER_INSIGHTS_SINGLE_VALUE_CONTAINER_HEIGHT = INFLUENCER_INSIGHTS_SALES_BREAKDOWN_CONTAINER_HEIGHT
export const INFLUENCER_INSIGHTS_LINKS_COLOR = '#28B2AF'
export const INFLUENCER_INSIGHTS_SHORTS_COLOR = '#3596FF'
export const INFLUENCER_INSIGHTS_ORGANIC_COLOR = '#B900D8'

export const HOME_PAGE_RECENT_ACTIVITIES_TABS = {
    LINKS: 'LINKS',
    SHORTS: 'SHORTS',
    INFLUENCERS: 'INFLUENCERS'
}
export const HOME_PAGE_TASKS = {
    ADD_LOGO: 'ADD_LOGO',
    CUSTOMIZE_THEME: 'CUSTOMIZE_THEME',
    ADD_ANALYTICS: 'ADD_ANALYTICS',
    ADD_SHIPPING_AND_RETURN: 'ADD_SHIPPING_AND_RETURN',
    ADD_UTM: 'ADD_UTM'
}

export const PAYDIN_ACCORDION_HEADER_GAP_PX = 5

export const HOME_PAGE_GET_LINKS_API_COUNT = 4
export const HOME_PAGE_DESKTOP_GET_SHORTS_API_COUNT = 6
export const HOME_PAGE_MOBILE_GET_SHORTS_API_COUNT = 3
export const HOME_PAGE_DESKTOP_GET_INFLUENCERS_API_COUNT = 4
export const HOME_PAGE_MOBILE_GET_INFLUENCERS_API_COUNT = 2

export const APPEARANCE_PAGE_LOGO_SECTION_ID = 'appearance_page__logo_section'
export const APPEARANCE_PAGE_THEME_SECTION_ID = 'appearance_page__theme_section'

export const SETTINGS_PAGE_UTM_SECTION_ID = 'settings-page-utm-section'
export const SETTINGS_PAGE_SHIPPING_AND_RETURN_SECTION_ID = 'settings-page-shipping-and-return-section'
export const SETTINGS_PAGE_ANALYTICS_SECTION_ID = 'settings-page-analytics-section'

export const SCROLL_TO_ID_DELAY_MILLISECONDS = 100

export const DASHBOARD_TOPBAR_HEIGHT_PX = 70
export const MOBILE_PAGE_VIEW_TOPBAR_HEIGHT_PX = 45

export const PRODUCT_TABLE_HEADERS_IDS = {
    IMAGE: "productImage",
    TITLE: "productName",
    VIEW: "viewCount",
    ADD: "addCount",
    BUY: "buyCount",
    RANK: "ranking",
}
export const ORDERS_TABLE_HEADERS_IDS = {
    DATE: 'createdAt',
    SOURCE: 'source',
    INFLUENCER: 'influencer',
    TOTAL_PRODUCTS_COUNT: 'totalProductsCount',
    TOTAL_PRICE: 'totalPrice',
    STATUS: 'status',
    ACTIONS: 'actions',
    LINK_SOURCE_TITLE: 'linkTitle'
}
export const ORDERS_TABLE_STATUS_OPTIONS = {
    PAID: 'PAID',
    REFUNDED: 'REFUNDED',
    PARTIALLY_REFUNDED: 'PARTIALLY_REFUNDED'
}

export const PRODUCTS_TABLE_ROW_HEIGHT_PX = 105
export const MOBILE_PRODUCTS_TABLE_ROW_HEIGHT_PX = 95
export const ORDERS_TABLE_ROW_HEIGHT_PX = 56
export const ORDERS_TABLE_PRODUCT_ROW_HEIGHT_PX = isMobile ? 70 : 80
export const ORDERS_TABLE_PRODUCT_LIST_GAP = isMobile ? 10 : 15
export const MOBILE_ORDERS_TABLE_ROW_HEIGHT_PX = 76
export const PRODUCTS_TABLE_ROW_HEIGHT = isMobile ? MOBILE_PRODUCTS_TABLE_ROW_HEIGHT_PX : PRODUCTS_TABLE_ROW_HEIGHT_PX
export const ORDERS_TABLE_ROW_HEIGHT = isMobile ? MOBILE_ORDERS_TABLE_ROW_HEIGHT_PX : ORDERS_TABLE_ROW_HEIGHT_PX

export const PRODUCTS_TABLE_ROWS_PER_PAGE = 4
export const ORDERS_TABLE_ROWS_PER_PAGE = 5
export const STORE_ORDERS_TABLE_ROWS_PER_PAGE = ORDERS_TABLE_ROWS_PER_PAGE
export const STORE_ORDERS_TABLE_COUNT = 20

export const DATE_PICKER_DATE_INTERVAL_OPTION_WIDTH_PX = 115

export const OPTION_VALUE_MAPPER_VALUE_STATUS = {
    VERIFIED: 'VERIFIED',
    NOT_VERIFIED: 'NOT_VERIFIED'
}
export const OPTION_VALUE_MAPPER_OPTION_TYPES = {
    COLOR: 'COLOR',
    TEXT: 'TEXT'
}

export const OPTION_VALUE_MAPPER_PAGE_COUNT = 8
export const OPTION_VALUE_MAPPER_ROW_HEIGHT = 30
export const OPTION_VALUE_MAPPER_ROW_GAP = 10

export const DASHBOARD_SIDEBAR_WIDTH_PX = 240
export const USER_MENU_WIDTH_PX = DASHBOARD_SIDEBAR_WIDTH_PX + 60
export const DASHBOARD_SIDEBAR_CLOSED_WIDTH_PX = 50


// Shipify
export const SHIPIFY_TOKEN_KEY = 'token'
export const SHIPIFY_SHOP_URL_KEY = 'shop_url'

export const ALERT_MESSAGE_TYPES = {
    INFO: 'info',
    SUCCESS: 'success',
    WARNING: 'warning',
    ERROR: 'error'
}

export const SHIPIFY_HOME_PAGE_TASKS = {
    ACTIVATE_CARRIER: 'ACTIVATE_CARRIER',
    ADD_SHIPPING_GROUP: 'ADD_SHIPPING_GROUP',
    DISPLAY_METHODS: 'DISPLAY_METHODS',
    UPDATE_RATE_INFO: 'UPDATE_RATE_INFO',
    ACTIVATE_APP: 'ACTIVATE_APP'
}

export const SHIPPING_GROUPS_TABLE_HEADERS_IDS = {
    GROUP_NAME: 'groupName',
    CREATED_AT: 'createdAt',
    COUNTRIES: 'countriesCount',
    COLLECTIONS: 'collectionsCount',
    RATES: 'ratesCount',
    ACTIONS: 'actions'
}

export const SHIPPING_GROUPS_TABLE_ROW_HEIGHT_PX = isMobile ? 56 : 56
export const SHIPPING_GROUPS_TABLE_HEADER_BACKGROUND = "#f7f7f7"
export const SHIPPING_GROUPS_TABLE_RATE_HEADER_HEIGHT_PX = 36
export const SHIPPING_GROUPS_TABLE_RATE_ROW_HEIGHT_PX = isMobile ? 54 : 33
export const SHIPPING_GROUPS_TABLE_RATE_ROW_GAP_PX = isMobile ? 0 : 15
export const SHIPPING_GROUPS_TABLE_ROWS_PER_PAGE = 5

export const SHIPIFY_TODO_LIST_DISPLAY_METHODS_TASK_LEARN_MORE_LINK_URL = 'https://help.shopify.com/en/manual/online-store/themes/theme-structure/extend/apps'
export const SHIPIFY_TODO_LIST_ACTIVATE_THIRD_PARTY_CALCULATED_SHIPPING_TASK_LEARN_MORE_LINK_URL = 'https://help.shopify.com/en/manual/shipping/setting-up-and-managing-your-shipping/enabling-shipping-carriers'

export const SHIPIFY_HOW_TO_USE_LEARN_MORE_ABOUT_SHOPIFY_CCS_LINK_URL = 'https://help.shopify.com/en/manual/shipping/setting-up-and-managing-your-shipping/enabling-shipping-carriers'
export const SHIPIFY_ADD_APP_BLOCK = (shop, id, app_handle) => `https://${shop}/admin/themes/current/editor?template=product&addAppBlockId=${id}/${app_handle}&target=mainSection`
export const SHIPIFY_ADD_APP_EMBED = (shop, id) => `https://${shop}/admin/themes/current/editor?context=apps&activateAppId=${id}/app_embed`
export const SHIPIFY_CHROME_EXTENSION_KEY = 'SHIPIFY_CHROME_EXTENSION_SIGN'

export const ALL_COUNTRIES = [
    { name: 'Algeria', code:'DZ',  group: 'Africa' },
    { name: 'Angola', code:'AO',  group: 'Africa' },
    { name: 'Ascension Island', code:'AC',  group: 'Africa' },
    { name: 'Benin', code:'BJ',  group: 'Africa' },
    { name: 'Botswana', code:'BW',  group: 'Africa' },
    { name: 'British Indian Ocean Territory', code:'IO',  group: 'Africa' },
    { name: 'Burkina Faso', code:'BF',  group: 'Africa' },
    { name: 'Burundi', code:'BI',  group: 'Africa' },
    { name: 'Cameroon', code:'CM',  group: 'Africa' },
    { name: 'Cape Verde', code:'CV',  group: 'Africa' },
    { name: 'Central African Republic', code:'CF',  group: 'Africa' },
    { name: 'Chad', code:'TD',  group: 'Africa' },
    { name: 'Comoros', code:'KM',  group: 'Africa' },
    { name: 'Congo - Brazzaville', code:'CG',  group: 'Africa' },
    { name: 'Congo - Kinshasa', code:'CD',  group: 'Africa' },
    { name: 'Côte d’Ivoire', code:'CI',  group: 'Africa' },
    { name: 'Djibouti', code:'DJ',  group: 'Africa' },
    { name: 'Egypt', code:'EG',  group: 'Africa' },
    { name: 'Equatorial Guinea', code:'GQ',  group: 'Africa' },
    { name: 'Eritrea', code:'ER',  group: 'Africa' },
    { name: 'Eswatini', code:'SZ',  group: 'Africa' },
    { name: 'Ethiopia', code:'ET',  group: 'Africa' },
    { name: 'French Southern Territories', code:'TF',  group: 'Africa' },
    { name: 'Gabon', code:'GA',  group: 'Africa' },
    { name: 'Gambia', code:'GM',  group: 'Africa' },
    { name: 'Ghana', code:'GH',  group: 'Africa' },
    { name: 'Guinea', code:'GN',  group: 'Africa' },
    { name: 'Guinea-Bissau', code:'GW',  group: 'Africa' },
    { name: 'Kenya', code:'KE',  group: 'Africa' },
    { name: 'Lesotho', code:'LS',  group: 'Africa' },
    { name: 'Liberia', code:'LR',  group: 'Africa' },
    { name: 'Libya', code:'LY',  group: 'Africa' },
    { name: 'Madagascar', code:'MG',  group: 'Africa' },
    { name: 'Malawi', code:'MW',  group: 'Africa' },
    { name: 'Mali', code:'ML',  group: 'Africa' },
    { name: 'Mauritania', code:'MR',  group: 'Africa' },
    { name: 'Mauritius', code:'MU',  group: 'Africa' },
    { name: 'Mayotte', code:'YT',  group: 'Africa' },
    { name: 'Morocco', code:'MA',  group: 'Africa' },
    { name: 'Mozambique', code:'MZ',  group: 'Africa' },
    { name: 'Namibia', code:'NA',  group: 'Africa' },
    { name: 'Niger', code:'NE',  group: 'Africa' },
    { name: 'Nigeria', code:'NG',  group: 'Africa' },
    { name: 'Réunion', code:'RE',  group: 'Africa' },
    { name: 'Rwanda', code:'RW',  group: 'Africa' },
    { name: 'São Tomé & Príncipe', code:'ST',  group: 'Africa' },
    { name: 'Senegal', code:'SN',  group: 'Africa' },
    { name: 'Seychelles', code:'SC',  group: 'Africa' },
    { name: 'Sierra Leone', code:'SL',  group: 'Africa' },
    { name: 'Somalia', code:'SO',  group: 'Africa' },
    { name: 'South Africa', code:'ZA',  group: 'Africa' },
    { name: 'South Sudan', code:'SS',  group: 'Africa' },
    { name: 'St. Helena', code:'SH',  group: 'Africa' },
    { name: 'Sudan', code:'SD',  group: 'Africa' },
    { name: 'Tanzania', code:'TZ',  group: 'Africa' },
    { name: 'Togo', code:'TG',  group: 'Africa' },
    { name: 'Tristan da Cunha', code:'TA',  group: 'Africa' },
    { name: 'Tunisia', code:'TN',  group: 'Africa' },
    { name: 'Uganda', code:'UG',  group: 'Africa' },
    { name: 'Western Sahara', code:'EH',  group: 'Africa' },
    { name: 'Zambia', code:'ZM',  group: 'Africa' },
    { name: 'Zimbabwe', code:'ZW',  group: 'Africa' },
    { name: 'Afghanistan', code:'AF',  group: 'Asia' },
    { name: 'Armenia', code:'AM',  group: 'Asia' },
    { name: 'Azerbaijan', code:'AZ',  group: 'Asia' },
    { name: 'Bahrain', code:'BH',  group: 'Asia' },
    { name: 'Bangladesh', code:'BD',  group: 'Asia' },
    { name: 'Bhutan', code:'BT',  group: 'Asia' },
    { name: 'Brunei', code:'BN',  group: 'Asia' },
    { name: 'Cambodia', code:'KH',  group: 'Asia' },
    { name: 'China', code:'CN',  group: 'Asia' },
    { name: 'Cyprus', code:'CY',  group: 'Asia' },
    { name: 'Georgia', code:'GE',  group: 'Asia' },
    { name: 'Hong Kong SAR China', code:'HK',  group: 'Asia' },
    { name: 'India', code:'IN',  group: 'Asia' },
    { name: 'Indonesia', code:'ID',  group: 'Asia' },
    { name: 'Iraq', code:'IQ',  group: 'Asia' },
    { name: 'Israel', code:'IL',  group: 'Asia' },
    { name: 'Japan', code:'JP',  group: 'Asia' },
    { name: 'Jordan', code:'JO',  group: 'Asia' },
    { name: 'Kazakhstan', code:'KZ',  group: 'Asia' },
    { name: 'Kuwait', code:'KW',  group: 'Asia' },
    { name: 'Kyrgyzstan', code:'KG',  group: 'Asia' },
    { name: 'Laos', code:'LA',  group: 'Asia' },
    { name: 'Lebanon', code:'LB',  group: 'Asia' },
    { name: 'Macau SAR China', code:'MO',  group: 'Asia' },
    { name: 'Malaysia', code:'MY',  group: 'Asia' },
    { name: 'Maldives', code:'MV',  group: 'Asia' },
    { name: 'Mongolia', code:'MN',  group: 'Asia' },
    { name: 'Myanmar (Burma)', code:'MM',  group: 'Asia' },
    { name: 'Nepal', code:'NP',  group: 'Asia' },
    { name: 'Oman', code:'OM',  group: 'Asia' },
    { name: 'Pakistan', code:'PK',  group: 'Asia' },
    { name: 'Palestinian Territories', code:'PS',  group: 'Asia' },
    { name: 'Philippines', code:'PH',  group: 'Asia' },
    { name: 'Qatar', code:'QA',  group: 'Asia' },
    { name: 'Saudi Arabia', code:'SA',  group: 'Asia' },
    { name: 'Singapore', code:'SG',  group: 'Asia' },
    { name: 'South Korea', code:'KR',  group: 'Asia' },
    { name: 'Sri Lanka', code:'LK',  group: 'Asia' },
    { name: 'Taiwan', code:'TW',  group: 'Asia' },
    { name: 'Tajikistan', code:'TJ',  group: 'Asia' },
    { name: 'Thailand', code:'TH',  group: 'Asia' },
    { name: 'Timor-Leste', code:'TL',  group: 'Asia' },
    { name: 'Turkey', code:'TR',  group: 'Asia' },
    { name: 'Turkmenistan', code:'TM',  group: 'Asia' },
    { name: 'United Arab Emirates', code:'AE',  group: 'Asia' },
    { name: 'Uzbekistan', code:'UZ',  group: 'Asia' },
    { name: 'Vietnam', code:'VN',  group: 'Asia' },
    { name: 'Yemen', code:'YE',  group: 'Asia' },
    { name: 'Åland Islands', code:'AX',  group: 'Europe' },
    { name: 'Albania', code:'AL',  group: 'Europe' },
    { name: 'Andorra', code:'AD',  group: 'Europe' },
    { name: 'Austria', code:'AT',  group: 'Europe' },
    { name: 'Belarus', code:'BY',  group: 'Europe' },
    { name: 'Belgium', code:'BE',  group: 'Europe' },
    { name: 'Bosnia & Herzegovina', code:'BA',  group: 'Europe' },
    { name: 'Bulgaria', code:'BG',  group: 'Europe' },
    { name: 'Croatia', code:'HR',  group: 'Europe' },
    { name: 'Czech Republic', code:'CZ',  group: 'Europe' },
    { name: 'Denmark', code:'DK',  group: 'Europe' },
    { name: 'Estonia', code:'EE',  group: 'Europe' },
    { name: 'Faroe Islands', code:'FO',  group: 'Europe' },
    { name: 'Finland', code:'FI',  group: 'Europe' },
    { name: 'France', code:'FR',  group: 'Europe' },
    { name: 'Germany', code:'DE',  group: 'Europe' },
    { name: 'Gibraltar', code:'GI',  group: 'Europe' },
    { name: 'Greece', code:'GR',  group: 'Europe' },
    { name: 'Guernsey', code:'GG',  group: 'Europe' },
    { name: 'Hungary', code:'HU',  group: 'Europe' },
    { name: 'Iceland', code:'IS',  group: 'Europe' },
    { name: 'Ireland', code:'IE',  group: 'Europe' },
    { name: 'Isle of Man', code:'IM',  group: 'Europe' },
    { name: 'Italy', code:'IT',  group: 'Europe' },
    { name: 'Jersey', code:'JE',  group: 'Europe' },
    { name: 'Kosovo', code:'XK',  group: 'Europe' },
    { name: 'Latvia', code:'LV',  group: 'Europe' },
    { name: 'Liechtenstein', code:'LI',  group: 'Europe' },
    { name: 'Lithuania', code:'LT',  group: 'Europe' },
    { name: 'Luxembourg', code:'LU',  group: 'Europe' },
    { name: 'Macedonia', code:'MK',  group: 'Europe' },
    { name: 'Malta', code:'MT',  group: 'Europe' },
    { name: 'Moldova', code:'MD',  group: 'Europe' },
    { name: 'Monaco', code:'MC',  group: 'Europe' },
    { name: 'Montenegro', code:'ME',  group: 'Europe' },
    { name: 'Netherlands', code:'NL',  group: 'Europe' },
    { name: 'Norway', code:'NO',  group: 'Europe' },
    { name: 'Poland', code:'PL',  group: 'Europe' },
    { name: 'Portugal', code:'PT',  group: 'Europe' },
    { name: 'Romania', code:'RO',  group: 'Europe' },
    { name: 'Russia', code:'RU',  group: 'Europe' },
    { name: 'San Marino', code:'SM',  group: 'Europe' },
    { name: 'Serbia', code:'RS',  group: 'Europe' },
    { name: 'Slovakia', code:'SK',  group: 'Europe' },
    { name: 'Slovenia', code:'SI',  group: 'Europe' },
    { name: 'Spain', code:'ES',  group: 'Europe' },
    { name: 'Svalbard & Jan Mayen', code:'SJ',  group: 'Europe' },
    { name: 'Sweden', code:'SE',  group: 'Europe' },
    { name: 'Switzerland', code:'CH',  group: 'Europe' },
    { name: 'Ukraine', code:'UA',  group: 'Europe' },
    { name: 'United Kingdom', code:'GB',  group: 'Europe' },
    { name: 'Vatican City', code:'VA',  group: 'Europe' },
    { name: 'Anguilla', code:'AI',  group: 'North America' },
    { name: 'Antigua & Barbuda', code:'AG',  group: 'North America' },
    { name: 'Aruba', code:'AW',  group: 'North America' },
    { name: 'Bahamas', code:'BS',  group: 'North America' },
    { name: 'Barbados', code:'BB',  group: 'North America' },
    { name: 'Belize', code:'BZ',  group: 'North America' },
    { name: 'Bermuda', code:'BM',  group: 'North America' },
    { name: 'British Virgin Islands', code:'VG',  group: 'North America' },
    { name: 'Canada', code:'CA',  group: 'North America' },
    { name: 'Caribbean Netherlands', code:'BQ',  group: 'North America' },
    { name: 'Cayman Islands', code:'KY',  group: 'North America' },
    { name: 'Costa Rica', code:'CR',  group: 'North America' },
    { name: 'Curaçao', code:'CW',  group: 'North America' },
    { name: 'Dominica', code:'DM',  group: 'North America' },
    { name: 'Dominican Republic', code:'DO',  group: 'North America' },
    { name: 'El Salvador', code:'SV',  group: 'North America' },
    { name: 'Greenland', code:'GL',  group: 'North America' },
    { name: 'Grenada', code:'GD',  group: 'North America' },
    { name: 'Guadeloupe', code:'GP',  group: 'North America' },
    { name: 'Guatemala', code:'GT',  group: 'North America' },
    { name: 'Haiti', code:'HT',  group: 'North America' },
    { name: 'Honduras', code:'HN',  group: 'North America' },
    { name: 'Jamaica', code:'JM',  group: 'North America' },
    { name: 'Martinique', code:'MQ',  group: 'North America' },
    { name: 'Mexico', code:'MX',  group: 'North America' },
    { name: 'Montserrat', code:'MS',  group: 'North America' },
    { name: 'Nicaragua', code:'NI',  group: 'North America' },
    { name: 'Panama', code:'PA',  group: 'North America' },
    { name: 'Saint Martin', code:'MF',  group: 'North America' },
    { name: 'St. Barthélemy', code:'BL',  group: 'North America' },
    { name: 'St. Kitts & Nevis', code:'KN',  group: 'North America' },
    { name: 'St. Lucia', code:'LC',  group: 'North America' },
    { name: 'St. Martin', code:'MF',  group: 'North America' },
    { name: 'St. Pierre & Miquelon', code:'PM',  group: 'North America' },
    { name: 'St. Vincent & Grenadines', code:'VC',  group: 'North America' },
    { name: 'Trinidad & Tobago', code:'TT',  group: 'North America' },
    { name: 'Turks & Caicos Islands', code:'TC',  group: 'North America' },
    { name: 'United States', code:'US',  group: 'North America' },
    { name: 'Australia', code:'AU',  group: 'North America' },
    { name: 'Christmas Island', code:'CX',  group: 'Oceania' },
    { name: 'Cocos (Keeling) Islands', code:'CC',  group: 'Oceania' },
    { name: 'Cook Islands', code:'CK',  group: 'Oceania' },
    { name: 'Fiji', code:'FJ',  group: 'Oceania' },
    { name: 'French Polynesia', code:'PF',  group: 'Oceania' },
    { name: 'Kiribati', code:'KI',  group: 'Oceania' },
    { name: 'Nauru', code:'NR',  group: 'Oceania' },
    { name: 'New Caledonia', code:'NC',  group: 'Oceania' },
    { name: 'New Zealand', code:'NZ',  group: 'Oceania' },
    { name: 'Niue', code:'NU',  group: 'Oceania' },
    { name: 'Norfolk Island', code:'NF',  group: 'Oceania' },
    { name: 'Papua New Guinea', code:'PG',  group: 'Oceania' },
    { name: 'Pitcairn Islands', code:'PN',  group: 'Oceania' },
    { name: 'Samoa', code:'WS',  group: 'Oceania' },
    { name: 'Solomon Islands', code:'SB',  group: 'Oceania' },
    { name: 'Tokelau', code:'TK',  group: 'Oceania' },
    { name: 'Tonga', code:'TO',  group: 'Oceania' },
    { name: 'Tuvalu', code:'TV',  group: 'Oceania' },
    { name: 'U.S. Outlying Islands', code:'UM',  group: 'Oceania' },
    { name: 'Vanuatu', code:'VU',  group: 'Oceania' },
    { name: 'Wallis & Futuna', code:'WF',  group: 'Oceania' },
    { name: 'Argentina', code:'AR',  group: 'Oceania' },
    { name: 'Bolivia', code:'BO',  group: 'South America' },
    { name: 'Brazil', code:'BR',  group: 'South America' },
    { name: 'Chile', code:'CL',  group: 'South America' },
    { name: 'Colombia', code:'CO',  group: 'South America' },
    { name: 'Ecuador', code:'EC',  group: 'South America' },
    { name: 'Falkland Islands', code:'FK',  group: 'South America' },
    { name: 'French Guiana', code:'GF',  group: 'South America' },
    { name: 'Guyana', code:'GY',  group: 'South America' },
    { name: 'Paraguay', code:'PY',  group: 'South America' },
    { name: 'Peru', code:'PE',  group: 'South America' },
    { name: 'South Georgia & South Sandwich Islands', code:'GS',  group: 'South America' },
    { name: 'Suriname', code:'SR',  group: 'South America' },
    { name: 'Uruguay', code:'UY',  group: 'South America' },
    { name: 'Venezuela', code:'VE',  group: 'South America' }
]

export const SHIPIFY_USER_MENU_BORDER_RADIUS_PX = 5

export const TERMS_OF_USE_URL = 'https://www.shipifyapp.io/terms-of-use'
export const CONTACT_US_URL = 'https://www.shipifyapp.io/?contact=open'
export const PRIVACY_POLICY_URL = 'https://www.shipifyapp.io/privacy-policy'

export const CALENDLY_MEETING_LINK = 'https://calendly.com/shipifyapp-sales/demo-with-shipify'
export const CHROME_EXTENSION_LINK = 'https://chromewebstore.google.com/detail/shipify-shipping-per-prod/fleobepdenpapbibddejogfphomihjkc'

export const PAYDIN_MENU_TRANSITION_DURATION_MILLISECONDS = 300

export const SIDEBAR_MODES = {
    OPEN: 'OPEN',
    MINIMIZED: 'MINIMIZED'
}